import React, {useEffect,useState,useContext} from "react";
import InputFile from "../InputFile";
import {useTranslate} from 'react-polyglot';
import CustomMonoriel from "../../services/CustomMonoriel";
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import "./editAR.scss"
import AlertDialog from '../general/AlertDialog';
import ExperienceService from "../../services/Experience";
import StorageService from "../../services/Storage";
export default function ChangeLoading (props){
    const t = useTranslate();
    const [imageURL, setImageURL] = useState(null)
    const [file, setFile] = useState (null)
    const [hasLoading,setHasLoading] = useState (false)
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })

    useEffect(()=> {
        StorageService.getExtras(props.data.publicAlias)
        .then((rta) => {
            if(rta.hasLoadingImg){
                setHasLoading(rta.hasLoadingImg)
                StorageService.getLoadImage(props.data.publicAlias)
                .then((rta) => {
                    setImageURL(rta)  
                })
            }
        })
    },[])

    async function changeLoading() {
        try {
            setShowLoading(true)
            const params = {
                hasLoadingImg: file ? true : false,
                publicAlias: props.data.publicAlias
            }
            if(file){
                await StorageService.uploadLoadImage(file,props.data.publicAlias)
            }
            await ExperienceService.ChangeLoadingImage(props.data.experienceId,params)
            .then(rta => {
               setShowLoading(false)
               setOpen({
                title: t('experiences.success'),
                subtitle: t('experiences.successBody'),
                isOpen: true
               })
            }) 
        } catch (error) {
            setShowLoading(false)
            throw new Error(error);
        } 
    }

    return (
        <div className="edit-container">
            <div style={{width:"100%", marginBottom:"20px", marginTop:"20px"}}>
            </div>
            <InputFile
                defaultFile={imageURL} 
                handleFile={(file) => setFile(file)} 
                handleURL={(url) => setImageURL(url)} 
                handleQuitFile={(file) => setFile(null)}>
            </InputFile>            
            <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                <DialogButton 
                    action="close">{t('actions.cancel')}
                </DialogButton>
                <DialogButton
                    action="accept" 
                    isDefaultAction 
                    onClick={() => {changeLoading()}}>{t('actions.accept')}
                </DialogButton>
            </DialogActions>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                buttonMessage='OK'
            />  
        </div>
    )
}