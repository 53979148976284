const BASE_ERROR = 'pages.signUp.errors';
export const ERROR_MAP = [
    {
        msg: 'Member must have length greater than or equal to 6',
        key: `${BASE_ERROR}.passwordLength`
    },
    {
        msg: 'passwordNotMatch',
        key: `${BASE_ERROR}.passwordNotMatch`
    },
    {
        msg: 'An account with the given email already exists.',
        key: `${BASE_ERROR}.accountExist`
    },
    {
        msg: 'Password does not conform to policy: Password must have symbol characters',
        key: `${BASE_ERROR}.characters`
    },
    {
        msg: 'Password did not conform with policy: Password must have uppercase characters',
        key: `${BASE_ERROR}.passwordMayus`
    },
    {
        msg: 'Password did not conform with policy: Password must have lowercase characters',
        key: `${BASE_ERROR}.passwordMinus`
    },
    {
        msg: 'Password did not conform with policy: Password not long enough',
        key: `${BASE_ERROR}.passwordLength`
    },
    {
        msg: 'Password did not conform with policy: Password must have symbol characters',
        key: `${BASE_ERROR}.passwordSymbol`
    },
    {
        msg: 'Incorrect username or password.',
        key: `${BASE_ERROR}.currentPasswordInvalid`
    },
    {
        msg: 'Attempt limit exceeded, please try after some time.',
        key: `${BASE_ERROR}.limitReached`
    }
];