import React, { useEffect, useState } from 'react';

import Creatable from 'react-select/creatable'
import CustomOption from './customOption';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import './dropInput.scss';
import { useTranslate } from 'react-polyglot';


function DropInput(props) {
    const [displaySurface, setDisplaySurface] = useState(false);
    const [typedValue, setTypedValue] = useState('');
    const [value, setValue] = useState('');
    const [id, setId] = useState(null);

    const t = useTranslate();

    useEffect(() => {
        if (props.project) {
            addToProject.apply(props.project)
        }
    }, [])

    function onChange(e) {

        const { name, value } = e.target;
        setValue(value)
        setTypedValue(value)
        props.onChangeFn({
            name: value,
            id
        })

    }
    function addToProject(e) {
        if (e) e.persist();

        const { name } = this;
        setValue(name);
        setId(name);
        props.onChangeFn({
            name,
            id
        })
    }
    function check(e) {


        const project = props.data[e.detail];
        setValue(project.name);
        setId(project.name);
        props.onChangeFn({
            name: project.name,
            id
        })

    }
    function setOther() {
        const project = this;
        setValue(project.name);
        setId(project.name);
        props.onChangeFn({
            name: project.name,
            id
        })

    }
    function optionsAdapter(data) {
        return data.map(project => {
            return {
                value: project.id,
                label: project.name
            }
        })
    }
    function change(val) {
        if (val.__isNew__) {
            props.onChangeFn({
                name: val.label,
            })
        } else {
            props.onChangeFn({
                name: val.label,
                id: val.value
            })
        }

    }
    return (
        <Creatable {...props.creatableProps}
         options={optionsAdapter(props.data)} onChange={change} className="drop-select-container" classNamePrefix="drop-select" placeholder={t("experiences.newExp.campaignSelectOrCreate")} />
    )
}

export default DropInput;