import request from '../utils/request';
import { parseParams } from '../utils/url';

class CompanyService {
    static async get(paginate = false, page = 1, perPage = 5, paramsQuery) {
        const baseUrl = `${process.env.REACT_APP_KWM_ENDPOINT}/companies`;
        if (paginate) {
            return request.get(`${baseUrl}?page=${page}&per_page=${perPage}&${parseParams(paramsQuery)}`);
        } else {
            if (paramsQuery) {
                return request.get(`${baseUrl}?${parseParams(paramsQuery)}`);
            }
            return request.get(baseUrl);
        }
    }
    static async signUp(params) {
        return request.post(`${process.env.REACT_APP_KWM_ENDPOINT}/signUp`, {
            body: params
        }, false);
    }
    static async addCompany(params) {
        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/companies`, {
            body: params
        });
    }
    static async updateCompany(params, companyId) {
        return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyId}`, {
            body: params
        });
    }
    static async deleteCompany(companyId) {
        return request.delete(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyId}`);
    }

    static async getCredentials() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/credentials`);
    }
}
export default CompanyService;
