import React, { memo, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  DataTable,
  DataTableHead,
  DataTableContent,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
} from '@rmwc/data-table';
import { Button } from '@rmwc/button';
import { useTranslate } from 'react-polyglot';
import Icon from '../../components/icon';
import StudentForm from '../../components/actions/StudentForm';
import UserService from '../../services/User';
import StudentService from '../../services/Student';
import Notification from '../../components/notification/notification';
import Search from '../../components/common/search';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import './user.scss';

const DialogDisableDelete = ({ data: { msg } }) => {
  const t = useTranslate();
  return (
    <div>
      <p>{msg}</p>
      <p>{t('common.msg.doYouContinue')}</p>
    </div>
  )
};

const ITEMS_PER_PAGE = 5;

const Student = memo(function Educational({ setModal, user }) {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState('');
  const [{ showNotification, propsNotification }, setNotification] = useReducer(
    (prevState, currState) => ({ ...prevState, ...currState }),
    {
      showNotification: false,
      propsNotification: {},
    }
  );
  const t = useTranslate();

  useEffect(() => {
    StudentService.get(true).then((res) => {
      setUsers(res.data);
      setResponse(res);
    });
  }, []);

  async function saveUser(params) {
    try {
      await UserService.addUser(params, 1);
      getDataPaginated(page);
      setNotification({
        showNotification: true,
        propsNotification: {label: t('common.msg.success'), message: t('pages.account.addMsg')}
      })
    } catch (error) {
      setNotification({
        showNotification: true,
        propsNotification: { label: 'Error', message: error.message },
      });
    }
  }
  async function updateUser(params) {
    try {
      params.user_type = params.userType;
      await UserService.updateUser(params, 1, this.componentData.id);
      getDataPaginated(page);
    } catch (error) {
      setNotification({
        showNotification: true,
        propsNotification: { label: 'Error', message: error.message },
      });
    }
  }
  async function deleteUser() {
    UserService.deleteAccount(this.componentData.userId)
      .then(() => {
        getDataPaginated(page);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function openAddUser() {
    const actionsDialogData = {
      title: t('pages.account.student.modal.add.title'),
      componentData: { user_type_id: 3, me: user },
      component: StudentForm,
      componentKey: 99,
      action: saveUser,
      buttonLess: true
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  function editUser() {
    const actionsDialogData = {
      title: t('pages.account.student.modal.edit.title'),
      componentData: this,
      component: StudentForm,
      componentKey: this.id,
      action: updateUser,
      buttonLess: true
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  function openDeleteUser(userId) {
    const actionsDialogData = {
      title: t('pages.account.student.modal.delete.title'),
      componentData: {
        userId,
        msg: t('pages.account.student.modal.delete.msg'),
      },
      component: DialogDisableDelete,
      componentKey: userId,
      action: deleteUser,
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  async function getDataPaginated(pageToGet) {
    return StudentService.get(true, pageToGet, ITEMS_PER_PAGE,
      { name: search, surname: search, email: search }).then((res) => {
      setUsers(res.data);
      setResponse(res);
    });
  }
  function goToCampaigns(id) {}
  function getLastPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
      getDataPaginated(page - 1);
    }
  }
  function getNextPage() {
    if (response.total / 5 > page) {
      setPage(page + 1);
      getDataPaginated(page + 1);
    }
  }
  function closeNotification() {
    setNotification({ showNotification: false });
  }
  function searchStudent(value) {
    setSearch(value);
    StudentService.get(true, 1, ITEMS_PER_PAGE, { name: value, surname: value, email: value })
        .then(res => {
            setUsers(res.data);
            setResponse(res)
        })
  }
  return (
    <section className="profile-user-container">
      <div className="ẗable-profile-actions-header">
        <Search func={searchStudent} />
        <Button
          raised
          label={t('pages.account.student.modal.add.title')}
          className="rounded-button"
          onClick={openAddUser}
        />
      </div>
      <DataTable className={'paddingTables'}>
        <DataTableContent>
          <DataTableHead className="list-table-head">
            <DataTableRow>
              <DataTableHeadCell alignMiddle>
                {t('common.labels.nameSurname')}
              </DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('common.labels.email')}</DataTableHeadCell>

              <DataTableHeadCell alignMiddle>{t('pages.account.student.headTable.classroom')}</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('common.labels.actions')}</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {users.length > 0 &&
              users.map((user, i) => {
                return (
                  <DataTableRow key={i}>
                    <DataTableCell alignMiddle>
                      {`${
                        user.name === null
                          ? '-'
                          : user.name + ' ' + user.surname
                      }`}{' '}
                    </DataTableCell>
                    <DataTableCell alignMiddle>{user.email}</DataTableCell>
                    <DataTableCell alignMiddle>
                      {user.classroom.name}
                    </DataTableCell>

                    <DataTableCell className="users-actions">
                      <Icon
                        name={'edit'}
                        alignMiddle
                        onClickFn={editUser.bind(user)}
                      />{' '}
                      <Icon
                        name={'campaings'}
                        onClickFn={goToCampaigns(user.id)}
                      />
                      <Icon
                        name={'delete-bin'}
                        alignMiddle
                        onClickFn={() => openDeleteUser(user.id)}
                      />
                    </DataTableCell>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
      <div className="table-pagination">
        
        <div className="table-pagination-element first">
        {page > 1 && (
          <span className="table-pagination-link" onClick={getLastPage}>
            {t('pages.account.pagination.prev')}
          </span>
        )}
        </div>
        {response.total / ITEMS_PER_PAGE > page && (
          <div className="table-pagination-element second">
            <span className="table-pagination-link" onClick={getNextPage}>
              {t('pages.account.pagination.next')}
            </span>
          </div>
        )}
      </div>
      {showNotification && (
        <Notification {...propsNotification} onClose={closeNotification} />
      )}
    </section>
  );
});

Student.propTypes = {};

export default Student;
