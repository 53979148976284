import React, { memo } from 'react'
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

const Sure = memo(function Sure(props) {
    const t = useTranslate();
    return (
        <div>
            {t('pages.account.sureMsg')}
        </div>
    )
})

Sure.propTypes = {

}

export default Sure
