import { Auth } from 'aws-amplify';

export const refreshToken = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    return new Promise((resolve, reject) => {
        cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
            if (err) reject(err);
            resolve(session);
        });
    });
}

export default async () => {
    const session = await Auth.currentSession();
    const accessToken = await session.getAccessToken();
    return await accessToken.getJwtToken();
}
