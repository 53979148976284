import React from 'react';

const ExperienceTitle = ({title}) => {
    return (
        <div className={'new-experience-title-container'}>
            <h2 className={'new-experience-title'}>{title}</h2>
        </div>
    );
};

export default ExperienceTitle;