import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'

const customOption = memo(function customOption(props) {
    const {
        children,
        className,
        cx,
        getStyles,
        isDisabled,
        isFocused,
        isSelected,
        innerRef,
        innerProps,
    } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (
        <div css={getStyles('option', props)}
            className={cx(
                {
                    option: true,
                    'option--is-disabled': isDisabled,
                    'option--is-focused': isFocused,
                    'option--is-selected': isSelected,
                },
                className
            )}
            ref={innerRef}
            {...innerProps}
        >
            {children}
        </div>
    )
})


export default customOption
