import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { useTranslate } from 'react-polyglot';

import SelectControl from '../common/select';
import CommonForm from './CommonForm';
import CompanyService from '../../services/Companies';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/button/dist/mdc.button.css';

const CompanyForm = memo(function CompanyForm(props) {
  const [credentials, setCredentials] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const t = useTranslate();

  useEffect(() => {
    CompanyService.getCredentials().then((res) => {
      if (!props.data.credentialId) {
        setInitialValues({
          ...initialValues,
          vuforia_account_id: res[0].id.toString(),
          company_type_id: props.data.companyType.id,
        })
      }
      setCredentials(res.map((x) => ({ label: x.name, value: x.id })));
    });
    if (props.data.id) {
      const company = props.data;
      setInitialValues({
        ...company,
        vuforia_account_id: company.credentialId.toString(),
        company_type_id: company.companyType.id.toString(),
        can_see_reports: company.canSeeReports,
        can_publish: company.canPublish,
      });
    } else {
      setInitialValues({
        company_type_id: props.data.companyType.id,
        vuforia_account_id: '1',
        can_see_reports: false,
        can_publish: false
      });
    }
  }, []);

  return (
    <CommonForm
      initialValues={initialValues}
      onBlur={props.onChange}
      validate={{}}
      funcSubmit={props.submitHandler}
    >
      {({ handleBlur, handleChange, handleSubmit, errors, values }) => (
        <>
          <form className="add-user-form" onSubmit={handleSubmit}>
            <SelectControl
              label={t('pages.account.companies.form.credentials')}
              name="vuforia_account_id"
              id="credential"
              className="fullwidth form-item"
              value={values.vuforia_account_id}
              options={credentials}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              outlined
              name="name"
              label={t('common.labels.name')}
              className="fullwidth form-item"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              invalid={errors['name']}
            />
            <Checkbox
              name="can_see_reports"
              label={t('pages.account.companies.headTable.reports')}
              checked={values.can_see_reports}
              className={'form-item'}
              onChange={handleChange}
            />
            <Checkbox
              name="can_publish"
              label={t('pages.account.companies.headTable.canPublish')}
              checked={values.can_publish}
              className={'form-item'}
              onChange={handleChange}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button label={t('actions.add')} type="submit"></Button>
              <Button
                label={t('actions.cancel')}
                onClick={(e) => props.setOpen(false)}
              ></Button>
            </div>
          </form>
        </>
      )}
    </CommonForm>
  );
});

CompanyForm.propTypes = {};

export default CompanyForm;
