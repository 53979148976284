import React, {useEffect} from 'react';
import {Auth} from "aws-amplify";
import {deleteCookie} from "../../utils/cookie";

const Logout = () => {

    useEffect( () => {
        const logout = async () => {
            await Auth.signOut();
            deleteCookie();
            window.location.href = '/';
        };
        logout();
    }, []);
    return (
        <div>
            
        </div>
    );
};

export default Logout;