import React from 'react';

import './icon.scss';
function Icon({ name, color, className, onClickFn }) {
    return (
        <div className={`icon-${name} ${className} topMarginIcon`} onClick={onClickFn}></div>
    )
}

export default Icon;
