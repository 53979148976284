import jwt from './jwt';
import refresh from './refresh';

export default function setCookie() {
    refresh()
        .then((token) => {
            document.cookie = `refresh_token =; domain = ${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
            document.cookie = `refresh_token=${token}; domain=${process.env.REACT_APP_APP_DOMAIN};`;
        })

}

export function deleteCookie() {
    document.cookie = `refresh_token=; domain=${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    document.cookie = `token=; domain=${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}


export function saveCookie(cookieName, cookieValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    const cookieString = `${cookieName}=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
};

export function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
};