import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    FlexboxGrid,
    Checkbox,
    SelectPicker,
    InputGroup,
    Icon, Whisper, Popover, AutoComplete

} from "rsuite";
import {useTranslate} from "react-polyglot";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import campaignService from "../../services/Campaign";
import SearchAddProject from '../searchProject/SearchProject';
import amplitude from 'amplitude-js';

/* Eliminando la libreria RMWC */
import SausageButton from '../coa/SausageButton';

const ExperienceForm = ({
                            actualStep, experienceRoad, projects,
                            project,
                            data,
                            setData,
                            fileValue,
                            setFileValue,
                            inputFileValue,
                            setInputFileValue,
                            filePath,
                            setFilePath,
                            handleCreateExperience,
                            setDialogOptions,
                            setOpenDialogAlert,
                            isEditor
                        }) => {
    const [showOfflineExpForm, setShowOfflineExpForm] = useState(false);
    const [, setDataError] = useState(false);
    const [, setValidDataMessage] = useState('');
    const [enabledCss, setEnabledCss] = useState('newExpButton-notValid');
    const [showForOtherUserForm, setShowForOtherUserForm] = useState(false);
    const [, setCampaignValue] = useState(null);
    const [disabledProject, setDisabledProject] = useState(false);
    const [savedCampaign, setSavedCampaign] = useState();
    const t = useTranslate();
    const [ validForm, setValidForm ] = useState(false);

    const hasTarget = () => {
        if (experienceRoad.experience_type === '3') {
            return true
        }
        if (experienceRoad.experience_type === '2' || experienceRoad.experience_type === '2s2' || experienceRoad.experience_type === '2s3') {
            return experienceRoad.world_tracking_type === 'app' && experienceRoad.world_tracking_based === 'marker-based'
        }
        if (experienceRoad.experience_type === '1' || experienceRoad.experience_type === '1s2') {
            return experienceRoad.world_tracking_type === 'web' || (experienceRoad.world_tracking_type === 'app' && experienceRoad.world_tracking_based === 'marker-based')
        }
    };

    const {
        users,
        onSearch, setSelectedUser, findUser,
        setCampaignId,
        userId,
        setUserId, selectedUser,
        setProjectsSearch,
        projectsSearch,
        setUsers,
    } = useContext(MigrateUserDialogContext);

    useEffect(() => {
        if (selectedUser) {
            setCampaignId(null);
            let user = findUser();
            setUserId(user.id);
            setData((lastData) => ({
                ...lastData, ...{
                    user_id: user.id,
                }
            }));
        } else {
            setUserId('');
            setCampaignId(null);
            setProjectsSearch([]);
            setData((lastData) => ({
                ...lastData, ...{
                    user_id: '',
                    user_project_id: ''
                }
            }));
        }
    }, [selectedUser]);

    useEffect(() => {
        if (userId) {
            const getProjects = async () => {
                let response = await campaignService.listWithoutExperiences(userId);
                let dataFormatted = response.data.map(e => ({
                    "label": e.name,
                    "value": e.name,
                    "data": e
                }));
                setProjectsSearch(dataFormatted)
            };
            getProjects();
        } else {
            setSelectedUser('');
            setProjectsSearch([]);
            setCampaignId(null);
            setCampaignValue(null);
            setData((lastData) => ({
                ...lastData, ...{
                    user_project_id: '',
                    user_id: ''
                }
            }))
        }
    }, [userId]);

    const triggerFile = (e) => {
        e.preventDefault();
        document.getElementById('inputFile').click();
    };

    const getFilePath = (file) => {
        let validFileTypes = ['JPEG', 'jpeg', 'jpg', 'JPG'];
        let fileExtension = file.files[0] ? file.files[0].name.split('.').pop() : '';
        window.URL = window.URL || window.webkitURL;
        if (file.files[0] && validFileTypes.includes(fileExtension)) {
            const size = file.files[0].size;
            const url = URL.createObjectURL(file.files[0]);

            if (Math.round(((size / 1024) / 1024)) >= 10) {
                // TODO: Api Gateway no soporta archivos mayores a 10mb validar que hacer con eso
            }

            if (file.files[0].size >= 5000000) {
                setDataError(true);
                setValidDataMessage(t('experiences.changeTarget.invalidSize'));
                setDialogOptions({bodyMsg: t('experiences.changeTarget.invalidSize')});
                setOpenDialogAlert(true);
                setFileValue(false);
            } else {
                setDataError(false);
                setValidDataMessage('');
                setFilePath(url);
            }
        } else {
            if (!validFileTypes.includes(fileExtension) && fileExtension !== '') {
                setDataError(true);
                setValidDataMessage(t('experiences.changeTarget.validFormat'));
                setDialogOptions({bodyMsg: t('experiences.changeTarget.validFormat')});
                setOpenDialogAlert(true);
                setFileValue(false);
            }
        }

    };

    const validData = (data) => {
        let valid = (data.name.trim() === '' || (!data.forOtherUser && data.campaign.name.trim() === '') || (hasTarget() && data.target.length === 0) ||
            (data.isOffline && (data.offlineName === '' || data.offlineTargetType === '')) ||
            (data.forOtherUser && (data.user_project_id === '' || data.user_id === '')));
            setValidForm(valid);
        return !valid
    };

    useEffect(() => {
        
        validData(data);

    }, [data]);

    const getParseDataOwnProject = (item) => {
        return {
            experiences: null,
            id: item.data ? item.data.id : '',
            name: item.data ? item.data.name : item.c,
            userId: item.data ? item.data.userId : ''
        }
    }

    const getParseDataExternalProject = (item) => {
        if (item.create) {
            return { name: item?item.value: '' };
        }
        return item.data.id
    };

    const setChangeProject = (field, c, item) => {
        if (!c) {
            setData((lastData) => ({
                ...lastData, ...{
                    [field]: {
                        name: ''
                    }
                }
            }));
        } else {
            amplitude.getInstance().logEvent('Proyecto nuevo Creado');

            setData((lastData) => ({
                ...lastData, ...{
                    [field]: item
                }
            }));
        }
    };

    const getPopover = (name) => {
        return <Popover style={{maxWidth: '350px', textAlign:"left"}} className={'popoverBackground'}>
            <p>{name}</p>
        </Popover>
    };

    const getForm = () => {
        return <Fragment>
            <FlexboxGrid justify='center'>
                <FlexboxGrid.Item colspan={12} style={{width: '480px', marginLeft:'33px'}} className={'paddingTopFlexExp'}>
                    <Form fluid>
                        <input type="hidden" name="campaign[name]"/>
                        <input type="hidden" name="campaign[id]"/>
                        <input type="hidden" name="type"/>
                        <FormGroup>
                            <ControlLabel
                                className={'boldText textFormExperience'}>{t('experienceNewFlow.nameOfExperience')}</ControlLabel>
                            <div style={{'display': 'flex'}}>
                                <InputGroup style={{
                                    width: '93%',
                                    height: '40px',
                                }} className={'grayHoverInputSearch'}>
                                    <InputGroup.Addon>
                                        <Icon icon="file"/>
                                    </InputGroup.Addon>
                                    <FormControl name="name" onChange={(e) => {
                                        setData({...data, ...{name: e}});
                                    }}/>
                                </InputGroup>
                                <div>
                                    <Whisper placement="right" trigger="hover"
                                             speaker={getPopover(t('experiences.infoExpInput'))}>
                                        <img src="/images/information.svg"
                                             style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                             alt=""/>
                                    </Whisper>
                                </div>
                            </div>
                        </FormGroup>
                        <FormGroup style={{marginBottom: '10px'}}>
                            <ControlLabel className={'boldText'}>{t('experienceNewFlow.nameOfCollection')}</ControlLabel>
                            <InputGroup style={{width: '100%', border: 'none'}}>
                                <div className={'containerIconInput'}>
                                    <div className={'searchIconContainer'}>
                                        <Icon icon="folder"/>
                                    </div>
                                    <SearchAddProject
                                        dataSearch={projects}
                                        onClean={() => setChangeProject('campaign', null, { name: '' })}
                                        onSelect={(c, item) => setChangeProject('campaign', c, getParseDataOwnProject({...item, c}))}
                                        disabled={disabledProject}
                                    />
                                </div>
                                <div>
                                    <Whisper placement="right" trigger="hover"
                                                speaker={getPopover(t('experiences.infoCampaign'))}>
                                        <img
                                            src="/images/information.svg"
                                            style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                            alt=""
                                        />
                                    </Whisper>
                                </div>
                            </InputGroup>
                        </FormGroup>
                    </Form> {isEditor() ?
                    <Form style={{marginTop: '10px'}}>
                        <FormGroup style={{marginBottom: '10px'}}>
                            <Checkbox checked={showForOtherUserForm} onChange={(e, checked) => {
                                setDisabledProject(true);
                                setSavedCampaign(data.campaign);
                                setData({
                                    ...data, ...{
                                        forOtherUser: checked,
                                        campaign: {
                                            name: '',
                                            
                                        },
                                    }
                                });
                                
                                if (!checked) {
                                    setData({
                                        ...data, ...{
                                            user_project_id: '',
                                            user_id: '',
                                            forOtherUser: false,
                                            campaign: savedCampaign
                                        }
                                    });
                                    setProjectsSearch([]);
                                    setSelectedUser('');
                                    setUsers([]);
                                    setDisabledProject(false);
                                }
                                setShowForOtherUserForm(checked)
                            }}>{t('experienceNewFlow.forAnotherUser')}</Checkbox>
                        </FormGroup>
                        {showForOtherUserForm ?
                            <Fragment>
                                <ControlLabel className={'boldText'}>{t('common.labels.user')}</ControlLabel>
                                <FormGroup>
                                    <InputGroup style={{width: '100%'}}>
                                        <AutoComplete style={{width: 160}} data={users} onChange={onSearch}
                                                      onSelect={(e) => {
                                                          setSelectedUser(e.value);
                                                      }}/>
                                        <InputGroup.Button>
                                            <Icon icon="search"/>
                                        </InputGroup.Button>
                                    </InputGroup>
                                </FormGroup>
                                <ControlLabel className={'boldText'}>{t('common.labels.project')}</ControlLabel>
                                <FormGroup>
                                    <InputGroup style={{width: '100%', border: 'none'}}>
                                        <div className={'containerIconInput'}>
                                            <div className={'searchIconContainer'}>
                                                <Icon icon="folder"/>
                                            </div>
                                            <SearchAddProject
                                                dataSearch={projectsSearch}
                                                onClean={() => setChangeProject('user_project_id', null, '')}
                                                onSelect={(e, item) => setChangeProject(
                                                    'user_project_id', e, getParseDataExternalProject(item)
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <Whisper placement="right" trigger="hover"
                                                        speaker={getPopover(t('experiences.infoCampaign'))}>
                                                <img
                                                    src="/images/information.svg"
                                                    style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                                    alt=""
                                                />
                                            </Whisper>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            </Fragment>
                            : null}
                    </Form> : null}
                    {(isEditor() && hasTarget()) &&
                    <FormGroup>
                        <Checkbox checked={showOfflineExpForm} onChange={(e, checked) => {
                            setData({
                                ...data, ...{
                                    isOffline: checked
                                }
                            });
                            if (!checked) {
                                setData({
                                    ...data, ...{
                                        offlineName: '',
                                        offlineTargetType: '',
                                        isOffline: false
                                    }
                                });
                            }
                            setShowOfflineExpForm(checked)
                        }}> Experiencia Offline</Checkbox>
                    </FormGroup>
                    }
                    {isEditor() && hasTarget() ? <Form>
                        {showOfflineExpForm ? <Fragment>
                            <FormGroup>
                                <ControlLabel
                                    className={'boldText'}>{t('experienceNewFlow.offlineTargetType')}:</ControlLabel>
                                <SelectPicker onChange={(e) => {
                                    if (!e) {
                                        setData({
                                            ...data, ...{
                                                offlineTargetType: '',
                                            }
                                        });
                                    } else {
                                        setData({
                                            ...data, ...{
                                                offlineTargetType: e
                                            }
                                        });
                                    }
                                }} searchable={false} data={[{
                                    "label": "2D",
                                    "value": 2,
                                }, {
                                    "label": "Cilindrico",
                                    "value": 3,
                                }]} style={{width: '100%'}}/>
                            </FormGroup>
                            <FormGroup className={'offlineInputControl'} style={{width: '100%'}}>
                                <ControlLabel
                                    className={'boldText'}> {t('experienceNewFlow.offlineName')} : </ControlLabel>
                                <FormControl className={'inputName'} name="name" onChange={(e, synthE) => {
                                    setData({
                                        ...data, ...{
                                            offlineName: e
                                        }
                                    });
                                }}/>
                            </FormGroup>
                        </Fragment> : null}
                    </Form> : null}
                    <Form>
                        <FormGroup>
                            <div className={'heightInvisible'}>
                                {hasTarget() ? <Fragment>
                                    <ControlLabel
                                        style={{marginTop: '24px', 'marginBottom': '5px'}}
                                        className={'boldText textFormExperience'}>{t('experienceNewFlow.addTarget')}</ControlLabel>
                                    <div className={'preview-file'} style={{'display': 'flex'}}>
                                        <div
                                            className={'new-experience-preview-form' + (fileValue ? ' file-selected' : '')}
                                            onClick={() => {
                                                if (fileValue) {
                                                    document.getElementById('inputFile').click();
                                                }
                                            }}>
                                            <input id="inputFile" type="file" name="target" style={{display: 'none'}}
                                                   onChange={(e) => {
                                                       setInputFileValue(e.target.value);
                                                       setFileValue(true);
                                                       getFilePath(e.target);
                                                       setData({...data, ...{target: e.target.files.length ? e.target.files : []}});
                                                       validData(data);
                                                   }}/>
                                            {fileValue ?
                                                <img src={filePath} alt={'fileValue'} height={252}
                                                     className={'new-experience-preview-image'}/>
                                                :
                                                <div className={'image-input'} onDragEnter={(e) => {
                                                    e.preventDefault()
                                                }} onDragOver={(e) => {
                                                    e.preventDefault()
                                                }} onDrop={(evt) => {
                                                    evt.preventDefault();
                                                    setInputFileValue(evt.dataTransfer.files[0]);
                                                    setFileValue(true);
                                                    getFilePath(evt.dataTransfer);
                                                    setData({...data, ...{target: evt.dataTransfer.files.length ? evt.dataTransfer.files : []}});
                                                    validData(data);
                                                }}>
                                                    <div className={'label-input-image'}>
                                                        <span>{t('experiences.addOrDragTarget')} {t('experiences.newExp.activeExp')} </span>
                                                    </div>
                                                    <div className={'label-secondary'}>
                                                        <span>{t('experiences.newExp.formatsTarget')}</span>
                                                    </div>
                                                    <div>
                                                        <SausageButton outlined label={t('experiences.addTarget')}
                                                                /* className={'new-experience-button'} */
                                                                onClick={triggerFile}
                                                                invert
                                                                />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <Whisper placement="right" trigger="hover"
                                                     speaker={getPopover(t('experiences.infoImage'))}>
                                                <img src="/images/information.svg"
                                                     style={{'marginLeft': '9px', 'marginTop': '105px'}}
                                                     alt=""/>
                                            </Whisper>
                                        </div>
                                    </div>

                                </Fragment> : null}
                            </div>
                        </FormGroup>
                    </Form>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className={'styleFaceFooterExpForm'}>
                <SausageButton outlined disabled={validForm} label={t('experienceNewFlow.createExperience')} onClick={(e) => {
                    e.preventDefault();
                    if (validData(data)) {
                        handleCreateExperience(data);
                    }
                }}/>
            </div>
        </Fragment>
    };

    const getFormControls = () => {
        switch (actualStep) {
            case '3':
            case '2s2':
            case'ImmersionWeb':
            case '1s2':
            case '4':
            case '2s3':
                return getForm();
            default:
                return null;
        }
    };
    return (
        getFormControls()
    );
};

export default ExperienceForm;