import React, {useEffect,useState,useContext} from "react";
import { DateRangePicker, Breadcrumb } from "rsuite";
import ReportGraphicBarChart from "../../components/Reports/ReportGraphicBarChart";
import ReportOverViewData from "../../components/Reports/ReportOverViewData";
import CompaniesTable from "./CompaniesTable"
import ExperienceTable from "./ExperienceTable";
import moment from "moment";
import AppContext from "../../components/app/AppContext";
import { useTranslate } from 'react-polyglot';
import ReportsServiceV2 from "../../services/ReportsV2";
import ReportsService from "../../services/Reports";
import { getFilters } from "../Reports/ReportUtils";
import AlertDialog from "../../components/general/AlertDialog";
import {Button} from "@rmwc/button";
export default function ReportsV2 () {
    const t = useTranslate();
    const context = useContext(AppContext);
    const [selected, setSelected] = useState({
        company: 0,
        campaign: 0,
        experience:0,
    })
    const [loading, setLoading] = useState(true)
    const [breadcrumb, setBreadcrumb] = useState([{
        value:"Todas las compañías",
        key: 0
    }])
    const [breadInfo, setBreadInfo] = useState([])
    const [date,setDate] = useState({
        from: moment().startOf('month').format("YYYY-MM-DD"),
        to: moment().subtract(1, "days"),
    })
    const [modal, setModal] = useState (false)
    const [data,setData] = useState({
        objData: {
            totalScans:0,
            totalUsers: 0,
            interactionTime: "0"
        },
        tableScans : {
            daily: [
                { date : "2022-01-01",scans : 2196},
            ]
        },
        viewTables : [
            { company_id : 6,  scans : 0,  name :  "CamOn" },
        ]
    })
    const [title,setTitle] = useState(t('pages.reports.headTable.company'))
    useEffect( () => {
      setLoading(true)
      setBreadInfo([
        ...breadInfo,
        selected
    ])
    },[selected])

    useEffect(()=>{
        context.setPage({
            name: t('pages.reports.reports'),
            hideFilters: true,
            shortLayout: false,
            noRenderFilterContainer: true,
            loading: loading
        });
        ReportsServiceV2.getData(date.from,date.to,selected.campaign,selected.company,selected.experience)
        .then((rta) =>{
            setData({
                objData: {
                    totalScans:rta.total_views,
                    totalUsers: rta.total_users,
                    interactionTime: rta.average_time
                },
                tableScans : {daily:rta.scans_per_day ? rta.scans_per_day : rta.scans_per_day_all_companies },
                viewTables : rta.views_table
            })
            setLoading(false)
            context.setPage({
                name: t('pages.reports.reports'),
                hideFilters: true,
                shortLayout: false,
                noRenderFilterContainer: true,
                loading: loading
            });
        })
    },[loading])
    function clickBreadcumb(e,key){
        if(breadcrumb.length-1 !== key){
            setSelected(
                breadInfo[key]
            )
            const index = breadcrumb.findIndex((item) => item.key === key);
            let newBreadcrumb =  breadcrumb.slice(0, index + 1);
            let newBreadInfo =  breadInfo.slice(0, index);
            setBreadcrumb(newBreadcrumb)
            setBreadInfo(newBreadInfo)
            setLoading(true)
        }
    }
    const onClickExport = async () => {
        let initialData = {
            from: moment(date.from, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            to: moment(date.to, 'YYYY-MM-DD').format('DD-MM-YYYY'),
            company_id: breadInfo[1] ? breadInfo[1].company : null,
            campaign_id: breadInfo[2] ? breadInfo[2].campaign : null,
            experience_id: breadInfo[3] ? breadInfo[3].experience : null
        }
        try {
            let filters = getFilters(initialData);
            let formattedString = encodeURIComponent(context.userData.email);
            filters = filters.concat(`&user_id=${formattedString}&interactions=yes`);
            await ReportsService.exportAG(filters);
        } catch (e) {
            console.log(e)
        }
    };
    useEffect(()=>{
        switch (breadInfo.length) {
            case 1:
                setTitle(t('pages.reports.headTable.company'))
                break;
            case 2:
                setTitle(t('pages.reports.headTable.campaign'))
                break;
            case 3:
                    setTitle(t('pages.reports.headTable.experience'))
                    break;
            default:
                break;
        }
    },[breadInfo])
    return (
        <>  
            <div className="reports-header">
                <Breadcrumb>
                    {
                        breadcrumb.map((b,key) => {
                            return <a
                            key={new Date().valueOf().toString(36) + Math.random().toString(36).substr(2)}
                            onClick={(e) => clickBreadcumb(e,key)}>
                                {b.value}
                             </a>
                        })
                    }
                </Breadcrumb>
                <div>
                    <DateRangePicker
                        style={{margin:'20px', }}
                        format={'YYYY-MM-DD'}  
                        className={'datepickerpos'} 
                        size={'md'} 
                        placement={"bottomStart"} 
                        showOneCalendar
                        ranges={[]}
                        value={[moment(date.from, 'YYYY-MM-DD').toDate(), moment(date.to, 'YYYY-MM-DD').toDate()]}
                        onOk={(dates) => {
                            setLoading(true);
                            setDate({
                                from: moment(dates[0]).format("YYYY-MM-DD"),
                                to: moment(dates[1]).format("YYYY-MM-DD")
                            });
                        }}
                    />
                    <Button
                            onClick={() => {
                                setModal(true)
                                onClickExport();
                            }}
                            icon='cloud_download'
                            label={t('pages.reports.downloadReport')}
                            raised
                        />
                </div>
            </div>
            <AlertDialog title={t('pages.reports.alertTitle')} bodyMessage={t('pages.reports.alertBody')} setOpen={setModal}
            open={modal} />
            <ReportOverViewData data={data.objData}></ReportOverViewData>
            <ReportGraphicBarChart data={data.tableScans} ></ReportGraphicBarChart>
            {
                data.viewTables[0] && data.viewTables[0].interaction_label ? 
                <ExperienceTable data={data.viewTables} loading={loading} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} setSelected={setSelected} selected={selected}></ExperienceTable> : 
                <CompaniesTable title={title} data={data.viewTables} setLoading={setLoading} loading={loading} breadcrumb={breadcrumb} setBreadcrumb={setBreadcrumb} setSelected={setSelected} selected={selected}></CompaniesTable>
            }
        </>
    )
}