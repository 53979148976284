import React, {useState, useEffect, Fragment} from 'react';
import {TextField} from '@rmwc/textfield';
import {navigate} from "@reach/router";
import {Auth} from 'aws-amplify';
import {Button} from '@rmwc/button';
import {CircularProgress} from '@rmwc/circular-progress';
import {useTranslate} from 'react-polyglot';

import SingLayout from '../signLayout';
import user from '../../utils/user';
import Icon from '../icon';
import {checkSessionExpired, hasSessionExpired} from "../../utils/utils";

import './signin.scss';
import '@rmwc/circular-progress/circular-progress.css';
import {ControlLabel, Form, FormControl, FormGroup, Input, InputGroup} from "rsuite";
import {Icon as RIcon} from "rsuite";
import RegisterHeader from "../headers/RegisterHeader";

import SausageButton from '../coa/SausageButton';

function SignIn(props) {

    const [loading, setLoading] = useState(false);
    const [logged, setLogged] = useState(false);
    const [error, setError] = useState(false);
    const [hide, setHide] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputType, setInputType] = useState('password');
    const [errorMail, setErrorClassMail] = useState({input: '', label: ''});
    const [errorPass, setErrorPass] = useState({input: '', label: ''});
    const t = useTranslate();

    useEffect(() => {


        if (window.location.pathname === '/reset') {
            setTimeout(() => {
                props.onStateChange('requireNewPassword', {});
            }, 200);
        }
        if (window.location.pathname === '/register') {
            setTimeout(() => {
                props.onStateChange('signUp', {});
            }, 100);
        }
    }, [])
    useEffect(() => {

        if (props.authData && props.authData.confirmation) {
            setHide(true);
        } else {
            setHide(false);
        }

    })

    async function SignIn(username, password) {
        try {
            setErrorVisible(true);
            let formattedUser = username.toLowerCase();
            const user = await Auth.signIn(formattedUser, password);
            window.localStorage.setItem('lang', user.attributes["custom:default_lang"] || 'en');
            setLogged(true);
            setErrorClassMail({input: '', label: ''});
            setErrorPass({input: '', label: ''});
            if (hasSessionExpired()) {
                checkSessionExpired();
            } else {
                window.location.href = '/';
            }
        } catch (err) {
            setErrorClassMail({input: '', label: ''});
            setErrorPass({input: '', label: ''});
            handleError(err, username);
        } finally {
            setLoading(false);
        }
    }

    function handleError(error, username) {
        if (error.code === 'UserNotFoundException') {
            setErrorMessage();
            setErrorClassMail({input: 'inputCoAError', label: 'inputCoALabelError', message: t('pages.signIn.errors.userNotFound')});
        }
        if (error.code === 'NotAuthorizedException') {
            setErrorClassMail({input: 'inputCoAError', label: 'inputCoALabelError', message:t('pages.signIn.errors.notAuthorized')});
            setErrorPass({input: 'inputCoAError', label: 'inputCoALabelError', message:t('pages.signIn.errors.notAuthorized')});
        }
        if (error.code === "UserNotConfirmedException") {
            setErrorClassMail({input: 'inputCoAError', label: 'inputCoALabelError', message: t('pages.signIn.errors.notConfirmed')});
        }
        if (error.code === 'PasswordResetRequiredException') {
            setErrorClassMail({input: 'inputCoAError', label: 'inputCoALabelError', message: t('pages.signIn.resetPass')});
            /*props.onStateChange('forgotPassword', {
                msg: t('pages.signIn.resetPass'), user: username, error: error.code
            });*/
        }
        setError(true);
    }

    async function SignInFn(e) {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.target);
        let formData = {};
        Array.from(data.entries()).forEach((d) => {
            formData[d[0]] = d[1];
        });
        if (!formData.email) {
            setLoading(false);
            setError(true);
            setErrorClassMail({input: 'inputCoAError', label: 'inputCoALabelError', message: 'Email required'});
            return
        }
        if (!formData.password) {
            setLoading(false);
            setError(true);
            setErrorPass({input: 'inputCoAError', label: 'inputCoALabelError', message: 'Password required'});
            return
        }
        setErrorClassMail({input: '', label: '', message: ''});
        setErrorPass({input: '', label: '', message: ''});
        await SignIn(formData.email, formData.password)

    }

    const [errorVisible, setErrorVisible] = useState(false);

    if (props.authState === 'signIn' && window.location.pathname !== '/confirmationSend' && window.location.pathname !== '/resetSend' && !hide) {
        return (
            <Fragment>
                <RegisterHeader/>
                <SingLayout type={'signIn'} base={
                    <div className="sign-in-container">
                        <p className={'sign-in-title'}>
                            {t('pages.signIn.enter')} <span>Studio</span>
                        </p>
                        <form className={'sign-form'} onSubmit={SignInFn}>
                            <div className={'form-control'}>
                                <ControlLabel style={{marginBottom: '8px'}}
                                              className={`labelInput ${errorMail.label}`}>{t('common.labels.email')}</ControlLabel>
                                <Input className={`inputCoA ${errorMail.input}`} errorMessage={'sss'}
                                       errorPlacement={'rightEnd'}
                                       name={'email'} type={'email'}
                                       placeholder={t('common.labels.email')}/>
                                <span className={'error'}>{t(errorMail.message)}</span>
                            </div>
                            <div className={'form-control'}>
                                <ControlLabel
                                    style={{marginBottom: '8px', marginTop: '30px'}}
                                    className={`labelInput ${errorPass.label}`}>{t('common.labels.password')}</ControlLabel>
                                <InputGroup className={`inputGroupCoA ${errorPass.input}`}>
                                    <Input name={'password'} type={inputType}
                                           placeholder={t('common.labels.password')}/>
                                    <InputGroup.Button onClick={() => {
                                        setInputType(inputType === 'password' ? 'text' : 'password')
                                    }}>
                                        <RIcon icon="eye"/>
                                    </InputGroup.Button>
                                </InputGroup>
                                <span className={'error'}>{t(errorPass.message)}</span>
                                <span className="sign-in-forgot" onClick={() => {
                                    props.onStateChange('forgotPassword', {});
                                }}>{t('pages.signIn.forgotMyPass')}</span>
                            </div>
                            {error &&
                            <div className="form-control">
                                <span className={'error'} style={{textAlign: 'center'}}>{errorMessage}</span>
                            </div>
                            }
                            <div className={'form-control'}>
                                <SausageButton type="submit" label={loading ? '': t('pages.signIn.enterStudio')}
                                        disabled={loading}
                                        icon={loading && 'icon'} 
                                        fluid
                                        size="large"
                                        />
                                <div className={'sign-in-actions'}>
                                    <span className={'new'}>{t('pages.signIn.isNewUser')}</span>
                                    <span
                                        className={'sign-up'}
                                        onClick={() => {
                                            props.onStateChange('signUp', {});
                                        }}>
                                    {t('pages.signIn.createFreeAccount')}
                                </span>
                                </div>
                            </div>
                        </form>

                    </div>
                }
                />
            </Fragment>
        )
    } else {
        return null;
    }

}

export default SignIn;
