import React from 'react';
import DialogContainer from '../dialog';
import ActionsDialog from '../actionsDialog';
import SubsDialog from '../actionsDialog/subsDialog'

import { Auth } from 'aws-amplify';
import './Layout.css';
import AppContext from '../app/AppContext';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
class LayoutBase extends React.Component {
    state = {
        collapsible: false,
        showLoading: false,
        onBoarding: false,
        endOfTrial: false,
        OpenSnack: false,
        showCmp: true,
        notification: {}
    }
    constructor(props) {
        super(props);
        this.handleCollapse = this.handleCollapse.bind(this);
        this.hide = this.hide.bind(this);
    }

    componentDidUpdate() {
        const showLoading = this.context ? this.context.page.loading : false;
        const showEndofTrial = this.context ? this.context.page.ondOfTrial : false;
        const showNotification = this.context?.page?.notification ? Object.keys(this.context.page.notification).length : 0;


        if (showLoading) {

            this.enableLoading();
        } else {
            this.disableLoading();
        }

        this.disableonBoarding();

        if (showEndofTrial) {
            this.enableEndOfTrial();
        }


    }
    enableEndOfTrial() {
        this.setState({
            endOfTrial: true
        })
    }
    enableonBoarding() {
        if (!this.state.onBoarding) {
            this.setState({
                onBoarding: true
            });
        }
    }
    enableSnack() {
        if (!this.state.OpenSnack) {
            this.setState({
                OpenSnack: true
            });
        }
    }
    disableSnack() {
        if (this.state.OpenSnack) {
            this.setState({
                OpenSnack: false
            });
        }
    }
    disableonBoarding() {
        if (this.state.onBoarding) {
            this.setState({
                onBoarding: false
            });
        }
    }
    enableLoading() {
        if (!this.state.showLoading) {
            this.setState({
                showLoading: true
            });
        }
    }
    disableLoading() {
        if (this.state.showLoading) {

            this.setState({
                showLoading: false
            });
        }
    }
    handleCollapse(collapse) {
        this.setState(
            {
                collapsible: collapse
            }
        );

    }
    hide(val) {
        this.setState({
            showCmp: !val
        })
    }
    async logout() {
        await Auth.signOut();
        window.location.href = '/';
    }
    render() {
        return (
            <div>

                <DialogContainer />
                <ActionsDialog allwaysShow={true} showCmp={this.hide} />
                <SubsDialog />
                {this.state.showCmp &&
                    React.cloneElement(this.props.children, {
                        hide: this.hide
                    })
                }

            </div>
        )
    }
}
LayoutBase.contextType = AppContext;
export default LayoutBase;
