import React, {memo} from 'react'
import PropTypes from 'prop-types'

const AppRoute = memo(function AppRoute({Layout, Component, ...props}) {
    return Layout ? <Layout>
        <Component {...props} />
    </Layout> : <Component{...props}/>
})

AppRoute.propTypes = {
    Layout: PropTypes.shape().isRequired,
    Component: PropTypes.shape().isRequired,
};

export default AppRoute
