import request from '../utils/request';

class SubscriptionService {

    static async create(params) {
        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/subscriptions`, {
            body: params
        });
    }

    static async get() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/subscriptions`);
    }
    static async getPlan() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/subscriptions/plan`);
    }
    static async getIntent() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/subscriptions/intent`);
    }
    static async getPaymentMethods() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/payment-methods`);
    }
    static async getInvoices() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/invoices`);
    }
    static async deleteSubscription() {
        return request.delete(`${process.env.REACT_APP_KWM_ENDPOINT}/subscriptions`);

    }
}


export default SubscriptionService;