import React, {Fragment, useState,useContext,useEffect} from 'react';
import {IconButton,FormControl,Grid,TextField, OutlinedInput, FormGroup,FormControlLabel,Modal,Typography,Box,InputLabel, Select,MenuItem, Collapse} from '@mui/material';
import './WebComponents.css';
import Previsualiser  from './Previsualiser';
import WidthForm  from './WidthForm';
import WebComponentModal  from './WebComponentModal';
import SausageButton from '../../components/coa/SausageButton';
import { useTranslate } from 'react-polyglot';
import { OverlaysContext } from '../../context/OverlaysContext';
import "./Previsualiser.css"
import BackgroundForm from './BackgroundForm';

function CowcModalTemplate() {
    const t = useTranslate();
    const {variableSelected, setVariableSelected} = useContext(OverlaysContext);
    const [openModal, setOpenModal] = useState(false);
    const [textColor, setTextColor] = useState("#050505")
    const [backgroundColor, setBackgroundColor] = useState("#ffffff")
    const [textColorButtons, setTextColorButtons] = useState("#ffffff")
    const [backgroundRightButton, setBackgroundRightButton] = useState("#F5841F")
    const [backgroundLefttButton, setBackgroundLefttButton] = useState("#C4C4C4")
    const [backgroundRightButtonHover, setBackgroundRightButtonHover] = useState("#F9BD88")
    const [backgroundLefttButtonHover, setBackgroundLefttButtonHover] = useState("#E3E3E3")
    const [textColorLeftButton, setTextColorLeftButton] = useState("#ffffff") //NO ANDA
    const [textColorRightButton, setTextColorRightButton] = useState("#ffffff") //NO ANDA
    const [textColorLeftButtonHover, setTextColorLeftButtonHover] = useState("#ffffff")//NO ANDA
    const [textColorRightButtonHover, setTextColorRightButtonHover] = useState("#ffffff")//NO ANDA
    const [font, setFont] = useState("Arial");
    const [top, setTop] = useState(100);
    const [primaryText, setPrimaryText] = useState();
    const [secondaryText, setSecondaryText] = useState();
    const [textLeftButton, setTextLeftButton] = useState("NO");
    const [textRightButton, setTextRightButton] = useState("OK!");

    
    const optionsVariables = [
        {label: t("overlays.properties.text"), value : t("overlays.properties.text")},
        {label: t("overlays.properties.colors"), value : t("overlays.properties.colors")},
        {label: t("overlays.properties.top"), value : t("overlays.properties.top")},
    ]
    
    const getScript = () =>{
        const script = `
            <cowc-modal 
                font=${font} 
                top=${top+"px"}
                primary-text=${primaryText}
                secondary-text=${secondaryText}
                text-color=${textColor}
                background=${backgroundColor}
                background-right-button=${backgroundRightButton}
                background-left-button=${backgroundLefttButton}
                text-right-button=${textRightButton}
                text-left-button=${textLeftButton}
                text-color-right-button=${textColorRightButton}
                text-color-left-button=${textColorLeftButton}
                background-right-button-mouse-up=${backgroundRightButtonHover}
                background-left-button-mouse-up=${backgroundLefttButtonHover}  
                text-color-right-button-mouse-up=${textColorRightButtonHover}
                text-color-left-button-mouse-up=${textColorLeftButtonHover}
        >
        </cowc-modal>
        `
        return script;
    }

    const renderVariableSelected = () => {
        
        switch(variableSelected){
            case t("overlays.properties.colors"): return (
                <Grid className='item-container'  container spacing={2} sx={{flexDirection: "column"}}>
                    <BackgroundForm
                        color={textColor}
                        onChange={(e) => {
                            setTextColor(e.target.value)
                        }}
                        label={t("overlays.properties.textColor")}
                    /> 
                    <BackgroundForm
                        color={backgroundColor}
                        onChange={(e) => {
                            setBackgroundColor(e.target.value)
                        }}
                        label={t("overlays.properties.background")}
                    />  
                    <BackgroundForm
                        color={textColorLeftButton}
                        onChange={(e) => {
                            setTextColorLeftButton(e.target.value)
                        }}
                        label={t("overlays.properties.textColorLeftButton")}
                    />  
                     <BackgroundForm
                        color={textColorRightButton}
                        onChange={(e) => {
                            setTextColorRightButton(e.target.value)
                        }}
                        label={t("overlays.properties.textColorRightButton")}
                    />  
                    <BackgroundForm
                        color={backgroundLefttButton}
                        onChange={(e) => {
                            setBackgroundLefttButton(e.target.value)
                        }}
                        label={t("overlays.properties.backgroundLefttButton")}
                    />  
                    <BackgroundForm
                        color={backgroundRightButton}
                        onChange={(e) => {
                            setBackgroundRightButton(e.target.value)
                        }}
                        label={t("overlays.properties.backgroundRightButton")}
                    />  
                                        <BackgroundForm
                        color={backgroundLefttButtonHover}
                        onChange={(e) => {
                            setBackgroundLefttButtonHover(e.target.value)
                        }}
                        label={t("overlays.properties.backgroundLefttButtonClick")}
                    />  
                    <BackgroundForm
                        color={backgroundRightButtonHover}
                        onChange={(e) => {
                            setBackgroundRightButtonHover(e.target.value)
                        }}
                        label={t("overlays.properties.backgroundRightButtonClick")}
                    />  
                    <BackgroundForm
                        color={textColorLeftButtonHover}
                        onChange={(e) => {
                            setTextColorLeftButtonHover(e.target.value)
                        }}
                        label={t("overlays.properties.textColorLeftButtonHover")}
                    />   
                    <BackgroundForm
                        color={textColorRightButtonHover}
                        onChange={(e) => {
                            setTextColorRightButtonHover(e.target.value)
                        }}
                        label={t("overlays.properties.textColorRightButtonHover")}
                    />  
                                      
                </Grid>
            ) ;
            case t("overlays.properties.text"): return(
                <Grid className='item-container'  container spacing={2} sx={{flexDirection: "column"}}>
                    <TextField
                        className='item-overlay'   
                        select
                        size='small' 
                        id="font" 
                        label={t("overlays.properties.font")} 
                        value={font} 
                        variant="outlined" 
                        onChange={ e=>{setFont(e.target.value)}}
                    >
                        <MenuItem value="Arial">Arial</MenuItem>
                        <MenuItem value="Roboto">Roboto</MenuItem>
                    </TextField>
                    <TextField 
                        className='item-overlay'
                        size='small'  
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                        label={t("overlays.properties.primaryText")} 
                        value={primaryText} 
                        variant="outlined" 
                        onChange={ e=>{setPrimaryText(e.target.value)}}/>
                    <TextField 
                        className='item-overlay'
                        size='small'  
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                        label={t("overlays.properties.secondaryText")}
                        value={secondaryText} 
                        variant="outlined" 
                        onChange={ e=>{setSecondaryText(e.target.value)}}/>
                    <TextField 
                        className='item-overlay'
                        size='small'  
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                        label={t("overlays.properties.textLeftButton")} 
                        value={textLeftButton} 
                        variant="outlined" 
                        onChange={ e=>{setTextLeftButton(e.target.value)}}/>
                    <TextField 
                        className='item-overlay'
                        size='small'  
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  
                        label={t("overlays.properties.textRightButton")} 
                        value={textRightButton} 
                        variant="outlined" 
                        onChange={ e=>{setTextRightButton(e.target.value)}}/>
                </Grid>
            ) ;
           
            case t("overlays.properties.top"): return(
                <Grid className='item-container'  container spacing={2} sx={{flexDirection: "column"}}>
                <TextField 
                
                    className='item-overlay'
                    size='small'  
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                    id="top" 
                    label={t("overlays.properties.top")+" (px)"} 
                    value={top} 
                    variant="outlined" 
                    onChange={ e=>{setTop(e.target.value)}}/>
                </Grid>

            ) ;
        }
    }

    

    //todo pasar a componente este modal
    const handleClickExport = () => {
        setOpenModal(!openModal);
    }

    const handleClickCloseModal = () => {
        setOpenModal(false)
    }
    
      
    return (
        <Fragment>
            <Grid className='item-container' container spacing={2}>
            <TextField
                size='small' 
                label='Variables'
                select
                className='item-overlay'
                id="variables-select"
                input={<OutlinedInput label="VARIABLES" />}
                onChange={e => {setVariableSelected(e.target.value)}}
                >
                {optionsVariables.map(component => {
                    return <MenuItem value={component.value}>{component.label}</MenuItem>
                })}
            </TextField> 
            </Grid>
            {renderVariableSelected()}
            <div className='previsualizer-container'>
            <div className='main'>
            <div className='modal-visualiser'>
            <div className='tt desktop-visualiser-contain'>
            <cowc-modal 
                position-overlay="absolute"
                position="absolute"
                width-desktop="80%"
                visible={true} 
                font={font} 
                top={top/3+"px"}
                primary-text={primaryText}
                secondary-text={secondaryText}
                text-color={textColor}
                background={backgroundColor}
                background-right-button={backgroundRightButton}
                background-left-button={backgroundLefttButton}
                text-right-button={textRightButton}
                text-left-button={textLeftButton}
                text-color-right-button={textColorRightButton}
                text-color-left-button={textColorLeftButton}
                background-right-button-mouse-up={backgroundRightButtonHover}
                background-left-button-mouse-up={backgroundLefttButtonHover}  
                text-color-right-button-mouse-up={textColorRightButtonHover}
                text-color-left-button-mouse-up={textColorLeftButtonHover}
            >
            </cowc-modal>
            </div>
            </div>
            </div>
            <FormGroup className="text-center"> 
                    <SausageButton

                        className="btn-export"
                        label={t("overlays.export")}
                        onClick={handleClickExport}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
            </FormGroup>
            </div>
            <WebComponentModal title="Cowc-footer-botton" open={openModal} onCloseModal={handleClickCloseModal}>{getScript()}</WebComponentModal>
        </Fragment>
    );
}

export default CowcModalTemplate;
