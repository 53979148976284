import React, {createContext, useEffect, useState} from 'react';

export const CustomLoaderContext = createContext();

const CustomLoaderProvider = (props) => {

    const [iconColor, setIconColor] = useState("#3434E0"); 
    const [textColor, setTextColor] = useState("#3434E0"); 
    const [loadingBarColor, setLoadingBarColor] = useState("#3434E0"); 
    const [backgroundColor, setBackgroundColor] = useState("#ffffff"); 
    const [footerColor, setFooterColor] = useState("#3434E0"); 
    const [isGrid, setIsGridActive] = useState(true); 
    const [isGradiant, setIsGradiantActive] = useState(false);
    const [backgroundGradiantColor, setBackgroundGradiantColor] = useState("#ffffff"); 
    const [footerText, setFooterText] = useState("Web360 powered by "); 
    const [footerTextBold, setFooterTextBold] = useState("CamOn"); 
    const [footerTextEn, setFooterTextEn] = useState("Web360 powered by "); 
    const [footerTextBoldEn, setFooterTextBoldEn] = useState("CamOn"); 
    const [logoFile, setLogoFile] = useState("undefined");
    const [backgroundFile, setBackgroundFile] = useState(); 
    const [backgroundLocal, setBackgroundLocal] = useState("undefined"); 
    const [backgroundURL, setBackgroundURL] = useState("/images/icon_360.svg");
    const [logoURL, setLogoURL] = useState("");
    const [resetLogo, setResetLogo] = useState(false);
    const [resetBackground, setResetBackground] = useState(false);
    const [language, setLanguage] = useState ("spanish")
    const [defaultLogo, setDefaultLogo] = useState(null)
    return (<CustomLoaderContext.Provider
        value={{iconColor, setIconColor,
                textColor, setTextColor,
                loadingBarColor, setLoadingBarColor,
                backgroundColor, setBackgroundColor,
                footerColor, setFooterColor,
                isGrid, setIsGridActive,
                isGradiant, setIsGradiantActive,
                backgroundGradiantColor, setBackgroundGradiantColor,
                footerText, setFooterText,
                footerTextBold, setFooterTextBold,
                footerTextEn, setFooterTextEn,
                footerTextBoldEn, setFooterTextBoldEn,
                logoFile, setLogoFile,
                defaultLogo,setDefaultLogo,
                backgroundFile, setBackgroundFile,
                backgroundLocal,setBackgroundLocal,
                backgroundURL, setBackgroundURL,
                logoURL, setLogoURL,
                resetLogo, setResetLogo,
                resetBackground, setResetBackground,
                language, setLanguage
                }}>
            {props.children}
        </CustomLoaderContext.Provider>
    );
};

export default CustomLoaderProvider;
