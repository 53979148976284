import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';

import Icon from '../icon';
import DropInput from '../dropInput';
import campaignService from '../../services/Campaign';
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';

function CloneDemoExp(props) {
    const [name, setName] = useState(props.data.name);

    const [showNotice, setShowNotice] = useState(props.data.showNotice)
    const [campaign, setCampaign] = useState(null);
    const [campaigns, setCampaigns] = useState([]);

    const [campaignId, setCampaignId] = useState(null);
    const [campaignName, setCampaignName] = useState('');


    const [errorState, setErrorState] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const t = useTranslate();

    useEffect(() => {
        fetchCampaings();
    }, [])

    function fetchCampaings() {
        setIsLoading(true)
        campaignService.list(false)
            .then((campaingsResponse) => {
                const campaingsMapped = campaingsResponse.filter(c => c.id !== props.data.campaign.id);
                setCampaigns(campaingsMapped);
                setIsLoading(false)
            });
    }

    return (
    <div className={'clone-demo-experience-container'}>
    {
        showNotice ?
        <>
        <p>{t("experiences.cloneDemoExpNotice")}</p>
        <div>
            <Button label={t("actions.accept")} onClick={() => setShowNotice(false)}></Button>
            <Button label={t("actions.cancel")} onClick={(e) => {
                e.preventDefault();
                props.setOpen(false);
            }}></Button>
        </div>
        </>
        :
        <>
        <TextField outlined name="name" label={t('common.labels.name')} className="fullwidth form-item clone-experience" value={name}
            onChange={e => {
                setName(e.target.value)
        }}/>
        {errorState ? <ErrorMessage message={message}/> : null}
        <div>
            <DropInput
                creatableProps={{
                    menuPortalTarget: document.body,
                    styles: {
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    },
                    isLoading: isLoading
                }}
                label="Crear un proyecto o agrega la experiencia a un proyecto existente"
                outlined
                className={'new-experience-file-input'}
                icon={<Icon name={'campaings'} className={'new-experience-file-input-icon'}/>}
                onChangeFn={e => {
                    setCampaign(e)
                    setCampaignId(e.id)
                    setCampaignName(e.name)
                    props.onChange({
                        campaign: {
                            id: e.id,
                            name: e.name
                        }
                    });
                }}
                data={campaigns}
                project={campaign}
            />
        </div>
        <div>
            <Button label={t('actions.add')} onClick={(e) => {
                e.preventDefault();
                if (!name) {
                    setErrorState(true);
                    setMessage('Es necesario agregar un nombre de experiencia')
                } else {
                    props.setOpen(false);
                    setErrorState(false);
                    props.actionHandler({
                        value: {},
                        name: name,
                        key: props.keyData,
                        isDemo: props.data.isDemo,
                        campaign: {
                            id: campaignId,
                            name: campaignName
                        }
                    });
                }
            }}>
            </Button>
            <Button label={t('actions.cancel')} onClick={(e) => {
                e.preventDefault();
                props.setOpen(false);
            }}>
            </Button>
        </div>
        </>
        }
    </div>)
}

export default CloneDemoExp;