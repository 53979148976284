import React, { memo } from 'react'
import PropTypes from 'prop-types'

const Confirm = memo(function Confirm({ data: { msg } }) {
    return (
        <div>
            <p>{msg}</p>
        </div>
    )
})

Confirm.propTypes = {
    msg: PropTypes.string.isRequired
}

export default Confirm;
