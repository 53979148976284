import request from '../utils/request';
import { parseParams } from '../utils/url';
import CustomMonoriel from './CustomMonoriel';
// TODO: se mantiene como legacy el status ON_REVIEW pero ya no se usa, en cualquier lo momento lo podemos deprecar

const statusMap = {
    NOT_PUBLISHED: 'unpublished',
    PUBLISHED: 'published',
    REJECTED: 'rejected',
    ON_REVIEW: 'published'
}

class ExperienceService {

    static async list(paginated = false, page = 1, perPage = 5) {
        if (paginated) {
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences?page=${page}&per_page=${perPage}`);

        } else {

            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences`);
        }
    }

    static async listByStatuses() {
        const status = { published: [], inRevision: [], unpublished: [], rejected: [] };

        return new Promise(async (resolve, reject) => {
            try {
                const response = await request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences`);
                if (response.data) {
                    resolve(response.data.reduce((curr, next) => {
                        curr[statusMap[next.status]].push(next);
                        return curr;
                    }, status));
                }
                resolve(status)
            } catch (error) {
                return reject(error)
            }
        })

    }
    static async getPendingForApproval(paramsQuery) {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/pendingApproval?${parseParams(paramsQuery)}`);
    }
    static async recent(paginated = false, page = 1, perPage = 15) {
        if (paginated) {
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/recents?page=${page}&per_page=${perPage}`);
        } else {
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/recents`);
        }
    }

    static async samplesGallery(paginated = false, page = 1, perPage = 15) {
      if (paginated) {
          return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/demos?page=${page}&per_page=${perPage}`);
      } else {
          return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/demos`);
      }
    }

    static async published(paginated = false, page = 1, perPage = 5) {
        if (paginated) {
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/published?page=${page}&per_page=${perPage}`);
        } else {
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/published`);
        }
    }
    static async rename (id,body) {
       
            return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}/alias`,body);
        
    }

    static async create(params) {
        return request.post(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences`, {
            body: params
        });
    }
    static async changeTarget(params) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences`, {
            body: params
        });
    }
    static async clone(params) {
        return request.post(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/clone`, {
            body: params
        });
    }
    static async update(params, id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`, {
            body: params
        });
    }

    static async delete(id) {
        return request.delete(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`)
    }
    static async activate(id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`, {
            body: { active: 1 }
        });
    }
    static async deactivate(id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`, {
            body: { active: 0 }
        });
    }
    static async enableVRMode(id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`, {
            body: { vr_mode_compatible: 1 }
        });
    }
    static async disableVRMode(id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}`, {
            body: { vr_mode_compatible: 0 }
        });
    }
    static async expirate(id,expiration_date) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${id}/expirate`, {
            body: { 
                expiration_date,
            }
        });
    }
    static async search(value) {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences?name=${value}`);
    }
    static async migrate(campaign_id,company_id,experience_id) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${experience_id}/migrate`,
            {
                body: {
                    campaign_id:campaign_id,
                    company_id: company_id     
                }
            }
        );
    }

    static async createModelViewExperience( params ) {
        return request.postJson(`${process.env.REACT_APP_MOES_ENDPOINT}/modelview`, {
            body: params
        });
    }
    static async ChangeLoadingImage( experience_id,params ) {
        return request.put(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${experience_id}/change-loading-img`, {
            body: params
        });
    }
    static async ExperienceAd(experienceTypeId,experience_id,isAd,publicAlias ) {
        if(experienceTypeId == "5"){
            //Modificar extras desde cletus
            const update = new FormData()
            update.append("isAd", isAd) 
            await CustomMonoriel.updateCustomMonorielConfig(publicAlias,update)
        }
        return request.postJson(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${experience_id}/experience-ad`, {
            body: {
                isAd,
                experienceTypeId,
                publicAlias
            }
        });
    }
}
export default ExperienceService;
