import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AutoComplete,
    Button,
    ControlLabel,
    Form,
    FormGroup,
    Icon,
    Input,
    InputGroup,
    Modal,
    SelectPicker
} from "rsuite";
import {useTranslate} from "react-polyglot";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";

const ChangeNameComponent = () => {
    const t = useTranslate();

    const {
        setOpenChangeDialog,
        openChangeNameDialog,
        changeNameData,
        setDialogChangeNameData,
        onChangeName
    } = useContext(ChangeNameDialogContext);


    return (
        <div>
            <Modal show={openChangeNameDialog} onHide={() => {
                setOpenChangeDialog(false);
            }}>
                <Modal.Header>
                    <Modal.Title>{t('pages.campaigns.actions.changeName')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ControlLabel
                        className={`labelInput `}>{t('pages.campaigns.namePlaceHolder')}</ControlLabel>
                    <Input style={{marginTop: '7px'}} className={`inputCoA `}
                           name="name"
                           id="name"
                           value={changeNameData.name}
                           onChange={value => {
                               setDialogChangeNameData({...changeNameData, ...{name: value}});
                           }}
                           placeholder={t('pages.campaigns.namePlaceHolder')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setOpenChangeDialog(false);
                        onChangeName();
                    }} appearance="primary" disabled={changeNameData.name.trim() === ''}>
                        {t('actions.accept')}
                    </Button>
                    <Button onClick={() => {
                        setOpenChangeDialog(false);
                    }} appearance="primary">
                        {t('actions.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ChangeNameComponent;