import styled from 'styled-components';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
export const SectionContainer = styled.section`
    position: absolute;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 84vh;
    background-color: #fff;
    z-index: 6000;
`
export const Back = styled.div`
    
`
export const ImgContainer = styled.div`
    display: flex;
    
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    .new{
        position: absolute;
        display: flex;
        justify-content: center;
        left: -130px;
        top:-80px;

    }
    .old{
        position: absolute;
        display: flex;
        justify-content: center;
        z-index: 50;
       
    }
`

export const Text = styled.h3`
        font-family: 'Public Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #292929;
`

export const Paragraph = styled.p`
         font-family: Public Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        text-align: center;
        padding: 20px;
        letter-spacing: -0.0125em;

        /* Neutrals / Alpha / 072 */

        color: rgba(41, 41, 41, 0.72);
`

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
`

export const Input = styled(TextField)`
    width: 500px;
    margin-right: 50px;
    input.mdc-text-field__input {
        color: #3487F0 !important
    }
`

export const Rounded = styled(Button)`
    width: 120px;
    cursor: pointer;
    border-radius: 50px;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    height: 40px;
    line-height: 24px;
    background-color: var(--primary-color) !important;
    color: #fff !important;
    margin-left: 16px;
`