import React, {useState, useContext, forwardRef, useReducer} from 'react';
import {Card, CardPrimaryAction, CardMedia, CardActions, CardActionIcon} from '@rmwc/card';
import {Badge} from '@rmwc/badge';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/es';
import {navigate} from '@reach/router';
import {useTranslate} from 'react-polyglot';
import {Popover, Whisper} from "rsuite";
import useObserver from '../../hooks/useObserver';
import {CardWrapper} from './ExperienceStyles';
import Notification from '../notification/notification';
import Appcontext from '../app/AppContext';
import ElipsisMenu from '../elipsisMenu';
import ExperienceService from '../../services/Experience';
import CustomMonoriel from '../../services/CustomMonoriel';
import ExpName from '../actions/ExpName';
import Sure from '../../pages/Profile/Sure';
import Modal from '../modal/modal';
import ExperienceType from './ExperienceType';
import MigExp from '../actions/MigExp';
import SeeTarget from '../actions/SeeTarget';
import DeleteExp from '../actions/DeleteExp';
import ChangeLoading from '../actions/ChangeLoadingAr'
import CheckPermission from '../checkPermision/checkPermision';
import {QRDialogContext} from "../../context/QRDialogContext";
import {userGroupsObject} from '../../utils/userGroups';
import getLocale from '../../utils/locale';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import ChangeToAd from '../actions/ChangeToAd';
import './experienceCard.scss';
import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@rmwc/tooltip/tooltip.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/badge/badge.css';
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";
import EditAR from '../actions/EditAR';
import ChangeAlias from '../actions/ChangeAlias';
import ChangeName from '../actions/ChangeName';
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import CloneExp from '../actions/CloneExp';

const ModelViewCard = forwardRef((props, ref) => {
    const context = useContext(Appcontext);
    const [exp, setExp] = useState(false);
    const [cmpDataObj, setCmpDataObj] = useState({});
    const [show, element] = useObserver();
    const locale = getLocale() || 'en';
    const { setShowLoading, setOverlayStyle } = useContext(ShowLoadingContext)

    const getPopover = (name) => {
        return <Popover>
            <p>{name}</p>
        </Popover>
    };

    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => ({ ...prevState, ...currState }),
        { showNotification: false, propsNotification: {} });

    const t = useTranslate();

    const isFreelance = () => context.userGroups.includes(userGroupsObject.FreelanceUsers);

    async function editExpName(value) {
        try {
            await ExperienceService.update(value, props.experience.id);
            if (context.page.refetch) {
                context.page.refetch()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setExp(false);
        }
    }

    async function activateExp() {
        try {
            const exp = this.componentKey;

            await ExperienceService.expirate(exp,null);


            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.activate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }
            // window.location.reload();
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }
    }

    async function deactivateExp() {
        try {
            const exp = this.componentKey;
            const date = moment()
            await ExperienceService.expirate(exp,date);

            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.deactivate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }

            // window.location.reload();
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }
    }
    async function migrate(params) {
        try {
            const companyId = context.userData.company.id;
            const exp = this.componentKey;
            const campaignId = params.campaign.id;
            setShowLoading(true)
            setOverlayStyle("loading-overlay-full")
            await ExperienceService.migrate(campaignId,companyId, exp);
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.migrate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }

        } catch (error) {
            console.log(error);
        } finally {
            setExp(false);
        }
    }
    async function clone(params) {
        try {

            let formData = {
                target: params.value,
                name: params.name,
                from: 'experience',
                id: params.key,
                experienceTypeId: props.experience.experienceTypeId
            };
            context.setPage({...context.page, loading: true});
            console.log(formData)
            await ExperienceService.clone(formData);
            context.setPage({...context.page, loading: false});
            if (context.page.refetch) {
                context.page.refetch()
            }
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.clone') }
            })
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }

    }
    function handleMenuClick(e) {
        e.stopPropagation();
        let actionsDialogData = {};
        switch (this.key) {
            case 'open':
                goToMonorail();
                break;
            case 'show':
                actionsDialogData = {
                    title: t('experiences.actions.seeTarget'),
                    componentData: props.experience,
                    component: SeeTarget,
                    componentKey: props.experience.id,
                    action: () => {
                    },
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            
            case 'edit':
                actionsDialogData = {
                    title: t('experiences.actions.edit'),
                    componentData: props.experience.name,
                    component: ExpName,
                    componentKey: props.experience.id,
                    action: editExpName,
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                //context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });

                break;
            case 'activate':

                actionsDialogData = {
                    title: props.experience.active ? t('experiences.actions.deactive') : t('experiences.actions.active'),
                    componentData: props.experience.name,
                    component: Sure,
                    componentKey: props.experience.id,
                    action: props.experience.active ? deactivateExp : activateExp,
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'reports':
                if (!isFreelance()) {
                    navigate(`${process.env.REACT_APP_REPORTS_V2}/goto?company_id=${props.experience.companyId}&campaign_id=${props.experience.campaign.id}&experience_id=${props.experience.id}`);
                }
                break;
            case 'delete':
                actionsDialogData = {
                    title: t('experiences.actions.removeExp'),
                    componentData: props.experience.name,
                    component: DeleteExp,
                    componentKey: props.experience.id,
                    action: deleteExperience,
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'editAR':
                actionsDialogData = {
                    title:t('experiences.editBackground'),
                    componentData: props.experience.link.public_alias,
                    component: EditAR,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{setExp(false)}
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});
                break;
            case 'changeAlias':
                actionsDialogData = {
                    title: t('actions.changeAlias'),
                    componentData: props.experience.link.public_alias,
                    component: ChangeAlias,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: () => {
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});
                break;
            case 'changeName':
                actionsDialogData = {
                    title: t('actions.changeName'),
                    componentData: props.experience.name,
                    component: ChangeName,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});         
                break;
            case 'changeLoading':
                actionsDialogData = {
                    title: t('actions.changeLoading'),
                    componentData: props.experience.link.public_alias,
                    component: ChangeLoading,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});         
                break;
            case 'experienceAd':
                actionsDialogData = {
                    title: props.experience.is_ad ? t('actions.converToNormal') : t('actions.converToAd') ,
                    componentData: {
                        experienceId: props.experience.id,
                        isAd: props.experience.is_ad,
                        experienceType: props.experience.experienceTypeId,
                        publicAlias: props.experience.link.public_alias
                    },
                    component: ChangeToAd,
                    componentKey: props.experience.id,
                    buttonLess: true,
                    action: ()=>{
                        setExp(false);
                    }
                };
                setExp(true);
                setCmpDataObj({...actionsDialogData});         
                break;
            case 'migrate':
                actionsDialogData = {
                    title: t('experiences.actions.migExp'),
                    componentData: props.experience,
                    component: MigExp,
                    componentKey: props.experience.id,
                    action: migrate,
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            case 'clone':
                actionsDialogData = {
                    title: t('experiences.actions.cloneExp'),
                    componentData: {name: props.experience.name, hasTarget: !!props.experience.target},
                    component: CloneExp,
                    componentKey: props.experience.id,
                    action: clone,
                    buttonLess: true
                };
                // context.setPage({ ...context.page, openActionsDialog: true, actionsDialogData });
                setExp(true);
                setCmpDataObj({...actionsDialogData})
                break;
            default:
                break;
        }
    }

    let url = getMonorailUrl();

    const {setOpenQRDialog, setDialogData} = useContext(QRDialogContext);
    const handleQRDialog = () => {
        setDialogData({
            published: true,
            url: url,
            shortUrl: url
        });
        setOpenQRDialog(true)
    };
    const {setExperienceId, setOpenMigrateExperienceDialog} = useContext(MigrateUserDialogContext);
    const openMigrationExperienceAdmin = () => {
        setExperienceId(props.experience.id);
        setOpenMigrateExperienceDialog(true)
    };


    async function deleteExperience() {
        try {
            setShowLoading(true);
            let response = await ExperienceService.delete(this.componentKey);
            response = await CustomMonoriel.deleteCletusAlias(props.experience.link.public_alias);

            if (context.page.refetch) {
                context.page.refetch()
            }
            setShowLoading(false);
            return response;

        } catch (error) {
            throw new Error(error);
        } finally {
            setShowLoading(false);
            setExp(false);
        }
    }

    const isFreelancePaid = () => {
        return context.userGroups.find(e => e === 'FreelancePaidUsers') !== undefined
    }

    const isEditor = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers') !== undefined) {
            return 'Editor'
        }
    };
    const isAdmin = () => {
        if (context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined) {
            return 'Admin'
        }
    };
    function goToMonorail() {
        window.open(getMonorailUrl(), "_blank")
    }

    function getMonorailUrl() {
        if(props.experience.link) {
            return `${process.env.REACT_APP_MONORAIL_MVAR}?a=${props.experience.link.public_alias}`;
        }
        else {
            return `${process.env.REACT_APP_MONORAIL_VIEWER}?model=${props.experience.name}`;
        }
    }

    const canSeeReport = () => {
        // //si son experiencias ar no da la opcion de ver reportes
        // if(props.experience.experienceTypeId === 5){
        //     return false;
        // }
        if (context.userGroups.includes(userGroupsObject.CompanyUsers)) {
            return context.userData.company ? context.userData.company.canSeeReport : false;
        }
        return true;
    };

    //context for migration
  
    
    const {changeNameData, setDialogChangeNameData, setOpenChangeDialog} = useContext(ChangeNameDialogContext);
    const handleChangeNameDialog = () => {
        setDialogChangeNameData({...changeNameData, ...{name: props.experience.name, id: props.experience.id}});
        setOpenChangeDialog(true);
    };


    function closeNotification() {
        setNotification({ showNotification: false })
    }

    return (
        <CardWrapper ref={element}>
            {show &&
            <Card style={{width: '232px', height: '200px'}}
                  className={props.className + ` experience-card ${props.experience.active ? 'activeExp' : 'inactiveAndDisabledExp'}`}>
                <a href={getMonorailUrl()}  target="_blank" rel="noopener noreferrer">
                    <CardPrimaryAction>
                        <CardMedia
                            sixteenByNine
                            style={{
                                backgroundImage: 'url(/images/illustration-world.svg)'
                            }}
                        />
                        <ExperienceType experience={props.experience} visible={false} goToEditor={goToMonorail}/>
                    </CardPrimaryAction>
                </a>
                <CardActions>
                    <div className={'card-experience description-block'}>
                        <div style={{marginLeft: '16px', marginRight: '16px', marginTop: '8px'}}>
                            <Whisper placement="bottom" trigger="hover" speaker={getPopover(props.experience.name)}>
                                <div className="truncateTitle">
                                    <span className={'card-experience title'}>{props.experience.name}</span>
                                </div>
                            </Whisper>
                        </div>

                        <div className="card-experience actions" style={{marginTop: '8px'}}>
                                                        <span className={'card-experience time'}> <Moment locale={locale}
                                                            fromNow>{props.experience.updated_at}</Moment></span>
                            <CardActionIcon icon={
                                <ElipsisMenu onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    <div className={'menu-item-dark'}
                                         onClick={handleQRDialog}>
                                         <span>{t('experiences.actions.watchQR')}</span>
                                    </div> 
                                        {/*Descomentar esto para luego poder editar el nombre*/}                                   {/*  <div className={'menu-item-dark'} onClick={handleChangeNameDialog}>
                                        <span>{t('pages.campaigns.actions.changeName')}</span>
                                    </div> */}

                                    <CheckPermission
                                        component={canSeeReport() && (
                                            <div className={`menu-item-dark ${isFreelance() ? 'report' : ''}`}
                                                 onClick={handleMenuClick.bind({key: 'reports'})}>
                                                <span
                                                    className={`${isFreelance() ? 'menu-disabled' : ''}`}>{t('experiences.actions.seeReports')}</span>
                                                <CheckPermission
                                                    component={<Badge
                                                        theme={['onSecondary']}
                                                        align="inline"
                                                        label="PRO"
                                                        style={{backgroundColor: '#F034A5', opacity: '1'}}
                                                    />}
                                                    groups={[userGroupsObject.FreelanceUsers]}
                                                />
                                            </div>
                                        )}
                                        groups={[
                                            userGroupsObject.CamonappUsers,
                                            userGroupsObject.CamonappAdminUsers,
                                            userGroupsObject.CamonappSalesUsers,
                                            userGroupsObject.FreelancePaidUsers,
                                            userGroupsObject.CompanyUsers,
                                            userGroupsObject.FreelanceUsers,
                                            userGroupsObject.FreelancePaidUsers,
                                        ]}
                                    />
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'delete'})}>
                                        <span>{t('actions.delete')}</span>
                                    </div>
                                    {
                                        isEditor() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'editAR'})} >
                                            <span>{t('actions.editAR')}</span>
                                        </div> 
                                    }
                                    {
                                        isEditor() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'changeAlias'})} >
                                            <span>{t('actions.changeAlias')}</span>
                                        </div> 
                                    }
                                    {
                                        isEditor() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'changeName'})} >
                                            <span>{t('actions.changeName')}</span>
                                        </div> 
                                    }
                                    {
                                        isEditor() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'changeLoading'})} >
                                            <span>{t('actions.changeLoading')}</span>
                                        </div> 
                                    }
                                    {
                                        isEditor() && !isFreelancePaid() &&
                                        <div className={'menu-item-dark'}  onClick={handleMenuClick.bind({key: 'experienceAd'})} >
                                            <span>{props.experience.is_ad ? t('actions.converToNormal') : t('actions.converToAd') }</span>
                                        </div> 
                                    }
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'migrate'})}>
                                        <span>{t('experiences.actions.migExp')}</span>
                                    </div>
                                    {
                                        isAdmin() ?
                                            <div className={'menu-item-dark'} onClick={openMigrationExperienceAdmin}>
                                                <span>{t('experiences.actions.migExpAdmin')}</span>
                                            </div> : null
                                    }
                                    <div className={'menu-item-dark'} onClick={handleMenuClick.bind({key: 'clone'})}>
                                        <span>{t('experiences.actions.cloneExp')}</span>
                                    </div>
                                </ElipsisMenu>
                            }/>
                        </div>
                    </div>
                </CardActions>
                {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
                {exp &&
                <Modal {...cmpDataObj} onClose={() => {
                    setExp(false)
                }}/>
                }
            </Card>
            }
        </CardWrapper>

    )
});

export default ModelViewCard;
