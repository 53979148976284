import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';

import SelectControl from '../common/select';
import campaignService from '../../services/Campaign';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';

function ExpName(props) {
    const [campaign, setCampaign] = useState('');
    const [campaings, setCampaigns] = useState([]);
    const t = useTranslate();


    useEffect(() => {
        fetchCampaings();
    }, [])

    function fetchCampaings() {
        campaignService.list(false)
            .then((campaingsResponse) => {
                const campaingsMapped = campaingsResponse.filter(c => c.id !== props.data.campaign.id).map(camp => {
                    return {
                        label: camp.name,
                        value: camp.id
                    }
                });
                setCampaigns(campaingsMapped);
            });
    }
    return (
        <SelectControl label={t('project')} name="user_type" className="fullwidth form-item" value={campaign} options={campaings} onChange={e => {
            setCampaign(e.target.value)
            props.onChange({
                campaign: {
                    id: e.target.value
                }
            });
        }} onBlur={(e) => {


            props.onChange({
                campaign: {
                    id: campaign
                }
            });
        }} />
    )
}

export default ExpName;