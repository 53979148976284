import React, {useEffect, useState, useContext, Fragment} from 'react';
import {useTranslate} from 'react-polyglot';
import './Publications.scss'
import AppContext from '../../components/app/AppContext';
import ExperienceService from '../../services/Experience';
import FilterBy from '../../components/Filters/FilterBy';
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import {QRDialogContext} from "../../context/QRDialogContext";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";
import Loading from '../../components/loading';
import {Pagination} from '@mui/material'
import { UserContext } from '../../context/UserContext';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import Table from '../../components/Table'
function Publications() {
    const context = useContext(AppContext);
    const [page,setPage] = useState(1)
    const [experiences, setExperiences] = useState([])
    const [total, setTotal] = useState(0)
    const t = useTranslate();
    let perPage = 50
    const {setNavLinks} = useContext(UserContext);
    const { 
        showLoading,
        setShowLoading,
        overlayStyle, 
        setOverlayStyle,
    } = useContext(ShowLoadingContext)
    const handleChange = (event, value) => {
        setPage(value);
      };
    console.log(page)
    const fetchData = async () => {
        setShowLoading(true)
        setOverlayStyle("loading-overlay-projects")
        const {data,total} = await ExperienceService.published(true,page,perPage);
        setTotal(total)
        setExperiences(data || []);
        setShowLoading(false)
    }
    useEffect(() => {
        if(page !== 1)fetchData()
    },[page])
    useEffect(() => {
        context.setPage({
            name: t('pages.recent.recentExp'),
            refetch: fetchData
        });
        if(page === 1) fetchData();
        setNavLinks([{
            id: '',
            name: t('tablePublications.title'),
            tab: 'controlpanel'
        }])
    }, [])
    const {MigrationUserExperienceDialog} = useContext(MigrateUserDialogContext);
    const {QRDialog} = useContext(QRDialogContext);
    const {ChangeTargetDialog} = useContext(ChangeTargetDialogContext);
    const {
        ChangeNameDialogComponent
    } = useContext(ChangeNameDialogContext);
    return (
        <Fragment>
            {QRDialog()}
            {MigrationUserExperienceDialog()}
            {ChangeTargetDialog()}
            {ChangeNameDialogComponent()}
            {showLoading && 
                <Loading/>
            }
            
            <FilterBy data={experiences} typeData="experiences">
                
                {result => (
                    <div>
                      <Table experiences={result}></Table>
                    </div>
                )}
            </FilterBy>
            <Pagination count={Math.ceil(total/perPage)} page={page} className='controlpanel-pagination' color='primary' onChange={handleChange} />
        </Fragment>
    );
}

export default Publications;
