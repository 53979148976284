import React, { useState } from 'react';
import { Button } from '@rmwc/button';
import {CardMedia} from '@rmwc/card';
import { CardActionIcon, CardActionIcons } from 'rmwc';

const SlideshowDemoExperience = ({onClick, experienceUrl}) => {
    const [thumbnailIndex, setThumbnailIndex] = useState(0)

    const steps = [
        `${experienceUrl}/assets/thumbnail_studio/1.png`,
        `${experienceUrl}/assets/thumbnail_studio/2.png`,
        `${experienceUrl}/assets/thumbnail_studio/3.png`,
        `${experienceUrl}/assets/thumbnail_studio/4.png`,
    ]

    function getUrlThumbnail(i) {
        return `${experienceUrl}/assets/thumbnail_studio/${i+1}.png`
    }

    const handleBack = () => {
        setThumbnailIndex((i) => i === 0 ? 3 : i-1 )
    }

    const handleNext = () => {
        setThumbnailIndex((i) => i === 3 ? 0 : i+1 )
    }

    return (<>
        <CardMedia
            sixteenByNine
            style={{
                backgroundImage: `url(${getUrlThumbnail(thumbnailIndex)})`
            }}
            onClick={onClick}
        />
        <div style={{'width': '36px','position': 'absolute', 'top': '50%','transform': 'translateY(-50%)', 'left': '0px'}}>
            <CardActionIcon icon="navigate_before" onClick={handleBack}/>
        </div>
        <div style={{'position': 'absolute', 'top': '50%','transform': 'translateY(-50%)', 'right': '0px'}}>
            <CardActionIcon icon="navigate_next" onClick={handleNext}/>
        </div>
    </>);
};

export default SlideshowDemoExperience;