import React from 'react';

const ExperienceSubtitle = ({subT}) => {
    return (
        <h4 className={'subTExp'}>
            {subT}
        </h4>
    );
};

export default ExperienceSubtitle;