import React, {createContext, useEffect, useState} from 'react';

export const UserContext = createContext();

const UserProvider = (props) => {

    const [navLinks, setNavLinks] = useState([]);
    const [userData, setUserData] = useState({
        id: '',
        username: ''
    });
    return (<UserContext.Provider
        value={{navLinks, setNavLinks, userData, setUserData}}>{props.children}</UserContext.Provider>)
};

export default UserProvider;