import jwt from '../utils/jwt';
import request from '../utils/request';

const url = process.env.REACT_APP_CUSTOM_MONORIEL;
const urlModel = process.env.REACT_APP_CUSTOM_MONORIEL_UPLOAD_MODEL;
class CustomMonoriel {

    static async getCustomMonorielConfig(selectedProject) {
        try {
            return fetch(url + '?projectId=' + selectedProject, {
                method: 'GET'
            })
        } 
        catch (error) {
            return Promise.reject(error);
        }
    }

    static async updateCustomMonorielConfig(selectedProject, formData) {
        try {
            const res = await fetch(url + '/background?projectId=' + selectedProject, {
                method: 'POST',
                body: formData
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }
    
    static async createMonorailFolder(selectedProject, formData) {
        try {
            const res = await fetch(url + '/?projectId=' + selectedProject, {
                method: 'POST',
                body: formData
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async changeCletusAlias(selectedProject, formData) {
        const headers = {};
        const token = await jwt();
        headers['authorization'] = `Bearer ${token}`;
        try {
            const res = await fetch(url + '/change-alias/?projectId=' + selectedProject, {
                method: 'POST',
                headers,
                body: formData
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async deleteCletusAlias(selectedProject) {
        const headers = {};
        const token = await jwt();
        headers['authorization'] = `Bearer ${token}`;
        try {
            const res = await fetch(url + '/delete/?projectId=' + selectedProject, {
                method: 'POST',
                headers
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async changeMoesAlias(selectedProject, alias) {
        return request.post(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${selectedProject}/change-alias/${alias}`, {});
    }

    static async changeMoesName(selectedProject, name) {
        return request.post(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${selectedProject}/change-name/${name}`, {});
    }
    static async cletusUpdateGlbUsdz (formData,formData2,selectedProject) {
        try {
            const res = await fetch(urlModel + '/usdz/?projectId=' + selectedProject, {
                method: 'POST',
                body: formData
            });
            const res2 = await fetch(urlModel + '/glb/?projectId=' + selectedProject, {
                method: 'POST',
                body: formData2
            });
            
            return Promise.all([res,res2]);

        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default CustomMonoriel;
