import React, { memo } from 'react';
import './SeeTarget.scss';

const SeeTarget = memo(function SeeTarget(props) {
    return (
        <div className="seeTarget" >
            <img src={`${props.data.target.target}?${(new Date()).getTime()}`} alt="Target" />
        </div>
    )
})



export default SeeTarget
