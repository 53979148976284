import _ from 'lodash';
import messages from '../lang/index';
import getLocale from "./locale";

const locale = getLocale() || 'en';
const message = messages[locale];

export const emailValidation = email => {

    if (
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
        )
    ) {
        return null;
    }
    if (email.trim() === "") {
        return `${message.pages.subscription.form.emailReq}`;
    }
    return `${message.pages.subscription.form.validEmail}`;
};

export const fieldRequired = (value, field) => {
    if (value.trim() === "") {
        return `${message.pages.subscription.form.fieldReq} ${field}`;
    }
    return null;
};

export const phoneValidation = value => {
    if (_.trim(value) === "") {
        return `${message.pages.subscription.form.reqTelephone}`;
    }
    if (!
        /^[0-9]+$/.test(
            value
        )
    ) {
        return `${message.pages.subscription.form.validTelephone}`;
    }
    return null;
};

