import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useTranslate } from 'react-polyglot';

import AppContext from "../../components/app/AppContext";
import ReportHeader from "../../components/Reports/ReportHeader";
import ReportOverViewData from "../../components/Reports/ReportOverViewData";
import ReportGraphicBarChart from "../../components/Reports/ReportGraphicBarChart";
import ReportTableDemographicChart from "../../components/Reports/ReportTableDemographicChart";
import { navigate } from '@reach/router';
import './Reports.scss'
import ReportsService from "../../services/Reports";
import moment from "moment";
import {
    getCompanyColumns,
    getCampaignColumns,
    getColumnsInitialState,
    getFilters,
    getExperienceColumns
} from "./ReportUtils";
import userService from "../../services/User";
import AlertDialog from "../../components/general/AlertDialog";
import { validRolesToGetCompany } from "./ReportUtils";
import { userGroupsObject } from "../../utils/userGroups";

const col = getColumnsInitialState();

const Reports = () => {
    //initial state of the application.
    const context = useContext(AppContext);
    //initial state for the query search on analytics, we take from this day, 2 days before plus one.
    // ex --> if we enter at 11/03/2020 the initial from and to will be 9/03/2020 and 10/03/2020
    const [initialData, setInitialData] = useState({
        from: moment().startOf('month').format('DD-MM-YYYY'),
        to: moment().subtract(1, "days").format("DD-MM-YYYY"),
        company_id: '',
        campaign_id: '',
        experience_id: ''
    });

    /**
     * STATES
     */
    const [company, setCompany] = useState({
        id: '',
        company_name: ''
    });
    const [firstCallData, setFirstCallData] = useState(false);
    const [campaign, setCampaign] = useState('');
    const [data, setData] = useState({});

    const t = useTranslate();

    const [loading, setLoading] = useState(true);
    const [navLinks, setNavLinks] = useState([{ tab: '/reports', text: t('pages.reports.tabs.allCompanies') }]);
    const [backButton, setBackButton] = useState('');
    const [insideDay, setInsideDay] = useState(false);
    const [columns, setColumns] = useState(col);
    const [withQuery, setWithQuery] = useState(false);

    const [companyNameTab, setCompanyNameTab] = useState(t('pages.reports.tabs.allCompanies'));
    const [campaignNameTab, setCampaignNameTab] = useState('');
    const [experienceNameTab, setExperienceNameTab] = useState('');
    const [idFilters, setIdFilters] = useState('');
    const [open, setOpen] = useState(false);


    useEffect(() => {
        setFirstCallData(true);
        if (company.id !== '') {
            setNavLinks([{ tab: '/company', company_id: company.id, text: company.name }]);
            setInitialData({ ...initialData, ...{ company_id: company.id } });
            let colTemplate;
            colTemplate = getCompanyColumns();
            setColumns(colTemplate);
        }
    }, [company]);

    useEffect(() => {
        if (withQuery)
            getAnalyticsData();
    }, [withQuery]);

    /**
     * EFFECT firing the FIRST TIME that the page render.
     */
    //effect to hide the main filters and to call first time filters
    useEffect(() => {
        context.setPage({
            name: 'Reportes',
            hideFilters: true,
            shortLayout: false,
            noRenderFilterContainer: true,
            loading: true
        });
        setLoading(true);
        if (window.location.search !== '') {
            let aTabs = [];
            const urlParams = new URLSearchParams(window.location.search);
            const company_id = urlParams.get('company_id') || '';
            let colTemplate;
            if (company_id) {
                aTabs.push({ tab: '/company', company_id: company_id, text: companyNameTab });
                colTemplate = getCompanyColumns();
                setColumns(colTemplate);
            }
            const campaign_id = urlParams.get('campaign_id') || '';
            if (campaign_id) {
                aTabs.push({ tab: '/campaign', campaign_id: campaign_id, text: t('pages.reports.tabs.campaign') });
                colTemplate = getCampaignColumns();
                setColumns(colTemplate);
            }
            const experience_id = urlParams.get('experience_id') || '';
            if (experience_id) {
                aTabs.push({
                    tab: '/experience',
                    experience_id: experience_id,
                    text: t('pages.reports.tabs.experience')
                });
                colTemplate = getExperienceColumns();
                setColumns(colTemplate);
            }
            const from = urlParams.get('from') || moment().startOf('month').format('DD-MM-YYYY');
            const to = urlParams.get('to') || moment().subtract(1, "days").format("DD-MM-YYYY");
            setInitialData({ ...initialData, ...{ company_id, campaign_id, experience_id, from, to } });
            setNavLinks(aTabs.length === 0 ? [{ tab: '/reports', text: t('pages.reports.tabs.allCompanies') }] : aTabs);
            setWithQuery(true);
        }
    }, []);

    useEffect(() => {
        if (window.location.search !== '') {
            let navLinks = [];
            if (initialData.company_id) {
                navLinks.push({ tab: '/company', company_id: initialData.company_id, text: companyNameTab });
            }
            if (initialData.campaign_id) {
                navLinks.push({ tab: '/campaign', campaign_id: initialData.campaign_id, text: campaignNameTab });
            }
            if (initialData.experience_id) {
                navLinks.push({ tab: '/experience', experience_id: initialData.experience_id, text: experienceNameTab });
            }
            setNavLinks(navLinks);
        }
    }, [companyNameTab, campaignNameTab, experienceNameTab]);

    let hasRoles = () => {
        let values = context.userGroups;
        let valid = false;
        for (let value of values) {
            valid = validRolesToGetCompany.includes(value);
            if (valid) {
                return valid
            }
        }
        return valid;
    };

    const getUserData = async () => {
        if (hasRoles()) {
            let user = await userService.getProfile();
            setCompany({ ...company, ...user.company });
        } else {
            if (window.location.search === '') {
                getAnalyticsData();
            }
        }
    };

    const canSeeReport = () => {
        return !!(context.userGroups.find(e => e === 'CamonappAdminUsers') !== undefined || context.userGroups.find(e => e === 'CompanyUsers') !== undefined || context.userGroups.find(e => e === 'FreelancePaidUsers') !== undefined || context.userGroups.find(e => e === 'CamonappSalesUsers') !== undefined);
    };

    /**
     * EFFECT that triggers every time the analytics data change the state
     */
    useEffect(() => {
        if (!canSeeReport()) {
            navigate('/');
            return;
        }
        setLoading(true);
        if (!firstCallData) {
            getUserData();
        } else {
            if (!withQuery)
                getAnalyticsData()
        }
    }, [initialData]);


    //function handler to get the analytics data
    const getAnalyticsData = async () => {
        try {
            context.setPage({
                name: t('pages.reports.reports'),
                hideFilters: true,
                shortLayout: false,
                noRenderFilterContainer: true,
                loading: true
            });
            let filters = getFilters(initialData, idFilters);
            const data = await ReportsService.getAnalyticsData(filters);
            setData(data);
            setCompanyNameTab(data.company_name);
            setCampaignNameTab(data.campaign_name);
            setExperienceNameTab(data.experience_name);
            setLoading(false);
            context.setPage({
                name: t('pages.reports.reports'),
                hideFilters: true,
                shortLayout: false,
                noRenderFilterContainer: true,
                loading: false
            });
            const {ids} = initialData
            setIdFilters(ids? ids:'');
            setWithQuery(false);
        } catch (e) {
            console.log(e)
        }
    };

    const downloadReport = async () => {
        try {
            let filters = getFilters(initialData);
            let formattedString = encodeURIComponent(context.userData.email);
            filters = filters.concat(`&user_id=${formattedString}&interactions=yes`);
            await ReportsService.exportAG(filters);
        } catch (e) {
            console.log(e)
        }
    };


    return loading ? null : <Fragment>
        <ReportHeader setLoading={setLoading} initialFilterData={initialData} setInitialData={setInitialData}
            navLinks={navLinks} setNavLinks={setNavLinks} backButton={backButton}
            setBackButton={setBackButton} insideDay={insideDay}
            setInsideDay={setInsideDay} setColumns={setColumns} setOpen={setOpen}
            downloadReport={downloadReport} />
        <ReportOverViewData data={data} />
        <ReportGraphicBarChart setLoading={setLoading} initialData={initialData} setInitialData={setInitialData}
            data={data} navLinks={navLinks} setNavLinks={setNavLinks} />
        <ReportTableDemographicChart setLoading={setLoading} initialData={initialData}
            setInitialData={setInitialData}
            data={data} company={company}
            setCompany={setCompany}
            campaign={campaign}
            setCampaign={setCampaign} navLinks={navLinks} setNavLinks={setNavLinks}
            columns={columns}
            setColumns={setColumns} withQuery={withQuery} />
        <AlertDialog title={t('pages.reports.alertTitle')} bodyMessage={t('pages.reports.alertBody')} setOpen={setOpen}
            open={open} />

    </Fragment>
};

export default Reports;