import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../app/AppContext';

const check = (userGroups, allowedGroups) => userGroups.some(userGroup => allowedGroups.includes(userGroup));

function CheckPermision({ component, groups, fallbackComponent = null }) {
  const context = useContext(AppContext)

  return (
    <>
      {check(context.userGroups, groups, fallbackComponent)? component : fallbackComponent}
    </>
  );
}

CheckPermision.propTypes = {
  component: PropTypes.element.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string.isRequired),
  fallbackComponent: PropTypes.element.isRequired
}

CheckPermision.check = check;

export default CheckPermision;
