const { NodeIO } = require('@gltf-transform/core');
const { inspect } = require('@gltf-transform/lib');
const { TextureTransform } = require('@gltf-transform/extensions');
const _ = require('lodash');


export const MAX_RESOLUTION_TEXTURES = "2048x2048";
export const MAX_VERTICES_ASSET= 75000;
const validFileTypes = ['glb', 'GLB'];


const io = new NodeIO();
io.registerExtensions([TextureTransform]);

/**
 * The Node.js filesystem API ('fs') returns a Buffer instance, which may be a
 * view into a larger buffer. Because GLTFLoader parsing expects a raw
 * ArrayBuffer, we make a trimmed copy of the original here.
 *
 * @param  {Buffer} buffer
 * @return {ArrayBuffer}
 */
const trimBuffer = (buffer) => {
    const { byteOffset, byteLength } = buffer;
  
    return buffer.buffer.slice(byteOffset, byteOffset + byteLength);
};

const getMetadata = (buffer) => {
    const doc = io.readBinary(trimBuffer(buffer));
    const res = inspect(doc);
    return res;
}

const validModelFormat = (file) => {
    const fileExtension = file ? file.name.split('.').pop() : '';
    const valid = validFileTypes.includes(fileExtension)
    return valid;

}

const validMaxVertex = (metadata) => {
    const sumVertices = _.sumBy(metadata.meshes.properties, 'vertices');
    return sumVertices < MAX_VERTICES_ASSET;
}

const validMaxTextures = (matadata) => {
    return matadata.textures.properties.every( (prop) => {
        const [x, y] = MAX_RESOLUTION_TEXTURES.split('x');
        const [x1, y1] = prop.resolution.split('x');
        return Number(x1) <= x && Number(y1) <= y;
    })
}

export const validateFile = async (file) => {
    const validFormatResult = validModelFormat(file);
    let errors = {
        validFormat: validModelFormat(file),
    }
    if ( !validFormatResult ) return Promise.resolve( {file: file, errors: errors} )
    const arrayBuffer = await file.arrayBuffer( ); 
    const buffer = Buffer.from(arrayBuffer);
    const metadata = getMetadata(buffer);
    errors = {
        ...errors,
        validMaxVertex: validMaxVertex(metadata),
        validMaxTextures: validMaxTextures(metadata)
    }
    return {file: file, errors: errors }
}

export const errorFormatTraslate = {
    validFormat : 'studioNewFlow.changeModel.validFormat',
    validMaxVertex: 'assets.error.maxVertices',
    validMaxTextures: 'assets.error.maxResolutionGlb'
}

