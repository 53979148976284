export default {
    button: {
        'backgroundColor': '#3492fb',
        'border': '1px solid #3492fb',
        'borderRadius': ' 25px'
    },
    a: {
        'color': '#3492fb'
    }
};
