import React, {useState,useContext} from "react";
import {useTranslate} from 'react-polyglot';
import CustomMonoriel from "../../services/CustomMonoriel";
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import Appcontext from '../app/AppContext';
import "./editAR.scss";
import { TextField} from '@mui/material';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';

export default function ChangeAlias(props) {
    const context = useContext(Appcontext);
    const t = useTranslate();
    const [alias, setAlias] = useState("")
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false,
        icon: ''
    })

    async function changeAlias() {
        try {
            setShowLoading(true);
            const aliasNormalizado = alias.replace(/\s+/g, ''); 
            await CustomMonoriel.changeMoesAlias(props.keyData, aliasNormalizado);
            const formData = new FormData();
            formData.append('newAlias', aliasNormalizado);
            await CustomMonoriel.changeCletusAlias(props.data,formData)
            setOpen({
                title: t('experiences.success'),
                subtitle: t('experiences.successBody'),
                isOpen: true,
                icon: 'check'
            });
            setShowLoading(false);
            context.page.refetch();
        } catch (error) {
            console.log('error', error);
            setShowLoading(false);
            setOpen({
                subtitle: t('experiences.error'),
                isOpen: true,
                icon: 'error'
            });
        }
    }

    return (
        <div className="edit-ar">
            <TextField
                type='text' 
                id="alias" 
                value={alias} 
                label={t('experiences.newAlias')} 
                onChange={(e)=>setAlias(e.target.value)} />

                <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                    <DialogButton action="close">
                        {t('actions.close')}
                    </DialogButton>
                    <DialogButton onClick= {
                            () => {
                                changeAlias();
                            }
                        }>{t('actions.accept')}
                    </DialogButton>
                </DialogActions>

            <Snackbar
                open={open.isOpen}
                message={open.subtitle}
                dismissesOnAction
                action={
                    <SnackbarAction icon={open.icon}/>
                }
            /> 
        </div>
    )
}
