import React, {memo, useState, Fragment} from 'react'
import {Button} from '@rmwc/button';
import {ReactSVG} from 'react-svg';
import {useTranslate} from 'react-polyglot';
import NewExperience from '../../components/actions/NewExperience';
import Modal from '../../components/modal/modal';
import {navigate} from '@reach/router';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import './onboard.scss';
import RegisterHeader from "../../components/headers/RegisterHeader";

const Onboarding = memo(function Onboarding(props) {
    const [exp, setExp] = useState(false);
    const t = useTranslate();

    const closeHandler = (val) => {
        setExp(false)
        if (val) {
            navigate('/');
            props.hide(true)
        }
    }
    return (
        <Fragment>
            <RegisterHeader/>
            <div>
                {!exp &&

                < section className="onboard-container">
                    <div className="amination-ob">
                        <div>
                            <ReactSVG src="/images/onboarding.svg"/>
                        </div>
                        <div className="grid-container-image">
                            <ReactSVG src="/images/grid.svg"/>
                        </div>
                        <div className="grid-container-cursor">
                            <ReactSVG src="/images/cursor.svg"/>
                        </div>
                    </div>
                    <h3>{t('pages.onboarding.welcome')}</h3>
                    <p>{t('pages.onboarding.firstExperience')}</p>
                    <Button label={t('pages.onboarding.buttonFirst')} outlined className="rounded-button"
                            onClick={() => {
                                navigate('/createExp')
                            }}/>
                </section>
                }
                {exp &&
                <Modal component={NewExperience} showback buttonLess full onClose={closeHandler}/>
                }
            </div>
        </Fragment>

    )
})

Onboarding.propTypes = {}

export default Onboarding
