import React, {useEffect,useState,useContext} from "react";
import {useTranslate} from 'react-polyglot';
import CustomMonoriel from "../../services/CustomMonoriel";
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import "./editAR.scss"
import AlertDialog from '../general/AlertDialog';
import { TextField} from '@mui/material';
import Appcontext from '../app/AppContext';

export default function ChangeName(props) {
    const context = useContext(Appcontext);
    const t = useTranslate();
    const [name, setName] = useState("")
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open, setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })

    useEffect(()=> {
    },[])

    async function changeName() {
        try {
            setShowLoading(true)
            await CustomMonoriel.changeMoesName(props.keyData, name)
            .then(rta => {
                setShowLoading(false)
                setOpen({
                 title: t('common.msg.success'),
                 subtitle: t('customLoaderLayout.applyChanges'),
                 isOpen: true
                });
                context.page.refetch();
            });
        } catch (error) {
            throw new Error(error);
        }
        setShowLoading(false); 
    }

    return (
        <div className="edit-ar">
                <TextField
                    type='text' 
                    id="name" 
                    value={name} 
                    label={t('experiences.newName')} 
                    onChange={(e)=>setName(e.target.value)} />

                    <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                    <DialogButton action="close">
                        {t('actions.cancel')}
                    </DialogButton>
                    <DialogButton action="accept" isDefaultAction onClick={
                        () => {
                            changeName();
                        }
                    }>{t('actions.accept')}</DialogButton>

            </DialogActions>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                buttonMessage='OK'
            />  
        </div>
    )
}