import React, {createContext, useState} from 'react';
import { useTranslate } from 'react-polyglot';
export const OverlaysContext = createContext();

const OverlaysProvider = (props) => {
    
    const t = useTranslate();
    const [overlay, setOverlay] = useState ()
    const [variableSelected, setVariableSelected] = useState()
    return (<OverlaysContext.Provider
        value={{
                overlay,setOverlay,
                variableSelected, setVariableSelected,
                }}>
            {props.children}
        </OverlaysContext.Provider>
    );
};

export default OverlaysProvider;
