import React, {useState, useContext, useEffect} from 'react';
import {Match} from "@reach/router";
import {useTranslate} from 'react-polyglot';
import AppContext from '../../components/app/AppContext';
import campaignService from '../../services/Campaign';
import FilterBy from "../../components/Filters/FilterBy";
import './Campaign.scss';
import {UserContext} from "../../context/UserContext";
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';
import Loading from '../../components/loading';
import ShowLoadingContext from '../../context/ShowLoadingContext';


/**
 * @deprecated
 * it is doesn't use
 * @param {*} props 
 * @returns 
 */
function Campaign(props) {
    const t = useTranslate();
    const context = useContext(AppContext);
    const [campaign, setCampaign] = useState({});
    const [id, setId] = useState(null);
    const {userData, navLinks, setNavLinks} = useContext(UserContext);
    const fetchData = () => campaignService.get(id, userData.id);

    const { 
        showLoading,
        setShowLoading,
        setOverlayStyle,
    } = useContext(ShowLoadingContext)


    function refetch() {
        setOverlayStyle("loading-overlay-projects")
        
        fetchData().then(res => {
            setCampaign(res);
        })
        
    }

    useEffect(() => {
        
        if (Object.keys(campaign).length === 0 && id !== null) {
            setShowLoading(true)
            fetchData()
            .then(res => {
                setCampaign(res);
                setOverlayStyle("loading-overlay-projects")
                setNavLinks([{
                    id: '',
                    name: t('pages.campaigns.project'),
                    tab: 'allprojects'
                }, {
                    id: res.id,
                    name: res.name,
                    tab: 'project'
                }]);
                context.setPage({
                    projectOpened: true,
                    name: `${t('pages.campaign.projectBreadcrumb')} ${res.name}`,
                    refetch
                });
            })
            .finally(()=>setShowLoading(false))
        }

    });

    function experiences() {
        return campaign.experiences ? campaign.experiences : [];
    }

    return (
        <Match path="/campaign/:id">
            {props => {
                if (props.match) {
                    setId(props.match.id);
                }
                return (
                    <div className="campaign-container">
                        <>
                            {showLoading && <Loading/>}
                            {
                                <FilterBy data={experiences()} typeData="experiences">
                                    {result => (
                                        result.map((exp, index) => {
                                            return (
                                                <CardHomeStudio key={index} experienceCardStyle={'campaign-exp-card'} experience={exp}/>
                                            )
                                        })
                                    )}
                                </FilterBy>
                            }
                        </>
                    </div>
                )
            }}

        </Match>
    )
}

export default Campaign;
