import React from 'react';
import { errorFormatTraslate } from '../../utils/modelViewerValidations';
import {useTranslate} from "react-polyglot";


const AlertDialogBodyMessageTemplate = ({invalidFiles}) => {
    const t = useTranslate();
    return(
        <div>
            <p> { t('studioNewFlow.changeModel.invalidFile') } </p>
            <br></br>
            <ul>
                { invalidFiles.map(aInvalidFile => <li key={Math.random(10).toString()} > <b> {aInvalidFile.fileName} </b> - { (aInvalidFile.errors.map(err => t(errorFormatTraslate[err]) )).join(', ') } </li> ) }
            </ul>
        </div>
    )
};

export default AlertDialogBodyMessageTemplate;