import React, {memo, useEffect, useContext, useState, useReducer} from 'react'
import {
    DataTable,
    DataTableHead,
    DataTableContent,
    DataTableRow,
    DataTableHeadCell,
    DataTableBody,
    DataTableCell
} from '@rmwc/data-table';
import { Button } from '@rmwc/button';
import { navigate } from '@reach/router';
import { useTranslate } from 'react-polyglot';

import Icon from '../../components/icon';
import UserForm from '../../components/actions/UserForm';
import userService from '../../services/User';
import CheckPermission from '../../components/checkPermision/checkPermision';
import { userGroupsObject } from '../../utils/userGroups';
import Notification from '../../components/notification/notification';
import { UserContext } from "../../context/UserContext";
import Search from '../../components/common/search';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import Loading from '../../components/loading';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import './user.scss';


const DialogDisableDelete = ({ data: { msg } }) => {
    const t = useTranslate();
    return (
      <div>
        <p>{msg}</p>
        <p>{t('common.msg.doYouContinue')}</p>
      </div>
    )
  };

const ITEMS_PER_PAGE = 5;

const Users = memo(function Users({setModal}) {
    const {userData, setUserData} = useContext(UserContext);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [response, setResponse] = useState([]);
    const [search, setSearch] = useState('');
    const [{showNotification, propsNotification}, setNotification] = useReducer(
        (prevState, currState) => ({...prevState, ...currState}),
        {showNotification: false, propsNotification: {}});
    const t = useTranslate();

    const { showLoading, setShowLoading, overlayStyle } = useContext(ShowLoadingContext)


    useEffect(() => {
        setShowLoading(true)
        userService.getUsers(true)
            .then(res => {
                setUsers(res.data);
                setResponse(res)
            })
            .finally(res =>setShowLoading(false))
    }, []);

    function closeNotification() {
        setNotification({showNotification: false})
    }

    async function saveUser(params) {
        try {
            params.user_type = params.userType;
            await userService.addUser(params, 1);
            setNotification({
                showNotification: true,
                propsNotification: {label: 'Success', message: t('pages.account.users.modal.add.msg')}
            })
            getDataPaginated(page);

        } catch (error) {
            setNotification({showNotification: true, propsNotification: {label: 'Error', message: error.message}})
        }


    }

    async function updateUser(params) {
        try {
            params.user_type = params.userType;
            await userService.updateUser(params, 1, this.componentData.id);
            setNotification({
                showNotification: true,
                propsNotification: {label: t('common.msg.success'), message: t('pages.account.users.modal.update.msg') }
            })
            getDataPaginated(page);

        } catch (error) {
            setNotification({showNotification: true, propsNotification: {label: 'Error', message: error.message}})
        }
    }

    async function disableUser() {
        userService.disableUser(this.componentData.userId)
            .then(() => {
                getDataPaginated(page)
            })
            .catch(err => {
                console.log(err)
            });
    }

    async function deleteUser() {
        userService.deleteAccount(this.componentData.userId)
            .then(() => {
                getDataPaginated(page)
            })
            .catch(err => {
                console.log(err)
            });
    }

    function openAddUser(event) {
        const actionsDialogData = {
            title: t('pages.account.users.modal.add.title'),
            componentData: {},
            component: UserForm,
            componentKey: 99,
            action: saveUser,
            buttonLess: true,
        };
        setModal({openModal: true, propsModal: actionsDialogData})
    }

    function editUser(user) {
        const actionsDialogData = {
            title: t('pages.account.users.modal.edit.title'),
            componentData: this,
            component: UserForm,
            componentKey: this.id,
            action: updateUser,
            buttonLess: true,
        };
        setModal({openModal: true, propsModal: actionsDialogData})
    }

    function modalDisableUser(userId) {
        const actionsDialogData = {
            title: t('pages.account.users.modal.disable.title'),
            componentData: {userId, msg: t('pages.account.users.modal.disable.msg') },
            component: DialogDisableDelete,
            componentKey: userId,
            action: disableUser,
        };
        setModal({openModal: true, propsModal: actionsDialogData})
    }

    function modalDeleteUser(userId) {
        const actionsDialogData = {
            title: t('pages.account.users.modal.delete.title'),
            componentData: {userId, msg: t('pages.account.users.modal.delete.msg') },
            component: DialogDisableDelete,
            componentKey: userId,
            action: deleteUser,
        };
        setModal({openModal: true, propsModal: actionsDialogData})
    }

    async function getDataPaginated(pageToGet) {
        return userService.getUsers(
            true,
            pageToGet,
            ITEMS_PER_PAGE,
            { name: search, surname: search, email: search })
            .then(res => {
                setUsers(res.data);
                setResponse(res)
            });
    }

    function goToCampaigns(id, username) {
        setUserData({...userData, ...{id, username}});
        navigate(`/user/${id}`);
    }

    function getLastPage() {
        if (page <= 1) {

        } else {
            setPage(page - 1)
            getDataPaginated(page - 1);
        }

    }

    function getNextPage() {

        if ((response.total / 5) > page) {
            setPage(page + 1)
            getDataPaginated(page + 1);
        }

    }

    function searchUsers(value) {
        setSearch(value);
        userService.getUsers(true, 1, ITEMS_PER_PAGE, { name: value, surname: value, email: value })
            .then(res => {
                setUsers(res.data);
                setResponse(res)
            })
    }

    return (

        <>
            {showLoading ?
                <Loading/>
                :
                <section className="profile-user-container">
                    <div className="ẗable-profile-actions-header">
                        <Search func={searchUsers} />
                        <CheckPermission
                            component={<Button style={{'height':'40px'}} raised label={t('pages.account.users.modal.add.title')} className="rounded-button"
                                            onClick={openAddUser}/>}
                            groups={[userGroupsObject.CamonappAdminUsers]}
                        />
                    </div>
                    <DataTable className={'paddingTables'}>
                        <DataTableContent>
                            <DataTableHead className="list-table-head">
                                <DataTableRow>
                                    <DataTableHeadCell alignMiddle className="first-cell">{t('common.labels.nameSurname')}</DataTableHeadCell>
                                    <DataTableHeadCell
                                        alignMiddle
                                    >
                                        {t('common.labels.email')}
                                    </DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.type')}</DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.status')}</DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.actions')}</DataTableHeadCell>
                                </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                                {users.length > 0 && users.map((user, i) => {
                                    return (
                                        <DataTableRow key={i}>
                                            <DataTableCell
                                                alignMiddle>{`${user.name === null ? "-" : user.name + " " + user.surname}`} </DataTableCell>
                                            <DataTableCell alignMiddle>{user.email}</DataTableCell>
                                            <DataTableCell alignMiddle>{user.userType.name}</DataTableCell>
                                            <DataTableCell alignMiddle>{user.active ? t('common.labels.active') : t('common.labels.inactive')}</DataTableCell>
                                            <DataTableCell className="users-actions">
                                                <CheckPermission
                                                    component={<Icon name={'edit'} alignMiddle
                                                                    onClickFn={editUser.bind(user)}/>}
                                                    groups={[userGroupsObject.CamonappAdminUsers]}
                                                />
                                                <Icon name={'campaings'} onClickFn={() => {
                                                    goToCampaigns(user.id, user.email)
                                                }}/>
                                                {user.active && (
                                                    <CheckPermission
                                                        component={<Icon name={'close'} alignMiddle
                                                                        onClickFn={() => modalDisableUser(user.id)}/>}
                                                        groups={[userGroupsObject.CamonappAdminUsers]}
                                                    />)}
                                                {user.active && (
                                                    <CheckPermission
                                                        component={<Icon name={'delete-bin'} alignMiddle
                                                                        onClickFn={() => modalDeleteUser(user.id)}/>}
                                                        groups={[userGroupsObject.CamonappAdminUsers]}
                                                    />)}
                                            </DataTableCell>
                                        </DataTableRow>
                                    )
                                })
                                }
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                    <div className="table-pagination">
                        <div className="table-pagination-element first">
                        {page > 1 && (
                        <span className="table-pagination-link" onClick={getLastPage}>
                            {t('pages.account.pagination.prev')}
                        </span>
                        )}
                        </div>
                        {response.total / ITEMS_PER_PAGE > page && (
                        <div className="table-pagination-element second">
                            <span className="table-pagination-link" onClick={getNextPage}>
                            {t('pages.account.pagination.next')}
                            </span>
                        </div>
                        )}
                    </div>
                    {showNotification && <Notification {...propsNotification} onClose={closeNotification}/>}
                </section>
            }
        </>
        
    )
})

Users.propTypes = {}

export default Users
