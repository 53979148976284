class Campaign {

    id;
    name;
    expname;
    
    setId(id) {
        this.id = id;
    }

    setName(name) {
        this.name = name;
    }

    setExpName(expname) {
        this.expname = expname;
    }
}

export default Campaign