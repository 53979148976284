import React, { memo, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import PropTypes from 'prop-types'
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
const Notification = memo(function Notification(props) {
    const [open, setOpen] = useState(true);
    const modalEl = document.getElementById('notification');

    const onClose = () => {
        setOpen(false);
        if (props.onClose) props.onClose();
    }

    return ReactDOM.createPortal(<Snackbar
        open={open}
        onClose={onClose}
        message={props.message}
        dismissesOnAction
        action={
            <SnackbarAction
                label={props.label}
            />
        }
    />, modalEl)
})

Notification.propTypes = {
    message: PropTypes.string

}

export default Notification
