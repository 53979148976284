import styled from 'styled-components';

const statusColor = {
    NOT_PUBLISHED: '#0DD391',
    PUBLISHED: '#3487F0',
    ON_REVIEW: '#F0B40F',
    REJECTED: '#EF0000',
};
export const MarkerContainer = styled.div`
    position : absolute;
    display: flex;
    width: 100%;
    top: 4px;
    left: 4px;
    
`
export const MarkerContainerOffline = styled.div`
    position : absolute;
    display: flex;
    width: 100%;
    top : 5px;
    height: 40px;
`

export const TypeWrapper = styled.div`
    background-color: #3487F0;
    width: 26px;
    height: 24px;
    margin-top: 4px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;

`;

export const TypeWithPlatformWrapper = styled.div`
    background-color: #fff;
    width: 71px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;
`;

export const TextPlatform = styled.p`
    font-family: Public Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    margin-top:4px;
    color: #1675e0 !important;
    width:32px;
`;

export const AppOrWebWrapper = styled.div`
    background-color: #fff;
    width: 26px;
    height: 24px;
    margin: 0 4px 0 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;

`;

export const StatusOfExperience = styled.div`
    background-color: ${props => statusColor[props.status]};
    width: 26px;
    height: 24px;
    margin-left: 125px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;
`;

export const StatusWrapper = styled.div`
    background-color: ${props => statusColor[props.status]};
    background-color: #fff;
    width: 26px;
    height: 24px;
    margin: 0 5px 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left:105px;
    color: #fff;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;
`;

export const ButtonWrapper = styled.div`
    background-color: red;
    width: 26px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;
    margin-top: 50px;
    box-shadow: 0px 4px 24px rgba(41, 41, 41, 0.12), 0px 4px 32px rgba(41, 41, 41, 0.04);
    border-radius: 4px;
`;
