import {useContext, useEffect, useState} from 'react';
import _ from 'lodash';
import AppContext from '../../components/app/AppContext';

const mapTypeData = {
    campaigns: {filter: filterCampaigns, sort: sortCampaign},
    experiences: {filter: filterExperiences, sort}
}

const mapSort = {
    recent: 'updated_at',
    created: 'created_at',
    name: 'name'
};

const getFieldSort = (field) => mapSort[field] || 'updated_at';

function filterCampaigns(campaigns, filterType, nameType) {
    return campaigns
        .map(c => filterExperienceByField(c, filterType, nameType))
        .filter(c => c.experiences.length);
}

function filterExperiences(experiences, filter, field) {
    return experiences.filter(exp => exp[field] === filter || filter === 'all')
}

function filterExperienceByField(campaign, filter, field) {
    const camp = {
        ...campaign,
        experiences: campaign.experiences.filter(exp => exp[field] === filter || filter === 'all')
    };
    return camp;
}

function sort(data, field, flow = 'desc') {
    if(field === 'name'){
        flow = 'asc';
    }
    return _.orderBy(data, x => x[field].toLowerCase(), flow)
}

function sortCampaign(data, field) {
    const campaigns = sort(data, 'name', 'asc');
    return campaigns.map(c => ({...c, experiences: sort(c.experiences, field)}));
}

const getExpTypeByName = (val) => {
    switch (val) {
        case 'immersive':
            return 1;
        case 'world':
            return 2;
        case 'image':
            return 3;
        case 'face':
            return 4;
        default:
            return val;
    }
};

export default ({children, data, typeData}) => {
    const context = useContext(AppContext);
    const [dataReturn, setDataReturn] = useState([]);

    useEffect(() => {
        const filterData = (ctxFilters) => {
            let filteredData = [...data];

            if (ctxFilters.type) {
                filteredData = mapTypeData[typeData].filter(filteredData, getExpTypeByName(ctxFilters.type.value), 'experienceTypeId');
            }

            if (ctxFilters.state) {
                filteredData = mapTypeData[typeData].filter(filteredData, ctxFilters.state.value, 'status');
            }

            return mapTypeData[typeData].sort(filteredData, getFieldSort(ctxFilters.sort?.value));
        };


        const ctxFilter = context.filter;
        setDataReturn(filterData(ctxFilter));
    }, [context.filter, data, typeData])

    return children(dataReturn);
}

