import React, { memo, useEffect, useContext } from 'react'
import { useTranslate } from 'react-polyglot';
import UserService from '../../services/User';
import AppContext from '../../components/app/AppContext';
const Confirm = memo(function Confirm(props) {
    const context = useContext(AppContext);
    const t = useTranslate()
    useEffect(() => {

        const url = new URLSearchParams(window.location.search);
        const code = url.get('code');
        const client = url.get('client');
        const username = decodeURIComponent(url.get('username'));

        if (code && client && username) {
            UserService.confirmUser({
                code, client, username
            }).then((res) => {
                context.setPage({
                    ...context.page, notification: {
                        text: t('pages.confirm.confirmeddUser')
                    }
                });
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [])

    return (
        <div>

        </div>
    )
})

Confirm.propTypes = {

}

export default Confirm
