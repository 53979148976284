import React from 'react';
import {ReactSVG} from "react-svg";

const NextArrowComponent = (props) => {
    const {className, style, onClick} = props;
    return (
        <div
            className={`${className} divNextArrow`}
            onClick={onClick}
        >
            <ReactSVG className={'svgArrowPrev'} src="/images/next-arrow-carou.svg"/>
        </div>
    );
};

export default NextArrowComponent;