import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslate} from "react-polyglot";
import {Button, Loader, Modal, Uploader, Icon} from "rsuite";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";


const ChangeTargetComponent = () => {
    const styles = {
        width: '440px',
        height: '320px'

    };
    const t = useTranslate();
    const {openChangeTargetDialog, setOpenChangeTargetDialog, payload, setPayload, onChangeTarget} = useContext(ChangeTargetDialogContext);
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const [fileList, setFileList] = React.useState([]);

    const previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const validFile = (file) => {
        let validFileTypes = ['JPEG', 'jpeg', 'jpg', 'JPG'];
        let fileExtension = file.name.split('.').pop();
        return !validFileTypes.includes(fileExtension)
    };

    const cleanData = () => {
        setFileInfo(null);
        setUploading(false);
        setFileList([]);
    };

    return (
        <div>
            <Modal keyboard={false} size={'sm'} show={openChangeTargetDialog} onHide={(e) => {
                e.stopPropagation();
                setFileInfo(null);
                setOpenChangeTargetDialog(false);
            }}>
                <Modal.Header>
                    <Modal.Title>{t('experiences.actions.changeTarget')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        <Uploader accept={'image/jpg'} fileList={fileList} draggable autoUpload={false}
                                  fileListVisible={false}
                                  onChange={(file, e) => {
                                      if (file.length === 0) {
                                          cleanData()
                                      } else {
                                          if (!validFile(file[0].blobFile)) {
                                              if (file[0].blobFile.size >= 5000000){
                                                  cleanData()
                                              }else{
                                                  previewFile(file[0].blobFile, value => {
                                                      setFileInfo(value);
                                                      setPayload({...payload, ...{file: file[0].blobFile}})
                                                  });
                                              }
                                          } else {
                                              cleanData()
                                          }
                                      }
                                  }}
                        >
                            <button style={styles}>
                                {uploading && <Loader backdrop center/>}
                                {fileInfo ? (
                                    <img alt={''} src={fileInfo} width="100%" height="100%"/>
                                ) : (
                                    <div>
                                        <p className="img-selector-title">
                                            {t('experiences.addOrDragTarget')}
                                        </p>
                                        <Button appearance={'primary'}
                                                style={{marginTop: '10px'}}> {t('experiences.addTarget')} </Button>
                                    </div>
                                )}
                            </button>
                        </Uploader>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={!fileInfo} onClick={() => {
                        setFileInfo(null);
                        setOpenChangeTargetDialog(false);
                        onChangeTarget();
                    }} appearance="primary">
                        {t('actions.changeTarget')}
                    </Button>
                    <Button onClick={() => {
                        setFileInfo(null);
                        setOpenChangeTargetDialog(false);
                    }} appearance="primary">
                        {t('actions.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ChangeTargetComponent;