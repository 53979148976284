import React, {Fragment, useState} from 'react';
import { TextField, FormControl,FormGroup,InputLabel, Select,MenuItem, Slider, Grid} from '@mui/material';
    
import './Previsualiser.css'


function BackgroundForm(props) {
   
    const {onChange, onChangeOpacity,hiddenOpacity, classInput} = props;
    const  handleChangeOpacity = ()=>{
        const opacity =document.getElementById("opacity").value;
        if( opacity < 0){
            document.getElementById("opacity").value = 0;
        }
        if(opacity > 100){
            document.getElementById("opacity").value = 100;
        }
        const value =   document.getElementById("opacity").value;
        
        onChangeOpacity(
            {
                target:{
                    value : value
                }
            }
        )
    }
    return (
        <div style={{display:"flex", flexDirection: "row", alignItems:"center", width:"100%"}}>
                    <TextField 
                        className={classInput ? classInput : 'item-overlay'}
                        size='small'
                        type='text' 
                        id="backgroundColor" 
                        value={props.color} 
                        label={props.label ? props.label : "Color"}
                        onChange={onChange}/>
                    <input 
                        className='color-picker-CL' 
                        type='color' 
                        id="backgroundColor" 
                        value={props.color} 
                        variant="outlined" 
                        onChange={onChange}/>
                    {
                        hiddenOpacity  && (
                            <Grid item xs={3}>
                                <TextField 
                                    
                                    size='small'  
                                    type="number" 
                                    InputProps={{ inputProps: { min: 0, max: 100 } }} 
                                    id="opacity" 
                                    label="opacity" 
                                    value={props.opacity} 
                                    variant="outlined" 
                                    onChange = {handleChangeOpacity}/>
                            </Grid>)
                    }
    </div>
    );
}

export default BackgroundForm;
