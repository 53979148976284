import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@rmwc/button';
import './style.scss'
import { useTranslate } from 'react-polyglot';
import AlertDialog from '../general/AlertDialog';

function InputFile (props) {
    const [fileValue, setFileValue] = useState(false);
    const [inputFileValue, setInputFileValue] = useState('');
    const [filePath, setFilePath] = useState('');
    const {handleFile, defaultFile, handleURL, handleQuitFile } = props;
    const t = useTranslate();
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false,
        icon: ''
    })
    useEffect(()=>{
        let formatImage = inputFileValue.split(".");
        let formatImageParse = formatImage[formatImage.length-1];
        if(fileValue && formatImageParse !== 'jpg' && formatImageParse !== 'jpeg')  {
            setOpen({
                title: t('editAr.error'),
                subtitle: t('editAr.formatImage'),
                isOpen: true
            });
            quitFile();
        }
    },[fileValue])

    function getFilePath(file) {
        if (file.files['0']) {
            const url = URL.createObjectURL(file.files[0]);
            setFilePath(url);
            handleFile(file.files['0']);
            handleURL(url);
        } else {
            setFileValue(false);
        }
    }

    function quitFile() {
        setFilePath('');
        setFileValue(false);
        handleQuitFile()
        handleURL(null)
    }

    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('inputFile').click();
    }
    return (
        <div className='preview-logo-container'>
            <div className={'new-logo-preview' + (fileValue ? ' file-selected' : '')}
                 onClick={() => {
                    if (fileValue) {
                        document.getElementById('inputFile').click();
                    }
                }}
            >
                <input id="inputFile"
                       type="file"
                       name="image"
                       style={{ display: 'none' }}
                       value={inputFileValue}
                       onChange={(e) => {
                             setInputFileValue(e.target.value);
                             setFileValue(true);
                             getFilePath(e.target);
                         }
                       }
                />
                {defaultFile ?
                    <img src={filePath ? filePath : defaultFile} alt={'fileValue'} className={'new-logo-preview-image'} />
                    :
                    <div className={'image-input'}>
                        <div className={'label-input-image'}>
                            <span>{t('dragAndDropFile.text')}</span>
                        </div>
                        <div>
                            <Button outlined label={t('dragAndDropFile.add')} className={'new-logo-button'} onClick={triggerFile} />
                        </div>
                         <span className={'label-input-image'}>{t('dragAndDropFile.format2')}</span> 
                    </div>
                }
            </div>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                functionHandler={quitFile}
                buttonMessage={t("editAr.ok")}
            />
            <Button className='quit-image' outlined onClick={() => quitFile()}>{t('dragAndDropFile.quit')}</Button>
        </div>
    );
}

export default InputFile;