import React, { memo, useState, useEffect } from 'react'
import { Select } from '@rmwc/select';

import '@rmwc/select/select.css';
import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/ripple/dist/mdc.ripple.css';
import PropTypes from 'prop-types'

const SelectControl = memo(function (props) {
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!value) {
            setValue(props.value)   
        }
    }, [props.value])

    const onChange = (e) => {
        setValue(e.target.value);
        e.target.name = props.name;
        props.onChange(e);
    }

    return (
        <Select
            {...props}
            label={props.label}
            outlined
            name={props.name}
            options={props.options}
            value={value}
            onChange={onChange}
            onBlur={(e) => { e.target.name = props.name; props.onBlur(e) }}
            className={props.className}
        >
            {props.children}
        </Select>
    )
})

SelectControl.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string
}

export default SelectControl
