import React, { useState, useEffect } from "react";

function CommonForm({ initialValues, validate, funcSubmit, children, onBlur }) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues])

  // change event handler
  const handleChange = evt => {
    const { name, value, checked, type } = evt.target;

    // keep number fields as numbers
    //const value = type === "number" ? +newValue : type === 'checkbox' ? checked : newValue;

    // save field values
    setValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
/*
    // was the field modified
    setTouched({
      ...touched,
      [name]: true
    });
    if (onBlur) onBlur({
      ...values,
      [name]: value
    });*/
  };

  const handleBlur = evt => {
    const { name, value } = evt.target;

    // remove whatever error was there previously
    const { [name]: removedError, ...rest } = errors;

    // check for a new error
    const error = validate[name]? validate[name](value) : null;

    // // validate the field if the value has been touched
    setErrors({
      ...rest,
      ...(error && { [name]: error })
    });
    if (onBlur) onBlur(values);
  };

  // form submit handler
  const handleSubmit = evt => {
    evt.preventDefault();

    // validate the form
    const formValidation = Object.keys(values).reduce(
      (acc, key) => {
        const newError = validate[key] ? validate[key](values[key]) : null;
        const newTouched = { [key]: true };
        // remove whatever error was there previously
        const { [key]: removedError, ...rest } = acc.errors;
        return {
          errors: {
            ...rest,
            ...(newError && { [key]: newError })
          },
          touched: {
            ...acc.touched,
            ...newTouched
          }
        };
      },
      {
        errors: { ...errors },
        touched: { ...touched }
      }
    );
    setErrors(formValidation.errors);
    setTouched(formValidation.touched);

    if (
      !Object.values(formValidation.errors).length && // errors object is empty
      Object.values(formValidation.touched).length ===
        Object.values(values).length && // all fields were touched
      Object.values(formValidation.touched).every(t => t === true) // every touched field is true
    ) {
      funcSubmit(values);
    }
  };

  return (
    <>
      {children({
        handleBlur, handleChange, handleSubmit, errors, touched, values
      })}
    </>
  );
}

export default CommonForm;
