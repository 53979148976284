import axios from 'axios';
import jwt from './jwt';

class Request {

    static async get(url, params = {}, auth = true) {
        return Request._request(url, params, auth);
    }

    static post(url, params, auth = true) {
        const body = new FormData();
    
        for (let key in params.body) {
            body.append(key, params.body[key]);
        }
        params = {
            method: 'POST',
            ...params

        }
        params.body = body;
        return Request._request(url, params, auth);
    }

    static postJson(url, params, auth = true) {

        params = {
            method: 'POST',
            ...params

        }
        return Request._request(url, params, auth);
    }

    static put(url, params, auth = true) {
        params = {
            method: 'PUT',
            ...params,
        }
        return Request._request(url, params, auth);
    }

    static delete(url, params, auth = true) {
        params = {
            method: 'Delete',
            ...params,
        }
        return Request._request(url, params, auth);
    }

    static async _request(url, params, auth) {
        const headers = {};
        if (auth) {
            const token = await jwt();
            headers['authorization'] = `Bearer ${token}`;
        }
        const axiosConfig = {
            method: params.method,
            headers,
            url,
            validateStatus: () => {
                return true;
            }
        }
        if (params.body) {
            axiosConfig.data = params.body;
        }
        try {
            const response = await axios(axiosConfig);
            if (response.status >= 400) {
                throw (response.data.message || response.data.errors?.shift());
            }
            return Promise.resolve(response.data);
        } catch (error) {
            if (typeof error === 'string') {
                throw new Error(error);   
            }
            throw new Error(`${error.param}: ${error.msg}`)
        }
    }
}

export default Request;

