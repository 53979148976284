import { Auth } from 'aws-amplify';
import moment from 'moment';

export default async () => { return await Auth.currentAuthenticatedUser() }

export async function getUserGroups() {
    const session = await Auth.currentSession();
    const accessToken = await session.getAccessToken();
    return Promise.resolve(accessToken.payload['cognito:groups']);
}

export function getExpirationDays(createdAt) {
    const expirationDay = moment(createdAt).add(15, 'd');
    const currentDay = moment();
    return expirationDay.diff(currentDay, 'days');
}

export function isExpiredUser(user) {
    return getExpirationDays(user.created_at) <= 0;
}
