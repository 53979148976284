import React, {useEffect, useState, useContext, Fragment} from 'react';
import {useTranslate} from 'react-polyglot';
import AppContext from '../../components/app/AppContext';
import FilterBy from '../../components/Filters/FilterBy';

import CampaignListItem from './campaignListItem';
import campaignService from '../../services/Campaign';
import {navigate} from "@reach/router"
import './Home.scss';
import '@brainhubeu/react-carousel/lib/style.css';
import '@material/list/dist/mdc.list.css';
import '@rmwc/list/collapsible-list.css';
import {UserContext} from "../../context/UserContext";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import {checkSessionExpired} from "../../utils/utils";
import MigrateUserProjectDialog from "../../components/actionsDialog/MigrateUserProjectDialog";
import {Card} from "@rmwc/card";
import CampaignService from "../../services/Campaign";
import AlertDialog from "../../components/general/AlertDialog";
import Loading from "../../components/loading";
import MigrationUserExperienceDialogComponent
    from "../../components/actionsDialog/MigrationUserExperienceDialogComponent";
import {QRDialogContext} from "../../context/QRDialogContext";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";
import ShowLoadingContext from '../../context/ShowLoadingContext';

function Home() {
    const t = useTranslate();
    const context = useContext(AppContext);
    const [campaigns, setCampaigns] = useState([]);
    const {userData} = useContext(UserContext);
    const { showLoading, setShowLoading, overlayStyle } = useContext(ShowLoadingContext)

    async function fetchData() {
        try {
            setShowLoading(true)
            let campaignsResponse = [];
            if (window.location.pathname === '/published') {
                campaignsResponse = await campaignService.listWithExperiencesPublished(userData.id);
            } else {
                campaignsResponse = await campaignService.listWithExperiences(userData.id);
            }
            setCampaigns(campaignsResponse.data);
        } catch (error) {
            openOnBoarding();
        } finally {
            setShowLoading(false)
        }
    }

    useEffect(() => {
        
        context.setPage({
            name: t('pages.campaigns.project'),
            refetch: fetchData
        });
        fetchData();
        checkSessionExpired();
    }, [, userData]);


    function openOnBoarding() {
        navigate('/onboarding')
    }

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [openUserExperienceDialog, setOpenUserExperienceDialog] = useState(false);

    const onMigrate = (selectedUser) => {
        context.setPage({
            loading: true
        });
        CampaignService.migrateCampaign(selectedCampaign, selectedUser)
            .then((res) => {
                fetchData();
                context.setPage({
                    loading: false
                });
                context.page.refetch();
            }).catch((e) => {
                context.setPage({
                    loading: false
                });
                setOpenMigrateDialog(false);
                setOpenAlertDialog(true);
                context.page.refetch();
        });
    };

    const [openMigrateDialog, setOpenMigrateDialog] = useState(false);

    const openUserMigrationDialog = () => {
        setOpenMigrateDialog(true);
    };

    //new Dialog
    const {MigrationUserExperienceDialog} = useContext(MigrateUserDialogContext);
    const {QRDialog} = useContext(QRDialogContext);
    const {ChangeTargetDialog} = useContext(ChangeTargetDialogContext);
    const {
        ChangeNameDialogComponent
    } = useContext(ChangeNameDialogContext);
    return (
        <Fragment>

            {showLoading && 
                <Loading />
            }

            <AlertDialog open={openAlertDialog} title={'Error'}
                         bodyMessage={'Se ha producido un error al migrar proyecto'} setOpen={setOpenAlertDialog}/>
            <MigrateUserProjectDialog open={openMigrateDialog} setOpenMigrateDialog={setOpenMigrateDialog}
                                      onMigrate={onMigrate}/>
            {ChangeTargetDialog()}
            {QRDialog()}
            {MigrationUserExperienceDialog()}
            {ChangeNameDialogComponent()}


            
            <FilterBy data={campaigns} typeData="campaigns">
                {campaignsFiltered => (
                    <div className="campaing-cards-container">
                        <div className="list">
                            {campaignsFiltered && campaignsFiltered.map(camp =>
                                <CampaignListItem onMigrate={onMigrate}
                                                openUserMigrationDialog={openUserMigrationDialog} key={camp.id}
                                                campaign={camp} setSelectedCampaign={setSelectedCampaign}
                                                reload={() => {
                                                    fetchData();
                                                }}/>
                            )}
                        </div>
                    </div>
                )}
            </FilterBy>
        </Fragment>

    );
}

export default Home;
