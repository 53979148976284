import React from 'react';
import {ReactSVG} from "react-svg";
import {Button} from "rsuite";
import {useTranslate} from "react-polyglot";


const TrialPayUsersWorldWebComponent = ({hover,setHover}) => {
    const t = useTranslate();
    return (
        <div className={'new-experience-type'} style={{ 'box-shadow':'none' }}onMouseEnter={() => {
            setHover({world_tracking_type: 'web_world_tracking'});
        }} onMouseLeave={() => {
            setHover({world_tracking_type: ''});
        }} onClick={() => {
            /*setTitle('World Tracking | Web');
            setStep(['world_tracking', 'world_tracking_type']);
            setExperienceRoad({...experienceRoad, ...{world_tracking_type: 'web', experience_type: '2s3'}});*/
        }}>
            <div className={'new-experience-type-header'}>
                <div className={'placeholder'}>
                    {hover.world_tracking_type === 'web_world_tracking' ?
                        <img src="/images/publish-web.svg" alt=""
                             style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                        :
                        <img src="/images/publish-web.svg" alt=""
                             style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                    }
                </div>
                <Button color="blue" className={'soonButton'} style={{'text-transform': 'uppercase'}}>
                    {t('experienceNewFlow.prox')}
                </Button>
            </div>
            <div className={'new-experience-type-title'}>
                            <span
                                className={'noHoverTitleGray'}>{t('experienceNewFlow.web')}</span>
            </div>
            {/*ss*/}
            <div className={'new-experience-type-text'}>
                <p className={'noHoverSpan'}>
                    {t('experienceNewFlow.worldTrackingWebDescription')}
                </p>
            </div>
            <div className={'new-experience-type-button'}>
            </div>
        </div>
    );
};

export default TrialPayUsersWorldWebComponent;