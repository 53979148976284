import React, {useState} from 'react';
import ExperienceSubtitle from "./ExperienceSubtitle";
import {ReactSVG} from "react-svg";
import TrialPayUsersWorldWebComponent from "./TrialPayUsersWorldWebComponent";
import EditorComponentWorldWeb from "./EditorComponentWorldWeb";
import {useTranslate} from "react-polyglot";
import {Button} from "rsuite";

const WorldTrackingTypeComponent = ({step, setStep, experienceRoad, setExperienceRoad, setTitle, role}) => {
    const t = useTranslate();
    const [hover, setHover] = useState({world_tracking_type: ''});
    const getComponentRole = () => {
        let roleValue = role();
        switch (roleValue) {
            case 'TrialOrPay':
                return <TrialPayUsersWorldWebComponent hover={hover} setHover={setHover}/>;
            case 'Editor':
                return <EditorComponentWorldWeb experienceRoad={experienceRoad} setExperienceRoad={setExperienceRoad}
                                                setTitle={setTitle} hover={hover} setHover={setHover}
                                                setStep={setStep}/>;
            default:
                return <EditorComponentWorldWeb experienceRoad={experienceRoad} setExperienceRoad={setExperienceRoad}
                                                setTitle={setTitle} hover={hover} setHover={setHover}
                                                setStep={setStep}/>;
        }
    };
    return (
        <div>
            <ExperienceSubtitle
                subT={t('experienceNewFlow.worldTrackingSubtitle')}/>
            <div className="new-experience-type-container">
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({world_tracking_type: 'app_world_tracking'});
                }} onMouseLeave={() => {
                    setHover({world_tracking_type: ''});
                }} onClick={() => {
                    setTitle(t('experienceNewFlow.worldTracking'));
                    setStep(['world_tracking', 'world_tracking_type']);
                    setExperienceRoad({...experienceRoad, ...{world_tracking_type: 'app', experience_type: '2s1'}});
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.world_tracking_type === 'app_world_tracking' ?
                                <img  src="/images/publish-app-hover.svg" alt=""
                                style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/publish-app.svg" alt=""
                                style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_type === 'app_world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.app')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.worldTrackingAppDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>
                    </div>
                </div>
                {getComponentRole()}
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'world_tracking'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});

                }} onClick={() => {
                    setExperienceRoad({...experienceRoad, ...{experience_type: 'model_3d'}});
                    setTitle(t('studioNewFlow.model3d'));
                    setStep(['world_tracking', 'model_3d']);
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'world_tracking' ?
                                <img src="/images/illustration-world-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/illustration-world.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'}>
                            {t('studioNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('studioNewFlow.model3d')} </span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('studioNewFlow.model3dDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>
                    </div>
                </div>
             
            </div>
        </div>
    );
};

export default WorldTrackingTypeComponent;