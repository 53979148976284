import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import Icon from '../icon';
import AppContext from '../app/AppContext';
import { useTranslate} from 'react-polyglot';

import './actionsDialog.scss';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
function ActionsDialog(props) {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [dialogConfig, setDialogConfig] = useState({});
    const [componentValue, setComponentValue] = useState(null);
    const [back, setBack] = useState(0);
    const t = useTranslate();

    useEffect(() => {

        if (context.page.openActionsDialog) {

            setOpen(true);

            setDialogConfig(context.page.actionsDialogData);

        } else {
            setOpen(false);
        }

    }, [context.page.openActionsDialog])
    function setContextToFalse() {
        if (props.allwaysShow) {
            context.setPage({ ...context.page, openActionsDialog: false, onBoarding: true });

        } else {
            context.setPage({ ...context.page, openActionsDialog: false });

        }
    }
    function setLoading() {
        context.setPage({ ...context.page, loading: true });
    }
    function hideLoading() {
        context.setPage({ ...context.page, loading: false });
    }
    async function execDialogfunction() {
        if (context.page.openActionsDialog) {
            setLoading();
            try {
                const response = await context.page.actionsDialogData.action(componentValue);
                setContextToFalse();
            } catch (error) {
                hideLoading();
                setOpen(false);
            }
        }
    }
    function handleDialogChange(value) {

        setComponentValue(value);
        setDialogConfig({ ...dialogConfig, componentData: '' })
    }
    function backHandler() {
        setBack(back + 1);
    }
    return (
        <>
            <Dialog
                preventOutsideDismiss={true}
                open={open}
                onClose={evt => {
                    setContextToFalse()
                    if (props.showCmp) {

                        (props).showCmp(false)
                    }
                    props.onClose();
                    setOpen(false);


                }}
                className={dialogConfig.full ? 'dialog-full' : 'dialog-normal'}
            >
                <DialogTitle>

                    {(context.page.actionsDialogData && context.page.actionsDialogData.showBack) &&

                        <Icon name={'arrow-left'} className="cursor" onClickFn={() => {
                            backHandler();
                        }} />
                    }
                    <Icon name={'close'} className="cursor" onClickFn={() => {
                        if (!props.allwaysShow) {

                            setContextToFalse(); setOpen(false)
                        }

                    }} />

                </DialogTitle>
                <DialogContent>

                    <div>
                        <h2>{dialogConfig.title}</h2>
                    </div>
                    {dialogConfig.component &&
                        <dialogConfig.component key={dialogConfig.componentKey} data={dialogConfig.componentData} onChange={handleDialogChange} back={back} />

                    }
                </DialogContent>
                {!dialogConfig.buttonLess &&
                    <DialogActions>
                        <DialogButton action="close">{t('actions.cancel')}</DialogButton>
                        <DialogButton action="accept" isDefaultAction onClick={
                            () => {
                                execDialogfunction();

                            }
                        }>{t('actions.accept')}</DialogButton>

                    </DialogActions>

                }
            </Dialog>
        </>
    )
}

export default ActionsDialog;