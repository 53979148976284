import React, {useContext, useEffect} from 'react';
import {
    AutoComplete,
    Button,
    Icon,
    InputGroup,
    Modal,
    SelectPicker,
    ControlLabel,
    FormGroup,
    Form
} from "rsuite";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import campaignService from "../../services/Campaign";
import { useTranslate } from 'react-polyglot';

const MigrationUserExperienceDialogComponent = () => {

    const {
        openMigrateExperienceDialog, setOpenMigrateExperienceDialog, users,
        onSearch, setSelectedUser, enabled, setEnabled, findUser, campaignId,
        setCampaignId,
        userId,
        setUserId, selectedUser,
        setProjects,
        projects,
        onMigrateUserExperience,
        companyId,
        setCompanyId
    } = useContext(MigrateUserDialogContext);
    const t = useTranslate();

    useEffect(() => {
        if (selectedUser) {
            let user = findUser();
            setUserId(user.id);
            setCompanyId(user.company_id)
        }
    }, [selectedUser]);

    useEffect(() => {
        if (userId) {
            const getProjects = async () => {
                let response = await campaignService.listWithoutExperiences(userId);
                let dataFormatted = response.data.map(e => ({
                    "label": e.name,
                    "value": e,
                }));
                setProjects(dataFormatted)

            };
            getProjects();
        }
    }, [userId]);

    useEffect(() => {
        setEnabled(campaignId && userId)
    }, [campaignId, userId]);

    return (
        <div className="modal-container">
            <Modal show={openMigrateExperienceDialog} onHide={() => {
                setOpenMigrateExperienceDialog(false)
            }}>
                <Modal.Header>
                    <Modal.Title>{t('experiences.actions.migExp')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormGroup>
                            <ControlLabel>{t('common.labels.user')}</ControlLabel>
                            <InputGroup style={{'width': '100%'}}>
                                <AutoComplete data={users} onChange={onSearch} onSelect={(e) => {
                                    setSelectedUser(e.value);
                                }}/>
                                <InputGroup.Button>
                                    <Icon icon="search"/>
                                </InputGroup.Button>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>{t('common.labels.project')}</ControlLabel>
                            <SelectPicker
                                style={{'marginTop': '10px'}}
                                data={projects}
                                block
                                onChange={(e) => {
                                    if (e) {
                                        setCampaignId(e.id);
                                    } else {
                                        setCampaignId(null);
                                    }
                                }}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setOpenMigrateExperienceDialog(false);
                        setSelectedUser('');
                        onMigrateUserExperience();
                    }} appearance="primary" disabled={!enabled}>
                        {t('actions.accept')}
                    </Button>
                    <Button onClick={() => {
                        setOpenMigrateExperienceDialog(false);
                        setSelectedUser('');
                    }} appearance="primary">
                        {t('actions.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MigrationUserExperienceDialogComponent;