import React, {useState, useEffect} from 'react';
import {Button, Modal, InputGroup, AutoComplete, Icon} from "rsuite";
import { useTranslate } from 'react-polyglot';

import userService from "../../services/User";

const MigrateUserProjectDialog = ({open, setOpenMigrateDialog, onMigrate}) => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [query, setQuery] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [dataForSearch, setDataForSearch] = useState([]);
    const t = useTranslate();


    const findUser = () => {
        let user = dataForSearch.find(e => e.string === selectedUser);
        if (user) {
            return {
                id: user.value.id
            }
        }
    };

    const onSearch = (e) => {
        setQuery(e);
        if (e.length >= 4) {
            userService.getUsers(false, 1, 20, {name: query, surname: query, email: query})
                .then(res => {
                    let formattedData = res.data.map((user) => {
                        return `${user.surname ? user.surname : ''} ${user.name ? user.name : ''}  (${user.email})`;
                    });
                    let dataForSearch = res.data.map((user) => {
                        return {
                            string: `${user.surname ? user.surname : ''} ${user.name ? user.name : ''}  (${user.email})`,
                            value: user
                        }
                    });
                    setDataForSearch(dataForSearch);
                    setUsers(formattedData);
                })
        }
        if (e.length === 0) {
            setSelectedUser('')
        }
    };

    useEffect(() => {
        setEnabled(selectedUser !== '');
    }, [selectedUser]);

    return (
        <div className="modal-container">
            <Modal show={open} onHide={() => {
                setOpenMigrateDialog(false)
            }}>
                <Modal.Header>
                    <Modal.Title>{t('pages.campaigns.actions.migProject')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup inside style={{width: '100%'}}>
                        <AutoComplete data={users} onChange={onSearch} onSelect={(e) => {
                            setSelectedUser(e.value);
                        }}/>
                        <InputGroup.Button>
                            <Icon icon="search"/>
                        </InputGroup.Button>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        setOpenMigrateDialog(false);
                        let user = findUser();
                        setSelectedUser('');
                        onMigrate(user);
                    }} appearance="primary" disabled={!enabled}>
                        {t('actions.accept')}
                    </Button>
                    <Button onClick={() => {
                        setOpenMigrateDialog(false);
                        setSelectedUser('');
                    }} appearance="primary">
                        {t('actions.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MigrateUserProjectDialog;