import React from 'react';
import {ReactSVG} from "react-svg";

const PrevArrowComponent = (props) => {
    const {className, style, onClick} = props;
    return (
        <div
            className={`${className} divPrevArrow`}
            onClick={onClick}
        >
            <ReactSVG className={'svgArrowPrev'} src="/images/arrow-left-carou.svg"/>
        </div>
    );
};

export default PrevArrowComponent;