import React, {useState} from 'react';
import ExperienceSubtitle from "./ExperienceSubtitle";
import {ReactSVG} from "react-svg";
import {Button} from "@rmwc/button";
import {useTranslate} from "react-polyglot";

const WorldTrackingBasedComponent = ({setExperienceRoad, experienceRoad, step, setStep, setTitle}) => {
    const t = useTranslate();
    const [hover, setHover] = useState({world_tracking_based: ''});
    return (
        <div>
            <ExperienceSubtitle
                subT={`${t('experienceNewFlow.subtitleWorldTracking')} ${experienceRoad.world_tracking_type}`}/>
            <div className="new-experience-type-container">
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({world_tracking_based: 'marker-based'});
                }} onMouseLeave={() => {
                    setHover({world_tracking_based: ''});
                }} onClick={() => {
                    setStep(['world_tracking', 'world_tracking_type', 'world_tracking_based']);
                    setTitle(`${t('experienceNewFlow.worldTracking')}`);
                    setExperienceRoad({
                        ...experienceRoad, ...{
                            world_tracking_based: 'marker-based',
                            experience_type: '2s2'
                        }
                    });
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.world_tracking_based === 'marker-based' ?
                                <img src="/images/maker-on-1.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/maker-on.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_based === 'marker-based' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.markerBased')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.markerBasedDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>

                    </div>
                </div>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({world_tracking_based: 'marker-less'});
                }} onMouseLeave={() => {
                    setHover({world_tracking_based: ''});
                }} onClick={() => {
                    setStep(['world_tracking', 'world_tracking_type', 'world_tracking_based']);
                    setTitle(`${t('experienceNewFlow.worldTracking')}`);
                    setExperienceRoad({
                        ...experienceRoad, ...{
                            world_tracking_based: 'marker-less',
                            experience_type: '2s2'
                        }
                    });
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.world_tracking_based === 'marker-less' ?
                                <img src="/images/maker-off-1.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/maker-off.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_based === 'marker-less' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.markerLess')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.markerLessDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorldTrackingBasedComponent;