import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useTranslate } from 'react-polyglot';

import SelectControl from '../common/select';
import CheckPermission from '../checkPermision/checkPermision';
import UserService from '../../services/User';
import { userGroupsObject } from '../../utils/userGroups';
import CommonForm from './CommonForm';
import { emailValidation } from '../../utils/formValidations';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import '@material/button/dist/mdc.button.css';
import './userForm.scss';

let isUpdate = false;
const validate = {
  email: emailValidation,
};

const StudentForm = memo(function UserForm(props) {
  const [professors, setProfessors] = useState([]);
  const [initialValues, setInitialValues] = useState({ user_type: 'STUDENT' });
  const t = useTranslate();

  useEffect(() => {
    const fetchProfessors = () =>
      UserService.getUsers(false, 0, 0, { user_type_id: 3 }).then((res) => {
        const profs = res.data.map((x) => ({
          label: `${x.name} ${x.surname}`,
          value: x.professor.id.toString(),
          companyid: x.company.id,
        }));
        setProfessors(profs);
        if (!isUpdate) {
          setInitialValues({
            ...initialValues,
            classroomId: profs[0]?.value,
            companyId: res.data[0]?.company.id,
          }); 
        }
      });

    fetchProfessors();

    if (props.data.email) {
      isUpdate = true;
      const user = props.data;
      setInitialValues({
        ...initialValues,
        ...user,
        classroomId: user.classroom.id.toString(),
      });
    } else {
      isUpdate = false;
      setInitialValues({
        ...initialValues,
        classroomId: props.data.me.professor?.id?.toString(),
        companyId: props.data.me.company.id,
      });
    }
  }, []);

  return (
    <CommonForm
      initialValues={initialValues}
      onBlur={props.onChange}
      validate={validate}
      funcSubmit={props.submitHandler}
    >
      {({ handleBlur, handleChange, handleSubmit, errors, values }) => (
        <form className="add-user-form" onSubmit={handleSubmit}>
          <CheckPermission
            component={
              <SelectControl
                label={t('pages.account.student.headTable.classroom')}
                name="classroomId"
                className="fullwidth form-item"
                options={professors}
                disabled={isUpdate}
                onChange={(e) => {
                  handleChange(e);
                  handleChange({
                    target: {
                      name: 'companyId',
                      type: 'number',
                      value: professors.find((p) => p.value === e.target.value)
                        .companyid,
                    },
                  });
                }}
                onBlur={handleBlur}
                value={values.classroomId}
              />
            }
            groups={[
              userGroupsObject.CamonappAdminUsers,
              userGroupsObject.EducationalUsers,
            ]}
          />
          <TextField
            outlined
            name="name"
            label={t('common.labels.name')}
            className="fullwidth form-item"
            value={values.name || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          <TextField
            outlined
            name="surname"
            label={t('common.labels.surname')}
            className="fullwidth form-item"
            value={values.surname || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          <TextField
            outlined
            name="email"
            label={t('common.labels.email')}
            className="fullwidth form-item"
            value={values.email || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            disabled={isUpdate}
          />
          <TextField
            outlined
            name="password"
            label={t('common.labels.password')}
            className="fullwidth form-item"
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            required={!isUpdate}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button label={t('actions.add')} type="submit"></Button>
            <Button
              label={t('actions.cancel')}
              onClick={(e) => props.setOpen(false)}
            ></Button>
          </div>
        </form>
      )}
    </CommonForm>
  );
});

StudentForm.propTypes = {};

export default StudentForm;
