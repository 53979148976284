import React, { memo } from 'react'
import NotFound from './NotFound';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import './Error.scss';
const Error = memo(function Error(props) {
    const t = useTranslate();
    return (
        <section className="not-found-container">
            <NotFound />
            <h3>{t('pages.error.404')}</h3>

            <h4>{t('pages.error.return')}</h4>
        </section>
    )
})

Error.propTypes = {

}

export default Error
