import React, { memo } from 'react'
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';
import { ReactSVG } from 'react-svg';
import { Button } from '@rmwc/button';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import './EndOfTrial.scss';

const EndOfTrial = memo(function EndOfTrial(props) {
    const t = useTranslate();
    return (
        <section className="onboard-container">
            <div className="end-of-trial">

                <div className="window">
                    <ReactSVG src="/images/endoftrialwindow.svg" />
                </div>
                <div className="end-of-trial-badge">
                    <ReactSVG src="/images/proEndofTrial.svg" />
                </div>
            </div>
            <span className="end-trial-tittle">{t('pages.endOfTrial.endTrial')}</span>
            <p className="end-trial-p">{t('pages.endOfTrial.paragraph')}</p>
            <Button label={t('pages.endOfTrial.upgrade')} raised className={'pro-button'} />
        </section>
    )
})

EndOfTrial.propTypes = {

}

export default EndOfTrial
