import React from 'react';
import { Row, Col } from "react-flexbox-grid";

import './SignLayout.scss';

function SignInLayout(props) {
    return (

        <section className={'container-fluid'}>

            <Row className={'login-box'}>
                {props.type === 'signUp' &&
                    <Col xs={4} className={'left-login-sidebar'}>
                        <img src="/sign-up-illustration.svg"  alt=""/>
                    </Col>
                }
                <Col xs={8} className={'main-content'}>
                    <Row className={'content-box'}>
                        <Col
                            {...(props.type === 'signIn' ? { style: { width: '360px' } } : {})}
                            className={'center full'}
                        >
                            {props.base}
                        </Col>
                        {props.footer &&
                            <Col xs={10} className={'flex-row center full'}>
                                {props.footer}
                            </Col>
                        }
                    </Row>

                </Col>
                {props.type === 'signIn' &&
                    <Col xs={4} className={'right-login-sidebar'}>
                            <img src="/img-login.svg" style={{float:'right', height:'100%'}} alt=""/>
                    </Col>
                }

            </Row>
        </section>
    )
}

export default SignInLayout;