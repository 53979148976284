import request from '../utils/request';
import { parseParams } from '../utils/url';

class StudentService {
    static async get(paginate = false, page = 1, perPage = 5, paramsQuery) {
        return request.get(
            `${process.env.REACT_APP_KWM_ENDPOINT}/students?page=${page}&per_page=${perPage}&${parseParams(paramsQuery)}`
            );
    }

    static async add(params) {
        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/students`,
            {
                body: params
            }
        );
    }
    static async update(params, student) {
        return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/students/${student}`,
            {
                body: params
            });
    }
    static async delete(params, student) {
        return request.delete(`${process.env.REACT_APP_KWM_ENDPOINT}/students/${student}`);
    }


}
export default StudentService;
