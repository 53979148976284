import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import _ from 'lodash';
import { useTranslate } from 'react-polyglot';

import SelectControl from '../common/select';
import CompanyService from '../../services/Companies';
import CheckPermission from '../checkPermision/checkPermision';
import { userGroupsObject } from '../../utils/userGroups';
import CommonForm from './CommonForm';
import { emailValidation } from '../../utils/formValidations';
import ErrorMessage from '../ErrorMessage/ErrorMessage'

import '@material/button/dist/mdc.button.css';
import './userForm.scss';

let isUpdate = false;

const validate = {
  email: emailValidation,
};

const ProfesorForm = memo(function ProfesorForm(props) {
  const [companies, setCompanies] = useState([]);
  const [initialValues, setInitialValues] = useState({ user_type: 'PROFESSOR' });
  const t = useTranslate();

  const COMPANY_TYPE = {
    EDUCATIONAL_ENTITY: 3,
  };

  useEffect(() => {
    const fetchCompanies = () =>
      CompanyService.get(false, 0, 0, {
        company_type_id: COMPANY_TYPE.EDUCATIONAL_ENTITY,
      }).then((res) =>
        setCompanies(
          res.data.map((x) => ({ label: x.name, value: x.id.toString() }))
        )
      );

    fetchCompanies();

    if (props.data.email) {
      isUpdate = true;
      const professor = props.data;

      setInitialValues({
        ...professor,
        user_type: professor.userType.name,
        companyId: professor.company.id.toString(),
      });
    } else {
      isUpdate = false;
    }
  }, [props.data]);


  const SelectEntity = (props) => (
    <SelectControl
      label={t('pages.account.educational.form.entityEducational')}
      name="companyId"
      className="fullwidth form-item"
      options={companies}
      required
      disabled={isUpdate}
      {...props}
    />
  );

  const getError = (errors) => Object.entries(errors)[0][1];

  return (
    <CommonForm
      initialValues={initialValues}
      onBlur={props.onChange}
      validate={validate}
      funcSubmit={props.submitHandler}
    >
      {({ handleBlur, handleChange, handleSubmit, errors, values }) => (
          <form className="add-user-form" onSubmit={handleSubmit}>
            {!_.isEqual(errors, {}) && <ErrorMessage message={getError(errors)}/>}
            <CheckPermission
              component={<SelectEntity onChange={handleChange} onBlur={handleBlur} value={values.companyId} />}
              groups={[userGroupsObject.CamonappAdminUsers]}
            />
            <TextField
              outlined
              name="email"
              label={t('common.labels.email')}
              className="fullwidth form-item"
              value={values.email || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              disabled={isUpdate}
              invalid={errors['email']}
            />
            <TextField
              outlined
              name="name"
              label={t('common.labels.name')}
              className="fullwidth form-item"
              value={values.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              invalid={errors['name']}
            />
            <TextField
              outlined
              required
              name="surname"
              label={t('common.labels.surname')}
              className="fullwidth form-item"
              value={values.surname || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={errors['surname']}
            />
            <TextField
              outlined
              name="password"
              label={t('common.labels.password')}
              className="fullwidth form-item"
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              required={!isUpdate}
              invalid={errors['password']}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button label={t('actions.add')} type="submit"></Button>
              <Button
                label={t('actions.cancel')}
                onClick={(e) => props.setOpen(false)}
              ></Button>
            </div>
          </form>
      )}
    </CommonForm>
  );
});

ProfesorForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    userType: PropTypes.shape({ id: PropTypes.number }),
  }),
  me: PropTypes.shape(),
  onChange: PropTypes.func.isRequired,
};

ProfesorForm.defaultProps = {
  data: {
    name: '',
    surname: '',
    email: '',
    userType: null,
    company: { id: 0 },
  },
};

export default ProfesorForm;
