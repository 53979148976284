


import React, {Fragment, useState, useEffect, useContext} from 'react';
import {Button,Tooltip,Checkbox, FormControl,Grid,TextField, Switch, FormGroup,FormControlLabel,Modal,Typography,Box,InputLabel, Select,MenuItem} from '@mui/material';
import './WebComponents.css';
import { Icon } from 'rsuite';
import Previsualiser  from './Previsualiser';
import HeightForm  from './HeightForm';
import WidthForm  from './WidthForm';
import BackgroundForm  from './BackgroundForm';
import {getLibaryScript,hexToRGB} from './WebComponentsUtils'
import SausageButton from '../../components/coa/SausageButton';
import { useTranslate } from 'react-polyglot';
import {OverlaysContext} from '../../context/OverlaysContext';
import WebComponentModal from './WebComponentModal';

function CowcFooterTemplate() {
    const t = useTranslate();
    const [visible, setVisible] = useState(true);
    const [srcIcon, setSrcIcon] = useState();
    const [backgroundColor, setBackgroundColor] = useState("#500000");
    const [position, setPosition] = useState("absolute");
    const [openModal, setOpenModal] = useState(false);
    const [height, setHeight] = useState(20);
    const [heigthTypeSelected, setHeigthTypeSelected] = useState("px");
    const [opacity, setOpacity] = useState(100);    
    const [width, setWidth] = useState(100);
    const [widthTypeSelected, setWidthTypeSelected] = useState("%");
    const [includeLibrary, setIncludeLibrary] = useState(false);
    const [tooltipCopyText, setTooltipCopyText] = useState("");
    const {variableSelected, setVariableSelected} = useContext(OverlaysContext);
    const optionsVariables = [
        {label: t("overlays.properties.height"), value : t("overlays.properties.height")},
        // {label: t("overlays.properties.width"), value : t("overlays.properties.width")},
        {label: t("overlays.properties.background"), value : t("overlays.properties.background")},
        {label: t("overlays.properties.iconUrl"), value : t("overlays.properties.iconUrl")},
    ]

    const getScript = ()=>{
        const script = `
        <cowc-footer-button visible="${visible}" 
        src-icon="${srcIcon}"
        height="${height}${heigthTypeSelected}"
        background-color="${hexToRGB(backgroundColor,opacity/100)}"
        > </cowc-footer-button>
        `; 
        return script;
    }

    useEffect(() => {
        setVariableSelected("")
    },[])

    const renderVariableSelected = () => {
        switch(variableSelected){
            case t("overlays.properties.height"): return (
                <div>
                     <HeightForm  height={height} type={heigthTypeSelected} onChange={ 
                         e => {
                            
                            if(isNaN(e.target.value)){
                                setHeigthTypeSelected(e.target.value);
                                const scaleHeight = e.target.value == "%" ? height : height;
                                setHeight(scaleHeight);
                            }else{
                                const scaleHeight = heigthTypeSelected == "%" ? e.target.value : e.target.value;
                                setHeight(scaleHeight) //lo divido por 3 para que quede a escala
                            }
                        }
                     } ></HeightForm>
                </div>
                ) ;
            case t("overlays.properties.background"): return (
                <Grid className='item-container'  container spacing={2} sx={{flexDirection: "column"}}>
                <BackgroundForm color = {backgroundColor} opacity ={opacity}
                onChange={ e=>{setBackgroundColor(e.target.value)}
                }
                onChangeOpacity = { e=> {
                    setOpacity(e.target.value);
                }}
                >
                </BackgroundForm>
                </Grid>
                );
            case t("overlays.properties.iconUrl"): return(
                <Grid className='item-container' sx={{flexDirection: "column"}}>
                        <FormControl fullWidth>
                             <TextField
                                className='item-overlay'
                                size='small' 
                                defaultValue={srcIcon}   
                                id="srcIcon" 
                                label={t("overlays.properties.setUrl")}
                                variant="outlined" 
                                onChange={ e=>{setSrcIcon(e.target.value)}}/>
                        </FormControl>
                </Grid>
           ) ;
        }
    }
    const handleClickExport = () => {
        setOpenModal(!openModal);
    }

    const handleClickCopy = () => {
        const library = getLibaryScript();
        let script = getScript();
        if(includeLibrary){
            script = `${library} 
            ${script}
            `
        }
        navigator.clipboard.writeText(script);
        setTooltipCopyText(t("overlays.copied"))
        const timeOutId = setTimeout(() => {
            setTooltipCopyText("");
            clearTimeout(timeOutId);
        }, 500);
    }

    
    const handleClickCloseModal = () => {
        setOpenModal(!openModal);
    }
    
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

    return (
        <Fragment>
            <Grid className='item-container' container spacing={2}>
            <TextField
                size='small' 
                select
                className='item-overlay'
                id="variables-select"
                label="Variables"
                onChange={e => {setVariableSelected(e.target.value)}}
                >
                {optionsVariables.map(component => {
                    return <MenuItem value={component.value}>{component.label}</MenuItem>
                })}
            </TextField>
            </Grid>                       
            {renderVariableSelected()}
            <div className='previsualizer-container'>
            <Previsualiser>
                    <cowc-footer-button 
                        visible={visible}
                        src-icon ={srcIcon}
                        height={height/3+heigthTypeSelected}
                        background-color = {hexToRGB(backgroundColor,opacity/100)}
                        position={position}
                        width={width + widthTypeSelected }
                    ></cowc-footer-button>
            </Previsualiser>
            <FormGroup className="text-center"> 
                    <SausageButton
                        className="btn-export"
                        label={t("overlays.export")}
                        onClick={handleClickExport}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
            </FormGroup>
            </div>
            <WebComponentModal title="Cowc-footer-botton" open={openModal} onCloseModal={handleClickCloseModal}>{getScript()}</WebComponentModal>
        </Fragment>
    );
}

export default CowcFooterTemplate;
