import React, {useState,useContext} from "react";
import {useTranslate} from 'react-polyglot';
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import "./editAR.scss"
import AlertDialog from '../general/AlertDialog';
import Appcontext from '../app/AppContext';
import ExperienceService from "../../services/Experience";
export default function ChangeToAd(props) {
    const context = useContext(Appcontext);
    const t = useTranslate();
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open, setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })
    const handleAccept = async() => {
        setShowLoading(true)
        await ExperienceService.ExperienceAd(props.data.experienceType,props.data.experienceId,!props.data.isAd, props.data.publicAlias);
        setShowLoading(false)
        context.page.refetch()
    }
    return (
        <div className="edit-ar">

                    <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                    <DialogButton action="close">
                        {t('actions.cancel')}
                    </DialogButton>
                    <DialogButton action="accept" isDefaultAction onClick={handleAccept}>{t('actions.accept')}</DialogButton>

            </DialogActions>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                buttonMessage='OK'
            />  
        </div>
    )
}