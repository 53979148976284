import React, {useEffect,useState,useContext} from "react";
import { MenuItem, TextField } from "@mui/material";
import BackgroundForm from "../../pages/WebComponent/BackgroundForm";
import InputFile from "../InputFile";
import {useTranslate} from 'react-polyglot';
import CustomMonoriel from "../../services/CustomMonoriel";
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import "./editAR.scss"
import AlertDialog from '../general/AlertDialog';
export default function EditAR (props){
    const t = useTranslate();
    const [color, setColor] = useState("#ffffff")
    const [imageURL, setImageURL] = useState(null)
    const [file, setFile] = useState (null)
    const [selectOption, setSelectOption] = useState()
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })

    useEffect(()=> {
        getBackgroundAR()
    },[])
    async function getBackgroundAR() {
        try {
            setShowLoading(true)
            await CustomMonoriel.getCustomMonorielConfig(props.data)
            .then(response=> response.json())
            .then(data => {
                if(data.hasBackgroundURL === "true"){
                    setSelectOption("image")
                    setImageURL(data.skyBox)
                }
                else if (data.background !== "#ffffff") {
                    setSelectOption("color")
                    setColor(data.background)
                }   
                setShowLoading(false)
            });
        } catch (error) {
            throw new Error(error);
        } 
    }
    async function changeBackgroundAR() {
        try {
            setShowLoading(true)
            const update = new FormData()
            update.append("background", color)
            update.append("hasBackgroundURL", file != null ? true : false)
            update.append("skyBox", file)
            await CustomMonoriel.updateCustomMonorielConfig(props.data,update)
            .then(rta => {
               setShowLoading(false)
               setOpen({
                title: t('experiences.success'),
                subtitle: t('experiences.successBody'),
                isOpen: true
               })
            })
        } catch (error) {
            throw new Error(error);
        } 
    }

    return (
        <div className="edit-container">
            <div style={{width:"100%", marginBottom:"20px", marginTop:"20px"}}>
            <TextField size="small" value={selectOption} label={t('dragAndDropFile.inputSelect')} select variant="outlined" className="edit-select" onChange={(e) => setSelectOption(e.target.value)}>
                <MenuItem value="image">{t('experiences.image')}</MenuItem>
                <MenuItem value="color">{t('experiences.color')}</MenuItem>
            </TextField>
            </div>
            {
                selectOption === "image" ?
                <InputFile defaultFile={imageURL} handleFile={(file) => setFile(file)} handleURL={(url) => setImageURL(url)} handleQuitFile={(file) => setFile(null)}></InputFile>
                : selectOption === "color" ?
                <BackgroundForm
                classInput= "edit-input-color"
                color={color}
                onChange={(e) => {
                    setColor(e.target.value)
                }}
                label={t('experiences.changeBackground')}
                />  
                :
                <></>

            }
            <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                    <DialogButton action="close">{t('actions.cancel')}</DialogButton>
                    <DialogButton action="accept" isDefaultAction onClick={
                        () => {
                            changeBackgroundAR();
                        }
                    }>{t('actions.accept')}</DialogButton>

            </DialogActions>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                buttonMessage='OK'
            />  
        </div>
    )
}