import React, { memo, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import { useTranslate } from 'react-polyglot';

import Icon from '../icon';

import '../actionsDialog/actionsDialog.scss';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

const Modal = function Modal(props) {
    const modalEl = document.getElementById('dialog');
    const [open, setOpen] = useState(true);
    const [changeStep, setChangeStep] = useState(false);
    const [componentValue, setComponentValue] = useState(null);
    const [back, setBack] = useState(0);
    const t = useTranslate();

    async function execDialogfunction() {

        if (props.action && props.componentKey) {
            try {
                if (componentValue) {
                    await props.action(componentValue);

                } else {
                    await props.action(props.componentData);
                }
                props.onClose(true);
                setOpen(false);
            } catch (error) {
                props.onClose();
                setOpen(false);
            }
        }

    }
    function handleDialogChange(value) {

        setComponentValue(value);

    }
    function backHandler() {
        setBack(back + 1);
    }
    function finishHandler() {
        props.onClose(true);
        setOpen(false);
    }
    return ReactDOM.createPortal(
        <Dialog
            preventOutsideDismiss={true}
            open={open}
            onClose={evt => {


                if (props.showCmp) {

                    props.showCmp(false)
                }

                props.onClose();
                setOpen(false);


            }}
            className={props.full ? 'dialog-full' : 'dialog-normal'}
            {...props.style}
        >
            <DialogTitle className={(props.showback && changeStep) ? 'space-bet' : 'only-close'}>
                {(props.showback && changeStep) &&

                    < Icon name={'arrow-left'} className="cursor" onClickFn={() => {
                        backHandler();
                    }} />
                }
                <Icon name={'close'} className="cursor" onClickFn={() => {
                    if (!props.allwaysShow) {
                        props.onClose();
                        setOpen(false)
                    }

                }} />

            </DialogTitle>
            <DialogContent>

                <div>
                    <h2>{props.title}</h2>
                </div>
                {props.component &&
                    //mandamos ahora setOpen y submitHandler para manejar la logica DESDE EL COMPONENTE.
                    //setOpen se mandaria porque no quiero cerrar el dialogo si no subo ningun target
                    //submithandle es execDialogFunction que gestiona todo lo mismo, con el mismo comportamiento
                    //solo que ahora lo tenemos como una funcion sin llamar, esperando para ejecutarla CUANDO QUERAMOS.
                    <props.component setOpen={setOpen} submitHandler={execDialogfunction} key={props.componentKey} keyData={props.componentKey} actionHandler={props.action} data={props.componentData} onChange={handleDialogChange} onFinish={finishHandler} back={back} onChangeStep={(val) => {
                        if (val) {
                            setChangeStep(false)
                        } else {
                            setChangeStep(true)
                        }
                    }} />
                }
            </DialogContent>
            {!props.buttonLess &&
                <DialogActions>
                    <DialogButton action="close">{t('actions.cancel')}</DialogButton>
                    <DialogButton action="accept" isDefaultAction onClick={
                        () => {
                            execDialogfunction();
                        }
                    }>{t('actions.accept')}</DialogButton>

                </DialogActions>

            }
        </Dialog>
        , modalEl)
}

Modal.propTypes = {

}

export default Modal
