import React, {Fragment, useContext} from 'react';
import  CowcFooterTemplate from './CowcFooterTemplate.js';
import './WebComponents.css';
import { useTranslate } from 'react-polyglot';
import CowcIconButtonTemplate from './CowcIconButtonTemplate.js';
import CowcNavigation360TourTemplate from './CowcNavigation360TourTemplate.js';
import CowcMenuTemplate from './CowcMenuTemplate.js';
import { Grid, MenuItem, TextField } from '@mui/material';
import {OverlaysContext} from '../../context/OverlaysContext'
import CowcBackButtonTemplate from './CowcBackButtonTemplate.js';
import CowcModalTemplate from './CowcModalTemplate.js';
function WebComponent() {

    const {overlay, setOverlay} = useContext(OverlaysContext)
    const t = useTranslate();
    const renderWebComponentSelected = () => {
        switch(overlay){
            case t("overlays.component.cowcFooterBotton.value") : return (
                <CowcFooterTemplate></CowcFooterTemplate>
            );
            case t("overlays.component.cowcIconButton.value") : return (
                <CowcIconButtonTemplate></CowcIconButtonTemplate>
            );
            case t("overlays.component.cowc360NavigationTour.value") : return (
                <CowcNavigation360TourTemplate></CowcNavigation360TourTemplate>
            );
            case t("overlays.component.cowcMenu.value") : return (
                <CowcMenuTemplate></CowcMenuTemplate>
            );
            case t("overlays.component.cowcBackButton.value") : return (
                <CowcBackButtonTemplate></CowcBackButtonTemplate>
            )    
            case t("overlays.component.cowcModal.value") : return (
                <CowcModalTemplate></CowcModalTemplate>
            ) 
                 
        }
    }
    const options = [
        {value: t("overlays.component.cowcIconButton.value"), label: t("overlays.component.cowcIconButton.label")},
        {value: t("overlays.component.cowcFooterBotton.value"), label: t("overlays.component.cowcFooterBotton.label")},
        {value: t("overlays.component.cowc360NavigationTour.value"), label: t("overlays.component.cowc360NavigationTour.label")},
        {value: t("overlays.component.cowcMenu.value"), label: t("overlays.component.cowcMenu.label")},
        {value: t("overlays.component.cowcBackButton.value"), label: t("overlays.component.cowcBackButton.label")},
        {value: t("overlays.component.cowcModal.value"), label: t("overlays.component.cowcModal.label")},
       /* {value: t("overlays.component.cowcModalWithTrigger.value"), label: t("overlays.component.cowcModalWithTrigger.label")},
        {value: t("overlays.component.cowcShopButton.value"), label: t("overlays.component.cowcShopButton.label")}, */
    ]
    return (
        <Fragment>
            <div class="section-previsualicer">
                <Grid className='item-container' container spacing={2}>
                    <TextField 
                        size='small'
                        select
                        className='item-overlay'
                        id="components-select"
                        label="Web Component"
                        onChange={e => {setOverlay(e.target.value)}}
                    >
                        {options.map(component => {
                            return <MenuItem value={component.value}>{component.label}</MenuItem>
                        })}
                    </TextField>  
                </Grid>       
            {
            overlay ?
            renderWebComponentSelected() :
            ""}          
            </div>  
        </Fragment>
    );
}

export default WebComponent;
