
import Amplitude from './trackers/Amplitude'
export default class Behavior extends Amplitude{

    createExperienceIntent() {
        this.logEvent('Intento de crear experiencia', this.data)
    }
    createAccount() {
        this.logEvent('Crear Cuenta', this.data)
    }
    newExperience() {
        this.logEvent('Nueva experiencia', this.data)

    }
    imageTracking() {
        this.logEvent('Image Tracking', this.data)

    }
    worldTracking() {
        this.logEvent('World Tracking', this.data)

    }
    world360g() {
        this.logEvent('World 360', this.data)

    }
    faceTracking() {
        this.logEvent('Face Tracking', this.data)

    }
    modelViewer() {
        this.logEvent('Web AR', this.data)

    }
    disableExperience() {
        this.logEvent('Disable Experience', this.data)

    }
    QR() {
        this.logEvent('Ver QR', this.data)

    }
    help(){
        this.logEvent('Help', this.data)

    }
}

