import React, {useEffect} from 'react';
import {useTranslate} from 'react-polyglot';

import {TextFieldWithStyles} from '../layout/HeaderStyles';
import Icon from '../icon';
import {Input, InputGroup} from "rsuite";
import {Icon as IconR} from 'rsuite';

const Searchbar = ({
                       location,
                       setActiveSearch,
                       searchValue,
                       setSearchValue,
                       keydown
                   }) => {
    const t = useTranslate();

    useEffect(() => {
        if (window.location.pathname.split('/').includes("search")) {
            setSearchValue('');
        }
    }, [location.pathname, setSearchValue]);
    return (
        /*<TextFieldWithStyles
            outlined
            icon={<Icon name={'search'} />}
            placeholder={t('header.placeholder')}
            onFocus={(e) => {
                if (window.location.pathname !== '/search') {
                    setActiveSearch(true);
                }
            }}
            onBlur={() => {
                setActiveSearch(false);
            }}
            onKeyDown={keydown}
            value={searchValue}
            onChange={(e) => {
                setSearchValue(e.target.value);
            }}
        />*/
        <InputGroup size='md'>
            <InputGroup.Addon style={{
                height: '40px',
                background: 'white',
                borderTop: '1px solid rgba(41, 41, 41, 0.12)',
                borderBottom: '1px solid rgba(41, 41, 41, 0.12)',
                borderLeft: '1px solid rgba(41, 41, 41, 0.12)',
                borderRight: 'transparent',
            }}>
                <IconR icon="search"
                       style={{
                           color: 'rgba(152, 152, 152, 1)',
                           marginTop: '13px',
                           marginBottom: '13px',
                       }}/>
            </InputGroup.Addon>
            <Input
                onChange={(e) => {
                    setSearchValue(e);
                }}
                onKeyDown={keydown}
                value={searchValue}
                placeholder={t('header.placeholder')}
                onFocus={(e) => {
                    if (window.location.pathname.split('/').includes("search")) {
                        setActiveSearch(true);
                    }
                }}
                onBlur={() => {
                    setActiveSearch(false);
                }}
                className={'placeHolderSearch'}
                style={{
                    height: '40px',
                    width: '65%',
                    borderTop: '1px solid rgba(41, 41, 41, 0.12)',
                    borderBottom: '1px solid rgba(41, 41, 41, 0.12)',
                    borderLeft: 'transparent',
                    borderRight: '1px solid rgba(41, 41, 41, 0.12)'
                }}/>
        </InputGroup>
    );
};

export default Searchbar;
