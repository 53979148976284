import React, {useEffect,useState} from "react";
import { DataTable,DataTableHeadCell,DataTableRow,DataTableHead, DataTableContent, DataTableBody } from "rmwc";
import TableContent from "./TableContent";
import { useTranslate } from "react-polyglot";
import "./styles.scss"
export default function Table ({experiences,setModalStatus}) {
    const [exp, setExp] = useState([])
    const t = useTranslate()
    useEffect(() => {
        setExp(experiences)
    },[experiences])
    return(
        <>
            <DataTable className="table">
                <DataTableHead>
                <DataTableRow className="table-row">
                <div className="table-row-left">
                    <DataTableHeadCell className="table-row-title">
                       {t('tablePublications.experience')}
                    </DataTableHeadCell>
                    <DataTableHeadCell className="table-row-title">
                        URL
                    </DataTableHeadCell>
                    <DataTableHeadCell className="table-row-title">
                    {t('tablePublications.user')}
                    </DataTableHeadCell>
                </div>
                <div className="table-row-rigth">
                    <DataTableHeadCell className="table-row-title-rigth">
                    {t('tablePublications.limitDate')}
                    </DataTableHeadCell>
                    <DataTableHeadCell className="table-row-title-rigth">
                    {t('tablePublications.timer')}
                    </DataTableHeadCell>
                    <DataTableHeadCell className="table-row-title-rigth">
                    {t('tablePublications.status')}
                    </DataTableHeadCell>
                </div>
                </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                    {
                        exp.length ?
                            exp.map ((v) => {
                                return <TableContent setModalStatus={setModalStatus} exp={v}></TableContent>
                            })
                        :
                       <div></div>
                        
                    }
                </DataTableBody>
                
            </DataTable>
        </>
    )
}