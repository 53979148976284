class Model3D {

    constructor(name, file) {
        this.name = this.createName(name); 
        this.file = file;
    }

    createName = (name) => {
        const [aName, ext] = name.split('.')
        return aName + '_' + (Date.now()).toString() + '.' + ext;
    }
}

export default Model3D