import request from '../utils/request';

class ReportsService {
    /**
     *
     * @param filters
     * @returns {Promise<*|undefined>}
     */
    static async getAnalyticsData(filters) {
        //de npp a kiwi
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/reports${filters}`)
    }

    static async test() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/reportsTest`)
    }

    static async exportExcel(filters) {
        //de npp a kiwi
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/excelexport${filters}`,
            { responseType: 'blob' }
        )
    }

    static async exportAG(filters) {
        //de npp a kiwi
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/reports/export${filters}`);
    }

    static async sendReportEmail() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/email`);
    }
}

export default ReportsService;