import React, { useState, useEffect } from 'react';
import { TextField } from '@rmwc/textfield';
import { useTranslate } from 'react-polyglot';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';
function ExpName(params) {
    const [value, setValue] = useState(params ? params.data : '');
    const t = useTranslate();

    useEffect(() => {
        if (value === params.data) {
            setValue(params.data);

        }
    })

    return (
        <TextField outlined label={t('experiences.nameExp')} name="name" className={'fullwidth'} value={value} onChange={(e) => { setValue(e.target.value); }} onBlur={(e) => { params.onChange({ name: value }) }} />
    )
}

export default ExpName;