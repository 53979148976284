import React, {memo, useEffect, useState, useRef, useContext} from 'react';
import {Button} from '@rmwc/button';
import {CircularProgress} from '@rmwc/circular-progress';
import countryList from 'react-select-country-list';
import {injectStripe} from 'react-stripe-elements';
import _ from 'lodash';
import {useTranslate} from 'react-polyglot';
import {refreshToken} from '../../utils/jwt';
import SubscriptionService from '../../services/Subscription';
import CommonForm from '../actions/CommonForm';
import {fieldRequired, phoneValidation} from '../../utils/formValidations';
import AlertDialog from '../general/AlertDialog';

import {Auth} from 'aws-amplify';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import 'antd/dist/antd.css';
import '@material/button/dist/mdc.button.css';
import '@rmwc/circular-progress/circular-progress.css';
import {ControlLabel, Input, SelectPicker} from "rsuite";
import getLocale from "../../utils/locale";
import messages from "../../lang";
import Loading from '../loading';
import ShowLoadingContext from '../../context/ShowLoadingContext';

import axios from 'axios'

const locale = getLocale() || 'en';
const message = messages[locale];
const EMAIL_FOR_SUBSCRIPTION = "https://qda6o4jhkgp5433c7xyvr7ufe40hqiwc.lambda-url.us-east-1.on.aws/"

const validate = {
    name: (val) => fieldRequired(val, message.pages.subscription.form.name),
    surname: (val) => fieldRequired(val, message.pages.subscription.form.surname),
    phone: phoneValidation,
    address: (val) => fieldRequired(val, message.pages.subscription.form.address),
    zip: (val) => fieldRequired(val, message.pages.subscription.form.cpCode),
    city: (val) => fieldRequired(val, message.pages.subscription.form.city),
    country: (val) => fieldRequired(val, message.pages.subscription.form.country),
};

const initialValues = {
    name: '',
    surname: '',
    phone: '',
    address: '',
    zip: '',
    city: '',
    country: '',
};

const refreshSession = async () => {
    try {
        await refreshToken();
        window.location.reload();
    } catch (e) {
        console.log('Unable to refresh Token', e);
    }
};

const SubForm = memo(function SubForm(props) {
    const [countries, setcountries] = useState([]);
    const [stripeFormError, setStripeFormError] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const [controlError, setControlError] = useState({
        discountCouponErrorClassLabel: '',
        discountCouponErrorClassInput: '',
        discountCouponErrorMessage: '',
    });
    const { setOverlayStyle } = useContext(ShowLoadingContext)

    const labelError = useRef(null);
    const t = useTranslate();

    useEffect(() => {
        const options = countryList().getData();
        setcountries(options);
    }, []);

    async function validDataSubscription() {
        const {intent} = await SubscriptionService.getIntent();
        const cardElement = props.elements.getElement('card');
        const res = await props.stripe.confirmCardSetup(intent, {
            payment_method: {
                card: cardElement,
            },
        });
        if (res.error) throw res.error;
        return res;
    }

    async function createPaymentMethod(billing) {
        const cardElement = props.elements.getElement('card');
        const res = await props.stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {...billing},
        });
        if (res.error) throw res.error;
        return res.paymentMethod;
    }

    function setError(message) {
        if (message === 'Coupon does not exist') {
            setControlError({
                discountCouponErrorClassLabel: 'inputCoALabelError',
                discountCouponErrorClassInput: 'inputCoAError',
                discountCouponErrorMessage: t('pages.subscription.form.errorCoupon')
            });
            document.getElementsByClassName('mdc-dialog__content')[0].scrollTo(0, 0)
            return;
        } else {
            setControlError({
                discountCouponErrorClassLabel: '',
                discountCouponErrorClassInput: '',
                discountCouponErrorMessage: ''
            });
        }
        setStripeFormError(message);
        document.getElementsByClassName('mdc-dialog__content')[0].scrollTo(0, 0)
    }

    async function createSubscription(values) {
        setLoading(true);
        setOverlayStyle("loading-overlay-full")
        try {
            const billing = mapBillingDetails(values);
            /* await validDataSubscription(); */
            const paymentMethod = await createPaymentMethod(billing);

            const res = await SubscriptionService.create({
                period: props.paymentType === 1 ? 'MONTHLY' : 'ANNUAL',
                currency: 'USD',
                payment_method_id: paymentMethod.id,
                /* coupon: values.coupon, */
            });

            setLoading(false);
            if (res.data) {
                setOpen(true);
            } else if (res.code === '422') {
                setError(res.message);
            }
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }


    async function sendFormData(values){
    
        try {
            const {idToken: {payload: {email: userEmail}}} = await Auth.currentSession()

            const response = await axios.post(EMAIL_FOR_SUBSCRIPTION,{
                ...values ,
                email: userEmail
            })
            setOpen(true)
            return response
        } catch (error) {

            console.error(error)
            return error
        }

    }

    function mapBillingDetails({
                                   city,
                                   country,
                                   address,
                                   zip,
                                   name,
                                   surname,
                                   phone,
                               }) {
        return {
            address: {
                city: city,
                country: country,
                line1: address,

                postal_code: zip,
                state: city,
            },
            email: null,
            name: name + ' ' + surname,
            phone: phone,
        };
    }

    return (
        <div className="subs-form">
            <h2 className={'titleProPassing'}>{t('pages.subscription.form.title')}</h2>
            {loading && 
                <Loading/>
            }
            <CommonForm
                initialValues={initialValues}
                validate={validate}
                funcSubmit={sendFormData}
            >
                {({handleBlur, handleChange, handleSubmit, errors, values}) => (
                    <form className="subs-form">
                        <section className={'profile-card-container'}>
                            <div className={'profile-element'}>
                                <div className={'profile-title'}>
                                    <span>{t('pages.subscription.form.personalData').toUpperCase()}</span>
                                </div>
                                <div className={'profile-element'}>
                                    <ControlLabel
                                        className={`labelInput ${errors['name'] ? 'inputCoALabelError' : ''}`}
                                    >
                                        {t('pages.subscription.form.labels.username')}
                                    </ControlLabel>
                                    <Input className={`inputCoA ${errors['name'] ? 'inputCoAError' : ''}`}
                                           name={'name'}
                                           id={'name'}
                                           onChange={(value, synthE) => {
                                               handleChange(synthE)
                                           }}
                                           placeholder={t('pages.subscription.form.insertUsername')}
                                    />
                                    <span className={'error'}>{errors['name'] ? errors['name'] : ''}</span>
                                    <ControlLabel
                                        className={`labelInput ${errors['surname'] ? 'inputCoALabelError' : ''}`}
                                    >
                                        {t('pages.subscription.form.labels.surname')}
                                    </ControlLabel>
                                    <Input className={`inputCoA ${errors['surname'] ? 'inputCoAError' : ''}`}
                                           name={'surname'}
                                           id={'surname'}
                                           onChange={(value, synthE) => {
                                               handleChange(synthE)
                                           }}
                                           placeholder={t('pages.subscription.form.insertSurname')}
                                    />
                                    <span className={'error'}>{errors['surname'] ? errors['surname'] : ''}</span>
                                    <ControlLabel
                                        className={`labelInput ${errors['phone'] ? 'inputCoALabelError' : ''}`}
                                    >
                                        {t('pages.subscription.form.labels.phone')}
                                    </ControlLabel>
                                    <Input className={`inputCoA ${errors['phone'] ? 'inputCoAError' : ''}`}
                                           name="phone"
                                           id="phone"
                                           onChange={(value, synthE) => {
                                               handleChange(synthE)
                                           }}
                                           placeholder={t('pages.subscription.form.insertPhone')}
                                    />
                                    <span className={'error'}>{errors['phone'] ? errors['phone'] : ''}</span>
                                    <div className={'containerAddressPostal'}>
                                        <div style={{width: '67%'}}>
                                            <ControlLabel
                                                className={`labelInput ${errors['address'] ? 'inputCoALabelError' : ''}`}
                                            >
                                                {t('pages.subscription.form.labels.address')}
                                            </ControlLabel>
                                            <Input className={`inputCoA ${errors['address'] ? 'inputCoAError' : ''}`}
                                                   name="address"
                                                   id="address"
                                                   onChange={(value, synthE) => {
                                                       handleChange(synthE)
                                                   }}
                                                   placeholder={t('pages.subscription.form.insertBillingAddress')}
                                            />
                                            <span
                                                className={'error'}>{errors['address'] ? errors['address'] : ''}</span>
                                        </div>
                                        <div style={{width: '10%'}}>

                                        </div>
                                        <div style={{width: '23%'}}>
                                            <ControlLabel
                                                className={`labelInput ${errors['zip'] ? 'inputCoALabelError' : ''}`}
                                            >
                                                {t('pages.subscription.form.labels.postalCode')}
                                            </ControlLabel>
                                            <Input className={`inputCoA ${errors['zip'] ? 'inputCoAError' : ''}`}
                                                   name="zip"
                                                   id="zip"
                                                   onChange={(value, synthE) => {
                                                       handleChange(synthE)
                                                   }}
                                                   placeholder={t('pages.subscription.form.insertCP')}
                                            />
                                            <span
                                                className={'error'}>{errors['zip'] ? errors['zip'] : ''}</span>
                                        </div>
                                    </div>
                                    <ControlLabel
                                        className={`labelInput ${errors['country'] ? 'inputCoALabelError' : ''}`}
                                    >
                                        {t('pages.subscription.form.labels.country')}
                                    </ControlLabel>
                                    <SelectPicker placement="topStart" name="country" menuClassName={'zindexSelectP'}
                                                  className={`selectCxoA ${errors['country'] ? 'selectCoAError' : ''}`}
                                                  data={countries}
                                                  id="country"
                                                  onClean={() => {
                                                      handleChange({
                                                          target: {
                                                              name: 'country',
                                                              value: ''
                                                          }
                                                      });
                                                  }}
                                                  onSelect={(selectedValue, synthE) => {
                                                      handleChange({
                                                          target: {
                                                              name: 'country',
                                                              value: selectedValue
                                                          }
                                                      });
                                                  }}
                                                  placeholder={t('pages.subscription.form.insertCountry')}
                                    />
                                    <span
                                        className={'error'}>{errors['country'] ? errors['country'] : ''}</span>
                                    <ControlLabel
                                        className={`labelInput ${errors['city'] ? 'inputCoALabelError' : ''}`}
                                    >
                                        {t('pages.subscription.form.labels.city')}
                                    </ControlLabel>
                                    <Input className={`inputCoA ${errors['city'] ? 'inputCoAError' : ''}`}
                                           name="city"
                                           id="city"
                                           onChange={(value, synthE) => {
                                               handleChange(synthE)
                                           }}
                                           placeholder={t('pages.subscription.form.insertCity')}
                                    />
                                    <span className={'error'}>{errors['city'] ? errors['city'] : ''}</span>
                                </div>
                            </div>
                        </section>

                        <div className="formButton">
                            <Button
                                label={t('pages.subscription.form.labels.submit')}
                                raised
                                onClick={handleSubmit}
                                className={`pro-button`}
                                disabled={loading}
                                icon={loading ? <CircularProgress className={'progress'}/> : ''}
                            />
                        </div>
                    </form>
                )}
            </CommonForm>
            <AlertDialog
                title={t('pages.subscription.title')}
                bodyMessage={t('pages.subscription.form.success')}
                setOpen={setOpen}
                open={open}
                functionHandler={refreshSession}
            />
        </div>
    );
});

SubForm.propTypes = {};

export default injectStripe(SubForm);
