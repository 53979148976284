import Request from './Request';

class ExperienceRequest extends Request {
    static make(params) {
        const reqParams = {
            ...params
        };
        if (reqParams.target["0"]) {
            reqParams.target = reqParams.target["0"];
        } else {
            delete reqParams.target
        }
        reqParams.campaign = JSON.stringify(reqParams.campaign);
        reqParams.experienceTypeId = reqParams.experience_type_id;
        reqParams.target_type = '2D';
        reqParams['campaign[id]'] = params.campaign.id ? params.campaign.id : ''
        if (params.campaign.name !== '') {
            reqParams["campaign[name]"] = params.campaign.name;
        }
        if (params.user_project_id !== '' ) {
            reqParams["user_project_id[id]"] = params.user_project_id || '';
        }
        if (params.user_project_id.name !== '') {
            reqParams["user_project_id[name]"] = params.user_project_id.name|| '';
        }
        if (params.target.length === 0) {
            reqParams.targetless = 1;
        }
        return reqParams;
    }
}

export default ExperienceRequest
