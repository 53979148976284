import styled from 'styled-components'
import {SimpleMenu, MenuItem} from '@rmwc/menu';
import {TextField} from '@rmwc/textfield';

export const HeaderWrapper = styled.div`
    background: #F6F6F6;
    box-shadow: inset 0px -1px 0px rgba(41, 41, 41, 0.04);
    box-sizing: border-box;
    min-height: 4rem;
    position: ${props => props.inherited ? 'inherit' : 'fixed'};
    width: 100%;
    z-index:20;
    display: grid;
    grid-template-columns: 1fr 6fr 2fr;
    border-bottom: solid 1px lightgrey;
    height: 72px;
`

export const ImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
export const Logo = styled.img`
    height: 46px;
    position: absolute;
    left: 5px;
    top: 5px;
`

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left:10%;
    margin-top:16px;
    margin-bottom:16px;
`

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; 
    margin-right: 16px;
    margin-top:16px;
    margin-bottom: 16px;
`
export const TextFieldWithStyles = styled(TextField)`
    width: 550px;
    background-color: #fff !important;
    height: 35px;
    margin-top: 10px;
    margin-bottom:  10px;
    &.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon{
      top: 1px;
    }
    &.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input{
      padding-bottom: 11px;
      padding-left: 50px;
    }
`;

export const SimpleMenuWithStyles = styled(SimpleMenu)`
    width: 12rem;
    background: #292929;
    color: #fff;
     & .mdc-list{
         width: 100%;    
     }
  
`

export const TextLabelMembership = styled.p`
/* 27 días */
height: 12px;

font-family: Public Sans;
font-style: normal;
font-weight: 600;
font-size: 10px;
line-height: 12px;
/* identical to box height, or 120% */

display: flex;
align-items: center;
text-transform: uppercase;

/* Neutrals / White */

color: #FFFFFF;

/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 0px;
`;

const colorType = {
    NORMAL_USER: '#0DD391',
    ADMIN: '#3487F0',
    PRO: '#F034A5'
};

export const ButtonMembership = styled.div`;
/* Small */
/* Auto Layout */
justify-content:center;
display: flex;
flex-direction: row;
align-items: center;
padding: 4px 8px;

position: absolute;
width: 55px;
height: 20px;
right: 8px;
top: calc(50% - 20px/2);

/* Messages / Success */
background ${props => colorType[props.colorType]};
/* Elevation / (Mid) Shape */

box-shadow: 0px 4px 48px rgba(41, 41, 41, 0.04), 0px 12px 24px rgba(41, 41, 41, 0.06);
border-radius: 4px;
`;

export const SimpleMenuItem = styled(MenuItem)`
    :hover{
       background-color: var(--primary-color);
    };
     display: flex;
      background-color: ${props => props.backgroundPro ? '#F034A5' : ''};
      border-bottom: ${props => props.hasBottomBorder ? 'solid 1px #888787' : ''};
    flex-direction: row;
    font-family: Public Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    padding: 16px;
    color: white;
    height: 32px;
`;
export const UserBoxWrapper = styled.div`
   display: flex;
   flex-direction: row;
   cursor: pointer;
     text-overflow: ellipsis; 
`

export const SearchContainerBox = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    top:70px;
    height: 100vh;
    background: #fff;
    z-index: 18;
`
export const SearchLabel = styled.h2`
      padding-top: 10rem;
    color: rgba(41, 41, 41, 0.72);
`