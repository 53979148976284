import React, { useState, useContext, useEffect } from "react";
import "@rmwc/badge/badge.css";
import "./CustomLoaderPreview.css";
import { CustomLoaderContext } from "../../context/CustomLoaderContext";
import Icon360SVG from "./Icon360SVG";

function CustomLoaderPreview(props) {
  const {
    iconColor,
    textColor,
    loadingBarColor,
    backgroundColor,
    backgroundGradiantColor,
    isGrid,
    logoURL,
    isGradiant,
    footerColor,
    footerText,
    footerTextBold,
    footerTextEn,
    footerTextBoldEn,
    backgroundFile,
    backgroundLocal,
    language
  } = useContext(CustomLoaderContext);
  const styles = {
    textColor: {
      color: textColor,
    },
    loadingBarColor: {
      color: loadingBarColor,
      borderColor: loadingBarColor,
      backgroundColor: loadingBarColor,
    },
    backgroundColor: {
      color: backgroundColor,
      background: isGradiant 
        ? `linear-gradient(${backgroundColor}, ${backgroundGradiantColor})`
        : backgroundLocal?.length
        ? "0"
        : backgroundColor,
      backgroundColor: backgroundLocal?.length ? "0" : backgroundColor,
    },
    backgroundImage: {
      backgroundImage: isGrid ? "url(/images/loader_bg.svg)" : "none",
    },
    backgroundFile: {
      backgroundImage: `url(${backgroundLocal !== 'undefined' ? backgroundLocal : backgroundFile})`,
    },
    logoImage: {
      backgroundImage: logoURL?.length
        ? `url(${logoURL}) no-repeat center`
        : "url(/images/icon_360.svg) no-repeat center",
    },
    iconColor: {
      color: iconColor,
    },
    footerColor: {
      color: footerColor,
    },
    iconSVG: {
      width: "40px",
      margin: "40px auto 10px auto"
    }
  };
  return (
    <div id="container">
      <div className="preload-menu">
        <div className="background" style={styles.backgroundFile}>
          <div id="panorama">
            {/* TODO */}
            {/* <div id="gl" data-imageOriginal="{preload_image_original}" data-imageDepth="{preload_image_depth}" data-horizontalThreshold="{preload_ht}" data-verticalThreshold="{preload_vt}"></div> */}
          </div>
        </div>
        <div className="start-experience">
          {/*                 <div className="init-button-container"> 
                    <div id="start-360" className="start-360">
                        <div className="start-360-logo"></div>
                         TODO  
                        <span>txt_start_360</span>
                    </div>
                    <div className="init-loading-indicator">
                        <div className="init-loading-indicator-inner"></div>
                    </div>
                </div> */}
        </div>
      </div>
      <div id="preloader" className="preloader" style={styles.backgroundColor}>
        <div className="preloader-header" style={styles.textColor}>
          {/* <div style={styles.logoImage}></div> */}
          <div style={logoURL ? styles.logoImage : styles.iconSVG}>
            { logoURL ? <img src={logoURL}/> : <Icon360SVG iconcolor={styles.iconColor.color} />}
          </div>
          <br />
          {/* <img src="{header_icon}" /> */}
          {/* TODO  */}
          {
          language === "spanish"
          ?
            <span>
            Estas ingresando a<br />
            <span className="bold">contenidos 360</span>
            </span>
          :
            <span>
            You are entering<br />
            <span className="bold">360 content</span>
            </span>
          }
        </div>
        <div className="preloader-header-qr">
          {/* TODO  */}
          logo_3d
        </div>
        <div className="preloader-center" style={styles.backgroundImage}>
          <div className="preloader-bg" />
          <div className="preloader-container">
            <div className="preloader-qr">
              <div className="preloader-qr-code"></div>
              {/* TODO  */}
              <div className="qr-description-1">txt_no_ar_1_available</div>
              {/* TODO  */}
              <div className="qr-description-2">txt_no_ar_2_available</div>
              <div className="link-p">
                {/* TODO  */}
                <span>txt_ar_back_to_1</span>
                {/* TODO  */}
                <a id="backLink">txt_ar_back_to_2</a>
              </div>
            </div>
            <div className="loading loading-ar">
              {/* TODO  */}
              <div id="start-ar" className="start-360">
                <div className="start-360-logo"></div>
                <span>txt_start_ar</span>
              </div>
            </div>
            <div className="loading loading-bar">
              <div className="loading-content-preview">
                {/* TODO  */}
                <span className="bold" style={styles.textColor}>
                  360
                </span>
                {/* TODO  logo ?*/}
              </div>
              <div className="loading-indicator" style={styles.loadingBarColor}>
                <div
                  className="loading-indicator-inner"
                  style={styles.loadingBarColor}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer {footer_360}" style={styles.footerColor}>
          <span className="text">{language === "spanish" ? footerText : footerTextEn}<p class='space-p'> </p></span>
          <span className="bold">{language === "spanish" ? footerTextBold : footerTextBoldEn}</span>
          <div className="logo"></div>
        </div>
        {/* TODO  */}
        {/* <div className="footer {footer_ar}"> */}
        {/* TODO  */}
        {/* <img className="poweredby_ar" src="{brand_image_url}footer_poweredby.svg" />            </div> */}
      </div>
      <div className="main-overlay">
        {/* <div className="sound no-select">${
            window.hideMusic
            ? ""
            : `<img src="{base_image_url}icon_sound_off.svg" />`
        }</div> */}

        {/* TODO  */}
        {/* <div className="fullscreen no-select"><img src="{base_image_url}icon_fullscreen.png" /></div> */}
        {/* TODO  */}
        {/* <div className="close no-select"><img src="{base_image_url}icon_close.png" /></div> */}
      </div>
      <div className="ar-overlay">{/* TODO  */}</div>
      <div id="experience-container"></div>
      <div id="debug-console"></div>
    </div>
  );
}

export default CustomLoaderPreview;
