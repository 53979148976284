
import request from '../utils/request';
import ExperienceService from './Experience';
import UserService from './User';

class PublicationService {


    static async list(paginated = false, page = 1, perPage = 15, paramsQuery) {
        try {

            const publications = await ExperienceService.getPendingForApproval({ ...paramsQuery, page, perPage });
            const users = {};
            publications.data = await Promise.all(
                publications.data.map(async p => {
                    p.user = users[p.user_id] ?? await UserService.getUser(p.user_id);
                    return p;
                })
            )
            return publications;
        } catch (error) {
            console.log(error);

        }
    }

    static getPaginatedPublications(publications, page, perPage) {
        const chunked = publications.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perPage)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, []);
        return {
            data: chunked[page - 1],
            total: publications.length
        };
    }

    static async approve(experience) {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${experience.id}/approve`);
    }
    static async reject(experience) {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/experiences/${experience.id}/reject`);

    }
}
export default PublicationService;
