import * as React from "react";

const Icon360SVG = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 156 156"
    style={{
      enableBackground: "new 0 0 156 156",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{`.st0{fill:${props.iconcolor}}`}</style>
    <path
      className="st0"
      d="M128.5 155.3h-13.4c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7h13.4c9.6 0 17.5-7.8 17.5-17.5v-14.2c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v14.2c-.1 14.9-12.1 26.9-26.9 26.9zM150.7 46.4c-2.6 0-4.7-2.1-4.7-4.7V27.5c0-9.6-7.8-17.5-17.5-17.5h-14.1c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7h14.1c14.8 0 26.8 12 26.8 26.8v14.2c0 2.7-2.1 4.8-4.6 4.8zM41.7 155.3H27.5c-14.8 0-26.8-12-26.8-26.8v-14.2c0-2.6 2.1-4.7 4.7-4.7s4.7 2.1 4.7 4.7v14.2c0 9.6 7.8 17.5 17.5 17.5h14.2c2.6 0 4.7 2.1 4.7 4.7-.1 2.5-2.2 4.6-4.8 4.6zM5.3 46.4c-2.6 0-4.7-2.1-4.7-4.7V27.5C.6 12.7 12.6.7 27.4.7h14.2c2.6 0 4.7 2.1 4.7 4.7s-2 4.6-4.6 4.6H27.5C17.9 10 10 17.9 10 27.5v14.2c0 2.6-2.1 4.7-4.7 4.7zM78 129.6c-15.1 0-26.5-22.2-26.5-51.6S62.9 26.4 78 26.4s26.5 22.2 26.5 51.6c0 2.6-2.1 4.7-4.7 4.7s-4.7-2.1-4.7-4.7c0-24.2-9-42.3-17.1-42.3-8.1 0-17.1 18.1-17.1 42.3s9 42.3 17.1 42.3c3.2 0 6-2.9 7.8-5.3 1.5-2.1 4.5-2.5 6.5-1 2.1 1.5 2.5 4.5 1 6.5-5.5 7.6-11.4 9.1-15.3 9.1zM40.1 95.5c-.9 0-1.7-.2-2.5-.7-9.3-5.9-11.2-12.4-11.2-16.8s1.9-10.9 11.2-16.8c2.2-1.4 5.1-.7 6.5 1.4 1.4 2.2.7 5.1-1.4 6.5-2.6 1.6-6.9 5-6.9 8.9 0 3.9 4.3 7.3 6.9 8.9 2.2 1.4 2.8 4.3 1.4 6.5-.9 1.4-2.5 2.1-4 2.1z"
    />
    <path
      className="st0"
      d="M78 104.5c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7c24.2 0 42.3-9 42.3-17.1 0-3.9-4.3-7.3-6.9-8.9-2.2-1.4-2.8-4.3-1.4-6.5s4.3-2.8 6.5-1.4c9.3 5.9 11.2 12.4 11.2 16.8-.1 15.1-22.3 26.5-51.7 26.5z"
    />
  </svg>
);

export default Icon360SVG;
