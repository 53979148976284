
export const config = {
    version : "1.6.4"
}

export const getLibaryScript = () => {
    const libaryScript = `
        <script type="module" src="https://cdn.jsdelivr.net/npm/springfield-elementary@${config.version}/dist/esm/springfield-elementary.js"></script>
        <script nomodule src="https://cdn.jsdelivr.net/npm/springfield-elementary@${config.version}/dist/esm/springfield-elementary.js"></script>
    `
    return libaryScript;
}


export const  hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}