import { Grid } from '@mui/material';
import React, {Fragment, useState} from 'react';
import { useTranslate } from 'react-polyglot';
import './Previsualiser.css'


function Previsualiser(props) {
    const t = useTranslate();

    const [previsualiserType, setPrevisualiserType] = useState("desktop");
    return (
        <Fragment> 
            <div className='main'>
                    {previsualiserType == "desktop"? 
                        <div className='desktop-visualiser'>
                            <div className='tt desktop-visualiser-contain'>
                              {props.children}
                             </div>
                        </div> :
                        <div className='desktop-mobile'>
                          <div className='tt desktop-mobile-contain'>
                              {props.children}
                          </div>
                        </div> 
                    }
                    <div className='options'>
                        <div className={`option option-desktop ${previsualiserType === "desktop" ? 'active' : ''}` } onClick={() => {setPrevisualiserType("desktop")}}>
                            {t("overlays.visualiser.desktop")}
                        </div>
                        <div className={`option ${previsualiserType === "mobile" ? 'active' : ''}`} onClick={() => {setPrevisualiserType("mobile")}}>
                            {t("overlays.visualiser.mobile")}
                        </div>
                    </div>
            </div>
        </Fragment>
    );
}

export default Previsualiser;
