import React, {memo, Fragment} from 'react'
import {
    MarkerContainer,
    StatusOfExperience,
    TypeWithPlatformWrapper,
    TextPlatform,
    MarkerContainerOffline
} from './ExperienceTypeStyles.js'
import ImageWithBackgroundComponent from "./ImageWithBackgroundComponent";
import {Button} from "rsuite";


const ExperienceType = memo(function ExperienceType({experience, visible, goToEditor}) {

    const getExperienceTypeIcon = () => {
        switch (experience.experienceTypeId) {
            case 1:
                return "/images/new-immersive-track-mini.svg";
            case 2:
                return "/images/new-world-track-mini.svg";
            case 3:
                return "/images/new-image-track-mini.svg";
            case 4:
                return "/images/new-face-track-mini.svg";
            case 5:
                return "/images/new-ar.svg";
        }
    };

    const getIconForStatus = () => {
        switch (experience.status) {
            case 'NOT_PUBLISHED':
                return '/images/notpublished-status.svg';
            case 'PUBLISHED':
                return '/images/published-status.svg';
            case 'ON_REVIEW':
                return '/images/pending-timer-status.svg';
            case 'REJECTED':
                return '/images/close-circle-line.svg'
        }
        const statusMap = {
            NOT_PUBLISHED: 'pencil-ruler',
            PUBLISHED: 'rocket',
            ON_REVIEW: 'pending-timer',
            REJECTED: 'close-circle-1',
        };
        return statusMap[experience.status];
    };
    return (
        <Fragment>
            <MarkerContainer>
                <TypeWithPlatformWrapper>
                    <ImageWithBackgroundComponent hasMargin={true} iconSrc={getExperienceTypeIcon()}/>
                    <TextPlatform> {"AR"} </TextPlatform>
                </TypeWithPlatformWrapper>
                <StatusOfExperience status={experience.status}>
                    <ImageWithBackgroundComponent iconSrc={getIconForStatus()} aditionalClass={'marginLeft'}/>
                </StatusOfExperience>
            </MarkerContainer>
            <div className='typeAd'>
            {!!experience.is_ad && <>
                    <TypeWithPlatformWrapper>
                        <ImageWithBackgroundComponent hasMargin={true} iconSrc={getExperienceTypeIcon()}/>
                        <TextPlatform> AD </TextPlatform>
                    </TypeWithPlatformWrapper>
            </> }
            </div>
            <MarkerContainerOffline>
                <Button onClick={goToEditor} color="blue" style={{width: '100px', height:'35px', marginLeft: '128px', marginTop: '84px'}}
                        className={`${visible ? 'visibleOffline' : 'invisibleOffline'}`}>
                    OFFLINE
                </Button>
            </MarkerContainerOffline>
        </Fragment>
    )
});


export default ExperienceType
