import React, { useEffect, useState, useContext } from 'react';
import ExperienceService from '../../services/Experience';
import campaignService from '../../services/Campaign'
import AppContext from '../../components/app/AppContext';
import FilterBy from '../../components/Filters/FilterBy';
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';


function Search(props) {
    const context = useContext(AppContext);
    const [searchValue, setSearchValue] = useState("");
    const [experience, setExperiences] = useState([]);


    const getUserIdFromUrl = () => {
        let paths = window.location.pathname.split('/');
        let userId = paths.length > 1 ? paths[2] : '';
        let hasUser = paths.length > 1 && paths[1] === 'user';
        if (hasUser) {
            return userId
        }
        return ''
    };


    useEffect(() => {
        context.setPage({
            name: 'Experiencias recientes',
            refetch: fetchData
        });
    }, [])
    useEffect(() => {
        if (searchValue !== props.location.state.text) {
            setSearchValue(props.location.state.text);
            fetchData();
        }
    });

    function fetchData() {

        if (getUserIdFromUrl().length == 0){
            ExperienceService.search(props.location.state.text)
                .then((res) => {
                    console.log('experience service\n '+JSON.stringify(res));
                    context.setPage({ ...context.page, name: `${res.data.length} resultados encontrados` });
                    setExperiences(res.data)
                });
            }else{
            campaignService.listWithExperiences(getUserIdFromUrl())
                .then((res) => {
                    let _response = {}
                    let _data = [];
                    res.data.forEach(campaign => {
                        (campaign.experiences).forEach(experience =>{
                            if (props.location.state.text.length >0 && (experience.name.toLowerCase()).includes((props.location.state.text).toLowerCase())){
                                _data.push(experience)
                            }
                        })
                    });
                    _response.data = _data;
                    _response.total = res.data.length;
                    context.setPage({ ...context.page, name: `${_response.data.length} resultados encontrados` });
                    setExperiences(_response.data)
            })
        }
    }

    return (
        experience && <FilterBy data={experience} typeData="experiences">
            {result => (
                <div className="campaign-container" >
                {result.map((exp, index) => <CardHomeStudio key={index} experienceCardStyle={'campaign-exp-card'} experience={exp}/>)}

                </div>
            )}
        </FilterBy>
    )
}

export default Search;