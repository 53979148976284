import React, {memo, useEffect, useState, useContext, Fragment} from 'react';
import {useTranslate} from 'react-polyglot';

import AppContext from '../../components/app/AppContext';
import StatusListItem from './StatusListItem';
import experienceService from '../../services/Experience';

import '@brainhubeu/react-carousel/lib/style.css';
import '@material/list/dist/mdc.list.css';
import '@rmwc/list/collapsible-list.css';
import {QRDialogContext} from "../../context/QRDialogContext";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";
import Loading from '../../components/loading';
import ShowLoadingContext from '../../context/ShowLoadingContext';

const Status = memo(function Status() {
    const context = useContext(AppContext);
    const [expByStatuses, setExpByStatuses] = useState(false);
    const t = useTranslate();

    const { 
        showLoading,
        setShowLoading,
        overlayStyle, 
        setOverlayStyle,
    } = useContext(ShowLoadingContext)

    

    async function fetchData() {

        try {
            setShowLoading(true)
            setOverlayStyle("loading-overlay-projects")

            let expResponse = []

            expResponse = await experienceService.listByStatuses();

            setExpByStatuses(expResponse);
            setShowLoading(false)
        } catch (error) {
            //openOnBoarding();
        } finally {
            setShowLoading(false)
        }

    }

    useEffect(() => {
        context.setPage({
            name: t('pages.status.statusExp'),// 'Estado de tus experiencias',
            hideFilters: true,
            refetch: fetchData
        });

        fetchData();
    }, []);

    const {MigrationUserExperienceDialog} = useContext(MigrateUserDialogContext);
    const {QRDialog} = useContext(QRDialogContext);
    const {ChangeTargetDialog} = useContext(ChangeTargetDialogContext);
    const {
        ChangeNameDialogComponent
    } = useContext(ChangeNameDialogContext);
    return (
        <div>
            {QRDialog()}
            {MigrationUserExperienceDialog()}
            {ChangeTargetDialog()}
            {ChangeNameDialogComponent()}
            
            {showLoading && 
                <Loading/>
            }
            <div className="campaing-cards-container">
                {expByStatuses &&
                <div className="list">
                    {expByStatuses.published.length > 0 && (
                        <StatusListItem name={'published'} icon="rocket" status={expByStatuses.published}
                                        reload={() => {
                                            fetchData();
                                        }}/>)}
                    {expByStatuses.inRevision.length > 0 && (
                        <StatusListItem name={'in_revision'} icon="pending-timer" status={expByStatuses.inRevision}
                                        reload={() => {
                                            fetchData();
                                        }}/>)}
                    {expByStatuses.unpublished.length > 0 && (
                        <StatusListItem name={'unpublished'} icon="pencil-ruler" status={expByStatuses.unpublished}
                                        reload={() => {
                                            fetchData();
                                        }}/>)}
                    {expByStatuses.rejected.length > 0 && (
                        <StatusListItem name={'rejected'} icon="close-circle-1" status={expByStatuses.rejected}
                                        reload={() => {
                                            fetchData();
                                        }}/>)}
                </div>
                }
            </div>
        </div>
    );
})


export default Status
