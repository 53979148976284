class Request {

    static getParams(form) {
        const formInstance = new FormData(form);
        let formData = {};
        Array.from(formInstance.entries()).forEach((d) => {
            formData[d[0]] = d[1];
        });
        return formData;
    }
}

export default Request;