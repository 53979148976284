import React, {createContext, useContext, useEffect, useState} from 'react';
import QrDialogComponent from "../components/actionsDialog/QRDialogComponent";

export const QRDialogContext = createContext();

const QRDialogProvider = (props) => {
    const [openQRDialog, setOpenQRDialog] = useState(false);
    const [dialogData, setDialogData] = useState({
        url: '',
        published: false
    });
    const QRDialog = () => (<QrDialogComponent/>);

    return (<QRDialogContext.Provider
        value={{
            openQRDialog,
            setOpenQRDialog,
            dialogData,
            setDialogData,
            QRDialog
        }}>{props.children}</QRDialogContext.Provider>)
};

export default QRDialogProvider;