import ExperienceService from '../services/Experience';
import ExperienceRequest from '../Request/ExperienceRequest';
export async function createExperience(data) {
    try {
        const params = ExperienceRequest.make(data);

        const response = await ExperienceService.create(params);
        return Promise.resolve(response);
    } catch (error) {
        return Promise.reject(error);
    }
}