import React, {memo, useState} from 'react'
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {useTranslate} from 'react-polyglot';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import userService from "../../services/User";
import {ERROR_MAP} from "../../utils/constants";

const ProfileSec = memo(function ProfileSec(props) {
    const t = useTranslate();
    const [handling, setHandling] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState({
        currentPass: '',
        password: '',
        newPassConfirm: '',
    })
    const handleInputChange = (e) => {
        const name = e.target.name;
        setData({
            ...data,
            [name]: e.target.value
        })
        props.onChange(data)
    };

    const changePassword = (e) => {
        e.preventDefault();
        if (validate(data)) {
            setHandling(true);
            let formattedData = {...data, companyId: props.data.user.company.id};
            userService.updateProfile(formattedData, props.data.user.company.id, props.data.user.id)
                .then((res) => {
                    props.data.closeModal();
                    props.data.setOpen(true);
                }).catch((err) => {
                const errorMsg = ERROR_MAP.find(e => err.message.includes(e.msg));
                setErrorState(true);
                setMessage(t(errorMsg.key));
                setHandling(false);
            })
        }
    };

    const validate = (data) => {
        let errMsge = '';
        if (Object.values(data).some(value => value === '')) {
            errMsge = 'someValueNull';
        }
        if (data.password !== data.newPassConfirm) {
            errMsge = 'passwordNotMatch';
        }
        if (errMsge === '') {
            return true;
        }
        setErrorState(true)
        setMessage(t(`pages.signUp.errors.${errMsge}`))
        return false;
    };
    return (
        <div>
            <div>
                <div className={'profile-item-field'}>
                    <TextField outlined type="password" name="currentPass" onChange={handleInputChange}
                               label={t('pages.account.userProfile.labels.previousPassword')} className={'fullwidth'}/>
                </div>
                <div className={'profile-item-field'}>
                    <TextField outlined type="password" name="password" onChange={handleInputChange}
                               label={t('pages.account.userProfile.labels.newPassword')} className={'fullwidth'}/>
                </div>
                <div className={'profile-item-field'}>
                    <TextField outlined type="password" name="newPassConfirm" onChange={handleInputChange}
                               label={t('pages.account.userProfile.labels.confirmPassword')} className={'fullwidth'}/>
                </div>

            </div>
            {errorState ? <ErrorMessage message={message}/> : null}
            <Button label={t('actions.add')} disabled={handling} enabled onClick={(e) => {
                changePassword(e);
            }}>
            </Button>
            <Button label={t('actions.cancel')} disabled={handling} onClick={(e) => {
                e.preventDefault();
                props.setOpen(false);
            }}>
            </Button>
        </div>
    )
})

ProfileSec.propTypes = {}

export default ProfileSec
