import React from 'react';
import {Row, Col} from "react-flexbox-grid";
import './ReportGraphicBarChart.scss'
import {Bar} from "react-chartjs-2";
import {useTranslate} from 'react-polyglot';

import {formatNumber, getRGBTableHorizontalGraph} from "../../pages/Reports/ReportUtils";

const ReportGraphicBarChart = ({
                                   data, setInitialData, initialData, setLoading, navLinks,
                                   setNavLinks
                               }) => {
    const t = useTranslate();
    const options = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    return `${t('pages.reports.viewForDay')}: ${formatNumber(dataset.data[tooltipItem.index])}`
                },
            }
        },
        responsive: true,
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        userCallback: function (value, index, values) {
                            // Convert the number to a string and splite the string every 3 charaters from the end
                            value = value.toString();
                            value = value.split(/(?=(?:...)*$)/);
                            value = value.join(localStorage.lang === 'es' ? '.' : ',');
                            return value;
                        }
                    }
                },
            ],
        },
    };

    const dataChart = {
        labels: data.daily.map(e => e.date),
        datasets: [
            {
                label: t('pages.reports.viewForDay'), // 'Numero de vistas por dia',
                data: data.daily.map(e => e.scans),
                backgroundColor: data.daily.map(e => getRGBTableHorizontalGraph()),
                borderColor: data.daily.map(e => getRGBTableHorizontalGraph()),
                borderWidth: 1,
            },
        ],
    };

    const setNewDate = (e) => {
        let chart = e[0];
        if (chart) {
            let date = chart._model.label;
            if (!routeFoundOnBreadCrumb(date)) {
                setLoading(true);
                setInitialData({...initialData, ...{from: date, to: date}})
            }
        }
    };

    const routeFoundOnBreadCrumb = (dateText) => {
        let dateFound = navLinks.find(e => e.text === dateText);
        return !!dateFound;
    };

    return (
        <Row className='container-tiles'>
            <Col lg={12} className={'colheight'}>
                <p className={'text-statics'}>{t('pages.reports.statistics')}</p>
            </Col>
            <Col lg={12}>
                <p className={'subtext-statics'}>{t('pages.reports.cantView')}</p>
            </Col>
            <Col lg={12}>
                <Bar onElementsClick={setNewDate} data={dataChart} options={options}/>
            </Col>
        </Row>
    );
};

export default ReportGraphicBarChart;