import React, {useContext, useState, useEffect} from 'react';
import {useTranslate} from 'react-polyglot';
import FilterMenu from './filterMenu';
import AppContext from '../app/AppContext';
import {EXPERIENCE_TYPE, EXPERIENCE_STATUS, EXPERIENCE_SORT} from '../../utils/experience';
import './filters.scss';
import {UserContext} from "../../context/UserContext";
import HomeBread from "../../pages/Home/HomeBread";

function Filters(props) {
    const t = useTranslate();
    const context = useContext(AppContext);
    const getTitleComp = () => {
        return <HomeBread/>
    };
    const defaultFilter = {
        state: {name: t('filters.status.all'), value: 'all'},
        type: {name: t('filters.expType.all'), value: 'all'},
        sort: {name: t('filters.sort.recent'), value: 'recent'},
    };

    const [filter, setFilter] = useState(defaultFilter);

    useEffect(() => {
        setFilter(defaultFilter);
        context.setFilter(defaultFilter);
    }, [context.page.name])

    function handler(data, property) {
        setFilter({...filter, [property]: data});
        context.setFilter((prev) => ({...prev, [property]: data}));
    }

    const getExperiencesByDict = (dict) => Object.entries(dict).map(([value, name]) => ({
        name: t(name),
        value
    }));

    return (props.noRenderFilterContainer ? null :
            <section className={'filters-container'}>
            <span className={'filter-page-name'}>{
                getTitleComp()
            }</span>
                {
                    (props.hideFilters !== true) &&

                    <div className="filters-actions">
                        <div className={'filter-action-container'}>
                            {props.hideStatusFilter ?
                                null
                                : <div className="filters-action">
                                    <span className="filter-action-title">{`${t('filters.labels.status')}`}:</span>
                                    <FilterMenu
                                        options={getExperiencesByDict(EXPERIENCE_STATUS)}
                                        onChange={(e) => handler(e, 'state')}
                                        selected={filter.state.name}
                                    />
                                </div>}
                            <div className="filters-action">
                                <span className="filter-action-title">{`${t('filters.labels.experienceType')}:`}</span>
                                <FilterMenu
                                    options={getExperiencesByDict(EXPERIENCE_TYPE)}
                                    onChange={(e) => handler(e, 'type')}
                                    selected={filter.type.name}
                                />
                            </div>
                            <div className="filters-action">
                                <span className="filter-action-title">{`${t('filters.labels.order')}:`}</span>
                                <FilterMenu
                                    options={getExperiencesByDict(EXPERIENCE_SORT)}
                                    onChange={(e) => handler(e, 'sort')}
                                    selected={filter.sort.name}
                                />
                            </div>

                        </div>

                    </div>
                }
            </section>
    )
}

export default Filters;