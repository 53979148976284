import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import _ from 'lodash';
import { useTranslate } from 'react-polyglot';
import { AutoComplete } from 'rsuite';

import SelectControl from '../common/select';
import CompanyService from '../../services/Companies';
import CommonForm from './CommonForm';
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { emailValidation } from '../../utils/formValidations';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import './userForm.scss';

let isUpdate = false;

const validate = {
  email: emailValidation,
};
const USER_TYPES_INTERNAL = ['USER_CAMONAPP', 'USER_CAMONAPP_SALES', 'USER_CAMONAPP_ADMIN']
const COMPANY_CAMONAPP_ID = 6;

const UserForm = memo(function UserForm(props) {
  const [companies, setCompanies] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    surname: '',
    email: '',
    companyId: '',
  });
  const [isUserInternal, setIsUserInternal] = useState(true);
  const t = useTranslate();

  const COMPANY_TYPE = {
    COMPANY: 1,
  };

  useEffect(() => {
    fetchCompanies();

    if (props.data.email) {
      isUpdate = true;
      const user = props.data;
      setInitialValues({
        ...user,
        userType: user.userType.name,
        companyId: user.company.id,
      });
    } else {
      isUpdate = false;
      setInitialValues({
        name: '',
        surname: '',
        email: '',
        companyId: COMPANY_CAMONAPP_ID,
        userType: 'USER_CAMONAPP',
      });
    }
  }, [props.data]);

  function getFilter(str) {
    const filter = { company_type_id: COMPANY_TYPE.COMPANY };
    if (str && str.length >= 3) {
      filter.name = str;
    }
    return filter;
  }

  function fetchCompanies(str) {
    if (!str || (str && str.length >= 3)) {
      CompanyService.get(false, 0, 0, getFilter(str)).then((res) =>
        setCompanies(
          res.data.map((x) => ({ label: x.name, value: x.id.toString() }))
        )
      ); 
    }
  }

  const isUserCompany = (userType) => userType === 'USER_COMPANY';

  const getError = (errors) => Object.entries(errors)[0][1];

  return (
    <CommonForm onBlur={props.onChange} initialValues={initialValues} validate={validate} funcSubmit={props.submitHandler}>
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        values,
      }) => (
        <form className="add-user-form" onSubmit={handleSubmit}>
          {!_.isEqual(errors, {}) && <ErrorMessage message={getError(errors)}/>}
          <SelectControl
            label={t('pages.account.users.form.labelUserType')}
            name="userType"
            className="fullwidth form-item"
            value={values.userType}
            onChange={(e) => {
              if (USER_TYPES_INTERNAL.includes(e.target.value)) {
                handleChange({
                  target: {
                    name: 'companyId',
                    type: 'number',
                    value: COMPANY_CAMONAPP_ID,
                  },
                });
                setIsUserInternal(true);
              } else {
                setIsUserInternal(false);
              }
              handleChange(e);
            }}
            onBlur={handleBlur}
            required
            disabled={isUpdate}
          >
            <optgroup label="Interno">
              <option value="USER_CAMONAPP">{t('pages.account.users.form.userType.userCamonapp')}</option>
              <option value="USER_CAMONAPP_SALES">{t('pages.account.users.form.userType.marketing')}</option>
              <option value="USER_CAMONAPP_ADMIN">{t('pages.account.users.form.userType.admin')}</option>
            </optgroup>
            <optgroup label="Externo">
              <option value="USER_COMPANY">{t('pages.account.users.form.userType.companyUser')}</option>
            </optgroup>
          </SelectControl>
          {(!isUserInternal && (isUserCompany(values.userType) || !isUpdate)) && (
            <AutoComplete
              name="companyId"
              data={companies}
              onChange={fetchCompanies}
              onSelect={(item, e) => {
                e.target.value = item.value;
                e.target.name = 'companyId'
                handleChange(e)
              }}
              placeholder="Company"
            />
          )}
          <TextField
            outlined
            name="email"
            label={t('common.labels.email')}
            className="fullwidth form-item"
            value={values.email || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            invalid={errors['email']}
            disabled={isUpdate}
          />
          <TextField
            outlined
            name="name"
            label={t('common.labels.name')}
            className="fullwidth form-item"
            value={values.name || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            invalid={errors['name']}
          />
          <TextField
            outlined
            required
            name="surname"
            label={t('common.labels.surname')}
            className="fullwidth form-item"
            value={values.surname || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            outlined
            name="password"
            label={t('common.labels.password')}
            className="fullwidth form-item"
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            required={!isUpdate}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button label={t('actions.add')} type="submit"></Button>
            <Button
              label={t('actions.cancel')}
              onClick={(e) => props.setOpen(false)}
            ></Button>
          </div>
        </form>
      )}
    </CommonForm>
  );
});

UserForm.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    userType: PropTypes.shape({ id: PropTypes.number }),
  }),
};

UserForm.defaultProps = {
  data: {
    name: '',
    surname: '',
    email: '',
    userType: null,
    company: { id: 0 },
  },
};

export default UserForm;
