import React, {useEffect, useState, useContext, Fragment} from 'react';
import {useTranslate} from 'react-polyglot';

import AppContext from '../../components/app/AppContext';
import ExperienceService from '../../services/Experience';
import FilterBy from '../../components/Filters/FilterBy';
import {QRDialogContext} from "../../context/QRDialogContext";
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';
import Loading from '../../components/loading';
import ShowLoadingContext from '../../context/ShowLoadingContext';

function Gallery() {
    const context = useContext(AppContext);
    const [experiences, setExperiences] = useState([])
    const t = useTranslate();

    const { 
        showLoading,
        setShowLoading,
        overlayStyle, 
        setOverlayStyle,
    } = useContext(ShowLoadingContext)

 
    const fetchData = async () => {
        setShowLoading(true)
        setOverlayStyle("loading-overlay-projects")
        const {data} = await ExperienceService.samplesGallery();
        setExperiences(data || []);
        setShowLoading(false)
    }

    useEffect(() => {
        context.setPage({
            name: t('pages.samplesGallery.samplesGallery'),
            refetch: fetchData
        });
        fetchData();
    }, [])
    const {QRDialog} = useContext(QRDialogContext);

    return (
        <Fragment>
            {QRDialog()}
            {showLoading && 
                <Loading/>
            }
            <FilterBy data={experiences} typeData="experiences">
                {result => (
                    <div className={'campaign-container'}>
                        {result.map(exp => <CardHomeStudio key={exp.id} experienceCardStyle={'campaign-exp-card'} experience={exp} isDemo={true}/>)}
                    </div>
                )}
            </FilterBy>
        </Fragment>
    );
}

export default Gallery;
