
export const parseParams = (params) => {
  if (params) {
      return Object.entries(params)
      .reduce((prev, [prop, value]) => {
          if (prev !== '') prev = prev.concat('&')
          return prev.concat(`${prop}=${encodeURIComponent(value)}`);
      }, '')   
  }
  return ''
}
