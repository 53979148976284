import React, {Fragment, useState} from 'react';
import { TextField, FormControl,FormGroup,InputLabel, Select,MenuItem, Grid} from '@mui/material';
    
import './Previsualiser.css'
import { useTranslate } from 'react-polyglot';


function WidthForm(props) {
    const t = useTranslate();
    const { onChange } = props;

    const optionsHeigthType= [
        {label: "px", value : "px"},
        {label: "%", value : "%"},
    ]

    return (
        <Fragment> 
                       {/*  <Grid item xs={3}>
                                <FormControl fullWidth>
                                        <TextField
                                            select
                                            labelId="heigth-type-select-label"
                                            id="heigth-type-select"
                                            label={t("overlays.properties.type")}
                                            onChange={onChange}
                                            >
                                            {optionsHeigthType.map(component => {
                                                return <MenuItem value={component.value}>{component.label}</MenuItem>
                                            })}
                                        </TextField>
                                </FormControl>   
                        </Grid> */}
                        <Grid className='item-container' item xs={3}>
                                  <TextField
                                    className='item-overlay'
                                    size='small' 
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                                    id="heigth"  
                                    value={props.width}
                                    label={props.label ? props.label : t("overlays.properties.width") + " (px)"} 
                                    variant="outlined" 
                                    onChange={onChange}/>
                                  {/* <Slider max={100} defaultValue={80} aria-label="Default" valueLabelDisplay="auto" onChange={onChange}/> */}
                        
                        </Grid>
        </Fragment>
    );
}

export default WidthForm;
