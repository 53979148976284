import React, {useContext, useEffect, useState} from 'react';
import AppContext from "../app/AppContext";
import {useTranslate} from "react-polyglot";
import ExperienceService from "../../services/Experience";
import FilterBy from "../Filters/FilterBy";
import {UserContext} from "../../context/UserContext";
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';


const ExperiencesListWithStatus = (props) => {
    const t = useTranslate();
    const [data, setData] = useState([]);
    const {setNavLinks} = useContext(UserContext);
    const formatName = (type) => {
        switch (type) {
            case 'published':
                return t('published');
            case 'unpublished':
                return t('unpublished');
            case 'in_revision':
                return t('in_revision');
            case 'rejected':
                return t('rejected');
            default:
                return 'N / A';
        }
    };

    const getExperiencesWithStatus = async () => {
        const response = await ExperienceService.listByStatuses();
        const experiencesWithSingleStatus = response[props.type] ? response[props.type] : [];
        setData(experiencesWithSingleStatus)
    };

    const context = useContext(AppContext);
    useEffect(() => {
            setNavLinks([{
            id: '',
            name: formatName(props.type),
            tab: 'status'
        }]);
        context.setPage({
            name: `Experiencias ${formatName(props.type)}`,
            hideFilters: false,
            hideStatusFilter: true,
            refetch: getExperiencesWithStatus()
        });
        getExperiencesWithStatus();
    }, []);


    return (
        <FilterBy data={data} typeData="experiences">
            {result => (
                <div className={'campaign-container'}>
                                            
                    {result.map(exp => <CardHomeStudio key={exp.id} experienceCardStyle={'campaign-exp-card'} experience={exp}/>)}
                </div>
            )}
        </FilterBy>
    );
};

export default ExperiencesListWithStatus;