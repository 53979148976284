import React, {useEffect, useState} from 'react';
import {Table, Checkbox, Button} from "rsuite";
import {useTranslate} from 'react-polyglot';

import {
    formatNumber,
    getCampaignColumns,
    getCompanyColumns,
    getExperienceColumns
} from "../../pages/Reports/ReportUtils";

const TableComponent = ({
                            data, setInitialData, initialData, setLoading,
                            navLinks, setNavLinks, columns,
                            setColumns, withQuery
                        }) => {
    const {HeaderCell, Cell, Column} = Table;
    const dataTable = data.table;
    dataTable.forEach(e => {
        e.scans = parseInt(e.scans);
        e.scansString = formatNumber(e.scans)
    });
    const [loadingTable,] = useState(false);
    const [sortColumn, setSortColumn] = useState('');
    const [sortType, setSortType] = useState('asc');
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [enabledFilter, setEnabledFilter] = useState(true);

    const t = useTranslate();

    useEffect(() => {
        if (checkedKeys.length > 0) {
            setEnabledFilter(false);
        } else {
            setEnabledFilter(true);
        }
    }, [checkedKeys]);

    const handleCheckAll = (value, checked) => {
        let keyAttr;
        if (initialData.from && initialData.to) {
            keyAttr = 'company_id'
        }
        if (initialData.from && initialData.to && initialData.company_id) {
            keyAttr = 'campaign_id'
        }
        if (initialData.from && initialData.to && initialData.company_id && initialData.campaign_id) {
            keyAttr = 'experience_id'
        }
        const checkedKeys = checked ? dataTable.map(item => item[keyAttr]) : [];
        setCheckedKeys(checkedKeys);
    };

    const handleCheck = (value, checked) => {
        const nextCheckedKeys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter(item => item !== value);

        setCheckedKeys(nextCheckedKeys);
    };

    const handleFilter = () => {
        let values = checkedKeys.join(',');
        setInitialData({...initialData, ...{ids: values}});
    };

    const getKeyRef = () => {
        if (navLinks[navLinks.length - 1].tab === '/reports')
            return 'company_id';
        if (navLinks[navLinks.length - 1].tab === '/company')
            return 'campaign_id';
        if (navLinks[navLinks.length - 1].tab === '/campaign')
            return 'experience_id';
        return ''
    };

    const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}) => (
        <Cell {...props} style={{padding: 0}}>
            <div style={{lineHeight: '46px'}}>
                <Checkbox
                    value={rowData[dataKey]}
                    onChange={onChange}
                    checked={checkedKeys.some(item => item === rowData[dataKey])}
                />
            </div>
        </Cell>
    );

    const getData = () => {
        if (sortColumn && sortType) {
            return dataTable.sort((a, b) => {
                let x = sortColumn === 'scansString' ? a['scans'] : a[sortColumn];
                let y = sortColumn === 'scansString' ? b['scans'] : b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return dataTable;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
        setSortColumn(sortColumn);
        setSortType(sortType);
    };


    let checkedVal = false;
    let indeterminateVal = false;

    if (checkedKeys.length === dataTable.length) {
        checkedVal = true
    } else if (checkedKeys.length === 0) {
        checkedVal = false
    } else if (checkedKeys.length > 0 && checkedKeys.length < dataTable) {
        indeterminateVal = true;
    }

    // TODO: Como hay un solo uso de esta table el style de la clase Cell esta harcodeado, pero
    // deberia recibirlo por parametro como setColumns con su configuracion en ReportUtils.js
    return (
        <div>
            <Table className={'tableMargin'}
                   height={320}
                   data={getData()}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   loading={loadingTable}
                   onRowClick={(data, event) => {
                       if (event.target.className === "rs-checkbox-wrapper") return;
                       if (!initialData.company_id) {
                           let tab = [{tab: '/company', company_id: data.company_id, text: data.company_name}];
                           setNavLinks([...navLinks, ...tab]);
                           setLoading(true);
                           setInitialData({
                               ...initialData, ...{
                                   company_id: data.company_id
                               }
                           });
                           let colTemplate = getCompanyColumns();
                           setColumns(colTemplate);
                       } else {
                           if (!initialData.campaign_id) {
                               let tab = [{tab: '/campaign', campaign_id: data.campaign_id, text: data.campaign_name}];
                               setNavLinks([...navLinks, ...tab]);
                               setLoading(true);
                               setInitialData({
                                   ...initialData, ...{
                                       campaign_id: data.campaign_id
                                   }
                               });
                               let colTemplate = getCampaignColumns();
                               setColumns(colTemplate);
                           } else {
                               if (!initialData.experience_id) {
                                   let tab = [{
                                       tab: '/experience',
                                       experience_id: data.experience_id,
                                       text: data.experience_name
                                   }];
                                   setNavLinks([...navLinks, ...tab]);
                                   setLoading(true);
                                   setInitialData({
                                       ...initialData, ...{
                                           experience_id: data.experience_id
                                       }
                                   });
                                   let colTemplate = getExperienceColumns();
                                   setColumns(colTemplate)
                               }
                           }
                       }
                   }}
            >
                {columns.map(e => (
                    <Column key={new Date().valueOf().toString(36) + Math.random().toString(36).substr(2)}
                            align="left" {...e} >
                        <HeaderCell style={e.styles}>
                            {e.checkvalid === 'x' ?
                                <div style={{lineHeight: '40px'}}>
                                    <Checkbox
                                        checked={checkedVal}
                                        indeterminate={indeterminateVal}
                                        onChange={handleCheckAll}
                                    />
                                </div> : e.buttonvalid === 'x' ?
                                    <Button disabled={enabledFilter} appearance="primary" onClick={handleFilter}
                                            style={{
                                                padding: '3px',
                                                width: '100%'
                                            }}>{t('pages.reports.headTable.filter')}</Button> : t(e.text)}
                        </HeaderCell>
                        {e.checkvalid === 'x' ? <CheckCell 
                            dataKey={getKeyRef()}
                            checkedKeys={checkedKeys}
                            onChange={handleCheck}
                        /> : <Cell style={{cursor: 'pointer'}}  dataKey={e.key}/>}
                    </Column>
                ))}
            </Table>
        </div>
    );
};

export default TableComponent;