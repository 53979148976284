import React from "react";
import ReactDOM from "react-dom";
import './styles.css'
import { Table, Toggle, TagPicker } from "rsuite";

const { Column, HeaderCell, Cell } = Table;


const CompactCell = (props) => <Cell {...props} style={{ padding: 4 }} />;
const CompactHeaderCell = (props) => (
  <HeaderCell {...props} style={{ padding: 4 }} />
);

const experienceColumns = [
  {
    key: "interaction_label",
    label: "Tipo de interaccion",
    flexGrow: 3
  },
  {
    key: "interactions",
    label: "Cantidad",
    flexGrow: 1
  }
];

export default function CompaniesTable({data,loading}) {
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [compact, setCompact] = React.useState(false);
  const [columnKeys, setColumnKeys] = React.useState(experienceColumns.map((column) => column.key))
  const columns = experienceColumns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const CustomCell = compact ? CompactCell : Cell;
  const CustomHeaderCell = compact ? CompactHeaderCell : HeaderCell;
  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };
  const handleSortColumn = (sortColumn, sortType) => {
      setSortColumn(sortColumn);
      setSortType(sortType);
  };
  return (
    <div>
      <div style={{ height: 400 }}>
        <Table
          rowClassName={'cursor-pointer'}
          loading={loading}
          height={300}
          hover={true}
          showHeader={true}
          data={data ? getData() : []}
          bordered={true}
          cellBordered={true}
          headerHeight={45}
          rowHeight={45}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          {columns.map((column) => {
            const { key, label, ...rest } = column;
            return (
              <Column sortable {...rest} key={key}>
                <CustomHeaderCell>{label}</CustomHeaderCell>
                <CustomCell dataKey={key} />
              </Column>
            );
          })}
        </Table>
      </div>
    </div>
  );
};
