import request from '../utils/request';
import ExperiencesServices from './Experience';
import moment from 'moment';

const localRoutProject = process.env.REACT_APP_LOCAL_ROUTE_PROJECT ? 'campaigns' : 'projects';

class CampaignService {
    static async list() {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/${localRoutProject}`);
    }

    static async listCampaings() {
        return request.get(`${process.env.REACT_APP_MOES_ENDPOINT}/${localRoutProject}`);
    }

    static async migrateCampaign(selectedCampaign, selectedUser) {
        return request.postJson(`${process.env.REACT_APP_MOES_ENDPOINT}/campaigns/${selectedCampaign}/migrate`, {
            body: {id: selectedUser.id}
        });
    }

    static async listWithExperiences(userId) {
        let url = `${process.env.REACT_APP_MOES_ENDPOINT}/${localRoutProject}?with_experiences=1`;
        if (userId)
            url = url.concat(`&user_id=${userId}`);
        try {
            const data = await request.get(url);
            return {data};
        } catch (error) {
            Promise.reject(error);
        }

    }

    static async listWithoutExperiences(userId){
        let url = `${process.env.REACT_APP_MOES_ENDPOINT}/${localRoutProject}?with_experiences=0`;
        if (userId)
            url = url.concat(`&user_id=${userId}`);
        try {
            const data = await request.get(url);
            return {data};
        } catch (error) {
            Promise.reject(error);
        }
    }

    static async listWithExperiencesRecent() {
        try {
            const [campaigns, experiences] = await Promise.all([
                CampaignService.list(),
                ExperiencesServices.list()
            ]);
            experiences.data.map((exp) => {
                return {...exp, hide: false}
            });
            // TODO: check better approach to get experiences by campaings
            campaigns.map((campaign) => {
                campaign.experiences = experiences.data.filter((experience) => {

                    return experience.campaign.id === campaign.id && moment(experience.updated_at).isAfter(moment().subtract('1 hour'))
                });
                return campaign;
            });
            const cmapaignsData = campaigns.filter((campaign) => {
                return campaign.experiences.length;
            });
            return Promise.resolve({data: cmapaignsData})
        } catch (error) {
            Promise.reject(error);
        }

    }

    static async listWithExperiencesPublished() {
        try {
            const [campaigns, experiences] = await Promise.all([
                CampaignService.list(),
                ExperiencesServices.list()
            ]);
            experiences.data.map((exp) => {
                return {...exp, hide: false}
            });
            // TODO: check better approach to get experiences by campaings
            campaigns.map((campaign) => {
                campaign.experiences = experiences.data.filter((experience) => {

                    return experience.campaign.id === campaign.id && experience.status === 'PUBLISHED'
                });
                return campaign;
            });
            const cmapaignsData = campaigns.filter((campaign) => {
                return campaign.experiences.length;
            });
            return Promise.resolve({data: cmapaignsData})
        } catch (error) {
            Promise.reject(error);
        }

    }

    static async get(id, userId) {
        try {
            const campaigns = await CampaignService.listWithExperiences(userId);
            const data = campaigns.data.filter((campaign) => {
                return campaign.id === parseInt(id)
            });
            return Promise.resolve(data[0]);
        } catch (error) {
            return Promise.reject(error);
        }

    }

    static async editCampaign(params, id) {
        try {
            request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/projects/${id}`, {
                body: params
            });
            return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/campaigns/${id}`, {
                body: params
            });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async removeCampaign(id) {
        return request.delete(`${process.env.REACT_APP_MOES_ENDPOINT}/campaigns/${id}`);
    }
}

export default CampaignService;
