export default () => {
    const localLang = window.localStorage.getItem('lang')
    if (localLang) {
        return localLang;
    }
    let lang = '';
    if (navigator.languages && navigator.languages.length) {
        lang = navigator.languages[0];
    } else {
        lang = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    }
    window.localStorage.setItem('lang', lang.split('-')[0]);
    return lang.split('-')[0];
}
