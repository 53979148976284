


import React, {Fragment, useState,useContext } from 'react';
import {Grid,TextField,FormGroup,FormControlLabel,FormControl,Modal,Typography,Box, Select,MenuItem, IconButton} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './CowcNavigation360TourTemplate.css';
import WebComponentModal  from './WebComponentModal';
import SausageButton from '../../components/coa/SausageButton';
import { useTranslate } from 'react-polyglot';
import BackgroundForm from './BackgroundForm';
import {OverlaysContext} from '../../context/OverlaysContext'




function CowcNavigation360TourTemplate() {
    const t = useTranslate();

   
    
    //se utiliza para saber que variable mostrar (Es la variable seleccionada)
    const {variableSelected, setVariableSelected} = useContext(OverlaysContext)
   
    //se utiliza para mostrar el modal que tiene los datos para copiar el codigo
    const [openModal, setOpenModal] = useState(false);


   

    //variables
    const [visible, setVisible] = useState(false);

    const [stepIndexActiveColor, setStepIndexActiveColor] = useState("#5C2D91");
    const [stepIndexColor, setStepIndexColor] = useState("#D8D7D7");

    const [textColor, setTextColor] = useState("#000");

    const [buttonsColor, setButtonsColor] = useState("#5d2f91");

    const [backgroudColor, setBackgroudColor] = useState("#ffffff");

    const [font, setFont] = useState("Arial");

    const [timeIn, setTimeIn] = useState(1);

    const [top, setTop] = useState(15);
    
    const [closeButtonUrl, setCloseButtonUrl] = useState()

    const [steps, setSteps] = useState(
        `[
                {"srcIcon":"https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/tour_icon_3.png",
                "title":"Rotate",
                "description":"Use two fingers to rotate the character"},
                
                {"srcIcon":"https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/tour_icon_4.png",
                "title":"Scale",
                "description":"Use two fingers to scale the character"},
                
                {"srcIcon":"https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/tour_icon_5.png",
                "title":"Recording",
                "description":"Press-hold the button to record video"},
                
                {"srcIcon":"https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/tour_icon_6.png",
                "title":"Share",
                "description":"Share your AR experience on social media and the hashtag #grabgiftofmagic"}]`
    
    );
    
    const [stepsArray, setStepsArray] = useState(
        [
            {"srcIcon":"https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/tour_icon_3.png",
            "title":"Rotate",
            "description":"Use two fingers to rotate the character"},
        ]
    );


    const [textLeftButton, setTextLeftButton] = useState("SKIP");
    const [textRightButton, setTextRightButton] = useState("NEXT");


     const [height, setHeight] = useState(200);
    // const [heigthTypeSelected, setHeigthTypeSelected] = useState("px");
   
     const [width, setWidth] = useState(400);
    // const [widthTypeSelected, setWidthTypeSelected] = useState("px");

    const [position, setPosition] = useState("absolute"); //fixed en real

    
    
    const optionsVariables = [
        {label: t("overlays.properties.steps"), value : t("overlays.properties.steps")},
        {label: t("overlays.properties.primaryBackgroundColor"), value : t("overlays.properties.primaryBackgroundColor")},
        {label: t("overlays.properties.itemColor"), value : t("overlays.properties.itemColor")},
        {label: t("overlays.properties.activeItemColor"), value : t("overlays.properties.activeItemColor")},
        {label: t("overlays.properties.primaryTextColor"), value : t("overlays.properties.primaryTextColor")},
        {label: t("overlays.properties.textAndColor"), value : t("overlays.properties.textAndColor")},
        {label: t("overlays.properties.font"), value : t("overlays.properties.font")},
        {label: t("overlays.properties.top"), value : t("overlays.properties.top")},
        {label: t("overlays.properties.timeToAppear"), value : t("overlays.properties.timeToAppear")},
        {label: t("overlays.properties.closeButton"), value: t("overlays.properties.closeButton")},
        {label: t("overlays.properties.mesaures"), value: t("overlays.properties.mesaures")}
    ]


  
    
    //Es la funcion que se utiliza para devolver el codigo que se va mostrar en el modal y que se va a utilizar
    //para copiar al clipboard
    const getScript = ()=>{
        let array = stepsArray.slice();
        let stepsString = ``;
        array.forEach((step ,index)=> {
            let stepString = `` ;
            if(index != array.length-1){
                stepString = `
                {"srcIcon":"${step.srcIcon}",
                "title":"${step.title}",
                "description":"${step.description}"},
                `       
            }else{
                stepString = `
                {"srcIcon":"${step.srcIcon}",
                "title":"${step.title}",
                "description":"${step.description}"}
                `       
            }
            stepsString = stepsString + stepString;
        });
        stepsString = `[${stepsString}]`;



        const script = `"
        <cowc-360-navigation-tour 
        visible='${visible}'
        steps-index-active-color='${stepIndexActiveColor}'
        steps-index-color='${stepIndexColor}'
        text-color='${textColor}'
        buttons-color='${buttonsColor} '
        background-color='${backgroudColor} '
        font='${font}'
        time-in='${timeIn} '
        text-left-button='${textLeftButton}'
        text-right-button='${textRightButton}'
        position='${position}'
        steps= '${stepsString}'
        icon-height = '40px'
        height =${height && height.length ? height + "px" : "none"}
        width = ${width + "px"}
        have-btn-close=${closeButtonUrl && closeButtonUrl.length ? true : false}
        btn-close-url=${closeButtonUrl}
        
        >
    </cowc-360-navigation-tour>
        "`;
        return script;
    }

    
    const handleClickAddStepButton = () =>{
        let newStepArray = stepsArray.slice();
        newStepArray.push(
            {
                title: "",
                description: "",
                srcIcon: ""
            }
        );
        setStepsString(newStepArray);
        setStepsArray(newStepArray)
    }
    
    
    const  handleClickDeleteteStepButton = (index) =>{
        if(stepsArray.length>1){

            let newArray =  stepsArray.slice();
            newArray = newArray.filter(
                step => {
                    return(step.title != newArray[index].title 
                        && step.description != newArray[index].description 
                        && step.srcIcon != newArray[index].srcIcon )
                    }
                    )
                    setStepsString(newArray);
                    setStepsArray(newArray)
        }
    }
    
    
    /**
     * 
     * @author AxelMolaro
     * @description recibe un array de steps y lo transforma en el string correcto para que lo reciba como parámetro el componente. Y setea la variable de estado correspondiente.
     **/
    const  setStepsString = (array) =>{
        let stepsString = ``;
        array.forEach((step ,index)=> {
            let stepString = `` ;
            if(index != array.length-1){
                stepString = `
                {"srcIcon":"${step.srcIcon}",
                "title":"${step.title}",
                "description":"${step.description}"},
                `       
            }else{
                stepString = `
                {"srcIcon":"${step.srcIcon}",
                "title":"${step.title}",
                "description":"${step.description}"}
                `       
            }
            stepsString = stepsString + stepString;
        });
        stepsString = `[${stepsString}]`;
        setSteps(
            stepsString
        )
    }
    
   
    /**
     * @param index es la ubicacion en el array
     * @param value es el valor del input
     * @description cambia el titulo de un step determinado
     * @author AxelMolaro
     */
    const handleChangeTitleStep = (e, index) => {
        let newStepArray = stepsArray.slice();
        newStepArray[index].title = e.target.value;
        setStepsString(newStepArray)
        setStepsArray(newStepArray)
    }

     /**
     * @param index es la ubicacion en el array
     * @param value es el valor del input
     * @description cambia la descriocion  de un step determinado
     * @author AxelMolaro
     */
      const handleChangeDescriptionStep = (e, index) => {
        let newStepArray = stepsArray.slice();
        newStepArray[index].description = e.target.value;
        setStepsString(newStepArray)
        setStepsArray(newStepArray)
    }

     /**
     * @param index es la ubicacion en el array
     * @param value es el valor del input
     * @description cambia el titulo de un step determinado
     * @author AxelMolaro
     */
      const handleChangeSrcIconStep = (e, index) => {
        let newStepArray = stepsArray.slice();
        newStepArray[index].srcIcon = e.target.value;
        setStepsString(newStepArray)
        setStepsArray(newStepArray)
    }


    

    const renderVariableSelected = () => {
        
        switch(variableSelected){
            case t("overlays.properties.itemColor"): return (
                <Grid className='item-container'>
                    <BackgroundForm color = {stepIndexColor}
                        //opacity ={opacity}
                        onChange={ e=>{setStepIndexColor(e.target.value)}
                    }>
                    </BackgroundForm>
                </Grid>
                );
            case t("overlays.properties.activeItemColor"): return (
                <Grid className='item-container'>
                    <BackgroundForm color = {stepIndexActiveColor}
                        //opacity ={opacity}
                        onChange={ e=>{setStepIndexActiveColor(e.target.value)}
                    }>
                    </BackgroundForm>
                </Grid>
                );
            case t("overlays.properties.primaryTextColor"): return (
                <Grid className='item-container'>
                    <BackgroundForm color = {textColor}
                        onChange={ e=>{setTextColor(e.target.value)}
                    }>
                    </BackgroundForm>
                </Grid>
            );
            case t("overlays.properties.textAndColor"): return (
                <>
                <Grid className='item-container'>
                    <BackgroundForm
                        color = {buttonsColor}
                        label = {t("overlays.properties.buttonsColor")}
                        onChange={ e=>{setButtonsColor(e.target.value)}
                    }>
                    </BackgroundForm>
                </Grid>
                <Grid className='item-container'>
                    <TextField 
                        className='item-overlay'
                        size='small'   
                        id="text-left-button" 
                        label={t("overlays.properties.textLeftButton")} 
                        value={textLeftButton} 
                        variant="outlined" 
                        onChange={ e=>{setTextLeftButton(e.target.value)}}
                    />
                </Grid>
                <Grid className='item-container'>
                    <TextField  
                        className='item-overlay'
                        size='small'  
                        id="text-right-button" 
                        label={t("overlays.properties.textRightButton")} 
                        value={textRightButton} 
                        variant="outlined" 
                        onChange={ e=>{setTextRightButton(e.target.value)}}
                    />
                </Grid>
                </>
            );
            case t("overlays.properties.primaryBackgroundColor"): return (
                <Grid className='item-container'>
                <BackgroundForm color = {backgroudColor}
                    onChange={ e=>{setBackgroudColor(e.target.value)}
                }>
                </BackgroundForm>
                </Grid>
            );
            case t("overlays.properties.font"): return (
                <Grid className='item-container' xs={4}>
                    <TextField   
                        className='item-overlay'
                        select
                        size='small' 
                        id="font" 
                        label={t("overlays.properties.font")} 
                        value={font} 
                        variant="outlined" 
                        onChange={ e=>{setFont(e.target.value)}}
                    >
                        <MenuItem value="Arial">Arial</MenuItem>
                        <MenuItem value="Roboto">Roboto</MenuItem>

                    </TextField>
                </Grid>
            );
            case t("overlays.properties.timeToAppear"): return (
                
                <Grid className='item-container'>
                    <TextField 
                        className='item-overlay'
                        size='small'   
                        id="timeIn" 
                        label={t("overlays.properties.timeToAppear") + " (seg)"} 
                        value={timeIn} variant="outlined" 
                        onChange={ e=>{setTimeIn(e.target.value)}}/>
                </Grid>
            );
            case t("overlays.properties.top"): return (
                <>
                <Grid className='item-container'>
                    <TextField   
                        className='item-overlay'
                        size='small' 
                        id="top" 
                        label={t("overlays.properties.top") + " (px)"} 
                        value={top} variant="outlined" 
                        onChange={ e=>{setTop(e.target.value)}}/>
                </Grid>
                </>
            );
            case t("overlays.properties.mesaures"): return (
                <>
                <Grid className='item-container'>
                    <TextField   
                        className='item-overlay'
                        size='small' 
                        id="width" 
                        label={t("overlays.properties.width") + " (px)"} 
                        value={width} variant="outlined" 
                        onChange={ e=>{setWidth(e.target.value)}}/>
                </Grid>
                <Grid className='item-container'>
                    <TextField   
                        className='item-overlay'
                        size='small' 
                        id="height" 
                        label={t("overlays.properties.height") + " (px)"} 
                        value={height} variant="outlined" 
                        onChange={ e=>{setHeight(e.target.value)}}/>
                </Grid>
                </>
            );
            case t("overlays.properties.closeButton"): return (
                <>
                <Grid className='item-container'>
                    <TextField   
                        className='item-overlay'
                        id="scloseButton" 
                        size='small' 
                        label={t("overlays.properties.setUrl")} 
                        value={closeButtonUrl} 
                        variant="outlined" 
                        onChange={(e)=>{setCloseButtonUrl(e.target.value)}}/>
                </Grid>
                </>
            );
            case t("overlays.properties.steps"): return (
                <Grid className='item-container' container spacing={2} xs={12}>
                    {stepsArray.map((step,index)=>{
                       return (
                        <Fragment key={index}>
                                <Grid item xs={1}>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <AddCircleIcon  onClick={handleClickAddStepButton}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <DeleteIcon  onClick={() => {handleClickDeleteteStepButton(index)}}/>
                                    </IconButton>
                                </Grid>  
                                <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <TextField   
                                            id="step-path" 
                                            size='small' 
                                            label={t("overlays.properties.setUrl")} 
                                            value={step.srcIcon} 
                                            variant="outlined" 
                                            onChange={(e)=>{handleChangeSrcIconStep(e,index)}}/>
                                    </FormControl>
                                </Grid>                             
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField   
                                            id="step-title" 
                                            size='small' 
                                            label={t("overlays.properties.title")} 
                                            value={step.title} 
                                            variant="outlined" 
                                            onChange={(e)=>{handleChangeTitleStep(e,index)}}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField   
                                            id="step-description" 
                                            label={t("overlays.properties.description")} 
                                            value={step.description} 
                                            size='small' 
                                            variant="outlined" 
                                            onChange={ (e)=> {handleChangeDescriptionStep(e,index)}}/>
                                    </FormControl>
                                </Grid>
                               
                        </Fragment>
                       )
                    })}
                    
                    {/* <Grid item xs={1}>

                         <IconButton color="primary" aria-label="upload picture" component="span">
                            <AddCircleIcon/>
                        </IconButton>
                    </Grid>
                     */}
                </Grid>
            );
        }
    }

    

    //todo pasar a componente este modal
    const handleClickExport = () => {
        setOpenModal(!openModal);
    }

    const handleClickCloseModal = () => {
        setOpenModal(false)
    }
    
      

    // useEffect(() => {
    //     let el = document.getElementsByTagName("cowc-360-navigation-tour")[0];
    //     debugger 
    //   });
    

    
    

    return (
        <Fragment>
            <Grid className='item-container' container spacing={2}>
            <TextField
                size='small' 
                variant='outlined'
                id="variables-select"
                select
                label="Variables"
                className='item-overlay'
                value={variableSelected}
                onChange={e => {setVariableSelected(e.target.value)}}
                >
                {optionsVariables.map(component => {
                    return <MenuItem value={component.value}>{component.label}</MenuItem>
                })}
            </TextField>   
            </Grid> 
            {renderVariableSelected()}  
            <div className='previsualizer-container'>
                <div className='main'>
                    <div className='modal-visualiser'>
                        <div className='tt desktop-visualiser-contain'>
                            <cowc-360-navigation-tour 
                                visible={visible}
                                steps-index-active-color={stepIndexActiveColor}
                                steps-index-color={stepIndexColor}
                                text-color={textColor}
                                buttons-color={buttonsColor} 
                                background-color={backgroudColor} 
                                font={font}
                                time-in={timeIn} 
                                top={top/3+"px"}
                                text-left-button={textLeftButton}
                                text-right-button={textRightButton}
                                position={position}
                                steps= {steps}
                                icon-height = "40px"
                                height = {height > 200 ? "200px" : height + "px"}
                                width = {width > 500 ? "500px" : width + "px"}
                                left="60px"
                                have-btn-close={closeButtonUrl && closeButtonUrl.length ? true : false}
                                btn-close-url={closeButtonUrl}
                                >
                            </cowc-360-navigation-tour>
                        </div>
                    </div>
                </div>
                <FormGroup className="text-center"> 
                    <SausageButton
                        className="btn-export"
                        label={t("overlays.export")}
                        onClick={handleClickExport}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
                </FormGroup>
            </div>
                <WebComponentModal title="Cowc-360-navigation-tour" open={openModal} onCloseModal={handleClickCloseModal}>{getScript()}</WebComponentModal>
        </Fragment>
    );
}

export default CowcNavigation360TourTemplate;
