import React, { memo, useEffect, useState, useReducer, useContext } from 'react'
import { DataTable, DataTableHead, DataTableContent, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import { Tooltip } from '@rmwc/tooltip';
import { Link } from "@reach/router";
import moment from 'moment';
import { useTranslate } from 'react-polyglot';
import Icon from '../../components/icon';
import Notification from '../../components/notification/notification';
import publicationsService from '../../services/Publications';
import Search from '../../components/common/search';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import Loading from '../../components/loading';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/tooltip/tooltip.css'
import '@material/icon-button/dist/mdc.icon-button.css';
import '@rmwc/icon/icon.css';
import './user.scss';

const DialogConfirm = ({ data: { msg } }) => {
    const t = useTranslate();
    return (
        <div>
            <p>{msg}</p>
            <p>{t('common.msg.doYouContinue')}</p>
        </div>
    )
};

const ITEMS_PER_PAGE = 5;

const Publications = memo(function Publications({ setModal }) {
    const [publications, setPublications] = useState([]);
    const [page, setPage] = useState(1);
    const [response, setResponse] = useState([]);
    const [search, setSearch] = useState('');
    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => ({ ...prevState, ...currState }),
        { showNotification: false, propsNotification: {} });
    const t = useTranslate();

    const { showLoading, setShowLoading } = useContext(ShowLoadingContext)


    useEffect(() => {
        setShowLoading(true)
        publicationsService.list()
            .then((res) => {
                setPublications(res.data);
                setResponse(res)

            })
            .finally(res =>setShowLoading(false))
    }, [])
    function goToExp() {
        window.open(`${process.env.REACT_APP_EDITOR_DOMAIN}?id=${this}`, "_blank")
    }
    function parseDate(date) {
        return new moment(date).format('YYYY-MM-DD');
    }
    function getTarget(campaign) {
        return (
            <Link to={`/campaign/${campaign.id}`}>{campaign.name}</Link>
        )
    }
    async function rejectExp() {
        await publicationsService.reject(this);
        getDataPaginated(page)
        setNotification({
            showNotification: true,
            propsNotification: {
                label: t('pages.account.publishes.notification.rejected'),
                message: 'Se rechazado correctamente'
            }
        });
    }
    async function approve() {
        await publicationsService.approve(this)
        getDataPaginated(page)
        setNotification({
            showNotification: true,
            propsNotification: {
                label: t('pages.account.publishes.notification.approved'),
                message: t('pages.account.publishes.notification.msgApproved')
            }
        });
    }
    async function getDataPaginated(pageToGet) {
        return publicationsService.list(true, pageToGet, ITEMS_PER_PAGE, { name: search, 'campaign.name': search })
            .then(res => { setPublications(res.data); setResponse(res) });
    }
    function getLastPage() {
        if (page <= 1) {

        } else {
            setPage(page - 1)
            getDataPaginated(page - 1);
        }

    }
    function getNextPage() {

        if ((response.total / 5) > page) {
            setPage(page + 1)
            getDataPaginated(page + 1);
        }

    }
    function modalConfirmApprove() {
        const actionsDialogData = {
            title: t('pages.account.publishes.modal.approve.title'),
            componentData: { msg: t('pages.account.publishes.modal.approve.msg') },
            component: DialogConfirm,
            componentKey: 99,
            action: approve.bind(this),
        };
        setModal({ openModal: true, propsModal: actionsDialogData })
    }
    function modalConfirmDisapprove(userId) {
        const actionsDialogData = {
            title: t('pages.account.publishes.modal.rejected.title'),
            componentData: { userId, msg: t('pages.account.publishes.modal.rejected.msg') },
            component: DialogConfirm,
            componentKey: 99,
            action: rejectExp.bind(this),
        };
        setModal({ openModal: true, propsModal: actionsDialogData })
    }
    function closeNotification() {
        setNotification({ showNotification: false })
    }
    function searchPublications(value) {
        setSearch(value);
        publicationsService.list(true, 1, ITEMS_PER_PAGE, { name: value, 'campaign.name': value })
            .then(res => { setPublications(res.data); setResponse(res) });
    }

    return (

        <>
            {showLoading ?
                <Loading/>
            :
                <section className="profile-user-container">
                    <div className="ẗable-profile-actions-header">
                        <Search func={searchPublications} />
                    </div>
                    <DataTable className={'paddingTables'}>
                        <DataTableContent>
                            <DataTableHead className="list-table-head">
                                <DataTableRow>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.name')}</DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.email')}</DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('pages.account.publishes.headTable.campaign')}</DataTableHeadCell>
                                    <DataTableHeadCell
                                        alignMiddle
                                    >
                                        {t('common.labels.experience')}
                                    </DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('pages.account.publishes.headTable.date')}</DataTableHeadCell>
                                    <DataTableHeadCell alignMiddle>{t('common.labels.actions')}</DataTableHeadCell>

                                </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                                {publications.map((publication, i) => {
                                    return (
                                        <DataTableRow key={i}>
                                            <DataTableCell alignMiddle>
                                                {publication.user.name ? `${publication.user.name} ${publication.user.surname}` : ''}
                                            </DataTableCell>
                                            <DataTableCell alignMiddle>{publication.user.email}</DataTableCell>
                                            <DataTableCell alignMiddle>{getTarget(publication.campaign)}</DataTableCell>
                                            <DataTableCell alignMiddle>{publication.name}</DataTableCell>


                                            <DataTableCell alignMiddle>{parseDate(publication.request_publish_at)}</DataTableCell>
                                            <DataTableCell className="users-actions" >
                                                <Tooltip content={t('pages.account.publishes.actions.see')} showArrow activateOn="hover">
                                                    <Icon name={'eye-line'} alignMiddle onClickFn={goToExp.bind(publication.id)} />
                                                </Tooltip>
                                                <Tooltip content={t('pages.account.publishes.actions.approve')} showArrow activateOn="hover">
                                                    <Icon name={'check-line'} alignMiddle onClickFn={modalConfirmApprove.bind(publication)} />
                                                </Tooltip>
                                                <Tooltip content={t('pages.account.publishes.actions.rejected')} showArrow activateOn="hover">
                                                    <Icon name={'close'} alignMiddle onClickFn={modalConfirmDisapprove.bind(publication)} />
                                                </Tooltip>
                                            </DataTableCell>
                                        </DataTableRow>
                                    )
                                })}
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                    <div className="table-pagination">
                        <div className="table-pagination-element first">
                        {page > 1 && (
                            <span className="table-pagination-link" onClick={getLastPage}>
                                {t('pages.account.pagination.prev')}
                            </span>
                            )}
                        </div>
                        {response.total / ITEMS_PER_PAGE > page && (
                            <div className="table-pagination-element second">
                                <span className="table-pagination-link" onClick={getNextPage}>
                                {t('pages.account.pagination.next')}
                                </span>
                            </div>
                        )}
                    </div>
                    {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
                </section>
            }
        </>
        
    )
})

Publications.propTypes = {

}

export default Publications
