import React from 'react';
import ReactDOM from 'react-dom';
import { I18n } from 'react-polyglot';
import './index.css';
import App from './components';
import Amplify, { Auth } from 'aws-amplify';
import AWSStorage from '@aws-amplify/storage';
import awsconfig from './aws-exports';
import * as serviceWorker from './serviceWorker';
import getLocale from './utils/locale';
import messages from './lang/index';
import amplitude from 'amplitude-js';

Amplify.configure(awsconfig);
Auth.configure({
    identityPoolId: 'us-east-1:47a2b454-3632-419b-9f2b-780c974b2809',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_gYGPNGcKE',

});

AWSStorage.configure(awsconfig);    

amplitude.getInstance().init("a4e488329c730a5f004479af5d7f9723");

const locale = getLocale();
const message = messages[locale] || messages['en'];


ReactDOM.render(<I18n locale={locale} messages={message}><App /></I18n>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
