import React, {createContext, useContext, useEffect, useState} from 'react';
import ChangeNameComponent from "../components/actionsDialog/ChangeNameComponent";
import ExperienceService from "../services/Experience";
import Appcontext from "../components/app/AppContext";

export const ChangeNameDialogContext = createContext();

const ChangeNameDialogProvider = (props) => {
    const context = useContext(Appcontext);
    const [openChangeNameDialog, setOpenChangeDialog] = useState(false);
    const [changeNameData, setDialogChangeNameData] = useState({
        id: '',
        name: ''
    });
    const ChangeNameDialogComponent = () => (<ChangeNameComponent/>);

    const onChangeName = async () => {
        const {id, name} = changeNameData;
        try {
            await ExperienceService.update({name}, id);
            if (context.page.refetch) {
                context.page.refetch()
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (<ChangeNameDialogContext.Provider
        value={{
            openChangeNameDialog,
            setOpenChangeDialog,
            changeNameData,
            setDialogChangeNameData,
            ChangeNameDialogComponent,
            onChangeName
        }}>{props.children}</ChangeNameDialogContext.Provider>)
};

export default ChangeNameDialogProvider;