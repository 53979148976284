import React from 'react';
import {ReactSVG} from "react-svg";
import {useTranslate} from "react-polyglot";
import {Button} from "rsuite";

const EditorComponentWorldWeb = ({hover, setHover, setTitle, setStep, setExperienceRoad, experienceRoad}) => {
    const t = useTranslate();
    return (
        <div className={'new-experience-type'} onMouseEnter={() => {
            setHover({world_tracking_type: 'web_world_tracking'});
        }} onMouseLeave={() => {
            setHover({world_tracking_type: ''});
        }} onClick={() => {
            setTitle('World Tracking');
            setStep(['world_tracking', 'world_tracking_type']);
            setExperienceRoad({...experienceRoad, ...{world_tracking_type: 'web', experience_type: '2s3'}});
        }}>
            <div className={'new-experience-type-header'}>
                <div className={'placeholder'}>
                    {hover.world_tracking_type === 'web_world_tracking' ?
                        <img src="/images/publish-web-hover.svg" alt=""
                             style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                        :
                        <img src="/images/publish-web.svg" alt=""
                             style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                    }
                </div>
                <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                    {t('experienceNewFlow.new')}
                </Button>
            </div>
            <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_type === 'web_world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.web')}</span>
            </div>
            <div className={'new-experience-type-text'}>
                <p>
                    {t('experienceNewFlow.worldTrackingWebDescription')}
                </p>
            </div>
            <div className={'new-experience-type-button'}>
            </div>
        </div>
    );
};

export default EditorComponentWorldWeb;