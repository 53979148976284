import React, {useContext, useEffect, useRef, useState} from 'react';
import {AutoComplete, Button, ControlLabel, Form, FormGroup, Icon, InputGroup, Modal, SelectPicker} from "rsuite";
import {QRDialogContext} from "../../context/QRDialogContext";
import {useTranslate} from "react-polyglot";
import QRCode from "easyqrcodejs";
import {Loader} from "rsuite";
import { Tooltip, Whisper } from 'rsuite';

import {CopyToClipboard} from 'react-copy-to-clipboard';


const QrDialogComponent = () => {
    const t = useTranslate();
    const {openQRDialog, setOpenQRDialog, dialogData} = useContext(QRDialogContext);
    const {url, published, shortUrl} = dialogData;
    const [loading, setLoading] = useState(true);
    const divElement = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect( () => {
        setLoading(true);
    }, [])

    const speaker = (
        <Tooltip style={{ width: 120 }}>
          
          {
                         t('experiences.QRDialog.tooltipMessage')
                        }
        </Tooltip>
    );

    return (
        <div>
            <Modal onShow={() => {
                setLoading(true);
                setTimeout(() => {
                    let options = {
                        text: url,
                        colorDark: published ? "#6DA9F4" : '#747474',
                    };
                    /*                    let father = document.getElementsByClassName('algo')[0];
                                        let element = document.getElementsByClassName('qrContainer')[0];*/
                    new QRCode(divElement.current, options);
                    setLoading(false);
                }, 10)
            }} size={'xs'} show={openQRDialog} onHide={() => {
                setOpenQRDialog(false);
                setLoading(true);
            }}>
                <Modal.Header>
                    <Modal.Title>{t('experiences.QRDialog.QRDialogTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingBottom: '10px'}}>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        {loading && <Loader size="lg" style={{height: '50px'}}/>}
                        <div ref={divElement} className="qrContainer" style={{height: 'auto'}}>
                        </div>
                    </div>
                    <div style={{
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: `${published ? "#6DA9F4" : '#747474'}`
                    }}>
                        {loading ? null : ''}
                    </div>
                    <a  href={url} 
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginTop: '10px',
                            color: `${published ? "#6DA9F4" : '#747474'}`
                    }}  >
                        { !shortUrl ? url : shortUrl }
                    </a>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        fontSize: '16px',
                        // fontWeight: 'bold',
                        // color: `${published ? "#6DA9F4" : '#747474'}`
                    }}>
                       
                        <CopyToClipboard text={ url }
                            onCopy={() => setCopied(true)}>
                            <Whisper
                                preventOverflow
                                trigger="click"
                                speaker={speaker}
                                placement="bottom">
                                <button>
                                    <Icon style={{ color: '#6DA9F4', marginRight: '5px' }} icon='copy' />
                                </button>
                            </Whisper>
                        </CopyToClipboard>
                        <CopyToClipboard 
                            text={ url }
                            style= {{marginRight: '16px'}}
                            onCopy={() => setCopied(true)}>
                            <Whisper
                                preventOverflow
                                trigger="click"
                                speaker={speaker}
                                placement="right">
                                <span style={{cursor: 'pointer'}}> {t('experiences.QRDialog.copyToClipboard')}</span>
                            </Whisper>

                        </CopyToClipboard>

                        
                    </div>
                    
                    {/* <Button onClick={() => {
                        setOpenQRDialog(false);
                    }} appearance="primary">
                        {t('actions.close')}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default QrDialogComponent;