import React, {useState, Fragment, useEffect, useContext} from 'react';
import {Container} from "rsuite";
import {useTranslate} from "react-polyglot";
import {navigate} from "@reach/router";
import ExperienceHeader from "../../components/Experience/ExperienceHeader";
import ExperienceOptions from "../../components/Experience/ExperienceOptions";
import StudioOptions from "../../components/Experience/StudioOptions";
import ExperienceTitle from "../../components/Experience/ExperienceTitle";
import WorldTrackingTypeComponent from "../../components/Experience/WorldTrackingTypeComponent";
import WorldTrackingBasedComponent from "../../components/Experience/WorldTrackingBasedComponent";
import ExperienceForm from "../../components/Experience/ExperienceForm";
import Model3dForm from "../../components/Experience/Model3dForm";
import ImmersiveTypeComponent from "../../components/Experience/ImmersiveTypeComponent";
import ImmersiveBasedComponent from "../../components/Experience/ImmersiveBasedComponent";
import CampaignService from "../../services/Campaign";
import {createExperience} from "../../utils/experienceActions";
import AppContext from "../../components/app/AppContext";
import Loading from "../../components/loading";
import AlertDialog from "../../components/general/AlertDialog";
import { EXPERIENCE_TYPE_ID } from '../../utils/experience';
import ExperienceService from '../../services/Experience';
import StorageService from '../../services/Storage';
import Experience3dModelRequest from '../../Request/Experience3dModelRequest';
import amplitude from 'amplitude-js';
import Behavior from "../../services/Behavior";
import User from "../../services/trackers/User"
import CustomMonoriel from '../../services/CustomMonoriel';
import FrinksLabV3 from '../../services/FrinksLabV3';

const ExperiencePage = () => {
   ;
    //states
    const t = useTranslate();
    const context = useContext(AppContext);
    const [step, setStep] = useState([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(null);
    const [fileValue, setFileValue] = useState('');
    const [inputFileValue, setInputFileValue] = useState("");
    const [filePath, setFilePath] = useState('');
    const [loading, showLoading] = useState(false);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [dialogOptions, setDialogOptions] = useState({
        bodyMsg: '',
        fnHandler: () => {
        }
    });
    const [trackerUser, setTrackerUser] = useState();
    const [tracker, setTracker] = useState();
    // TODO: abstraer esto a una custom hook
    useEffect(() => { 
        if (context.userGroups && context.userData) {
            const user = new User(context)
            setTrackerUser(user)
            if (user.userId) {
                const tracker = new Behavior(user)
                setTracker(tracker);
                tracker.createExperienceIntent();
            }
        }
        
    }, [context])
    /**
     * Type 3 image tracking
     * Type 2 world tracking ---> world tracking type (app || web) ----> based (marker-based || marker-less)
     */
    const [hover, setHover] = useState({experience_type: ''});
    const [title, setTitle] = useState(t('experienceNewFlow.title'));
    const [experienceRoad, setExperienceRoad] = useState({
        experience_type: '',
        world_tracking_type: '',
        world_tracking_based: ''
    });
    const [hideExperienceOptions, setHideExperienceOptions] = useState(true);
    const [isMagioGroup, setIsMagioGroup] = useState(true);

    const [data, setData] = useState({
        name: '',
        campaign: {
            name: ''
        },
        target: [],
        models: [],
        offlineName: '',
        offlineTargetType: '',
        isOffline: false,
        forOtherUser: false,
        user_project_id: '',
        user_id: '',
        isAd:''
    });

    const isEditor = () => {
        return context.userGroups.find(e => e === 'CamonappUsers') !== undefined
    };

    const isMagio = () => {
        return context.userGroups.find(e => e === 'Magios') !== undefined
    };

    const role = () => {
        if (context.userGroups.find(e => e === 'CamonappUsers') !== undefined) {
            return 'Editor'
        }
        if (context.userGroups.find(e => e === 'FreelanceUsers') !== undefined || context.userGroups.find(e => e === 'FreelancePaidUsers') !== undefined) {
            return 'TrialOrPay'
        }
    };

    //effects
    useEffect(() => {
        CampaignService.listCampaings()
            .then((res) => {
                let formatted = res.map(p => {
                    return {"label": p.name, "value": p.name, 'data': p}
                });
                setProjects(formatted);
            });
        
        setIsMagioGroup(isMagio());

    }, []);

    const getExperienceId = () => {
        const {experience_type} = experienceRoad;
        if (experience_type === '3')
            return "3";
        if (experience_type === '2s2' || experience_type === '2s3')
            return '2';
        if (experience_type === '1s2' || experience_type === "ImmersionWeb")
            return '1';
        return experience_type;
    };

    const handleCreateExperience = (data) => {
        showLoading(true);
        let payload = {
            experience_type_id: getExperienceId(),
            withTarget: experienceRoad.experience_type === '3' ? true : experienceRoad.experience_type === '2s3' ? false : experienceRoad.world_tracking_based === 'marker-based',
            platform: getExperienceId() === '3' || getExperienceId() === '4' ? 'app' : experienceRoad.world_tracking_type,
            ...data
        };

        createExperience(payload)
            .then(function (res) {
                showLoading(false);
                navigate('/');
                window.open(`${process.env.REACT_APP_EDITOR_DOMAIN}?id=${res.id}`, "_self");
            })
            .catch(function (err) {
                let message = err.message === 'Experience Already Exists!' ? t('experiences.newExp.valids.ExperienceAllreadyExists') : t('experienceNewFlow.internalError');
                
                showLoading(false);
                setDialogOptions({
                    bodyMsg: message,
                    fnHandler: () => { if (err.message !== 'Experience Already Exists!') window.location.reload();}
                });
                setOpenDialogAlert(true);
            })
    };

    const handleUploadModels = ( data ) => {
        showLoading(true);
        let payload = {
            experience_type_id: EXPERIENCE_TYPE_ID[experienceRoad.experience_type],
            platform: 'web',
            ...data
        }
        // crear el request 
        let params = new Experience3dModelRequest(payload);
        console.log("Pegada al servicio modelsViewer: ", params);
        //ejecutarlo
        
        ExperienceService.createModelViewExperience(params)
           /*  .then(async (res) => {
                await StorageService.uploadsModels(data.models).then(()=>{
                // window.open(`${process.env.REACT_APP_EDITOR_DOMAIN}?id=${res.id}`, "_self");
                }).catch( (err) => {throw new Error("Error al subir el modelo al bucket: "+ err.message)})
                return res;
            }) */
            .then(async(res) =>{
                const formData = new FormData();
                formData.append('experienceId', res.experience.id);
                formData.append('campaignId', res.experience.campaign_id);
                formData.append('companyId', res.experience.company_id);
                formData.append('customerId', res.experience.user_id);
/*                 formData.append('isAd', data.isAd) */
                await CustomMonoriel.createMonorailFolder(res.alias, formData);
                return res;
            })
           .then(async(res) => {
            let upload = await StorageService.uploadOneModel(data.models,res.alias)
            /*  const formData = new FormData();
                const formData2 = new FormData();
                formData2.append("model", data.models[0].file)
                formData.append("model", data.models[1].file)
                /* await FrinksLabV3.ConvertGlb(data.models[0].name, res.alias); 
                await CustomMonoriel.cletusUpdateGlbUsdz(formData,formData2,res.alias) */
            })
            .then(() => {
                showLoading(false);
                // navegar al home
                navigate('/');
            })
            .catch(function (err) {
                
                let message = `${err}`;
                if (message.includes('El nombre ya existe')){
                    message = t('studioNewFlow.nameError')
                }else message = t('experienceNewFlow.internalError');
                showLoading(false);
                setDialogOptions({
                    bodyMsg: message,
                    fnHandler: () => window.location.reload()
                });
                setOpenDialogAlert(true);
            })
    }

    //functions
    const onBack = () => {
        let option = step[step.length - 1];
        if (option === 'interactive_experience') {
            setStep([]);
            setExperienceRoad({...experienceRoad, ...{experience_type: ''}});
            setHideExperienceOptions(true);
        }
        if (option === 'image_tracking') {
            setTitle(t('experienceNewFlow.title'));
            setStep([]);
            setExperienceRoad({...experienceRoad, ...{experience_type: ''}});
            goToMainExperiencePage();
        }
        if (option === 'world_tracking') {
            setTitle(t('experienceNewFlow.title'));
            setStep([]);
            setExperienceRoad({...experienceRoad, ...{experience_type: ''}});
            goToMainExperiencePage();
        }
        if (option === 'world_tracking_type') {
            if (experienceRoad.experience_type === '1s1' || experienceRoad.experience_type === 'ImmersionWeb') {
                setExperienceRoad({...experienceRoad, ...{experience_type: '1'}});
                setTitle(t('experienceNewFlow.immersive'));
            }
            if (experienceRoad.experience_type === '2s1' || experienceRoad.experience_type === '2s3') {
                setExperienceRoad({...experienceRoad, ...{experience_type: '2'}});
                setTitle(t('experienceNewFlow.worldTracking'));
            }
            setStep(['world_tracking']);
        }

        if (option === 'model_3d' ) {
            if (step.length > 1 && step[step.length - 2] === 'world_tracking') {
                setExperienceRoad({...experienceRoad, ...{experience_type: '2'}});
                setTitle(t('experienceNewFlow.worldTracking'));
                setStep(['world_tracking']);
            } else {
                setStep([]);
                setExperienceRoad({...experienceRoad, ...{experience_type: ''}});
                setHideExperienceOptions(true);
            }
            
        }

        if (option === 'interactive_experience_immersionWeb') {
                setStep([]);
                setExperienceRoad({...experienceRoad, ...{experience_type: ''}});
                setHideExperienceOptions(true);
        }

        if (option === 'world_tracking_based') {
            if (experienceRoad.experience_type === '1s2') {
                setExperienceRoad({...experienceRoad, ...{experience_type: '1s1'}});
                setTitle(t('experienceNewFlow.immersive'));
            }
            if (experienceRoad.experience_type === '2s2') {
                setExperienceRoad({...experienceRoad, ...{experience_type: '2s1'}});
                setTitle(t('experienceNewFlow.worldTracking'));
            }
            setStep(['world_tracking', 'world_tracking_type']);
        }
        cleanData()
    };

    const onClose = () => {
        navigate('/')
    };

    const cleanData = () => {
        setData({
            name: '',
            campaign: {
                name: ''
            },
            target: [],
            models:[],
            offlineName: '',
            offlineTargetType: '',
            isOffline: false
        });
        setFileValue('');
    };

    const goToMainExperiencePage = () => {
        setTitle(t('experiences.newExp.chooseTypeExp'));
        setHideExperienceOptions(true);
    };


    const getComponentByExperienceType = () => {
        switch (experienceRoad.experience_type) {
            case '1':
                amplitude.getInstance().logEvent('Click en Experiencia Immersiva');

                return <ImmersiveTypeComponent step={step} setStep={setStep} experienceRoad={experienceRoad}
                                               setExperienceRoad={setExperienceRoad} setTitle={setTitle}/>;
            case '2':
                amplitude.getInstance().logEvent('Click en Experiencia World Tracking');

                return <WorldTrackingTypeComponent step={step} setStep={setStep} experienceRoad={experienceRoad}
                                                   setExperienceRoad={setExperienceRoad} setTitle={setTitle}
                                                   role={role}/>;
            case '1s1':
                return <ImmersiveBasedComponent step={step} setStep={setStep} experienceRoad={experienceRoad}
                                                setExperienceRoad={setExperienceRoad} setTitle={setTitle}/>;
            case'2s1':
                return <WorldTrackingBasedComponent step={step} setStep={setStep} experienceRoad={experienceRoad}
                                                    setExperienceRoad={setExperienceRoad} setTitle={setTitle}
                />;
            case'model_3d':
                return <Model3dForm experienceRoad={experienceRoad} actualStep={experienceRoad.experience_type}
                                       projects={projects} project={project} data={data} setData={setData}
                                       fileValue={fileValue}
                                       filePath={filePath}
                                       setFilePath={setFilePath} handleUploadModels={handleUploadModels}
                                       setOpenDialogAlert={setOpenDialogAlert}
                                       setDialogOptions={setDialogOptions} isEditor={isEditor}/>;
            case'2s2':
            case 'ImmersionWeb':
            case '3':
            case '1s2':
            case'2s3':
            case '4':
                return <ExperienceForm experienceRoad={experienceRoad} actualStep={experienceRoad.experience_type}
                                       projects={projects} project={project} data={data} setData={setData}
                                       fileValue={fileValue}
                                       setFileValue={setFileValue}
                                       inputFileValue={inputFileValue}
                                       filePath={filePath}
                                       setInputFileValue={setInputFileValue}
                                       setFilePath={setFilePath} handleCreateExperience={handleCreateExperience}
                                       setOpenDialogAlert={setOpenDialogAlert}
                                       setDialogOptions={setDialogOptions} isEditor={isEditor}/>;
            default:
                return <ExperienceOptions hideExperienceOptions={hideExperienceOptions}
                                          setHideExperienceOptions={setHideExperienceOptions} setTitle={setTitle}
                                          experienceRoad={experienceRoad} setStep={setStep} step={step}
                                          hover={hover} setExperienceRoad={setExperienceRoad}
                                          setHover={setHover} isEditor={isEditor} />
        }
    };
    
    return (
        <Container>
            <AlertDialog
                title={t('common.alerts.title')}
                bodyMessage={dialogOptions.bodyMsg}
                setOpen={setOpenDialogAlert}
                open={openDialogAlert}
                functionHandler={() => {
                    if (dialogOptions.fnHandler) {
                        dialogOptions.fnHandler()
                    }
                }}
                buttonMessage={t('actions.understood')}
            />
            {loading && <Loading/>}
            <ExperienceHeader step={step} onClose={onClose} onBack={onBack}/>
            <ExperienceTitle title={title}/>
            { isMagioGroup && hideExperienceOptions? 
            <Fragment>
                    
                    <StudioOptions hideExperienceOptions={hideExperienceOptions}
                                    setHideExperienceOptions={setHideExperienceOptions} setTitle={setTitle}
                                    experienceRoad={experienceRoad} setStep={setStep} step={step}
                                    hover={hover} setExperienceRoad={setExperienceRoad}
                                    setHover={setHover} isEditor={isEditor} isFreelanceX={() => role() === 'TrialOrPay'}/>

            </Fragment>
            : getComponentByExperienceType() }

        </Container>
    );
};

export default ExperiencePage;