import React from 'react';
import {navigate} from "@reach/router"
import _ from 'lodash';
import FloatingButton from '../layout/FloatingButton';
import {Layout, Fixed, Flex} from 'react-layout-pane';
import Header from '../layout/Header';
import { useTranslate } from 'react-polyglot';
import './OverlayLayout.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
function OverlayLayout(props) {

    const t = useTranslate();
       
    const goHome = () => {
        navigate('/');
    }

    return (
        <Layout type="column">

            <Flex>
                <Layout type="row">

                    <Fixed className="header">
                        <Header hideSearch='true'/>
                    </Fixed>

                    <Flex className="content-overlay">
                        <div className='layout-title-container'>
                            <span className='filter-page-name'>Overlays</span>
                            <span className='filter-page-name go-back-button' onClick={goHome}>Volver</span>
                        </div>
                        <hr className='line'></hr>
                        <div>                    
                            {props.children}
                        </div>
                        <FloatingButton/>
                    </Flex>
                    
                </Layout>
            </Flex>
        </Layout>
    )
    
}

export default OverlayLayout;