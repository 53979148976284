export const userGroups = [
    'CamonappAdminUsers',
    'CamonappSalesUsers',
    'CamonappUsers',
    'CamonappUsersCommon',
    'CompanyUsers',
    'EducationalUsers',
    'FreelanceUsers',
    'ProfessorsUsers',
    'StudentsUsers'
]
export const userGroupsObject = {
    CamonappAdminUsers: 'CamonappAdminUsers',
    CamonappSalesUsers: 'CamonappSalesUsers',
    CamonappUsers: 'CamonappUsers',
    CamonappUsersCommon: 'CamonappUsersCommon',
    CompanyUsers: 'CompanyUsers',
    EducationalUsers: 'EducationalUsers',
    FreelanceUsers: 'FreelanceUsers',
    FreelancePaidUsers: 'FreelancePaidUsers',
    ProfessorsUsers: 'ProfessorsUsers',
    StudentsUsers: 'StudentsUsers'
}


export function checkUserGroupsfromGroups(group) {
    return userGroups.find((g) => { return g === group });
}

export function checkUserGroups(groups, group) {
    return groups.find((g) => { return g === group });
}

