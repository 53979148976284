import React, { memo, useEffect, useState, useReducer } from 'react'
import {
    DataTable, DataTableHead, DataTableContent, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell
} from '@rmwc/data-table';
import { Button } from '@rmwc/button';
import { useTranslate } from 'react-polyglot';
import Icon from '../../components/icon';
import { Icon as IconM } from '@rmwc/icon';
import CompanyForm from '../../components/actions/CompanyForm';
import Notification from '../../components/notification/notification';
import CompanyService from '../../services/Companies';
import Confirm from '../../components/actions/Confirm';
import Search from '../../components/common/search';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import './user.scss';


const ITEMS_PER_PAGE = 5;

const Companies = memo(function Companies({ setModal }) {
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(1);
    const [response, setResponse] = useState([]);
    const [search, setSearch] = useState('');
    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => ({ ...prevState, ...currState }),
        { showNotification: false, propsNotification: {} });
    const t = useTranslate();

    useEffect(() => {
        CompanyService.get(true)
            .then(res => { setCompanies(res.data); setResponse(res) });

    }, []);

    async function saveCompany(params) {
        try {

            await CompanyService.addCompany(params);
            getDataPaginated(page);
            setNotification({
                showNotification: true,
                propsNotification: {label: t('common.msg.success'), message: t('pages.account.addMsg')}
            })
        } catch (error) {
            setNotification({ showNotification: true, propsNotification: { label: 'Error', message: error.message } })
        }
    }
    async function updateCompany(params) {

        try {
            await CompanyService.updateCompany(params, this.componentData.id);
            getDataPaginated(page);
        } catch (error) {
            setNotification({ showNotification: true, propsNotification: { label: 'Error', message: error.message } })
        }
    }
    async function deleteCompany(params) {

        try {
            await CompanyService.deleteCompany(this.componentData.companyId);
            getDataPaginated(page);
        } catch (error) {
            setNotification({ showNotification: true, propsNotification: { label: 'Error', message: error.message } })
        }
    }
    function openAddCompany(event) {
        const actionsDialogData = {
            title: t('pages.account.companies.modal.add.title'),
            component: CompanyForm,
            componentData: { companyType: { id: 1 } },
            componentKey: '99',
            action: saveCompany,
            buttonLess: true
        };
        setModal({ openModal: true, propsModal: actionsDialogData })
    }
    function editCompany(company) {
        const actionsDialogData = {
            title: t('pages.account.companies.modal.edit.title'),
            componentData: this,
            component: CompanyForm,
            componentKey: this.id,
            action: updateCompany,
            buttonLess: true
        };
        setModal({ openModal: true, propsModal: actionsDialogData })
    }
    function confirmDelete() {
        const actionsDialogData = {
            title: t('pages.account.companies.modal.delete.title'),
            componentData: { msg: t('pages.account.companies.modal.delete.msg'), companyId: this.id },
            component: Confirm,
            componentKey: this.id,
            action: deleteCompany,
        };
        setModal({ openModal: true, propsModal: actionsDialogData })
    }

    function getMark(value) {
        if (value) {
            return (<IconM icon="done" className="ico-done" />);
        } else {
            return (<IconM icon="close" className="ico-close" />);
        }
    }
    async function getDataPaginated(pageToGet) {
        return CompanyService.get(true, pageToGet, ITEMS_PER_PAGE, (search ? { name: search } : undefined))
            .then(res => { setCompanies(res.data); setResponse(res) });
    }
    function getLastPage() {
        if (page <= 1) {

        } else {
            setPage(page - 1)
            getDataPaginated(page - 1);
        }

    }
    function getNextPage() {

        if ((response.total / ITEMS_PER_PAGE) > page) {
            setPage(page + 1)
            getDataPaginated(page + 1);
        }

    }
    function closeNotification() {
        setNotification({ showNotification: false })
    }

    function searchCompany(value) {
        setSearch(value);
        CompanyService.get(true, 1, ITEMS_PER_PAGE, { name: value })
            .then(res => { setCompanies(res.data); setResponse(res) })
    }

    return (
        <section className="profile-user-container">
            <div className="ẗable-profile-actions-header">
                <Search func={searchCompany} />
                <Button raised label={t('pages.account.companies.modal.add.title')} className="rounded-button" onClick={openAddCompany} />
            </div>
            <DataTable className={'paddingTables'}>
                <DataTableContent>
                    <DataTableHead className="list-table-head">
                        <DataTableRow>
                            <DataTableHeadCell alignMiddle>{t('pages.account.companies.headTable.name')}</DataTableHeadCell>

                            <DataTableHeadCell alignMiddle>{t('pages.account.companies.headTable.type')}</DataTableHeadCell>
                            <DataTableHeadCell alignMiddle>{t('pages.account.companies.headTable.canPublish')}</DataTableHeadCell>
                            <DataTableHeadCell alignMiddle>{t('pages.account.companies.headTable.reports')}</DataTableHeadCell>
                            <DataTableHeadCell alignMiddle>{t('pages.account.companies.headTable.actions')}</DataTableHeadCell>

                        </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                        {companies.length > 0 && companies.map((company, i) => {
                            return (
                                <DataTableRow key={i}>
                                    <DataTableCell alignMiddle>{company.name}</DataTableCell>
                                    <DataTableCell alignMiddle>{company.companyType.name}</DataTableCell>
                                    <DataTableCell alignMiddle>{getMark(company.canPublish)}</DataTableCell>

                                    <DataTableCell alignMiddle>{getMark(company.canSeeReports)}</DataTableCell>
                                    <DataTableCell className="users-actions">
                                        <Icon name={'edit'} alignMiddle onClickFn={editCompany.bind(company)} />
                                        <Icon name={'delete-bin'} alignMiddle onClickFn={confirmDelete.bind(company)} />
                                    </DataTableCell>
                                </DataTableRow>
                            )
                        })

                        }


                    </DataTableBody>
                </DataTableContent>
            </DataTable>
            <div className="table-pagination">
                <div className="table-pagination-element first">
                {page > 1 && (
                <span className="table-pagination-link" onClick={getLastPage}>
                    {t('pages.account.pagination.prev')}
                </span>
                )}
                </div>
                {response.total / ITEMS_PER_PAGE > page && (
                <div className="table-pagination-element second">
                    <span className="table-pagination-link" onClick={getNextPage}>
                    {t('pages.account.pagination.next')}
                    </span>
                </div>
                )}
            </div>
            {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
        </section>
    )
})

Companies.propTypes = {

}

export default Companies
