import React, { useState, useEffect } from 'react';
import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';
function ProjectName(params) {
    const [value, setValue] = useState(params.data);

    useEffect(() => {
        if (value === params.data) {
            setValue(params.data);

        }

    })
    return (
        <TextField outlined label="project Name" className={'fullwidth'} value={value} onChange={(e) => { setValue(e.target.value); params.onChange(e.target.value) }} onBlur={(e) => { params.onChange(e.target.value) }} />
    )
}

export default ProjectName;