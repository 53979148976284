import styled from 'styled-components';
import Icon from '../../components/icon';

export const ListContainer = styled.div`
    margin: 15px 0 15px 0;
    display: flex;
    flex-direction: column !important;
    background: ${props => props.name === 'rejected' ? "url('/backgound.png')" : 'none'};

    .list-container-header{
        margin: 15px 0 15px 0;
        display: flex;
        flex-direction: row;

        width: 100%;
        .header-actions{
            
            display: flex;
            flex-direction: row;
            margin-left: auto;
            .actions{
                display: flex;
                flex-direction: row;
                color: #292929;
                align-items: center;

            .value{
                font-family: Public Sans;
                font-style: normal;
                font-weight: normal;
                margin-right: 18px;
                font-size: 12px;
                color: #292929;
            }
            .quantity{
                font-family: 'Public Sans';
                font-style: normal;
                font-weight: normal;
                margin-right: 18px;
                font-size: 12px;
                color: #292929;
                width: 100px;
                text-align: center;
                border-left: solid;
                border-right: solid;
                border-left-width: thin;
                border-right-width: thin;
                border-color: rgba(41, 41, 41, 0.12 );
            }
            }
        }
    }
`

export const Title = styled.span`
    font-family: Public Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    letter-spacing: -0.0125em;

    /* Neutrals / Black */

    color: #292929;
`
export const IconWithStyle = styled(Icon)`

  font-size: 20px;
  line-height: 24px;
`