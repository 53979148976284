import React, { memo, useContext } from 'react'
import './loading.scss';
import ShowLoadingContext from '../../context/ShowLoadingContext';

const Loading = memo(function Loading(props) {

    const { 
        showLoading,
        setShowLoading,
        overlayStyle, 
        setOverlayStyle,
    } = useContext(ShowLoadingContext)

    return (
        <div className={`loading-overlay loading-overlay-full`}>
            <div className="loading-container">
                <div className="loading-content"></div>
            </div>
        </div>
    )
})

Loading.propTypes = {

}

export default Loading
