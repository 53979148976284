import { useEffect, useRef, useState } from 'react';

export default options => {
    const [show, setShow] = useState(false);
    const element = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(observedEntries => {

            const { isIntersecting } = observedEntries[0]
            if (isIntersecting) {
                setShow(true);
                observer.disconnect()
            }
        });

        observer.observe(element.current)
    }, [element])
    return [show, element];
};
