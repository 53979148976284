import React, {Fragment, useContext, useState} from 'react';
import {FormControl,Grid,TextField,MenuItem,FormGroup, Switch,Typography} from '@mui/material';
import './WebComponents.css';
import Previsualiser  from './Previsualiser';
import WidthForm  from './WidthForm';
import WebComponentModal  from './WebComponentModal';
import SausageButton from '../../components/coa/SausageButton';
import { useTranslate } from 'react-polyglot';
import { OverlaysContext } from '../../context/OverlaysContext';


function CowcBackButtonTemplate() {
    const t = useTranslate();
    const {variableSelected, setVariableSelected} = useContext(OverlaysContext);
    const [openModal, setOpenModal] = useState(false);
    const [srcBackground, setSrcBackground] = useState("https://image.shutterstock.com/image-vector/arrow-back-vector-icon-direction-260nw-1638337234.jpg");
    const [srcBackgroundAlt, setSrcBackgroundAlt] = useState("https://image.shutterstock.com/image-vector/arrow-back-vector-icon-direction-260nw-1638337234.jpg");
    const [heigthTypeSelected, setHeigthTypeSelected] = useState("px");
    const [width, setWidth] = useState(80);
    const [widthTypeSelected, setWidthTypeSelected] = useState("px");
    const [position, setPosition] = useState("absolute"); //fixed en real
    const [shape,setShape] = useState('circle')
    const [top, setTop] = useState(40);
    const [left, setLeft] = useState(40);
    const [right, setRight] = useState(0);
    const [bottom, setBottom] = useState(0);

    const optionsVariables = [
        {label: t("overlays.properties.size"), value : t("overlays.properties.size")},
        {label: t("overlays.properties.position"), value : t("overlays.properties.position")},
        {label: t("overlays.properties.personalizationButton"), value : t("overlays.properties.personalizationButton")},
/*      {label: t("overlays.properties.shape"), value : t("overlays.properties.shape")}, COMENTO HASTA QUE SEPAMOS SI SE USA Y COMO */
    ]

    const optionsShape = ["circle","square","triangle","hexagon"]

    const getScript = ()=>{
        const script = `
        <cowc-back-button 
        top="${top}px"
        left="${left}px"
        right="${right}px"
        bottom="${bottom}px"
        visible="true"
        height="${width}${heigthTypeSelected}"
        shape="${shape}"
        width="${width}${widthTypeSelected}"    
        src-background="${srcBackground}"
        src-background-alt="${srcBackgroundAlt}"
        ></cowc-back-button>
        `;
        return script;
    }

    const renderVariableSelected = () => {
        
        switch(variableSelected){
            case t("overlays.properties.size"): return (
                <WidthForm label={t("overlays.properties.size") + " (px)"} width={width} type={widthTypeSelected} onChange={ 
                    e => {
                        if(isNaN(e.target.value)){
                            setWidthTypeSelected(e.target.value);
                            const scaleWidth = e.target.value == "%" ? width : width;
                            setWidth(scaleWidth);
                        }else{
                            const scaleWidth = widthTypeSelected == "%" ? e.target.value : e.target.value;
                            setWidth(scaleWidth) //lo divido por 3 para que quede a escala
                        }
                    }
                }></WidthForm>
            );
            case t("overlays.properties.personalizationButton"): return(
                <>
                <Grid className='item-container' container spacing={2}>
                    <TextField
                        className='item-overlay'
                        size='small' 
                        id="primary-background-url" 
                        label={t("overlays.properties.primaryBackgroundUrl")} 
                        value={srcBackground} 
                        variant="outlined" 
                        onChange={ e=>{setSrcBackground(e.target.value)}}/>
                            
                </Grid>
                <Grid className='item-container' container spacing={2}>
                    <TextField  
                        className='item-overlay'
                        size='small'  
                        id="secondary-background-url" 
                        label={t("overlays.properties.secondaryBackgroundUrl")} 
                        value={srcBackgroundAlt} 
                        variant="outlined" 
                        onChange={ e=>{setSrcBackgroundAlt(e.target.value)}}/>
                </Grid>
                </>
            ) ;

            case t("overlays.properties.position"): return(
                <>
                    <Grid className='item-container' container spacing={2}>
                        <TextField 
                            className='item-overlay'
                            size='small'  
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                            id="top" 
                            label={t("overlays.properties.top")+" (px)"} 
                            value={top} 
                            variant="outlined" 
                            onChange={ e=>{setTop(e.target.value)}}/>
                    </Grid>
                    <Grid className='item-container' container spacing={2}>
                        <TextField  
                            className='item-overlay'
                            size='small'  
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                            id="left" 
                            label={t("overlays.properties.left")+" (px)"} 
                            value={left} 
                            variant="outlined" 
                            onChange={ e=>{setLeft(e.target.value)}}/>
                    </Grid>
                    <Grid className='item-container' container spacing={2}>
                         <TextField 
                            className='item-overlay'
                            size='small'   
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}     
                            id="right" 
                            label={t("overlays.properties.right")+" (px)"} 
                            value={right} 
                            variant="outlined" 
                            onChange={ e=>{setRight(e.target.value)}}/>
                    </Grid>
                    <Grid className='item-container' container spacing={2}>
                        <TextField 
                            className='item-overlay'
                            size='small'    
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}   
                            id="bottom" 
                            label={t("overlays.properties.bottom")+" (px)"} 
                            value={bottom} 
                            variant="outlined" 
                            onChange={ e=>{setBottom(e.target.value)}}/>
                    </Grid>
                </>
            ) ;
            case t("overlays.properties.shape"): return(
                <>
                    <Grid className='item-container' container spacing={4}>
                        <TextField
                            className='item-overlay'
                            size='small' 
                            select
                            id="shape" 
                            label={t("overlays.properties.shape")} 
                            value={shape} 
                            variant="outlined" 
                            onChange={ e=>{setShape(e.target.value)}}
                        >
                            {optionsShape.map((s) => {
                                return <MenuItem value={s}>{s}</MenuItem>
                            })}
                        </TextField>
                    </Grid>
                </>
            ) ;
        }
    }
    const handleClickExport = () => {
        setOpenModal(!openModal);
    }
    const handleClickCloseModal = () => {
        setOpenModal(false)
    }
    
    return (
        <Fragment>
            <Grid className='item-container' container spacing={2}>
            <TextField
                size='small' 
                variant='outlined'
                id="variables-select"
                select
                label="Variables"
                className='item-overlay'
                onChange={e => {setVariableSelected(e.target.value)}}
                >
                {optionsVariables.map(component => {
                    return <MenuItem value={component.value}>{component.label}</MenuItem>
                })}
            </TextField>
            </Grid>   
            {renderVariableSelected()}
            <div className='previsualizer-container'>
                <Previsualiser>
                    <cowc-back-button 
                        visible={true}
                        height={`${width/3}${heigthTypeSelected}`}
                        width={`${width/3}${widthTypeSelected}`}
                        position ={position}
                        top={top?`${top/3}px`:""}
                        left={left?`${left/3}px`:""}
                        right={right?`${right/3}px`:""}
                        bottom={bottom?`${bottom/3}px`:""}
                        src-background={srcBackground}
                        src-background-alt={srcBackgroundAlt}
                        shape={shape}
                    ></cowc-back-button>
                </Previsualiser>
                <FormGroup className="text-center"> 
                    <SausageButton
                        className="btn-export"
                        label={t("overlays.export")}
                        onClick={handleClickExport}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
                </FormGroup>
            </div>
            <WebComponentModal title="Cowc-footer-botton" open={openModal} onCloseModal={handleClickCloseModal}>{getScript()}</WebComponentModal>
        
        </Fragment>
    );
}

export default CowcBackButtonTemplate;
