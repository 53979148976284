export default class User {

    constructor(params) {
        this.userId= params.userData.id;
        this.email = params.userData.email;
        this.location = '';
        this.os = '';
        this.browser = '';
        this.userGroup = params.userGroups;
        return this;
    }
}