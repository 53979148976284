import React from 'react';
import {formatNumber} from "../../pages/Reports/ReportUtils";

const OverviewTile = ({value, title, color, seconds}) => {

    return (
        <div className='overviewTile'>
            <h1 className='overviewTileValue'>{formatNumber(value)} {seconds ? seconds : ''}</h1>
            <h3 className='overviewTileTitle'>{title}</h3>
            <div className={`${color}-background`}>
            </div>
        </div>
    );
};

export default OverviewTile;