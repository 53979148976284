import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@rmwc/button';
import './InputFileDragAndDrop.scss'
import { useTranslate } from 'react-polyglot';
import { CustomLoaderContext } from '../../context/CustomLoaderContext';
function InputFileDragAndDrop (props) {
    const [fileValue, setFileValue] = useState(false);
    const [inputFileValue, setInputFileValue] = useState('');
    const [filePath, setFilePath] = useState('');
    const {handleFile, defaultFile, handleURL, setLogoFile, setBackgroundFile, setOpen, setLogoURL } = props;
    const {setBackgroundLocal, setResetLogo,defaultLogo, setDefaultLogo, setResetBackground,setIsGridActive,isGrid} = useContext(CustomLoaderContext);
    const t = useTranslate();

    useEffect(() => {
        if(filePath !== '') {
            let formatImage = inputFileValue.split(".");
            let formatImageParse = formatImage[formatImage.length-1];
            
            if(setBackgroundFile) {
                setBackgroundLocal(filePath);
                setBackgroundFile(handleFile);
                setResetBackground(false);
                setIsGridActive(false)
            }
            else if(setLogoFile) {
                setLogoURL(filePath);
                setLogoFile(handleFile);
                setResetLogo(false);
                setDefaultLogo(false)
            }

            if(formatImageParse !== 'svg') {
                setOpen({
                    title: t('customLoaderLayout.error'),
                    subtitle: t('customLoaderLayout.formatImage'),
                    isOpen: true
                });
                quitFile();
            }
        }
    },[filePath])

    function getFilePath(file) {
        if (file.files['0']) {
            const url = URL.createObjectURL(file.files[0]);
            setFilePath(url);
            handleFile(file.files['0']);
            handleURL(url);
        } else {
            setFileValue(false);
        }
    }

    function quitFile() {
        if(setBackgroundFile){
            setBackgroundFile();
            setBackgroundLocal('');
            setResetBackground(true);
            setIsGridActive(true)
        }
        else if(setLogoFile){
            setLogoFile('');
            setLogoURL('');
            setResetLogo(true);
            setDefaultLogo(true)
        }
        setFilePath('');
        setFileValue(false);
    }

    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('inputFile').click();
    }
    return (
        <div className='preview-logo'>
            <div className={'new-logo-preview' + (fileValue ? ' file-selected' : '')}
                 onClick={() => {
                    if (fileValue) {
                        document.getElementById('inputFile').click();
                    }
                }}
            >
                <input id="inputFile"
                       type="file"
                       name="image"
                       style={{ display: 'none' }}
                       value={inputFileValue}
                       onChange={(e) => {
                             setInputFileValue(e.target.value);
                             setFileValue(true);
                             getFilePath(e.target);
                         }
                       }
                />
                {defaultFile ?
                    <img src={filePath ? filePath : defaultFile} alt={'fileValue'} className={'new-logo-preview-image'} />
                    :
                    <div className={'image-input'}>
                        <div className={'label-input-image'}>
                            <span>{ setBackgroundFile ? t('dragAndDropFile.textBackground') : t('dragAndDropFile.textHeader')}</span>
                        </div>
                        <div>
                            <Button outlined label={t('dragAndDropFile.add')} className={'new-logo-button'} onClick={triggerFile} />
                        </div>
                        <span className={'label-input-image'}>{t('dragAndDropFile.format')}</span>
                    </div>
                }
            </div>
            <Button className='quit-image' outlined onClick={() => quitFile()}>{t('dragAndDropFile.quit')}</Button>
        </div>
    );
}

export default InputFileDragAndDrop;