import React, { memo, useState, useEffect } from 'react';
import { useTranslate } from 'react-polyglot';
import { TextField } from '@rmwc/textfield';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';



const DeleteAccount = memo(function DeleteAccount(props) {
    const t = useTranslate();
    const [value, setValue] = useState('');

    useEffect(() => {
        if (value === props.data) {
            setValue(props.data);
        }
    })
    return (
        <div>
            <p>
                {t('pages.profile.deleteAccount.dialog')}
                <em>{props.data?.email}</em>
            </p>
            <TextField outlined label={t('pages.profile.deleteAccount.inputLabel')} name="mail" className={'fullwidth'} value={value} onChange={(e) => { setValue(e.target.value); }} onBlur={(e) => { props.onChange({ name: value }) }} />

        </div>
    )
})


export default DeleteAccount
