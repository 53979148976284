export const EXPERIENCE_TYPE = {

    // world: 'filters.expType.world', // Mundo
    // plane: 'filters.expType.plane', // Plana
    'all': 'filters.expType.all', // Todos
    'immersive': 'common.labels.360',
    'world': 'common.labels.worldTracking',
    'image': 'common.labels.imageTracking',
    'face': 'common.labels.face',

};

export const EXPERIENCE_STATUS = {
    all: 'filters.status.all', // 'Todos'
    PUBLISHED: 'filters.status.published', // Publicados
    NOT_PUBLISHED: 'filters.status.notPublished', // Sin publicar
    //ON_REVIEW: 'filters.status.onReview', // Revision
    REJECTED: 'filters.status.rejected' //Rechazados
}

export const EXPERIENCE_SORT = {
    recent: 'filters.sort.recent', // Recientes
    created: 'filters.sort.created', // Fecha de creacion 
    name: 'filters.sort.name' // Nombre
}

export const EXPERIENCE_TYPE_ID = {
    '3': '3',
    '2s2': '2',
    '2s3': '2',
    '1s2': '1',
    'ImmersionWeb': '1',
    'face':'4',
    'model_3d': '5',
}