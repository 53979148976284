import jwt from '../utils/jwt';

const url = process.env.REACT_APP_CUSTOM_LOADER_LAMBDA;

class CustomLoaderService {

    static async getCustomLoaderConfig(selectedProject) {
        try {
            const token = await jwt();
            const headers = Object.assign({
                'Authorization': `Bearer ${token}`
            });
            return fetch(url + '?projectId=' + selectedProject, {
                method: 'GET',
                headers
            })
        } 
        catch (error) {
            return Promise.reject(error);
        }
    }

    static async applyInitialConfiguration(selectedProject) {
        try {
            const token = await jwt();
            const headers = Object.assign({
                'Authorization': `Bearer ${token}`
            });
            return fetch(url + '/base?projectId=' + selectedProject, {
                method: 'GET',
                headers
            })
        } 
        catch (error) {
            return Promise.reject(error);
        }
    }

    static async updateCustomLoaderConfigJson(selectedProject, formData) {
        try {
            const body = new FormData();
            const token = await jwt();
            const headers = Object.assign({
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin':'*'
            });

            for (let key in formData) {
                body.append(key, formData[key]);
            }

            const res = await fetch(url + '?projectId=' + selectedProject, {
                method: 'POST',
                headers: headers,
                body: body
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async updateCustomLoaderConfig(selectedProject, formData) {
        try {
            const token = await jwt();
            const headers = Object.assign({
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin':'*'
            });

            const res = await fetch(url + '?projectId=' + selectedProject, {
                method: 'POST',
                headers: headers,
                body: formData
            });

            return Promise.resolve(res);

        } catch (error) {
            return Promise.reject(error);
        }
    }

}

export default CustomLoaderService;
