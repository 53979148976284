import React from 'react';
import {Header, Icon, Nav, Navbar} from "rsuite";

const ExperienceHeader = ({onClose, step, onBack}) => {
    return (
        <Header>
            <Navbar appearance="inverse" className="headerExpColor">
                <Navbar.Body>
                    {step.length > 0 ? <Nav onSelect={onBack}>
                        <Nav.Item icon={<Icon icon="back-arrow"/>}> </Nav.Item>
                    </Nav> : null}

                    <Nav className="hoverColor" onSelect={onClose} pullRight>
                        <Nav.Item icon={<Icon icon="close"/>}> </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </Header>
    );
};

export default ExperienceHeader;