import React, { memo, useState, useEffect } from 'react';
import { Button } from '@rmwc/button';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import './changeTarget.scss';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

import ErrorMessage from '../ErrorMessage/ErrorMessage'

const ChangeTarget = memo(function ChangeTarget(props) {
    const [value, setValue] = useState('');
    const [img, setImg] = useState(props.data);
    const [filePath, setFilePath] = useState('');

    const [errorState, setErrorState] = useState(false)
    const [message, setMessage] = useState('');
    const t = useTranslate();

    useEffect(() => {
        if (value === props.data) {
            setValue(props.data);
        }
    })
    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('fileInput').click();
    }
    function getFilePath(file) {
        let validFileTypes = ['JPEG','jpeg', 'jpg', 'JPG'];
        let fileExtension = file.files[0] ? file.files[0].name.split('.').pop() : '';
        if (file.files[0] && validFileTypes.includes(fileExtension)) {
            const url = URL.createObjectURL(file.files[0]);
            setErrorState(false);
            setMessage('');
            setFilePath(url);
        } else {
            if (!validFileTypes.includes(fileExtension)){
                setErrorState(true);
                setMessage(t('experiences.changeTarget.validFormat'))
            }
            setValue(false);
        }
    }
    return (
        <div>
            <form id="changeTargetForm" >
                <div className="img-selector" >
                    <input type="file" name="target" id="fileInput" defaultValue={value} onChange={(e) => { setValue(e.target.value); getFilePath(e.target); props.onChange(e.target.files[0]) }} className="img-selector-input" />
                    {!value &&
                        <div>
                            <p className="img-selector-title">
                                {t('experiences.addOrDragTarget')}
                            </p>
                            <Button label={t('experiences.addTarget')} outlined onClick={triggerFile} className="btn-rounded" />
                        </div>

                    }
                    {value &&
                        <img width='430px' height='310px' src={filePath} alt={'fileValue'} className={'new-experience-preview-image imageMargin'} />
                    }
                </div>
                {errorState ? <ErrorMessage message={message}/> : null }

            </form>
            <Button label={t('actions.add')} onClick={ (e) => {
                e.preventDefault();
                if (value){
                    setErrorState(false)
                    props.submitHandler();
                }else{
                    setErrorState(true)
                    setMessage(t('experiences.changeTarget.validEmptyTarget'))
                }
            }}>
            </Button>
            <Button label={t('actions.cancel')} onClick={ (e) => {
                e.preventDefault();
                props.setOpen(false);
            }}>
            </Button>
        </div>
    )
})

ChangeTarget.propTypes = {

}

export default ChangeTarget
