import React, {Fragment, useContext, useEffect, useState} from 'react';
import AppContext from "../../components/app/AppContext";
import {useTranslate} from "react-polyglot";
import campaignService from "../../services/Campaign";
import CampaignService from "../../services/Campaign";
import {navigate} from "@reach/router";
import {UserContext} from "../../context/UserContext";
import UserService from "../../services/User";
import CampaignListItem from "./campaignListItem";
import FilterBy from "../../components/Filters/FilterBy";
import Loading from "../../components/loading";
import AlertDialog from "../../components/general/AlertDialog";
import MigrateUserProjectDialog from "../../components/actionsDialog/MigrateUserProjectDialog";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import {QRDialogContext} from "../../context/QRDialogContext";
import {ChangeTargetDialogContext} from "../../context/ChangeTargetDialogContext";
import {ChangeNameDialogContext} from "../../context/ChangeNameDialogContext";
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';
import ShowLoadingContext from '../../context/ShowLoadingContext';

const HomeUserAndCampaigns = () => {
    const context = useContext(AppContext);
    const t = useTranslate();
    const [campaigns, setCampaigns] = useState([]);
    const {userData, setUserData, navLinks, setNavLinks} = useContext(UserContext);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [openMigrateDialog, setOpenMigrateDialog] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const { showLoading, setShowLoading, overlayStyle } = useContext(ShowLoadingContext)

    

    const getCampaignId = () => {
        let paths = window.location.pathname.split('/');
        let campaignId = paths.length > 1 ? paths[4] : '';
        let hasCampaign = paths.length > 1 && paths[3] === 'project';
        if (hasCampaign) {
            return campaignId
        }
        return ''
    };
    const getUserIdFromUrl = () => {
        let paths = window.location.pathname.split('/');
        let userId = paths.length > 1 ? paths[2] : '';
        let hasUser = paths.length > 1 && paths[1] === 'user';
        if (hasUser) {
            return userId
        }
        return ''
    };

    const [data, setData] = useState({
        id: getUserIdFromUrl(),
        project: getCampaignId(),
    });
    const [campaign, setCampaign] = useState({});
    const openUserMigrationDialog = () => {
        setOpenMigrateDialog(true);
    };
    const openOnBoarding = () => {
        navigate('/onboarding')
    };


    useEffect(() => {
        context.setPage({
            name: t('pages.campaigns.project'),
            refetch: fetchData
        });
        if (data.id) {
            const getData = async () => {
                let userResponse = await UserService.getUser(parseInt(data.id));
                let user = userResponse;
                setUserData({id: user.id, username: user.email});
                if (data.id && data.project) {
                    campaignService.get(data.project, data.id).then((res) => {
                        setCampaign(res);
                        context.setPage({
                            projectOpened: true,
                            name: `${t('pages.campaign.projectBreadcrumb')} ${res.name}`,
                        });
                        setNavLinks([{id: data.id, name: user.email, tab: 'user'}, {
                            id: res.id,
                            name: res.name,
                            tab: 'project'
                        }]);
                        
                    });
                } else {
                    fetchData();
                }
            };
            getData();
        }
    }, []);

    async function fetchData() {
        try {
            setShowLoading(true)
            let campaignsResponse = [];
            campaignsResponse = await campaignService.listWithExperiences(data.id);
            setCampaigns(campaignsResponse.data);
            setShowLoading(false)
        } catch (error) {
            openOnBoarding();
        }
    }

    const onMigrate = (selectedUser) => {
        context.setPage({
            loading: true
        });
        CampaignService.migrateCampaign(selectedCampaign, selectedUser)
            .then((res) => {
                fetchData();
                context.setPage({
                    loading: false
                });
                context.page.refetch();
            }).catch((e) => {
                context.setPage({
                    loading: false
                });
                setOpenMigrateDialog(false);
                setOpenAlertDialog(true);
                context.page.refetch();
        });
    };

    function experiences() {
        return campaign.experiences ? campaign.experiences : [];
    }
    const {MigrationUserExperienceDialog} = useContext(MigrateUserDialogContext);
    const {QRDialog} = useContext(QRDialogContext);
    const {ChangeTargetDialog} = useContext(ChangeTargetDialogContext);
    const {
        ChangeNameDialogComponent
    } = useContext(ChangeNameDialogContext);
    const [loading, setLoading] = useState(false);
    return (
        <Fragment>

            {showLoading && 
                <Loading />
            }
            
            <AlertDialog open={openAlertDialog} title={'Error'}
                         bodyMessage={'Se ha producido un error al migrar proyecto'} setOpen={setOpenAlertDialog}/>
            <MigrateUserProjectDialog open={openMigrateDialog} setOpenMigrateDialog={setOpenMigrateDialog}
                                      onMigrate={onMigrate}/>
            {QRDialog()}
            {MigrationUserExperienceDialog()}
            {ChangeTargetDialog()}
            {ChangeNameDialogComponent()}
            {        data.project ? <div className="campaign-container">
                    {
                        <FilterBy data={experiences()} typeData="experiences">
                            {result => (
                                result.map((exp, index) => {
                                    return (
                                        <CardHomeStudio key={index} experienceCardStyle={'campaign-exp-card'} experience={exp} accessFromEditor={true}/>
                                    )

                                })
                            )}
                        </FilterBy>
                    }
                </div>
                : <Fragment>
                    <FilterBy data={campaigns} typeData="campaigns">
                        {campaignsFiltered => (
                            <div className="campaing-cards-container">
                                <div className="list">
                                    {campaignsFiltered && campaignsFiltered.map(camp =>
                                        <CampaignListItem onMigrate={onMigrate}
                                                          openUserMigrationDialog={openUserMigrationDialog} key={camp.id}
                                                          campaign={camp} setSelectedCampaign={setSelectedCampaign}
                                                          reload={() => {
                                                              fetchData();
                                                          }}/>
                                    )}
                                </div>
                            </div>
                        )}
                    </FilterBy>
                </Fragment>}
        </Fragment>
    )

};

export default HomeUserAndCampaigns;