import React, { memo, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Auth from 'aws-amplify';
import UserService from '../../services/User';
import AppContext from '../../components/app/AppContext';
const Confirm = memo(function Confirm(props) {
    const context = useContext(AppContext);
    useEffect(() => {

        const url = new URLSearchParams(window.location.search);
        const code = url.get('code');
        const client = url.get('client');
        const username = decodeURIComponent(url.get('username'));
    }, [])

    return (
        <div>

        </div>
    )
})

Confirm.propTypes = {

}

export default Confirm
