import React, { useState } from 'react'

const AppContext = React.createContext({})

function AppProviderContext({ children, value: { userGroups, userData } }) {
    const [page, setPage] = useState({ newExperience: false });
    const [filter, setFilter] = useState({});
    const [openSubPro, setOpenSubPro] = useState(false);
    return (
        <AppContext.Provider
            value={{
                page,
                setPage,
                filter,
                setFilter,
                openSubPro,
                setOpenSubPro,
                userGroups,
                userData
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export const AppProvider = AppProviderContext;
export const AppConsumer = AppContext.Consumer

export default AppContext;
