import AWSStorage from '@aws-amplify/storage';

class StorageService{
    static async uploadsModels(models){
        const uploadModelPromises = models.map((model) => AWSStorage.put(model.name, model.file))
        return Promise.all(uploadModelPromises)
    }
    static async uploadOneModel(models,alias) {
        const bucketName = process.env.REACT_APP_MONORAIL_MVAR.substring(8,process.env.REACT_APP_MONORAIL_MVAR.length-1)
        const uploadModels = models.map((model)  => {

            const fileExtension =  model.name.split('.').pop();
            return AWSStorage.put(`experiences/${alias}/model.${fileExtension}`, model.file, {
                bucket:bucketName,
                level: "public",
                customPrefix: {public: ""}
            })
        })
        return Promise.all(uploadModels)
    }
    static async uploadLoadImage(file,alias) {
        const bucketName = process.env.REACT_APP_URL_PAGE.substring(8,process.env.REACT_APP_URL_PAGE.length)
        return await AWSStorage.put(`${alias}/loading-image.jpg`, file, {
            bucket:bucketName,
            level: "public",
            customPrefix: {public: ""}
        })
        
    }
    static async getLoadImage(alias) {
        const bucketName = process.env.REACT_APP_URL_PAGE.substring(8,process.env.REACT_APP_URL_PAGE.length)
        return await AWSStorage.get(`${alias}/loading-image.jpg`, {
            bucket:bucketName,
            level: "public",
            customPrefix: {public: ""}
        })
        
    }
    static async getExtras(alias) {
        const bucketName = process.env.REACT_APP_URL_PAGE.substring(8,process.env.REACT_APP_URL_PAGE.length)
        const data =  await AWSStorage.get(`${alias}/extras.json`, {
            bucket:bucketName,
            level: "public",
            download: true,
            customPrefix: {public: ""}
        })
        const jsonData = JSON.parse(new TextDecoder().decode(data.Body))
        return jsonData
    }
}

export default StorageService;