import React from 'react';
import {ReactSVG} from "react-svg";

const ImageWithBackgroundComponent = ({iconSrc, aditionalClass, hasMargin}) => {
    return (
        <ReactSVG src={iconSrc}
                  className={`${hasMargin ? 'marginLeftImage' : 'marginImage'} ${aditionalClass}`}/>
    );
};

export default ImageWithBackgroundComponent;