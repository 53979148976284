import React, {useState,useContext,useReducer} from 'react'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ExperienceService from '../../services/Experience';
import AppContext from '../app/AppContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'rmwc';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import moment from 'moment';
import './styles.scss'
import Sure from "../../pages/Profile/Sure";
import {useTranslate} from 'react-polyglot';
import Modal from '../modal/modal';
import Notification from '../notification/notification';
export default function DateModal({status,setModalStatus,exp}) {
  const [date, setDate] = useState (moment().add(1,'day').format());
  const [modal, setModal] = useState(false)
  const [cmpDataObj, setCmpDataObj] = useState({});
  const { setShowLoading } = useContext(ShowLoadingContext)
  const [{ showNotification, propsNotification }, setNotification] = useReducer(
    (prevState, currState) => ({ ...prevState, ...currState }),
    { showNotification: false, propsNotification: {} });
  const context = useContext(AppContext);
  const t = useTranslate();
  function handleCancel (){
    setModalStatus(false)
  }
  async function handleAccept () {
    setShowLoading(true)
    await ExperienceService.expirate(exp.id,date)
    setModalStatus(false)
    setShowLoading(false)
    if (context.page.refetch) {
      context.page.refetch()
    }
  }
  function dateError (){
    setNotification({
      showNotification: true,
      propsNotification: { label: 'ERROR', message:"ERROR FECHA INCORRECTA" }
  })
  }
  function handleClick(){
    let actionsDialogData = {
        title: t('experiences.actions.endDate') + date.format("DD/MM/YYYY"),
        componentData: exp.name,
        component: Sure,
        componentKey: exp.id,
        action: date > moment() ? handleAccept : dateError
    };
    setModal(true);
    setCmpDataObj({...actionsDialogData})
  }
  function closeNotification() {
    setNotification({ showNotification: false })
  }
  return (
    <Dialog
      className='picker-dialog'
      open={status}
      onClose={(evt) => {
        setModalStatus(false);
      }}
    >
      <div className='picker-dialog'>
          <LocalizationProvider  dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            value={date}
            onChange={(newValue) => {
              console.log(newValue)
              setDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>   
        <div className='date-picker-error'>{date && date < moment() ? t('dateModal.msjError') : ""}</div>
      </div>
      <DialogActions>
        <Button action='close' onClick={() => handleCancel()}>{t('actions.cancel')}</Button>
        <Button action='acept' disabled={date < moment()} onClick={() => handleClick()}>{t('actions.accept')}</Button>
      </DialogActions>
      {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
      {modal &&
                <Modal {...cmpDataObj} onClose={() => {
                    setModal(false)
                }}/>}
    </Dialog>
  );
}