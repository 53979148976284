import React, {useState,useReducer,useContext} from "react";
import "./styles.scss"
import { DataTableRow, DataTableCell, ListItemPrimaryText,ListItemSecondaryText } from "rmwc";
import ExperienceService from "../../../services/Experience";
import {useTranslate} from 'react-polyglot';
import Appcontext from '../../app/AppContext';
import Sure from "../../../pages/Profile/Sure";
import Notification from '../../../components/notification/notification'
import Modal from '../../../components/modal/modal'
import ShowLoadingContext from '../../../context/ShowLoadingContext';
import {QRDialogContext} from "../../../context/QRDialogContext";
import moment from "moment";

import DateModal from "../../DateModal";
export default function TableContent ({exp}) {
    const context = useContext(Appcontext);
    const { setShowLoading} = useContext(ShowLoadingContext) 
    const [cmpDataObj, setCmpDataObj] = useState({});
    const [modalStatus,setModalStatus] = useState(false)
    const [experience, setExp] = useState(false);
    const actualDay = moment().format("DD/MM/YYYY")
    const dateExp = moment(exp.expiration_date).format("DD/MM/YYYY")
    const link = process.env.REACT_APP_URL_PAGE
    const [{ showNotification, propsNotification }, setNotification] = useReducer(
        (prevState, currState) => ({ ...prevState, ...currState }),
        { showNotification: false, propsNotification: {} });
    const t = useTranslate();
    async function activateExp() {
        try {
            setShowLoading(true)
            await ExperienceService.expirate(exp.id,null)
            await ExperienceService.activate(exp.id);
            setShowLoading(false)
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.activate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }
            
        } catch (error) {
            setShowLoading(false)
            console.log(error);
            //FALTA MOSTRAR MENSAJE DE ERROR
        } finally {
            setExp(false);
        }
    }
    
    async function desactivateExp() {
        try {
            setShowLoading(true)
            await ExperienceService.deactivate(exp.id);
            await ExperienceService.expirate(exp.id,moment())
            setShowLoading(false)
            setNotification({
                showNotification: true,
                propsNotification: { label: t('common.msg.success'), message: t('experiences.msgActions.deactivate') }
            })
            if (context.page.refetch) {
                context.page.refetch()
            }
        } catch (error) {
            console.log(error);

        } finally {
            setExp(false);
        }
    }

    function handleClick(){
        let actionsDialogData = {
            title: t('experiences.actions.changeURL'),
            componentData: exp.name,
            component: Sure,
            componentKey: exp.id,
            action: exp.active && dateExp > moment().format() ? desactivateExp : activateExp,
        };
        setExp(true);
        setCmpDataObj({...actionsDialogData})
    }

    const {setOpenQRDialog, setDialogData} = useContext(QRDialogContext);
    const handleQRDialog = () => {
        setDialogData({
            published: true,
            url: exp.active === true && dateExp > actualDay ? `${link}/${exp.link?.public_alias}`: `${link}/${exp.link?.public_alias}/?status=off`,
            shortUrl: exp.active === true && dateExp > actualDay ? `${link}/${exp.link?.public_alias}`: `${link}/${exp.link?.public_alias}/?status=off`
        });
        setOpenQRDialog(true)
    };

    function closeNotification() {
        setNotification({ showNotification: false })
    }
    function setDate (){
        setModalStatus(true)
    }
    return (
        <>
           <DataTableRow className="table-row">
                <div className="table-row-left">
                <DataTableCell className="table-experience">
                    <ListItemPrimaryText className="table-experience-name">
                       {exp.name}
                    </ListItemPrimaryText>
                    <ListItemSecondaryText className="table-experience-company">
                        {exp.campaign?.name}
                    </ListItemSecondaryText>
                </DataTableCell>
                <DataTableCell className="table-row-content">
                    <button
                        onClick={handleQRDialog}
                    >
                    <img
                        alt={""} src={`/icons/share.png`}
                        className="share"
                    /> 
                    </button>
                    <a 
                    className="url"
                    id="urlExp"
                    target="_blank"
                    href={ exp.active && dateExp > actualDay ? `${link}/${exp.link?.public_alias}`: `${link}/${exp.link?.public_alias}/?status=off`}> 

                    { exp.active && dateExp > actualDay ? `${link}/${exp.link?.public_alias}`: `${link}/${exp.link?.public_alias}/?status=off`}
                    
                     </a>
                </DataTableCell >
                <DataTableCell className="table-row-content">
                    {exp.user}
                </DataTableCell>
                </div>
                <div className="table-row-rigth">
                <DataTableCell className="table-row-content-rigth">
                    {exp.expiration_date !== null ? moment(exp.expiration_date).format('DD/MM/YYYY') : "--/--/---"}
                </DataTableCell>
                <DataTableCell className="table-row-content-rigth">
                    <a
                    onClick={() => setDate()}
                    className={exp.expiration_date && dateExp > actualDay ? "table-row-content-clock-end" : "table-row-content-clock" }>
                     <img
                            alt={""} src={`/icons/time.png`}
                            className="clock-image"
                    />
                    </a>
                </DataTableCell>
                <DataTableCell className="table-row-content-rigth">
                <div className={ dateExp > actualDay && exp.active === true ? "table-switch" : "table-switch-inactive"}>
                       <button onClick={() => handleClick() } className={exp.active === true && dateExp > actualDay ? "table-switch-button" : "table-switch-button-inactive"}>
                           <div className={ exp.active === true && dateExp > actualDay ? "table-switch-button-line" : "table-switch-button-line-inactive"}></div>
                       </button>
                </div>
                </DataTableCell>
                </div>
           </DataTableRow>
           {showNotification && <Notification {...propsNotification} onClose={closeNotification} />}
           {experience &&
                <Modal {...cmpDataObj} onClose={() => {
                    setExp(false)
                }}/>}
                <DateModal setModalStatus={setModalStatus} status={modalStatus} exp={exp}></DateModal>
        </>
    )
}