import React from "react";
import ReactDOM from "react-dom";
import './styles.css'
import { Table, Toggle, TagPicker } from "rsuite";

const { Column, HeaderCell, Cell } = Table;


const CompactCell = (props) => <Cell {...props} style={{ padding: 4 }} />;
const CompactHeaderCell = (props) => (
  <HeaderCell {...props} style={{ padding: 4 }} />
);




export default function CompaniesTable({data,loading,setSelected,setBreadcrumb,breadcrumb,title}) {
  const defaultColumns = [
    {
      key: "name",
      label: title,
      flexGrow: 3
    },
    {
      key: "scans",
      label: "Vistas",
      flexGrow: 1
    }
  ];
  const [compact, setCompact] = React.useState(false);
  const [columnKeys, setColumnKeys] = React.useState(defaultColumns.map((column) => column.key))
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const columns = defaultColumns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const CustomCell = compact ? CompactCell : Cell;
  const CustomHeaderCell = compact ? CompactHeaderCell : HeaderCell;
  function handleClick (data) {
    setSelected({
      company: data.company_id && !data.campaign_id ? data.company_id : 0,
      campaign: data.campaign_id && !data.experience_id ? data.campaign_id : 0,
      experience: data.experience_id ? data.experience_id : 0,
    })
    setBreadcrumb([
      ...breadcrumb,
      {
        value:data.name,
        key: breadcrumb.length
      }
    ])
  }
  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };
  const handleSortColumn = (sortColumn, sortType) => {
      setSortColumn(sortColumn);
      setSortType(sortType);
  };

  return (
    <div>
      <div style={{ height: 400 }}>
        <Table
          rowClassName={'cursor-pointer'}
          onRowClick={(data) => {handleClick(data)}}
          loading={loading}
          height={300}
          hover={true}
          showHeader={true}
          data={data ? getData() : []}
          bordered={true}
          cellBordered={true}
          headerHeight={45}
          rowHeight={45}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
        >
          {columns.map((column) => {
            const { key, label, ...rest } = column;
            return (
              <Column sortable {...rest} key={key}>
                <CustomHeaderCell>{label}</CustomHeaderCell>
                <CustomCell dataKey={key} />
              </Column>
            );
          })}
        </Table>
      </div>
    </div>
  );
};
