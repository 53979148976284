import React, { useState, useEffect } from 'react';
import { TextField } from '@rmwc/textfield';
import { useTranslate } from 'react-polyglot';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@rmwc/icon/icon.css';

const Search = ({ func }) => {
  const [search, setSearch] = useState('');
  const [trigger, setTrigger] = useState(false);
  const t = useTranslate();

  useEffect(() => {
    if (trigger && (search.length > 2 || search.length === 0)) {
      const timer = setTimeout(() => {
        func(search);
      }, 500);
  
      return () => clearTimeout(timer); 
    }
  }, [search]);

  return (
    <TextField
      icon="search"
      outlined
      label={t('header.placeholder')}
      onChange={(e) => { setSearch(e.target.value); setTrigger(true); }}
    />
  );
};

export default Search;
