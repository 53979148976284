import React, { memo, useEffect, useState, useContext, Fragment } from 'react';

import { ReactSVG } from 'react-svg';
import { Auth } from 'aws-amplify';
import { navigate } from '@reach/router';
import _ from 'lodash';
import { useTranslate } from 'react-polyglot';

import Icon from '../icon';
import { SectionContainer, ImgContainer, Text, Paragraph, InputWrapper, Input, Rounded } from './CustomForgotStyle'

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import AppContext from '../app/AppContext';
import RegisterHeader from "../headers/RegisterHeader";
import { ControlLabel } from "rsuite";
import { Form, FormGroup, Input as RInput, Button as RButton } from 'rsuite';
import { Button } from "@rmwc/button";
import { CircularProgress } from "@rmwc/circular-progress";

const CustomForgot = memo(function CustomForgot(props) {
    const [email, setEmail] = useState('');
    const [paragraph, setParagraph] = useState('');
    const context = useContext(AppContext);
    const t = useTranslate();
    const [error, setError] = useState('');
    const [errorMail, setErrorClassMail] = useState({ input: '', label: '' });

    useEffect(() => {
        if (!_.isEmpty(props.authData)) {
            setParagraph(props.authData.msg);
        } else {
            setParagraph(t('pages.customForgot.paragraph'));
        }
    }, [props.authData])

    async function forgotRequest() {
        try {
            context.setPage({ ...context.page, userChangePass: email });
            const res = await Auth.forgotPassword(email.toLowerCase());
            props.onStateChange('signIn', { confirmation: true });
            navigate('/resetSend')

        } catch (error) {
            if (error.code === 'UserNotFoundException') {
                setError(t('pages.signIn.errors.userNotFound'));
                setErrorClassMail({
                    input: 'inputCoAError',
                    label: 'inputCoALabelError',
                    message: t('pages.signIn.errors.userNotFound')
                });
            }
            if (error.code === 'NotAuthorizedException') {
                setError(t('pages.signIn.errors.notAuthorized'));
                setErrorClassMail({
                    input: 'inputCoAError',
                    label: 'inputCoALabelError',
                    message: t('pages.signIn.errors.notAuthorized')
                });
            }
            if (error.code === "UserNotConfirmedException") {
                setError(t('pages.signIn.errors.notConfirmed'));
                setErrorClassMail({
                    input: 'inputCoAError',
                    label: 'inputCoALabelError',
                    message: t('pages.signIn.errors.notConfirmed')
                });
            }
        }
    }

    function goToLogin() {
        props.onStateChange('signIn', {});
    }

    return (
        <div>
            {props.authState === "forgotPassword" &&
                <Fragment>
                    <RegisterHeader />
                    <div className="back-container">
                        <Icon name={'close'} className="cursor" onClickFn={() => {
                            goToLogin();
                        }} />
                    </div>
                    <SectionContainer>
                        <ImgContainer>
                            <div className="old">
                                <ReactSVG src="/images/old.svg" />
                            </div>
                            <div className="new">
                                <ReactSVG src="/images/new.svg" />
                            </div>
                        </ImgContainer>
                        <Text>{_.isEmpty(props.authData) ? t('pages.customForgot.recoveryPass') : t('pages.customForgot.resetPass')}</Text>
                        <Paragraph>{paragraph}</Paragraph>

                        {/* <InputWrapper>
                            <Input value={email} outlined label={t('common.labels.email')} onChange={(e) => { setEmail(e.target.value) }} />
                            <Rounded label={t('actions.send')} outlined onClick={forgotRequest} />
                        </InputWrapper>*/}
                        <div style={{ display: 'flex' }}>
                            <Form>
                                <FormGroup className={'rsformdisplay'}>
                                    <div>
                                        <ControlLabel style={{ marginBottom: '8px' }}
                                            className={`labelInput ${errorMail.label}`}>{t('common.labels.email')}</ControlLabel>
                                        <RInput style={{ width: '392px' }} className={`inputCoA ${errorMail.input}`}
                                            errorMessage={'sss'}
                                            onChange={(e) => {
                                                setEmail(e)
                                            }}
                                            errorPlacement={'rightEnd'}
                                            value={email}
                                            name={'email'} type={'email'}
                                            placeholder={t('common.labels.email')} />
                                        <Rounded label={t('actions.send')} outlined
                                            onClick={forgotRequest} />
                                    </div>
                                </FormGroup>
                                <div style={{ height: '96px' }}>
                                    {error &&
                                        <div className="form-control" style={{ width: '35%' }}>
                                            <span className={'error'}>{error}</span>
                                        </div>
                                    }
                                </div>
                            </Form>
                        </div>
                    </SectionContainer>
                </Fragment>

            }
        </div>
    )
});

CustomForgot.propTypes = {};

export default CustomForgot
