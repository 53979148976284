import React, {Fragment} from 'react';
import { TextField, FormControl,FormGroup,InputLabel, Select,MenuItem, Slider, Grid} from '@mui/material'; 
import './Previsualiser.css'
import { useTranslate } from 'react-polyglot';


function HeightForm(props) {
    const { onChange } = props;
    const t = useTranslate();
    const optionsHeigthType= [
        {label: "px", value : "px"},
        {label: "%", value : "%"},
        //Dejo comentadas esas medidas, por si las quieren en un futuro
        // {label: "rem", value : "rem"},
        // {label: "em", value : "em"},
        // {label: "vh", value : "vh"},
    ]

    return (
        <Fragment> 
                        {/* <Grid item xs={3}>
                                <FormControl fullWidth>
                                        <TextField
                                            select
                                            id="heigth-type-select"
                                            label="Unidad"
                                            onChange={onChange}
                                            >
                                            {optionsHeigthType.map(component => {
                                                return <MenuItem value={component.value}>{component.label}</MenuItem>
                                            })}
                                        </TextField>
                                </FormControl>   
                        </Grid> */}
                        <Grid className='item-container' item xs={3}>
                                  <TextField 
                                    className='item-overlay'
                                    size='small' 
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                                    id="heigth"  
                                    value={props.height}
                                    label={t("overlays.properties.height") + " (px)"} 
                                    variant="outlined" 
                                    onChange={onChange}/>
                                  {/* <Slider max={100} defaultValue={80} aria-label="Default" valueLabelDisplay="auto" onChange={onChange}/> */}
                        
                        </Grid>
        </Fragment>
    );
}

export default HeightForm;
