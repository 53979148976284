import React from 'react';

import { Menu, MenuSurfaceAnchor, } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';

import Icon from '../icon';
import './elipsisMenu.scss';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/ripple/dist/mdc.ripple.css';

import '@material/list/dist/mdc.list.css';
function ElipsisMenu(props) {
    const [open, setOpen] = React.useState(false);
    if (props.rippled) {
        return (
            <Ripple unbounded  >
                <MenuSurfaceAnchor className={'cursor menu-surface'} >
                    <Menu
                        open={open}
                        onClose={evt => setOpen(false)}
                        className={'drop-menu-container'}
                        anchorCorner={'topEnd'}
                        renderToPortal={true}
                        onClick={evt => setOpen(false)}
                    >
                        {props.children}
                    </Menu>
                    <Icon name={'ellipsis'} onClickFn={() => setOpen(!open)} />
                </MenuSurfaceAnchor>

            </Ripple>
        )
    } else {
        return (
            <MenuSurfaceAnchor className={'cursor menu-surface'} >
                <Menu
                    open={open}
                    onClose={evt => setOpen(false)}
                    className={'drop-menu-container'}
                    anchorCorner={'topEnd'}
                    renderToPortal={true}
                >
                    {props.children}
                </Menu>
                <Icon name={'ellipsis'} onClickFn={() => setOpen(!open)} />
            </MenuSurfaceAnchor>
        )
    }

}

export default ElipsisMenu;