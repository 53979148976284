import Campaign from '../models/campaign';
import Request from './Request';

class Experience3dModelRequest extends Request {
    
    user_project_id = {
        name:'',
    }
    constructor(params) {
        super();
        this.campaign = new Campaign();
        this.campaign.setName(params.campaign.name);
        this.campaign.setId(params.campaign.id ? params.campaign.id : '');
        this.campaign.setExpName(params.expname);
        this.experienceTypeId = params.experience_type_id;
        // TODO: aca estan los files tengo que mapear los nombre
        this.models = params.models.map( file => file.name );
    }
        
}

export default Experience3dModelRequest