import React, { memo, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  DataTable,
  DataTableHead,
  DataTableContent,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
} from '@rmwc/data-table';
import { Button } from '@rmwc/button';
import { Icon as IconM } from '@rmwc/icon';
import { useTranslate } from 'react-polyglot';
import Icon from '../../components/icon';
import CompanyForm from '../../components/actions/CompanyForm';
import Confirm from '../../components/actions/Confirm';
import Notification from '../../components/notification/notification';
import Search from '../../components/common/search';
import CompanyService from '../../services/Companies';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/data-table/dist/mdc.data-table.css';
import '@rmwc/data-table/data-table.css';

function getMark(value) {
  if (value) {
    return <IconM icon="done" className="ico-done" />;
  } else {
    return <IconM icon="close" className="ico-close" />;
  }
}

const ITEMS_PER_PAGE = 5;

const Educational = memo(function Educational({ setModal }) {
  const [educationals, setEducationals] = useState([]);
  const [page, setPage] = useState(1);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState('');
  const [
    { showNotification, propsNotification },
    setNotification,
  ] = useReducer((prevState, currState) => ({ ...prevState, ...currState }), {
    showNotification: false,
    propsNotification: {},
  });
  const t = useTranslate();

  useEffect(() => {
    CompanyService.get(true, 1, 5, { company_type_id: 3 }).then((res) => {
      setEducationals(res.data);
      setResponse(res);
    });
  }, []);

  async function saveEducational(params) {
    try {
      await CompanyService.addCompany(params);
      getDataPaginated(page);
      setNotification({
        showNotification: true,
        propsNotification: {label: t('common.msg.success'), message: t('pages.account.addMsg')}
      })
    } catch (error) {
      console.log(error);
    }
  }
  async function updateEducational(params) {
    try {
      await CompanyService.updateCompany(params, this.componentData.id);
      getDataPaginated(page);
    } catch (error) {}
  }
  async function deleteEducational(params) {
    try {
      await CompanyService.deleteCompany(this.componentData.companyId);
      getDataPaginated(page);
    } catch (error) {
      setNotification({
        showNotification: true,
        propsNotification: { label: 'Error', message: error.message },
      });
    }
  }
  function openAddEducational(event) {
    const actionsDialogData = {
      title: t('pages.account.educational.modal.add.title'),
      componentData: { companyType: { id: '3' } },
      component: CompanyForm,
      componentKey: '99',
      action: saveEducational,
      buttonLess: true
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  function openEditEducational() {
    const actionsDialogData = {
      title: t('pages.account.educational.modal.edit.title'),
      componentData: this,
      component: CompanyForm,
      componentKey: this.id,
      action: updateEducational,
      buttonLess: true
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  function confirmDelete() {
    const actionsDialogData = {
      title: t('pages.account.educational.modal.delete.title'),
      componentData: {
        msg: t('pages.account.educational.modal.delete.msg'),
        companyId: this.id,
      },
      component: Confirm,
      componentKey: this.id,
      action: deleteEducational,
    };
    setModal({ openModal: true, propsModal: actionsDialogData });
  }
  async function getDataPaginated(pageToGet) {
    return CompanyService.get(true, pageToGet, 5,  { company_type_id: 3, name: search }).then(
      (res) => {
        setEducationals(res.data);
        setResponse(res);
      }
    );
  }
  function getLastPage() {
    if (page <= 1) {
    } else {
      setPage(page - 1);
      getDataPaginated(page - 1);
    }
  }
  function getNextPage() {
    if (response.total / 5 > page) {
      setPage(page + 1);
      getDataPaginated(page + 1);
    }
  }
  function closeNotification() {
    setNotification({ showNotification: false });
  }
  function searchEducational(value) {
    setSearch(value);
    CompanyService.get(true, 1, ITEMS_PER_PAGE, { company_type_id: 3, name: value })
        .then(res => { setEducationals(res.data); setResponse(res) })
  }
  return (
    <section className="profile-user-container">
      <div className="ẗable-profile-actions-header">
        <Search func={searchEducational} />
        <Button
          raised
          label={t('pages.account.educational.addEntity')}
          className="rounded-button"
          onClick={openAddEducational}
        />
      </div>
      <DataTable className={'paddingTables'}>
        <DataTableContent>
          <DataTableHead className="list-table-head">
            <DataTableRow>
              <DataTableHeadCell alignMiddle>{t('pages.account.educational.headTable.name')}</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('pages.account.educational.headTable.type')}</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('pages.account.educational.headTable.canPublish')}</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('pages.account.educational.headTable.reports')}</DataTableHeadCell>
              <DataTableHeadCell alignMiddle>{t('pages.account.educational.headTable.actions')}</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {educationals.length > 0 &&
              educationals.map((educational, i) => {
                return (
                  <DataTableRow key={i}>
                    <DataTableCell alignMiddle>
                      {educational.name}
                    </DataTableCell>
                    <DataTableCell alignMiddle>
                      {educational.companyType.name}
                    </DataTableCell>
                    <DataTableCell alignMiddle>
                      {getMark(educational.canPublish)}
                    </DataTableCell>

                    <DataTableCell alignMiddle>
                      {getMark(educational.canSeeReports)}
                    </DataTableCell>
                    <DataTableCell className="users-actions">
                      <Icon
                        name={'edit'}
                        alignMiddle
                        onClickFn={openEditEducational.bind(educational)}
                      />
                      <Icon
                        name={'delete-bin'}
                        alignMiddle
                        onClickFn={confirmDelete.bind(educational)}
                      />
                    </DataTableCell>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
      <div className="table-pagination">
        <div className="table-pagination-element first">
        {page > 1 && (
          <span className="table-pagination-link" onClick={getLastPage}>
            {t('pages.account.pagination.prev')}
          </span>
        )}
        </div>
        {response.total / ITEMS_PER_PAGE > page && (
          <div className="table-pagination-element second">
            <span className="table-pagination-link" onClick={getNextPage}>
              {t('pages.account.pagination.next')}
            </span>
          </div>
        )}
      </div>
      {showNotification && (
        <Notification {...propsNotification} onClose={closeNotification} />
      )}
    </section>
  );
});

Educational.propTypes = {
  setModal: PropTypes.func.isRequired,
};

export default Educational;
