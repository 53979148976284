import React, {createContext, useContext, useEffect, useState} from 'react';
import MigrationUserExperienceDialogComponent from "../components/actionsDialog/MigrationUserExperienceDialogComponent";
import userService from "../services/User";
import ExperienceService from "../services/Experience";
import AppContext from "../components/app/AppContext";

export const MigrateUserDialogContext = createContext();

const MigrateUserDialogProvider = (props) => {
    const context = useContext(AppContext);
    const [query, setQuery] = useState('');
    const [openMigrateExperienceDialog, setOpenMigrateExperienceDialog] = useState(false);
    const [users, setUsers] = useState([]);

    const [dataForSearch, setDataForSearch] = useState({});
    const [selectedUser, setSelectedUser] = useState('');
    const [enabled, setEnabled] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const [userId, setUserId] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [projects, setProjects] = useState([]);
    const [projectsSearch, setProjectsSearch] = useState([]);
    const [experienceId, setExperienceId] = useState([]);

    const MigrationUserExperienceDialog = () => (
        <MigrationUserExperienceDialogComponent/>
    );

    const onMigrateUserExperience = async () => {
        context.setPage({
            loading: true
        });
        try {
            await ExperienceService.migrate(campaignId,companyId, experienceId);
            context.setPage({
                loading: false
            });
            window.location.reload()
        } catch (error) {
            console.log(error);
            context.setPage({
                loading: false
            });
        }
    };

    const findUser = () => {
        let user = dataForSearch.find(e => e.string === selectedUser);
        if (user) {
            return {
                id: user.value.id,
                company_id: user.value.company.id
            }
        }
    };
    const onSearch = (e) => {
        setQuery(e);
        if (e.length >= 4) {
            userService.getUsers(false, 1, 20, {name: query, surname: query, email: query})
                .then(res => {
                    let formattedData = res.data.map((user) => {
                        return `${user.surname ? user.surname : ''} ${user.name ? user.name : ''}  (${user.email})`;
                    });
                    let dataForSearch = res.data.map((user) => {
                        return {
                            string: `${user.surname ? user.surname : ''} ${user.name ? user.name : ''}  (${user.email})`,
                            value: user
                        }
                    });
                    setDataForSearch(dataForSearch);
                    setUsers(formattedData);
                })
        }
        if (e.length === 0) {
            setUserId('');
            setProjects([]);
            setProjectsSearch([]);
            setCampaignId('');
        }
    };


    return (<MigrateUserDialogContext.Provider
        value={{
            openMigrateExperienceDialog,
            setOpenMigrateExperienceDialog,
            users,
            setSelectedUser,
            enabled,
            setEnabled,
            campaignId,
            setCampaignId,
            userId,
            setUserId,
            selectedUser,
            setProjects,
            projects,
            setProjectsSearch,
            projectsSearch,
            setExperienceId,
            companyId,
            setCompanyId,
            setUsers,
            MigrationUserExperienceDialog,
            onSearch,
            findUser,
            onMigrateUserExperience,

        }}>{props.children}</MigrateUserDialogContext.Provider>)
};

export default MigrateUserDialogProvider;