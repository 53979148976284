
import React from 'react';
import { InputPicker } from 'rsuite';

const SearchProject = ({ dataSearch, onSelect, onClean, disabled}) => {

  return (
    <InputPicker
        disabled={disabled}
        className={'grayHover notActiveColor'}
        onClean={onClean}
        onSelect={onSelect}
        style={{
            width: '100%',
            borderLeft: 'none',
            borderRadius: '0px',
            height: '40px'
        }}
        creatable
        data={dataSearch}
    />
  )
};

export default SearchProject;
