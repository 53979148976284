import React from 'react';
import {Col, Row} from "react-flexbox-grid";
import {Pie} from "react-chartjs-2";
import {useTranslate} from 'react-polyglot';

import TableComponent from "./TableComponent";
import {getRGBDoughnutCountries, getRGBDoughnut, formatNumber} from "../../pages/Reports/ReportUtils";

const ReportTableDemographicChart = ({
                                         data, setInitialData, initialData, setLoading, company,
                                         setCompany,
                                         campaign,
                                         setCampaign, navLinks, setNavLinks, columns,
                                         setColumns, withQuery
                                     }) => {
    const t = useTranslate();
    const option = {
        legend: {
            display: false
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    let total = meta.total;
                    let currentValue = dataset.data[tooltipItem.index];
                    let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return `${formatNumber(currentValue)} (${percentage}%)`;
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };

    const dataCountries = {
        responsive: true,
        maintainAspectRatio: true,
        labels: data.country.map(e => e.country),
        datasets: [{
            label: '% of countries',
            data: data.country.map(e => e.scans),
            backgroundColor: data.country.map((e, i) => getRGBDoughnutCountries(e, i)),
            borderColor: data.country.map((e, i) => getRGBDoughnutCountries(e, i)),
            borderWidth: 1
        }]
    };

    const dataDevices = {
        responsive: true,
        maintainAspectRatio: true,
        labels: data.os.map(e => e.os),
        datasets: [{
            label: '% of devices',
            data: data.os.map(e => e.scans),
            backgroundColor: data.os.map((e, i) => getRGBDoughnut(i, data.os.length)),
            borderColor: data.os.map((e, i) => getRGBDoughnut(i, data.os.length)),
            borderWidth: 1
        }]
    };

    return (
        <Row>
            <Col lg={7}>
                <div className='container-tiles'>
                    <TableComponent setLoading={setLoading} initialData={initialData} setInitialData={setInitialData}
                                    data={data} company={company}
                                    setCompany={setCompany}
                                    campaign={campaign}
                                    setCampaign={setCampaign} navLinks={navLinks} setNavLinks={setNavLinks}
                                    columns={columns}
                                    setColumns={setColumns} withQuery={withQuery}/>
                </div>
            </Col>
            <Col lg={5}>
                <div className='container-tilesBot'>
                    <Row>
                        <Col lg={12}>
                            <p className={'demog-text'}>{t('pages.reports.demographicData')}</p>
                        </Col>
                        <Col lg={6}>
                            <Pie data={dataCountries} options={option}/>
                            <p className={'text-center'}>{t('common.labels.country')}</p>
                        </Col>
                        <Col lg={6}>
                            <Pie data={dataDevices} options={option}/>
                            <p className={'text-center'}>{t('pages.reports.so')}</p>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default ReportTableDemographicChart;