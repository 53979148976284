import request from '../utils/request';

class ReportsServiceV2 {
    /**
     *
     * @param filters
     * @returns {Promise<*|undefined>}
     */
    static async getData(from,to,campaign,company,experience) {
         return request.get(`${process.env.REACT_APP_APP_REPORTS_NEWS}/reportsnews/?dateFrom=${from}&dateTo=${to}&campaignId=${campaign}&companyId=${company}&experienceId=${experience}`) 
    }
   
}

export default ReportsServiceV2;