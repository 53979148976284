import React, {Fragment, useState} from 'react';
import { useTranslate } from 'react-polyglot';

import {Button,Tooltip,Checkbox,FormControlLabel,Modal,Typography,Box,InputLabel, Select,MenuItem} from '@mui/material';
import { Icon } from 'rsuite';
import {getLibaryScript} from './WebComponentsUtils'
import './WebComponentModal.css'


function WebComponentModal(props) {

    const t = useTranslate()
    
    //si es true, incluye el <script> con la libreria de Stencil.js SpringfieldElementary
    const [includeLibrary, setIncludeLibrary] = useState(false);
    
    //Tooltip que aparece cuando toco copiar
    const [tooltipCopyText, setTooltipCopyText] = useState("");

    const {onCloseModal} = props;


   
    const handleClickCopy = () => {
        const library = getLibaryScript();
        let script = props.children
        if(includeLibrary){
            script = `${library} 
            ${script}
                `
        }
        navigator.clipboard.writeText(script);
        setTooltipCopyText(t("overlays.copied"))
        const timeOutId = setTimeout(() => {    
            setTooltipCopyText("");
            clearTimeout(timeOutId);
        }, 500);
    }

          
  
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

        
    

    return (
        <Fragment> 
            <Modal
                open={props.open}
                onClose=""
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                       {props.title}
                        <Icon icon="close" className='modal-btn-close' onClick={onCloseModal}/>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <pre style={{overflow:"auto"}} className="prettyprint lang-javascript"> 
                            <code style={{whiteSpace:"pre-wrap"}}>
                                {includeLibrary && getLibaryScript()}
                                {props.children}
                            </code>
                        </pre>
                    </Typography>
                    <div className="text-center btn-modal-container">
                        <FormControlLabel control={<Checkbox  />} label={t("overlays.includeLibrary")}  onChange={e => {setIncludeLibrary(e.target.checked)}}/>
                        <Tooltip title={tooltipCopyText}  placement="top-start">
                            
                            <Button className="btn-copy" onClick={handleClickCopy}  variant="ou">{t("overlays.copy")} </Button>
                        </Tooltip>
                    </div>
                </Box>

            </Modal>

        </Fragment>
    );
}

export default WebComponentModal;
