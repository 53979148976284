import React, { useEffect, useState } from 'react';
import { SimpleMenu, MenuItem } from '@rmwc/menu';
import { Button } from '@rmwc/button';
import Icon from '../icon';


import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import '@material/button/dist/mdc.button.css';

import './filterMenu.scss'
function FilterMenu({ options, onChange, selected }) {
    return (
        <SimpleMenu
            anchorCorner={'bottomStart'}
            handle={<Button className={'drop-filter-button'}>{selected} <Icon name="caret-down" className="filter-drop-caret" /></Button>}
            onSelect={e => onChange(options[e.target.index])}
            className={'drop-menu-container'}
        >
            {options.map((option, index) => {
                return (
                    <MenuItem key={index} className={`drop-menu-item ${selected === option.name ? 'selected' : ''}`}>
                        <div className={'menu-item-container'}>
                            {option.name === selected &&
                                <Icon name={'checkbox-circle'} className={'selected-check'} />
                            }
                        </div>
                        <div className={'menu-text-container'}>
                            {option.name}
                        </div>
                    </MenuItem>
                )
            })}


        </SimpleMenu>
    )
}

export default FilterMenu;