import React, {useEffect, useState} from "react";

import { TextField, OutlinedInput } from '@mui/material';
import './ColorPicker.css';

function ColorPicker(props) {

    //Replace #000 with a real value.
    const [color, setColor] = useState('#ffffff');
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const { handleColor, defaultColor, disabled } = props;
    
    useEffect(()=>{
        setColor(defaultColor);
    }, [defaultColor])

    const handleClick = () => {
      setDisplayColorPicker(!displayColorPicker);
    };
    
    // Call this when we click out of the component
    const handleClose = () => {
        setDisplayColorPicker(false);
    };
    
    const handleChange = (color) => {
        handleColor(color);
        setColor(color);
    };

    const styles = {
        spanColor: {
            backgroundColor: color
        }
    }
       
    return (
        <div className="color-picker-container"> 
            <div className="color-picker-row" onClick={handleClick}>
                {/* <TextField className="text-field-color"
                    disabled='true'
                    value={color.hex}
                    ></TextField>
                <span className="span-color" style={styles.spanColor}/> */}

                {/* {displayColorPicker? 
                <ChromePicker className="color-picker" 
                    color={ color }
                    onChange={handleChange} />: null}  */}
{/* 
                {displayColorPicker? 
                    <TextField type='color' id="backgroundColor" label="" value={color} variant="outlined" onChange = {handleChange}/>
                : null}  */}
                <OutlinedInput 
                    disabled={disabled}
                    className="text-field-color"
                    type='text'    
                    label=' '
                    value={disabled ? "" : color} 
                    onChange = {e => handleChange(e.target.value)}/>
                <input 
                    disabled={disabled}
                    className="span-color"  
                    type='color' 
                    value={disabled ? "#ffffff" : color} 
                    onChange = {e => handleChange(e.target.value)}
                />

            </div>  
        </div> 
    );
}

export default ColorPicker;
  