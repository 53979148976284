import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
    Form,
    FormGroup,
    ControlLabel,
    FlexboxGrid,
    InputGroup,
    Icon, 
    Whisper, 
    Popover,
    FormControl,
    Checkbox
} from "rsuite";
import {useTranslate} from "react-polyglot";
import {MigrateUserDialogContext} from "../../context/MigrateExperienceUserDialogContext";
import campaignService from "../../services/Campaign";
import SearchAddProject from '../searchProject/SearchProject';
import Model3dCard from "../cards/Model3dCard";
import Model3D from '../../models/models3D';
import { validateFile } from '../../utils/modelViewerValidations';
import AlertDialogBodyMessageTemplate from '../general/AlertDialogBodyMessageTemplate';
import "./Model3dForm.scss";

/* Eliminando la libreria RMWC */
import SausageButton from '../coa/SausageButton';


const MAX_CANT_MODELS = 2;

const Model3dForm = ({
                            actualStep, experienceRoad, projects,
                            project,
                            data,
                            setData,
                            fileValue,
                            filePath,
                            setFilePath,
                            handleUploadModels,
                            setDialogOptions,
                            setOpenDialogAlert,
                            isEditor
                        }) => {
    const [showOfflineExpForm, setShowOfflineExpForm] = useState(false);
    const [, setDataError] = useState(false);
    const [, setValidDataMessage] = useState('');
    const [enabledCss, setEnabledCss] = useState('newExpButton-notValid');
    const [enabledLoadCss, setEnabledLoadCss] = useState('newExpButton-valid');
    const [showForOtherUserForm, setShowForOtherUserForm] = useState(false);
    const [, setCampaignValue] = useState(null);
    const [disabledProject, setDisabledProject] = useState(false);
    const [filesInputs, setFilesInputs] = useState([]);
    const [ disableAddModelButton, setDisableAddModelButton] = useState("false");
    const [usdzDisabled, setUsdzDisabled] = useState(true)
    const [uploadUsdz, setUploadUsdz] = useState(false)
    const [ errorProjectName, setErrorProjectName ] = useState(false);
    const [ errorModels, setErrorModels ] = useState(false);
    const [ errorExpName, setErrorExpName ] = useState(false);

    const t = useTranslate();

    const [ validForm, setValidForm ] = useState(false);

    const {
        users,
        onSearch, setSelectedUser, findUser,
        setCampaignId,
        userId,
        setUserId, selectedUser,
        setProjectsSearch,
        projectsSearch,
        setUsers,
    } = useContext(MigrateUserDialogContext);

    useEffect( () => { 
        const cssStyleChoose = filesInputs.length < MAX_CANT_MODELS ? 'newExpButton-valid':'newExpButton-notValid';
        setEnabledLoadCss(cssStyleChoose)
    }, [filesInputs])

    useEffect(() => {
        if (selectedUser) {
            setCampaignId(null);
            let user = findUser();
            setUserId(user.id);
            setData((lastData) => ({
                ...lastData, ...{
                    user_id: user.id,
                    expname: ''
                }
            }));
        } else {
            setUserId('');
            setCampaignId(null);
            setProjectsSearch([]);
            setData((lastData) => ({
                ...lastData, ...{
                    user_id: '',
                    user_project_id: '',
                    expname: ''
                }
            }));
        }
    }, [selectedUser]);

    useEffect(() => {
        if (userId) {
            const getProjects = async () => {
                let response = await campaignService.listWithoutExperiences(userId);
                let dataFormatted = response.data.map(e => ({
                    "label": e.name,
                    "value": e.name,
                    "data": e
                }));
                setProjectsSearch(dataFormatted)
            };
            getProjects();
        } else {
            setSelectedUser('');
            setProjectsSearch([]);
            setCampaignId(null);
            setCampaignValue(null);
            setData((lastData) => ({
                ...lastData, ...{
                    user_project_id: '',
                    user_id: '',
                    expname: ''
                }
            }))
        }
    }, [userId]);

    const triggerFile = (e) => {
        e.preventDefault();
        if (filesInputs.length < MAX_CANT_MODELS) {
            document.getElementById('inputFile').click();
        }
    };
    const triggerFileUsdz = (e) => {
        e.preventDefault();
        if (filesInputs.length < MAX_CANT_MODELS) {
            document.getElementById('inputFileUsdz').click();
        }
    };
    const handleDeleteAction = (aModel3d) => {
        if(aModel3d.name.includes("glb")){
            setUsdzDisabled(true)
        }else{
            if(filesInputs.length > 1)
            setUsdzDisabled(false)
        }
        const filterInputs = filesInputs.filter( aFile => aFile.name !== aModel3d.name )
        setFilesInputs( filterInputs );

    }

    // TODO: Api Gateway no soporta archivos mayores a 10mb validar que hacer con eso. Agregamos validationApiGateway?
    const addModelsFiles = (files) => {
            const validFiles = [];
            const invalidFiles = [];
            const results = Array.from(files).map( aFile => validateFile(aFile) ); 
            Promise.all(results).then( (filesValidations) => {
                filesValidations.forEach( (validationFile) => {
                    if ( Object.values(validationFile.errors).includes(false) ) {
                        let errors = Object.keys(validationFile.errors).filter( key => !validationFile.errors[key])
                        invalidFiles.push({fileName: validationFile.file.name, errors: errors });
                    } else {
                        validFiles.push( new Model3D(validationFile.file.name, validationFile.file) );
                        setErrorModels(false);
                    }
                })

                if (invalidFiles.length) {
                    setUsdzDisabled(true)
                    setDataError(true);
                    setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                    setDialogOptions( { bodyMsg: <AlertDialogBodyMessageTemplate invalidFiles={invalidFiles}/> }  )
                    setOpenDialogAlert(true);
                    document.getElementById('inputFile').value = null;
                }else{
                    setFilesInputs([
                        validFiles[0],
                        ...filesInputs
                    ])
                    setUsdzDisabled(false)
                }
            })
    };
    const addUsdzFile = (files) => {
        const validFiles = [];
        const invalidFiles = [];
        const results = Array.from(files).map( aFile => {
            if(aFile.name.includes(".usdz")){
                return {file:aFile, errors:{ validFormat:true,}}
            }else{
                return {file:aFile, errors:{ validFormat:false,}}
            }
        } ); 
        Promise.all(results).then( (filesValidations) => {
            filesValidations.forEach( (validationFile) => {
                if ( Object.values(validationFile.errors).includes(false) ) {
                    let errors = Object.keys(validationFile.errors).filter( key => !validationFile.errors[key])
                    invalidFiles.push({fileName: validationFile.file.name, errors: errors });
                } else {
                    validFiles.push( new Model3D(validationFile.file.name, validationFile.file) );
                    setErrorModels(false);
                }
            })

            if (invalidFiles.length) {
                setDataError(true);
                setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                setDialogOptions( { bodyMsg: <AlertDialogBodyMessageTemplate invalidFiles={invalidFiles}/> }  )
                setOpenDialogAlert(true);
                document.getElementById('inputFile').value = null;
            }else{
                setFilesInputs([
                    ...filesInputs,
                    validFiles[0]
                ])
            }
        })
};

    // TODO: revisar que saque   data.name.trim() === '' ||
    const validData = (data) => {
        let valid = ( 
            (!data.forOtherUser && data.campaign.name.trim() === '') ||
            data.models.length < 2 || 
            !data.expname ||
             data.expname === '' ||
            (data.isOffline && (data.offlineName === '' || data.offlineTargetType === '')) ||
            (data.forOtherUser && (data.user_project_id === '' || data.user_id === '')));
        setValidForm(valid);
            /* if (!valid) {
            setEnabledCss('newExpButton-valid')
        } else {
            setEnabledCss('newExpButton-notValid')
            
        }
        } */
        return !valid
    };

    useEffect(() => {
        setData({...data, ...{models: filesInputs}});
    }, [filesInputs])

    useEffect(() => {
        validData(data);
    }, [data]);

    const getParseDataOwnProject = (item) => {
        return {
            experiences: null,
            id: item.data ? item.data.id : '',
            name: item.data ? item.data.name : item.c,
            userId: item.data ? item.data.userId : ''
        }
    }

    const setChangeProject = (field, c, item) => {
        if (!c) {
            setData((lastData) => ({
                ...lastData, ...{
                    [field]: {
                        name: ''
                    }
                }
            }));
        } else {
            setErrorProjectName(false)
            setData((lastData) => ({
                ...lastData, ...{
                    [field]: item
                }
            }));
        }
    };

    const getPopover = (name) => {
        return <Popover style={{maxWidth: '350px', textAlign:"left"}} className={'popoverBackground'}>
            <p>{name}</p>
        </Popover>
    };
    

    const getForm = () => {
        return <Fragment>
            <FlexboxGrid justify='center' style={ {flexDirection: 'column', alignItems: 'center', marginLeft:'33px'} }>
                <FlexboxGrid.Item colspan={12} style={{width: '480px'}} className={'paddingTopFlexExp'}>
                    <Form fluid>
                        <input type="hidden" name="campaign[name]"/>
                        <input type="hidden" name="campaign[id]"/>
                        <input type="hidden" name="expname"/>
                        <input type="hidden" name="type"/>
                        <FormGroup>
                            <ControlLabel
                                className={'boldText textFormExperience'}>{t('experienceNewFlow.nameOfExperience')}</ControlLabel>
                            <div style={{'display': 'flex'}}>
                                <InputGroup style={{
                                    width: '93%',
                                    height: '40px',
                                }} className={'grayHoverInputSearch'}>
                                    <InputGroup.Addon>
                                        <Icon icon="file"/>
                                    </InputGroup.Addon>
                                    <FormControl name="name" onChange={(e) => {
                                        setData({...data, ...{expname: e}});
                                        setErrorExpName(false);
                                    }}/>
                                </InputGroup>
                                <div>
                                    {!errorExpName ? 
                                    <Whisper placement="right" trigger="hover"
                                                speaker={getPopover(t('experiences.infoExpInput'))}>
                                        <img
                                            src="/images/information.svg"
                                            style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                            alt=""
                                        />
                                    </Whisper>
                                    :                            
                                    <Whisper placement="right" open="true"
                                             speaker={getPopover(t('experiences.infoExpInput'))}>
                                        <img src="/images/information.svg"
                                             style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                             alt=""/>
                                    </Whisper>}
                                </div>
                            </div>                                
                           
                        </FormGroup>
                        <FormGroup style={{marginBottom: '10px'}}>
                            <ControlLabel className={'boldText'}>{t('experienceNewFlow.nameOfCampaign')}</ControlLabel>
                            <InputGroup style={{width: '100%', border: 'none'}}>
                                <div className={'containerIconInput'}>
                                    <div className={'searchIconContainer'}>
                                        <Icon icon="folder"/>
                                    </div>
                                    <SearchAddProject
                                        dataSearch={projects}
                                        onClean={() => setChangeProject('campaign', null, { name: '' })}
                                        onSelect={(c, item) => setChangeProject('campaign', c, getParseDataOwnProject({...item, c}))}
                                        disabled={disabledProject}
                                    />
                                </div>
                                <div>
                                    {!errorProjectName ? 
                                    <Whisper placement="right" trigger="hover"
                                                speaker={getPopover(t('experiences.infoCampaign'))}>
                                        <img
                                            src="/images/information.svg"
                                            style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                            alt=""
                                        />
                                    </Whisper>
                                    :
                                    <Whisper placement="right" open="true"
                                                speaker={getPopover(t('experiences.infoCampaign'))}>
                                        <img
                                            src="/images/information.svg"
                                            style={{'marginLeft': '9px', 'marginTop': '9px'}}
                                            alt=""
                                        />
                                    </Whisper>}
                                </div>
                            </InputGroup>
                           {/*  { isEditor() && 
                                <Checkbox 
                                    onChange={(e,c) => {
                                        setData({
                                            ...data,
                                            isAd:c})
                                    }}
                                >
                                    {t('experienceNewFlow.advertisement')}
                                </Checkbox>
                            } */}
                        </FormGroup>
                        { filesInputs.length >= MAX_CANT_MODELS  ? <div style={{height: '50px'}} ></div>:
                        <FormGroup>
                            <div>
                                    <ControlLabel
                                        style={{marginTop: '4px'}}
                                        className={'boldText textFormModelViewer'}>{t('studioNewFlow.labelFromDragComponent')}
                                    </ControlLabel>
                                    <ControlLabel
                                        style={{ 'marginBottom': '5px'}}
                                        >{t('studioNewFlow.format')}
                                    </ControlLabel>
                                    <div className={'preview-file'} style={{'display': 'flex'}}>
                                        <div
                                            style={{display:usdzDisabled ? 'flex' : 'none'}}
                                            className={'new-experience-preview-form'}
                                            onClick={(evt) => {
                                                // if (fileValue ) {
                                                //     document.getElementById('inputFile').click();
                                                // }
                                            }}>
                                            <input id="inputFile"
                                                   type="file"
                                                   name="target"
                                                   multiple style={{display: 'none'}}
                                                   onChange={(evt) => {
                                                       evt.preventDefault();
                                                       if (evt.target.files.length + filesInputs.length > MAX_CANT_MODELS) {
                                                        setDataError(true);
                                                        setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                                                        setDialogOptions( { bodyMsg: t('studioNewFlow.maxFilesError')});
                                                        setOpenDialogAlert(true);
                                                        } else {
                                                            
                                                            addModelsFiles(evt.target.files);
                                                            // setData({...data, ...{models: filesInputs}});
                                                            validData(data);
                                                        }
                                                   }}/>
                                            {  
                                                <div className={'image-input'} onDragEnter={(e) => {
                                                    e.preventDefault()
                                                }} onDragOver={(e) => {
                                                    e.preventDefault()
                                                }} onDrop={(evt) => {
                                                    evt.preventDefault();
                                                    if (evt.dataTransfer.files.length + filesInputs.length > MAX_CANT_MODELS) {
                                                        setDataError(true);
                                                        setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                                                        setDialogOptions( { bodyMsg: t('studioNewFlow.maxFilesError')});
                                                        setOpenDialogAlert(true);
                                                        } else {
                                                            addModelsFiles(evt.dataTransfer.files);
                                                            // setData({...data, ...{models: filesInputs}});
                                                            validData(data);
                                                        }
                                                }}> 
                                                    <div className={'label-input-image'}>
                                                        <span>{t('studioNewFlow.addOrDragModel')}</span>
                                                    </div>
                                                    <div className={'label-secondary'}>
                                                        <span>{t('studioNewFlow.newExp.formatsModels')}</span>
                                                    </div>
                                                    <div>
                                                        <SausageButton outlined label={t('studioNewFlow.addModelGLB')}
                                                                /* className={`new-experience-button`} */
                                                                onClick={triggerFile}
                                                                />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                         <div
                                            style={{display:!usdzDisabled ? 'flex' : 'none'}}
                                            className={'new-experience-preview-form'}
                                            onClick={(evt) => {
                                                // if (fileValue ) {
                                                //     document.getElementById('inputFile').click();
                                                // }
                                            }}>
                                            <input id="inputFileUsdz"
                                                   type="file"
                                                   name="target"
                                                   multiple style={{display: 'none'}}
                                                   onChange={(evt) => {
                                                       evt.preventDefault();
                                                       if (evt.target.files.length + filesInputs.length > MAX_CANT_MODELS) {
                                                        setDataError(true);
                                                        setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                                                        setDialogOptions( { bodyMsg: t('studioNewFlow.maxFilesError')});
                                                        setOpenDialogAlert(true);
                                                        } else {
                                                            addUsdzFile(evt.target.files);
                                                            // setData({...data, ...{models: filesInputs}});
                                                            validData(data);
                                                        }
                                                   }}/>
                                            {  
                                                <div className={'image-input'} onDragEnter={(e) => {
                                                    e.preventDefault()
                                                }} onDragOver={(e) => {
                                                    e.preventDefault()
                                                }} onDrop={(evt) => {
                                                    evt.preventDefault();
                                                    if (evt.dataTransfer.files.length + filesInputs.length > MAX_CANT_MODELS) {
                                                        setDataError(true);
                                                        setValidDataMessage(t('studioNewFlow.changeModel.invalidFile'));
                                                        setDialogOptions( { bodyMsg: t('studioNewFlow.maxFilesError')});
                                                        setOpenDialogAlert(true);
                                                        } else {
                                                            addUsdzFile(evt.dataTransfer.files);
                                                            // setData({...data, ...{models: filesInputs}});
                                                            validData(data);
                                                        }
                                                }}> 
                                                    <div className={'label-input-image'}>
                                                        <span>{t('studioNewFlow.addOrDragModel')}</span>
                                                    </div>
                                                    <div className={'label-secondary'}>
                                                        <span>{t('studioNewFlow.newExp.formatModels2')}</span>
                                                    </div>
                                                    <div>
                                                        <SausageButton outlined label={t('studioNewFlow.addModelUSDZ')}
                                                                /* className={`new-experience-button`} */                                                                
                                                                onClick={triggerFileUsdz}
                                                                />
                                                    </div>
                                                </div>
                                            }
                                        </div> 
                                        <div> 
                                            {errorModels ? 
                                            <Whisper placement="right" open="true"
                                                     speaker={getPopover(t('studioNewFlow.infoImage'))}>
                                                <img src="/images/information.svg"
                                                     style={{'marginLeft': '9px', 'marginTop': '105px'}}
                                                     alt=""/>
                                            </Whisper>
                                                :
                                            <Whisper placement="right" trigger="hover"
                                                     speaker={getPopover(t('studioNewFlow.infoImage'))}>
                                                <img src="/images/information.svg"
                                                     style={{'marginLeft': '9px', 'marginTop': '105px'}}
                                                     alt=""/>
                                            </Whisper>
                                            }
                                        </div>
                                    </div>

                               
                            </div>
                        </FormGroup>
                        }
                    </Form> 
                  
                    
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <FlexboxGrid className="items3dContainer">
                        { filesInputs.map( aModel3d => (
                            <FlexboxGrid.Item className="item3d">
                                <Model3dCard  model3d={aModel3d} handleDeleteAction={handleDeleteAction}></Model3dCard>
                            </FlexboxGrid.Item>
                        ))
                        }
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className={'styleFaceFooterExpForm'}>
                <SausageButton invert outlined disabled={validForm} label={t('studioNewFlow.uploadsModels')} onClick={(e) => {
                    e.preventDefault();
                    if (validData(data)) {
                        handleUploadModels(data);
                    }
                    if (data.models.length > 2) {
                        setErrorModels(true)
                    }
                    if (data.campaign.name.trim() === '') {
                        setErrorProjectName(true);
                    }
                    if (!data.expname || data.expname.trim() === '') {
                        setErrorExpName(true);
                    }
                }}
                        className={`new-experience-button ${enabledCss}`}/>
            
            </div>
        </Fragment>
    };
   
    return (
        getForm()
    );
};

export default Model3dForm;