import React, {useState} from 'react';
import ExperienceSubtitle from "./ExperienceSubtitle";
import {Button} from "@rmwc/button";
import {useTranslate} from "react-polyglot";

const ImmersiveTypeComponent = ({step, setStep, experienceRoad, setExperienceRoad, setTitle}) => {
    const [hover, setHover] = useState({world_tracking_type: ''});
    const t = useTranslate()
    return (
        <div>
            <ExperienceSubtitle
                subT={`${t('experienceNewFlow.subtitleImmersive')}`}/>
            <div className="new-experience-type-container">
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({world_tracking_type: 'app_world_tracking'});
                }} onMouseLeave={() => {
                    setHover({world_tracking_type: ''});
                }} onClick={() => {
                    setTitle(t('experienceNewFlow.immersive'));
                    setStep(['world_tracking', 'world_tracking_type']);
                    setExperienceRoad({...experienceRoad, ...{world_tracking_type: 'app', experience_type: '1s1'}});
                    /*                    setTitle('experiences.newExp.imgTracking');
                                        setHideExperienceOptions(false);
                                        setStep(['image_tracking']);*/
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.world_tracking_type === 'app_world_tracking' ?
                                <img src="/images/publish-app-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/publish-app.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_type === 'app_world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.app')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.immersiveAppDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>

                    </div>
                </div>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({world_tracking_type: 'web_world_tracking'});
                }} onMouseLeave={() => {
                    setHover({world_tracking_type: ''});
                }} onClick={() => {
                    setTitle(`${t('experienceNewFlow.immersive')}`);
                    setStep(['world_tracking', 'world_tracking_type']);
                    setExperienceRoad({
                        ...experienceRoad, ...{
                            world_tracking_type: 'web',
                            experience_type: 'ImmersionWeb'
                        }
                    });
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.world_tracking_type === 'web_world_tracking' ?
                                <img src="/images/publish-web-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/publish-web.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.world_tracking_type === 'web_world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.web')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.immersiveWebDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImmersiveTypeComponent;