import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {Select} from '@rmwc/select';
import {Button} from '@rmwc/button';
import {useTranslate} from 'react-polyglot';

import ProfileInfo from '../../components/actions/ProfileInfo';
import DeleteAccount from '../../components/actions/DeleteAccount';
import Modal from '../../components/modal/modal';
import ProfileSec from '../../components/actions/ProfileSec';
import CheckPermision from '../../components/checkPermision/checkPermision';
import {userGroups, userGroupsObject} from '../../utils/userGroups';
import AlertDialog from "../../components/general/AlertDialog";
import {Auth} from "aws-amplify";
import {deleteCookie} from "../../utils/cookie";
import {SelectPicker} from "rsuite";


import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/button/dist/mdc.button.css';
import '@rmwc/icon/icon.css';
import '@material/ripple/dist/mdc.ripple.css';
import './userProfile.scss';
import { getExpirationDays } from '../../utils/user';
import AppContext from '../../components/app/AppContext';


function UserProfile({user, updateInfo, deleteAccount, updateLang, setProfile}) {
    const context = useContext(AppContext);
    const [lang, setLang] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [actionsDialogData, setActionsDialogData] = useState(null);
    const [open, setOpen] = useState(false);
    const t = useTranslate();
    useEffect(() => {
        setLang(window.localStorage.getItem('lang'));
    }, [])

    function openSubscriptionDialog(event) {
        context.setPage({...context.page, openSubDialog: true});
    }

    function openEdit() {
        const type = this;
        let actionsDialogData = {};
        if (type === 'info') {
            actionsDialogData = {
                title: t('pages.account.userProfile.modal.edit.title'),
                componentData: {
                    name: user.name,
                    surname: user.surname,
                    phone: user.phone,
                    closeModal: () => {
                        setOpenModal(false);
                    },
                    setProfileInfo: (res) => {
                        setProfile(res);
                    },
                    user
                },
                buttonLess: true,
                component: ProfileInfo,
                componentKey: user.id,
                action: editUserInfo,
            };

        }
        if (type === 'sec') {
            actionsDialogData = {
                title: t('pages.account.userProfile.modal.sec.title'),
                componentData: {
                    user,
                    open,
                    setOpen,
                    closeModal: () => {
                        setOpenModal(false);
                    },
                },
                buttonLess: true,
                component: ProfileSec,
                componentKey: user.id,
            };

        }
        if (type === 'delete') {
            actionsDialogData = {
                title: t('pages.account.userProfile.modal.delete.title'),
                componentData: {
                    id: user.id,
                    email: user.email
                },
                component: DeleteAccount,
                componentKey: user.id,
                action: deleteUserAccount
            };

        }
        setActionsDialogData({...actionsDialogData});
        setOpenModal(true)
    }

    const editUserInfo = (data) => {
        updateInfo({...data, companyId: user.company.id}, user.company.id, user.id)
    };
    const deleteUserAccount = (data) => {
        deleteAccount(data, user);
    };
    const getName = () => {
        return user.name && user.surname ? `${user.name} ${user.surname}` : t('pages.account.userProfile.labels.fakeName');
    };

    const onLangChange = (e) => {
        if (e) {
            window.localStorage.setItem('lang', e);
            updateLang(user.id, e)
                .then(() => {
                    window.location.reload();
                })

        }
    };

    const getInvitationLink = () => `${process.env.REACT_APP_NPP_BASE_URL}/register?tag=${user.professor?.code}`;

    const copyToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = getInvitationLink();
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return (
        <section className={'profile-card-container'}>
            <AlertDialog
                title={t('pages.account.userProfile.modal.changePass.title')}
                bodyMessage={t('pages.account.userProfile.modal.changePass.msg')}
                setOpen={setOpen}
                open={open}
                functionHandler={async () => {
                    await Auth.signOut();
                    deleteCookie();
                    window.location.href = '/';
                }}/>

            <div className={'profile-element'}>
                <div className={'profile-title'}>
                    <span>{t('pages.account.userProfile.labels.user')}</span>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('common.labels.email')}</span>
                    </div>
                    <div className="profile-item-value">
                        <span>{user.email || 'Correo'}</span>
                        {
                            getExpirationDays(context.userData.created_at) <= 0 &&
                            context.userGroups.includes("FreelanceUsers") &&
                            <span className='profile-pro-button' onClick={openSubscriptionDialog}>(Pasar a PRO)</span>
                        }
                    </div>
                </div>
            </div>

            <div className={'profile-element'}>
                <div className={'profile-title'}>
                    <span>{t('pages.account.subscription.labels.billingInfo').toUpperCase()}</span>
                    <span className="profile-title-edit" onClick={openEdit.bind('info')}>{t('actions.edit')}</span>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('pages.account.subscription.labels.nameSurname')}</span>
                    </div>
                    <div className="profile-item-value">
                        <span>{getName()}</span>
                    </div>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('common.labels.phone')}</span>
                    </div>
                    <div className="profile-item-value">
                        <span>{user.phone || t('common.labels.phone')}</span>
                    </div>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('pages.account.userProfile.labels.language')}</span>
                    </div>
                    <div className="profile-item-value">
                        <SelectPicker className={'noCleanIcon'} value={lang} style={{width: '200px'}} onChange={onLangChange} placeholder={''}
                                      searchable={false} data={[{
                            label: t('common.labels.spanish'),
                            value: 'es'
                        }, {
                            label: t('common.labels.english'),
                            value: 'en'
                        }]}/>
                    </div>
                </div>
            </div>

            <div className={'profile-element'}>
                <div className={'profile-title'}>
                    <span>{t('pages.account.userProfile.labels.security').toUpperCase()}</span>
                    <span className="profile-title-edit" onClick={openEdit.bind('sec')}>{t('actions.edit')}</span>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('pages.account.userProfile.labels.password')}</span>
                    </div>
                    <div className="profile-item-value">
                        <span>*****</span>
                    </div>
                </div>
            </div>

            <CheckPermision
                component={
                    <div className={'profile-element'}>
                        <div className={'profile-title'}>
                            <span>{t('pages.account.userProfile.labels.inviteLink')}</span>
                            <span className="profile-title-edit" onClick={copyToClipboard}>{t('actions.copy')}</span>
                        </div>
                        <div className={'profile-item'}>
                            <div className="profile-item-label">
                                <span>{t('pages.account.userProfile.labels.link')}</span>
                            </div>
                            <div className="profile-item-value">
                                <span>{getInvitationLink()}</span>
                            </div>
                        </div>
                    </div>}
                groups={[userGroupsObject.ProfessorsUsers]}
            />

            <div className={'profile-element'}>
                <div className={'profile-title'}>
                    <span>{t('pages.account.userProfile.labels.deleteAccount').toUpperCase()}</span>
                </div>
                <div className={'profile-item'}>
                    <div className="profile-item-label">
                        <span>{t('pages.account.userProfile.labels.deleteAccountStudio')}</span>
                    </div>
                    <div className="profile-item-value">
                        <Button label={t('pages.account.userProfile.labels.deleteAccount')} danger
                                onClick={openEdit.bind('delete')}/>
                    </div>
                </div>

            </div>
            {openModal &&

            <Modal {...actionsDialogData} onClose={() => {
                setOpenModal(false)
            }}/>
            }
        </section>
    )
}

UserProfile.propTypes = {
    user: PropTypes.object
}

export default UserProfile;
