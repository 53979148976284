import moment from "moment";

export const formatNumber = (value) => {
    return value.toLocaleString(localStorage.lang);
};

export const validRolesToGetCompany = ['CompanyUsers', 'FreelancePaidUsers', 'FreelanceUsers'];

/**
 *
 * @returns {[{styles: {padding: number}, check: boolean, sortable: boolean, text: string}, {width: number, fixed: boolean, check: boolean, sortable: boolean, text: string, key: string}, {width: number, fixed: boolean, check: boolean, sortable: boolean, text: string, key: string}]}
 */
export const getColumnsInitialState = () => {
    return [
        {
            checkvalid: 'x', fixed: true, sortable: false, text: '', styles: { padding: 0 }
        }, {
            checkvalid: '',
            sortable: true,
            flexGrow: 1,
            text: 'pages.reports.headTable.company',
            key: 'company_name'
        }, {
            checkvalid: '',
            sortable: true,
            text: 'pages.reports.headTable.views',
            key: 'scansString'
        },
        {
            buttonvalid: 'x',
            checkvalid: '',
            text: 'pages.reports.headTable.filter',
            fixed: 'right'
        }]
};

export const getCompanyColumns = () => {
    return [
        {
            styles: { padding: 0 }, checkvalid: 'x', fixed: true, sortable: false, text: ''
        }, {
            checkvalid: '',
            sortable: true,
            text: 'pages.reports.headTable.campaign',
            flexGrow: 1,
            key: 'campaign_name'
        }, {
            checkvalid: '',
            sortable: true,
            text: 'pages.reports.headTable.views',
            key: 'scansString'
        },
        {
            buttonvalid: 'x',
            checkvalid: '',
            text: 'pages.reports.headTable.filter',
        }]
};


export const getCampaignColumns = () => {
    return [
        {
            styles: { padding: 0 }, checkvalid: 'x', sortable: false, text: ''
        }, {
            checkvalid: '',
            sortable: true,
            fixed: false,
            flexGrow: 1,
            text: 'pages.reports.headTable.experience',
            key: 'experience_name'
        }, {
            checkvalid: '',
            sortable: true,
            fixed: false,
            text: 'pages.reports.headTable.views',
            key: 'scansString'
        }, {
            buttonvalid: 'x',
            checkvalid: '',
            text: 'Filtrar',
        }];
};

export const getExperienceColumns = () => {
    return [
        {
            checkvalid: '',
            sortable: true,
            fixed: false,
            width: 700,
            text: 'pages.reports.headTable.actionType',
            key: 'name'
        }, {
            checkvalid: '',
            sortable: true,
            fixed: false,
            width: 100,
            text: 'pages.reports.headTable.quantity',
            key: 'scansString'
        }];
};


/**
 *
 * @param initialData
 * @returns {string}
 */
export const getFilters = (initialData, idFilters = '') => {
    let fromFormatted = moment(initialData.from, 'DD-MM-YYYY').format('YYYY-MM-DD');
    let toFormatted = moment(initialData.to, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const { from, to, company_id, campaign_id, experience_id, ids } = initialData;
    let query;
    if (from && to) {
        query = `?from=${fromFormatted}&to=${toFormatted}`;
    }
    if (from && to && company_id) {
        query = `?from=${fromFormatted}&to=${toFormatted}&company_id=${company_id}`;
    }
    if (from && to && company_id && campaign_id) {
        query = `?from=${fromFormatted}&to=${toFormatted}&company_id=${company_id}&campaign_id=${campaign_id}`;
    }
    if (from && to && company_id && campaign_id && experience_id) {
        query = `?from=${fromFormatted}&to=${toFormatted}&company_id=${company_id}&campaign_id=${campaign_id}&experience_id=${experience_id}`;
    }
    
    query += `${ids && ids !== idFilters ? `&ids=${ids}` : ''}`;

    return query
};


export const getRGBTableHorizontalGraph = () => {
    return 'rgba(52, 135, 240, 1)';
};

export const getRGBDoughnut = (i, osLength) => {
    let colors = ["#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1"];
    if (osLength === colors.length) {
        return colors ? colors[i] : '';
    } else {
        if (i < colors.length - 1) {
            return colors[i];
        } else {
            colors.push("#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1", "#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1", "#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1")
            return colors[i];
        }
    }

};

export const getRGBDoughnutCountries = (e, i) => {
    let colors = ["#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1"];
    if (e.country === 'Others') {
        return "#929caf"
    } else {
        if (i < colors.length - 1) {
            return colors[i];
        } else {
            colors.push("#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1", "#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1", "#3586ef", "#fe2ab3", "#866be0", "#e140c2", "#bc54d1")
            return colors[i];
        }
    }

};

