import React from 'react';
import { Button, Modal} from "rsuite";
import { useTranslate } from 'react-polyglot';

/**
 *
 * @param open
 * @param setOpen
 * @param title
 * @param bodyMessage
 * @param functionHandler en el caso de que cuando se le de al boton de cerrar o se haga el escape ejecute algun handler especifico se realiza
 * @returns {*}
 * @constructor
 */
const AlertDialog = ({ 
        open,
        setOpen,
        title,
        bodyMessage,
        functionHandler,
        buttonMessage
    }) => {
    const t = useTranslate();
    return (
        <div className="modal-container">
            <Modal show={open} onHide={() => {
                setOpen(false)
            }}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {bodyMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        if (functionHandler)
                            functionHandler();
                        setOpen(false)
                    }} appearance="primary">
                        {buttonMessage? buttonMessage:t('actions.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AlertDialog;