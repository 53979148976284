import React from 'react';
import {
    Card,
    CardActionIcon,
    CardActionIcons,
    CardActions,
    CardMedia,
    CardPrimaryAction
} from "@rmwc/card";
import {Typography} from "antd";

const Model3dCard = ({
        model3d,
        handleDeleteAction

    }) => {

    const deleteAction = () => {
        handleDeleteAction(model3d)
    }

    const cropLongText = (text)=>{

        if (text.length >= 30 )
            return text.slice(0,26)+'...glb';
        else return text;
    }
    const cropLongTextUsdz = (text)=>{

        if (text.length >= 30 )
            return text.slice(0,26)+'...usdz';
        else return text;
    }
    return (
        <Card style={{ width: '160px' }}>
            <CardPrimaryAction>
                <CardMedia
                sixteenByNine
                style={{
                    width: '128px',
                    height:'128px',
                    margin: '16px',
                    backgroundImage: 'url(images/object3D.png)',
                    
                }}
                />
                <div style={{ padding: '0 1rem 1rem 1rem', textAlign: 'center' }}>
                    <Typography use="headline6" tag="h2">
                        {model3d.name.includes('glb')?
                        cropLongText(model3d.name):
                        cropLongTextUsdz(model3d.name)}
                    </Typography>
                </div>
            </CardPrimaryAction>
            <CardActions style={{ height: '32px' , minHeight: '32px', marginBottom:'16px'  }}>
                <CardActionIcons>
                    <CardActionIcon onClick={deleteAction} onIcon="delete" icon="delete" />
                </CardActionIcons>
            </CardActions>
        </Card>
    );
}


export default Model3dCard;













 