import React, {useState} from 'react';
import {navigate} from "@reach/router";
import {useTranslate} from 'react-polyglot';
import Slider from "react-slick";
import PrevArrowComponent from "../../components/layout/PrevArrowComponent";
import NextArrowComponent from "../../components/layout/NextArrowComponent";

import {ListContainer, Title, IconWithStyle} from './Styles';
import { CardHomeStudio } from '../../pages/Home/CardHomeStudio';



function StatusListItem({campaign, name, icon, status}) {
    const [expand, setExpand] = useState(true);
    const t = useTranslate();

    function goToCampaign() {
        navigate(`/experiences/${name}`);
    }

    const settings = {
        className: "slider variable-width",
        variableWidth: true,
        prevArrow: <PrevArrowComponent/>,
        nextArrow: <NextArrowComponent/>,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <ListContainer
            open={expand}
            name={name}
        >
            <div className={'list-container-header'} style={{'align-items': 'flex-end'}}>
                <IconWithStyle name={icon}/>
                <Title>{t(name)}</Title>
                <span className="action" onClick={goToCampaign}>{t('pages.status.seeAll')}</span>
            </div>
            <Slider {...settings}>
                {status && status.map((experience, index) => {
                    return (
                        (!experience.hide &&
                            <CardHomeStudio key={index} experienceCardStyle={'margin-card'} experience={experience}/>

                        )
                    )
                })
                }
            </Slider>
        </ListContainer>
    )
}

export default StatusListItem