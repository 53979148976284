import React, { memo, useEffect, useState, useContext } from 'react'
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useTranslate } from 'react-polyglot';

import ErrorMessage from "../ErrorMessage/ErrorMessage";
import AppContext from "../app/AppContext";

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

const CloneExp = memo(function CloneExp(props) {
    const [value, setValue] = useState('');
    const [name, setName] = useState('');
    const [filePath, setFilePath] = useState('');
    const t = useTranslate();

    const [errorState, setErrorState] = useState(false);
    const [message, setMessage] = useState('');
    const [fileValue, setFileValue] = useState({});
    const context = useContext(AppContext);
    useEffect(() => {
        return () => {
            setFilePath('');
            setValue('');
        }
    }, [])

    useEffect(() => {
        if (value === props.data.name) {
            setValue(props.data.name);
        }
    });

    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('fileInput').click();
    }

    function getFilePath(file) {
        let validFileTypes = ['JPEG', 'jpeg', 'jpg', 'JPG'];
        let fileExtension = file.files[0] ? file.files[0].name.split('.').pop() : '';
        if (file.files[0] && validFileTypes.includes(fileExtension)) {
            const url = URL.createObjectURL(file.files[0]);
            setErrorState(false);
            setMessage('');
            setFilePath(url);
        } else {
            if (!validFileTypes.includes(fileExtension)) {
                setErrorState(true);
                setFileValue({});
                setMessage('Tienes que agregar una imagen con formato valido (.jpg)')
            }
            setValue(false);
        }
    }

    return (
        <div>
            <TextField outlined name="name" label={t('common.labels.name')} className="fullwidth form-item clone-experience" value={name}
                       onChange={e => {
                           setName(e.target.value)
                       }}/>
            {errorState ? <ErrorMessage message={message}/> : null}
            {props.data.hasTarget && <div className="img-selector">
                <input type="file" name="target" id="fileInput" defaultValue={value} onChange={(e) => {
                    setValue(e.target.value);
                    setFileValue(e.target.files[0]);
                    getFilePath(e.target);
                }} className="img-selector-input"/>
                {!value &&
                <div>
                    <p className="img-selector-title">
                    {t('experiences.addOrDragTarget')}
                    </p>
                    <Button label={t('experiences.addTarget')} outlined onClick={triggerFile} className="btn-rounded"/>
                </div>

                }
                {value &&
                <img width='430px' height='310px' src={filePath} alt={'fileValue'}
                     className={'new-experience-preview-image imageMargin'}/>
                }
            </div>}
            <Button label={t('actions.add')} onClick={(e) => {
                e.preventDefault();
                if (!name) {
                    setErrorState(true);
                    setMessage('Es necesario agregar un nombre de experiencia')
                } else {
                    if (props.data.hasTarget && !fileValue.name) {
                        setErrorState(true);
                        setMessage('Es necesario agregar un target')
                    } else {
                        props.setOpen(false);
                        setErrorState(false);
                        props.actionHandler({value: fileValue, name: name, key: props.keyData, isDemo: props.isDemo});
                    }
                }
            }}>
            </Button>
            <Button label={t('actions.cancel')} onClick={(e) => {
                e.preventDefault();
                props.setOpen(false);
            }}>
            </Button>
        </div>
    )
})


export default CloneExp
