import React from 'react';
import {Row, Col} from "react-flexbox-grid";
import {useTranslate} from 'react-polyglot';

import OverviewTile from "./OverviewTile";
import './ReportOverViewData.scss';

const ReportOverViewData = ({data}) => {
    const t = useTranslate();
    const {totalScans, totalUsers, interactionTime} = data;
    return (
        <Row>
            <Col lg={4}>
                <OverviewTile value={totalScans} color={'blue'} title={t('pages.reports.totalViews')}/>
            </Col>
            <Col lg={4}>
                <OverviewTile value={totalUsers} color={'yellow'} title={t('pages.reports.users')}/>
            </Col>
            <Col lg={4}>
                <OverviewTile value={`${Math.floor(interactionTime)}`}
                              seconds={localStorage.lang === 'es' ? 'seg' : 'sec'} color={'purple'}
                              title={t('pages.reports.time')}/>
            </Col>
        </Row>
    );
};

export default ReportOverViewData;