import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-flexbox-grid";
import {Button} from "@rmwc/button";
import {DateRangePicker, Breadcrumb} from 'rsuite';
import moment from "moment";
import { useTranslate } from 'react-polyglot';

import './ReportHeader.scss';
import NavLink from '../menu/navLink';
import {
    getCampaignColumns,
    getColumnsInitialState,
    getCompanyColumns,
    getExperienceColumns

} from "../../pages/Reports/ReportUtils";

const ReportHeader = ({
                          initialFilterData, setInitialData, setLoading, navLinks, setNavLinks, setColumns, downloadReport, setOpen
                      }) => {
    const {from, to, company_id, campaign_id} = initialFilterData;
    const {
        allowedRange,
    } = DateRangePicker;
    const t = useTranslate();

    const checkRoute = (e) => {
        e.preventDefault();
        let data, index, spliced, colTemplate;
        switch (e.target.pathname) {
            case '/reports':
                data = {
                    //from: moment().startOf('month').format('YYYY-MM-DD'),
                    // to: moment().subtract(1, "days").format("YYYY-MM-DD"),
                    company_id: '',
                    campaign_id: '',
                    experience_id: '',
                    ids: ''
                };
                let filtered = navLinks.filter(e => e.tab === '/reports');
                colTemplate = getColumnsInitialState();
                setColumns(colTemplate);
                setNavLinks(filtered);
                setInitialData({...initialFilterData, ...data});
                break;
            case '/date':
                data = {
                    from: e.target.innerText,
                    to: e.target.innerText,
                    company_id: '',
                    campaign_id: '',
                    experience_id: '',
                    ids: ''
                };
                index = navLinks.findIndex(e => e.tab === '/date');
                spliced = navLinks.splice(0, index + 1);
                setNavLinks(spliced);
                setInitialData({...initialFilterData, ...data});
                break;
            case '/company':
                data = {
                    from: from,
                    to: to,
                    company_id: e.target.dataset['key'],
                    campaign_id: '',
                    experience_id: '',
                    ids: ''
                };
                index = navLinks.findIndex(e => e.tab === '/company');
                spliced = navLinks.splice(0, index + 1);
                colTemplate = getCompanyColumns();
                setColumns(colTemplate);
                setNavLinks(spliced);
                setInitialData({...initialFilterData, ...data});
                break;
            case '/campaign':
                data = {
                    from: from,
                    to: to,
                    company_id: company_id,
                    campaign_id: e.target.dataset['key'],
                    experience_id: '',
                    ids: ''
                };
                colTemplate = getCampaignColumns();
                setColumns(colTemplate);
                index = navLinks.findIndex(e => e.tab === '/campaign');
                spliced = navLinks.splice(0, index + 1);
                setNavLinks(spliced);
                setInitialData({...initialFilterData, ...data});
                break;
            case '/experience':
                data = {
                    from: from,
                    to: to,
                    company_id: company_id,
                    campaign_id: campaign_id,
                    experience_id: e.target.dataset['key'],
                    ids: ''
                };
                index = navLinks.findIndex(e => e.tab === '/experience');
                spliced = navLinks.splice(0, index + 1);
                colTemplate = getExperienceColumns();
                setColumns(colTemplate);
                setNavLinks(spliced);
                setInitialData({...initialFilterData, ...data});
                break;

        }
    };

    const getKeyRef = (e) => {
        if (e.tab === '/company')
            return e.company_id;
        if (e.tab === '/campaign')
            return e.campaign_id;
        if (e.tab === '/experience')
            return e.experience_id;
        return ''
    };

    return (
        <Row className='margin-report-header'>
            <Col lg={5} sm={12}>
                <Breadcrumb className={'breadStyles'}>
                    {navLinks.map((e) => (
                        <NavLink data-key={getKeyRef(e)}
                                 key={new Date().valueOf().toString(36) + Math.random().toString(36).substr(2)}
                                 to={e.tab} onClick={checkRoute}>{e.text}</NavLink>))
                    }
                </Breadcrumb>
            </Col>
            <Col lg={5} lgOffset={2} sm={12}>
                <Row end={'lg'}>
                    <DateRangePicker format={localStorage.lang === 'es' ? 'DD-MM-YYYY' : 'MM-DD-YYYY'} showOneCalendar ranges={[]}
                                     disabledDate={allowedRange(null, moment().toDate())}
                                     className={'datepickerpos'} size={'md'} placement={"bottomStart"}
                                     value={[moment(from, 'DD-MM-YYYY').toDate(), moment(to, 'DD-MM-YYYY').toDate()]}
                                     onOk={(dates) => {
                                         setLoading(true);
                                         setInitialData({
                                             ...initialFilterData, ...{
                                                 from: moment(dates[0]).format("DD-MM-YYYY"),
                                                 to: moment(dates[1]).format("DD-MM-YYYY")
                                             }

                                         });
                                     }}/>
                    <Button
                        onClick={() => {
                            setOpen(true);
                            downloadReport();
                        }}
                        icon='cloud_download'
                        label={t('pages.reports.downloadReport')}
                        raised
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default ReportHeader