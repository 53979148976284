import React, {forwardRef, useContext, useState} from 'react';
import ExperienceCard from '../../components/experienceCard';
import ModelViewCard from '../../components/modelViewCard/ModelViewCard';
import { EXPERIENCE_TYPE_ID } from '../../utils/experience';


export const CardHomeStudio = forwardRef( (props) => {

    // TODO la diferenciación no deberia ser un simple if, podria ser una estrategia de cards
    return (
        props.experience.experienceTypeId == EXPERIENCE_TYPE_ID['model_3d'] ?
        <ModelViewCard key={props.key} className={props.experienceCardStyle} experience={props.experience} /> :
        <ExperienceCard key={props.key} className={props.experienceCardStyle} experience={props.experience} accessFromEditor={props.accessFromEditor} isDemo={props.isDemo}/>
    )
})