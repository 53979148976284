import React, {useEffect, useState, useContext, useReducer, Fragment} from 'react';
import {useTranslate} from 'react-polyglot';

import userService from '../../services/User';
import UserProfile from './UserProfile';
import Subscription from './Subscription';
import Companies from './Companies';
import Users from './Users';
import Educational from './Educational';
import Publications from './Publications';
import Students from './Student';
import Profesors from './Profesors';
import {Auth} from 'aws-amplify';
import AppContext from '../../components/app/AppContext';
import Icon from '../../components/icon';
import Modal from '../../components/modal/modal';
import {isExpiredUser} from '../../utils/user';
import {userGroupsObject} from '../../utils/userGroups';
import CheckPermision from '../../components/checkPermision/checkPermision';

import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';
import './Profile.scss';
import {Nav} from 'rsuite'
import Header from "../../components/layout/Header";
import {Fixed} from "react-layout-pane";

function Profile({tabId, navigate, ...props}) {
    const context = useContext(AppContext);
    const [active, setActive] = useState({active: 'profile'});
    const [profile, setProfile] = useState({});
    const [index, setIndex] = useState(0);
    const [tabs, setTabs] = useState({tabsOption: [], tabsContent: []});
    const t = useTranslate();

    const [{openModal, propsModal, style}, setModal] = useReducer(
        (prevState, currState) => ({...prevState, ...currState}),
        {openModal: false, propsModal: {}, style: { style: { zIndex: '5' } }}
    );

    useEffect(() => {
        context.setPage({...context.page, shortLayout: true});
        initProfile();
    }, []);

    useEffect(() => {
        initProfile();
    }, [profile]);

    useEffect(() => {
        const indexTabActive = tabs.tabsOption?.findIndex((x) => x.route === tabId);
        setIndex(indexTabActive === -1 ? 0 : indexTabActive);
        setActive({active: tabId});
    }, [tabId, tabs.tabsOption]);

    const configTabs = [{
        name: t('pages.account.tabs.subscription'),
        component: <Subscription setModal={setModal}/>,
        /* viewFreelanceUsers: true,
        viewFreelancePaidUsers: true, */
        route: 'subscription'
    }, {
        name: t('pages.account.tabs.companies'),
        component: <Companies setModal={setModal}/>,
        viewCamonappAdminUsers: true,
        route: 'companies'
    }, {
        name: t('pages.account.tabs.users'),
        component: <Users user={profile} updateInfo={onUpdateInfo} deleteAccount={onDeleteAccount}
                          setModal={setModal}/>,
        viewCamonappUsers: true,
        viewCamonappAdminUsers: true,
        route: 'users'
    }, {
        name: t('pages.account.tabs.publishes'),
        component: <Publications setModal={setModal}/>,
        viewCamonappUsers: true,
        route: 'publishes'
    }, {
        name: t('pages.account.tabs.educationals'),
        component: <Educational setModal={setModal}/>,
        viewCamonappAdminUsers: true,
        route: 'educationals'
    }, {
        name: t('pages.account.tabs.professors'),
        component: <Profesors setModal={setModal}/>,
        viewEducationalUsers: true,
        viewCamonappAdminUsers: true,
        route: 'professors'
    }, {
        name: t('pages.account.tabs.students'),
        component: <Students setModal={setModal}/>,
        viewEducationalUsers: true,
        viewProfessorsUsers: true,
        viewCamonappAdminUsers: true,
        route: 'students'
    }];

    function initProfile() {
        userService.getProfile().then((res) => {
            initTabs(context.userGroups, res);
        });
    }

    function initTabs(userGroups, user) {
        let tabsPane = [
            {
                name: t('pages.account.tabs.profile'),
                route: 'profile',
                component: (
                    <UserProfile
                        user={user}
                        updateInfo={onUpdateInfo}
                        deleteAccount={onDeleteAccount}
                        updateLang={onUpdateLang}
                        setModal={setModal}
                        setProfile={setProfile}
                    />
                ),
            },
        ];

        tabsPane = tabsPane.concat(getTabsByRole(userGroups));
        const tabsRender = tabsPane.reduce(
            (prev, curr, i) => ({
                ...prev,
                [i]: React.cloneElement(curr.component, {user}),
            }),
            {}
        );


        setTabs({
            tabsOption: tabsPane,
            tabsContent: tabsRender,
        });
    }

    function getTabsByRole(userGroups) {
        if (userGroups && userGroups.length) {
            return configTabs.filter((tab) =>
                userGroups.some((group) => tab[`view${group}`])
            );
        }
        return [];
    }

    function backHandler() {
        navigate('/');
        context.setPage({...context.page, shortLayout: false});
    }

    function onUpdateInfo(data, company, user) {
        // eslint-disable-next-line no-undef
        userService.updateProfile(data, company, user).then((res) => {
            setProfile(res);
        });
    }

    function onUpdateLang(user, lang) {
        return userService.updateLang(user, lang)
    }

    async function onDeleteAccount(data, user) {
        try {
            if (user.email === data.name) {
                await userService.deleteAccount(user.id);
                await Auth.signOut();
                window.location.href = '/';

            } else {
                const actionsDialogData = {
                    title: t('pages.account.deleteAccount'),
                    componentData: null,
                    component: null,
                    componentKey: null,
                    action: () => {
                    },
                };
                setModal({openModal: true, propsModal: actionsDialogData})
            }
        } catch (error) {

        }

    }

    function clickTab(e, synthE) {
        synthE.preventDefault();
        setActive({active: e});
        navigate(`/account/${e}`);
    }

    const renderContent = () => tabs.tabsContent[index];

    const renderTabs = () =>
        tabs.tabsOption?.map((x) => (
            <Nav.Item key={x.name} eventKey={x.route}>
                {x.name}
            </Nav.Item>
        ));

    return (
        <Fragment>
            <Header inherited={true} hideSearch={true}/>
            <section>
                {!(isExpiredUser(context.userData)
                    && CheckPermision.check(context.userGroups, [userGroupsObject.FreelanceUsers])
                ) && (
                    <div className="Profile__back-section">
                        <Icon
                            name={'arrow-left'}
                            className="cursor back-button"
                            onClickFn={() => {
                                backHandler();
                            }}
                        />
                        <span
                            className="cursor"
                            onClick={() => {
                                backHandler();
                            }}
                        >
                    {t('pages.account.backToProject')}
                </span>
                    </div>
                )}
                <div className={'tabs-profile-container'}>
                    {/*<TabBar>{renderTabs()}</TabBar>*/}
                    <Nav appearance="subtle" activeKey={active.active}
                         onSelect={(eventKey, synthEvent) => clickTab(eventKey, synthEvent)}>
                        {renderTabs()}
                    </Nav>
                </div>
                <div className={'profile-container'}>{renderContent()}</div>
                {openModal && (
                    <Modal
                        {...propsModal}
                        style={style}
                        onClose={() => {
                            setModal({openModal: false});
                        }}
                    />
                )}
            </section>
        </Fragment>
    );
}

export default Profile;
