import React, {Fragment, useState} from 'react';
import {ReactSVG} from "react-svg";
import {Button, Icon} from "rsuite";
import {useTranslate} from "react-polyglot";
import imageNoHover from './exp-plane-tracking.png'
import imageHover from './exp-plane-tracking-hover.png'

const ExperienceOptions = ({
                               setStep,
                               step,
                               setTitle,
                               setHideExperienceOptions,
                               hover,
                               setHover,
                               experienceRoad,
                               setExperienceRoad,
                               isEditor
                           }) => {
    const t = useTranslate();
    const goToFaceExps = () => {
        setExperienceRoad({...experienceRoad, ...{experience_type: '4'}});
        setTitle('Face Experience');
        setHideExperienceOptions(false);
        setStep(['image_tracking']);
    };
    const [hoverImage, setHoverImage] = useState(false);
    return (
        <Fragment>
            <div className={'new-experience-type-container'}>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'image_tracking'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});
                }} onClick={() => {
                    setExperienceRoad({...experienceRoad, ...{experience_type: '3'}});
                    setTitle(t('experienceNewFlow.imageTracking'));
                    setHideExperienceOptions(false);
                    setStep(['image_tracking']);
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'image_tracking' ?
                                <img src="/images/illustration-plain-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/illustration-plain.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'} style={{visibility: 'hidden'}}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'image_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.imageTracking')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.imageTrackingDescription')}
                        </p>
                    </div>
                </div>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'world_tracking'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});

                }} onClick={() => {
                    setExperienceRoad({...experienceRoad, ...{experience_type: '2'}});
                    setHideExperienceOptions(false);
                    setTitle(t('experienceNewFlow.worldTracking'));
                    setStep(['world_tracking']);
                }}>
                    <div className={'new-experience-type-header'}>
                        <div className={'placeholder'}>
                            {hover.experience_type === 'world_tracking' ?
                                <img src="/images/illustration-world-hover.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                                :
                                <img src="/images/illustration-world.svg" alt=""
                                     style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            }
                        </div>
                        <Button color="blue" className={'newButton'}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.worldTracking')} </span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.worldTrackingDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>
                    </div>
                </div>
                <div className={'new-experience-type'} onMouseEnter={() => {
                    setHover({experience_type: 'immersive'});
                }} onMouseLeave={() => {
                    setHover({experience_type: ''});
                }} onClick={() => {
                    setStep(['world_tracking']);
                    setExperienceRoad({...experienceRoad, ...{experience_type: '1'}});
                    setTitle(t('experienceNewFlow.immersive'));
                    setHideExperienceOptions(false)
                }}>
                    <div className={'new-experience-type-header'}>
                        {hover.experience_type === 'immersive' ?
                            <img src="/images/exp-360-hover.svg" alt=""
                                 style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                            :
                            <img src="/images/exp-360.svg" alt=""
                                 style={{width: '288px', marginLeft: '16px', marginTop: '16px'}}/>
                        }
                        <Button color="blue" className={'newButton'}>
                            {t('experienceNewFlow.new')}
                        </Button>
                    </div>
                    <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'immersive' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('experienceNewFlow.immersive')}</span>
                    </div>
                    <div className={'new-experience-type-text'}>
                        <p>
                            {t('experienceNewFlow.immersiveDescription')}
                        </p>
                    </div>
                    <div className={'new-experience-type-button'}>
                    </div>
                </div>
            </div>
            {isEditor() ? <div className={'styleFaceFooter'} onClick={goToFaceExps} onMouseEnter={() => {
                setHoverImage(true)
            }} onMouseLeave={() => {
                setHoverImage(false);
            }}>
                <img alt={''} src={hoverImage ? imageHover : imageNoHover} width={65} height={65}
                     className={'imageFaceTrack'}/>
                <p className={hoverImage ? 'colorhoverFace' : 'bold'}>{t('experienceNewFlow.faceTracking')}</p>
            </div> : null}

        </Fragment>
    );
};

export default ExperienceOptions;