import amplitude from 'amplitude-js';

export default class Amplitude {

    constructor(data){
        this.provider = amplitude.getInstance();
        this.data = data || {};
    }

    logEvent(event){
        this.provider.logEvent(event, this.data);
    }

}