import React, {memo, useState, useContext, useEffect, Fragment} from 'react';
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {ReactSVG} from 'react-svg';
import {Auth} from 'aws-amplify';
import Icon from '../icon';
import PropTypes from 'prop-types'
import {useTranslate} from 'react-polyglot';
import './customForgot.scss';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import AppContext from '../app/AppContext';
import {navigate} from '@reach/router';
import {ControlLabel, Input} from "rsuite";

const BASE_ERROR = 'pages.signUp.errors';
const ERROR_MAP = [
    {
        msg:'Password cannot be empty',
        key: `${BASE_ERROR}.notEmpty`
    },
    {
        msg: 'Member must have length greater than or equal to 6',
        key: `${BASE_ERROR}.passwordLength`
    },
    {
        msg: 'passwordNotMatch',
        key: `${BASE_ERROR}.passwordNotMatch`
    },
    {
        msg: 'An account with the given email already exists.',
        key: `${BASE_ERROR}.accountExist`
    },
    {
        msg: 'Password does not conform to policy: Password must have symbol characters',
        key: `${BASE_ERROR}.characters`
    },
    {
        msg: 'Password does not conform to policy: Password must have uppercase characters',
        key: `${BASE_ERROR}.passwordMayus`
    },
    {
        msg: 'Password did not conform with policy: Password must have lowercase characters',
        key: `${BASE_ERROR}.passwordMinus`
    },
    {
        msg: 'Password did not conform with policy: Password not long enough',
        key: `${BASE_ERROR}.passwordLength`
    },
    {
        msg: 'Password did not conform with policy: Password must have symbol characters',
        key: `${BASE_ERROR}.passwordSymbol`
    }
]

const CustomRequireNewPass = memo(function CustomRequireNewPass(props) {
    const [pass, setPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [username, setUsername] = useState('')
    const [errorHandler, setErrorHandler] = useState({labelOldPass: '', inputOldPass: '', message: ''});
    const context = useContext(AppContext);
    const t = useTranslate();
    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        setCode(url.get('code'));
        setUsername(url.get('user'));
    }, [])

    async function sendChange() {
        try {

            if (pass !== confirmPass) {
                throw new Error('passwordNotMatch');
            }

            await Auth.forgotPasswordSubmit(username.toLowerCase(), code, pass)
            context.setPage({
                ...context.page, userChangePass: null, notification: {
                    text: t('pages.requireNewPass.passwordReset')
                }
            });
            props.onStateChange('signIn', {confirmation: true});
            navigate('/resetOk')
        } catch (error) {
            handleError(error);
        }
    }

    function handleError(e) {
        const err = ERROR_MAP.find(er => e.message.includes(er.msg))
        setError(err ? err.key : e.message);
        setErrorHandler({
            labelOldPass: 'inputCoALabelError',
            inputOldPass: 'inputCoAError',
            message: err ? err.key : e.message
        });
    }

    function goToLogin() {
        navigate('/');
        props.onStateChange('signIn', {});
    }

    return (
        <div>
            {props.authState === "requireNewPassword" &&
            <Fragment>
                <div className="back-container">
                    <Icon name={'arrow-left'} className="cursor" onClickFn={() => {
                        goToLogin();
                    }}/>
                </div>
                <section className="onboard-container">

                    <div className="forgot-container">
                        <div className="old">
                            <ReactSVG src="/images/old.svg"/>
                        </div>
                        <div className="new">
                            <ReactSVG src="/images/new.svg"/>
                        </div>
                    </div>
                    <h3>{t('pages.requireNewPass.updatePassword')}</h3>

                    <div className="change-form">
                        <div>
                            <ControlLabel style={{marginBottom: '8px'}}
                                          className={`labelInput ${errorHandler.labelOldPass}`}>{t('common.labels.password')}</ControlLabel>
                            <Input className={`inputCoA ${errorHandler.inputOldPass}`}
                                   style={{marginBottom: '30px', marginTop: '8px'}}
                                   type="password" value={pass} autocomplete='off' onChange={(e) => {
                                setPass(e)
                            }}
                            />
                        </div>
                        <div style={{marginBottom: '25px'}}>
                            <ControlLabel style={{marginBottom: '8px', marginTop: '15px'}}
                                          className={`labelInput ${errorHandler.labelOldPass}`}>{t('pages.account.userProfile.labels.confirmPassword')}</ControlLabel>
                            <Input className={`inputCoA ${errorHandler.inputOldPass}`}
                                   name={'email'} type="password" autocomplete='off'
                                   style={{marginTop: '8px', marginBottom: '15px'}}
                                   onChange={(e) => {
                                       setConfirmPass(e)
                                   }}
                            />
                            <div style={{textAlign: 'center'}}>
                                <span className={'error'}>{t(errorHandler.message)}</span>
                            </div>
                        </div>
                        {/* <div className={"change-form-inputs"}>
                            <TextField className="input changepass" value={pass} outlined
                                       label={t('common.labels.password')} type="password" onChange={(e) => {
                                setPass(e.target.value)
                            }}/>
                        </div>
                        <div className={"change-form-inputs"}>
                            <TextField className="input changepass" value={confirmPass} outlined
                                       label={t('pages.account.userProfile.labels.confirmPassword')} type="password"
                                       onChange={(e) => {
                                           setConfirmPass(e.target.value)
                                       }}/>
                        </div>*/}
                        <div className={"change-form-inputs button"}>
                            <Button label="Enviar" outlined className="rounded-button" onClick={sendChange}/>
                        </div>
                    </div>
                </section>
            </Fragment>


            }
        </div>
    )
})

CustomRequireNewPass.propTypes = {}

export default CustomRequireNewPass
