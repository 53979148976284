import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

const DeleteExp = memo(function DeleteExp(props) {
    const t = useTranslate();
    return (
        <div>
            <p>{t('experiences.deleteExp.msg')}</p>
        </div>
    )
})


export default DeleteExp
