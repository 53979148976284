import React from 'react';
import './RegisterHeader.scss'

const RegisterHeader = () => {
    return (
        <div className={'registerHeader'}>
            <img alt={''} src={`/images/logo-register.svg`} className={'headerImageMargin'}/>
        </div>
    );
};

export default RegisterHeader;