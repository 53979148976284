import React, { createContext, useState } from 'react';

const ShowLoadingContext = createContext()

export const ShowLoadingProvider = ({children}) => {

    const [ showLoading, setShowLoading ] = useState(false)
    const [ overlayStyle, setOverlayStyle ] = useState("loading-overlay-full");

    return(
        <ShowLoadingContext.Provider value={{ showLoading, setShowLoading, overlayStyle, setOverlayStyle }}>
            {children}
        </ShowLoadingContext.Provider>
    )
}


export default ShowLoadingContext;