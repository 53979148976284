import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg';
const NotFound = memo(function NotFound(props) {
    return (
        <ReactSVG src="/images/error-illustration.svg" />
    )
})

NotFound.propTypes = {

}

export default NotFound
