import React, {Fragment, useState,useContext,useEffect} from 'react';
import {IconButton,FormControl,Grid,TextField, OutlinedInput, FormGroup,MenuItem} from '@mui/material';
import './WebComponents.css';
import Previsualiser  from './Previsualiser';
import WidthForm  from './WidthForm';
import WebComponentModal  from './WebComponentModal';
import SausageButton from '../../components/coa/SausageButton';
import { useTranslate } from 'react-polyglot';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BackgroundForm from './BackgroundForm';
import { OverlaysContext } from '../../context/OverlaysContext';

//   @State() hover;

//   @Event() clickCustomFunction: EventEmitter;

function CowcMenuTemplate() {
    const t = useTranslate();
    
    //se utiliza para saber que variable mostrar (Es la variable seleccionada)
    const {variableSelected, setVariableSelected} = useContext(OverlaysContext);
   
    //se utiliza para mostrar el modal que tiene los datos para copiar el codigo
    const [openModal, setOpenModal] = useState(false);

    //variables
    const [visible, setVisible] = useState(true);

    const [position, setPosition] = useState("left");
    const [header, setHeader] = useState();
    const [buttonShape, setButtonShape] = useState("circle");
    const [type, setType] = useState("list");
   
    const [srcIcon, setSrcIcon] = useState("https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-menu.png");
    const [srcIconAlt, setSrcIconAlt] = useState("https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-close.png");
    const [srcIconHover, setSrcIconHover] = useState("https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-menu.png");
    const [primaryColor, setPrimaryColor] = useState()
    const [secondaryColor, setSecondaryColor] = useState()
    const [height, setHeight] = useState(40);
    const [heigthTypeSelected, setHeigthTypeSelected] = useState("px");
   
    const [width, setWidth] = useState("100");
    const [widthTypeSelected, setWidthTypeSelected] = useState("px");

    const [font, setFont] = useState("Arial"); //fixed en real

    const [transitionDirect, setTransitionDirect] = useState();
    const [top, setTop] = useState();
    const [cowcMenuItems, setCowcMenuItems] = useState([
        {
            href:"" ,
            tab:"",
            textAlign:"" ,
            textColor: "" ,
            text: "",
            collapse: true,
            index: 0,
        }
    ])
    
    const optionsVariables = [
        {label: t("overlays.properties.size"), value : t("overlays.properties.size")},
        {label: t("overlays.properties.position"), value : t("overlays.properties.position")},
        {label: t("overlays.properties.personalizationButton"), value : t("overlays.properties.personalizationButton")},
        {label: t("overlays.properties.items"), value : t("overlays.properties.items")},
    ]
    

    //Es la funcion que se utiliza para devolver el codigo que se va mostrar en el modal y que se va a utilizar
    //para copiar al clipboard
    const getScript = () =>{
        const script = 
    `<cowc-menu
        button-position="absolute"
        z-index="1900"
        visible=${visible} 
        position=${position} 
        header=${header}
        button-shape=${buttonShape}
        type=${type}
        width=${width+widthTypeSelected} 
        height=${height+heigthTypeSelected} 
        src-icon=${srcIcon}
        src-icon-alt=${srcIconAlt}
        src-icon-hover=${srcIconHover}
        font=${font} 
        transition-direction=${transitionDirect} 
        top=${top+"px"}
        id="menu-header" 
    >
        ${getItemsArrayString()}
    </cowc-menu>`
        return script;
    }

    const getItemsArrayString = () => {
        let cowcMenuItemsString = ``;
        cowcMenuItems.forEach(mi=>{
            cowcMenuItemsString = cowcMenuItemsString + `<cowc-menu-item
    href=${mi.href} 
    tab=${mi.tab}
    text-align=${mi.textAlign}
    text-color=${mi.textColor} 
    >${mi.text}
</cowc-menu-item>
`
        });
        return cowcMenuItemsString;
    }

    


      const handleChangeItemHref= (e, index) => {
        let newItemArray = cowcMenuItems.slice();
        newItemArray[index].href = e.target.value;
        setCowcMenuItems(newItemArray)
      }

      const handleChangeItemTab= (e, index) => {
        let newItemArray = cowcMenuItems.slice();
        newItemArray[index].tab = e.target.value;
        setCowcMenuItems(newItemArray)
      }

      const handleChangeItemTextAlign= (e, index) => {
        let newItemArray = cowcMenuItems.slice();
        newItemArray[index].textAlign = e.target.value;
        setCowcMenuItems(newItemArray)
      }

      const handleChangeItemTextColor= (e, index) => {
        let newItemArray = cowcMenuItems.slice();
        newItemArray[index].textColor = e.target.value;
        setCowcMenuItems(newItemArray)
      }

      const handleChangeItemText= (e, index) => {
        let newItemArray = cowcMenuItems.slice();
        newItemArray[index].text = e.target.value;
        setCowcMenuItems(newItemArray)
      }



      const handleClickAddItemButton = () =>{
        setCowcMenuItems([...cowcMenuItems,{
            href:"" ,
            tab:"",
            textAlign:"" ,
            textColor: "" ,
            text:"",
            collapse:true,
            index: cowcMenuItems.length
        }])
    }
    
    
    const  handleClickDeleteteItemButton = (item) =>{
        if(cowcMenuItems.length >= 2){
            let newArray =  cowcMenuItems.slice();
            newArray = newArray.filter((step) => (step.index != item.index ))
            setCowcMenuItems(newArray)
        }
    }
    useEffect(() => {
        if(cowcMenuItems.length === 0){
            setCowcMenuItems([{
                href:"" ,
                tab:"",
                textAlign:"" ,
                textColor: "" ,
                text:"",
                collapse:true
            }])
        }
    },[cowcMenuItems])

    const renderVariableSelected = () => {
        
        switch(variableSelected){
            case t("overlays.properties.size"): return (
                <WidthForm label={t("overlays.properties.size") + " (px)"} value={width} width={width} type={widthTypeSelected} onChange={ 
                    e => {
                        if(isNaN(e.target.value)){
                            setWidthTypeSelected(e.target.value);
                            const scaleWidth = e.target.value == "%" ? width : width;
                            setWidth(scaleWidth);
                        }else{
                            const scaleWidth = widthTypeSelected == "%" ? e.target.value : e.target.value;
                            setWidth(scaleWidth) //lo divido por 3 para que quede a escala
                        }
                    }
                }></WidthForm>
            ) ;
            case t("overlays.properties.personalizationButton"): return(
                <>
                <Grid className='item-container' container spacing={2}>
                     <TextField
                        size='small' 
                        error={srcIcon.length ? false : true}
                        placeholder='https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-menu.png' 
                        className='item-overlay' 
                        id="primary-background-url" 
                        label={t("overlays.properties.primaryBackgroundUrl")} 
                        variant="outlined" 
                        value={srcIcon}
                        onChange={ e=>{setSrcIcon(e.target.value)}}/>
                </Grid>
                <Grid className='item-container' container spacing={2}>
                    <TextField 
                        className='item-overlay' 
                        size='small' 
                        error={srcIconHover.length ? false : true}
                        placeholder='https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-menu.png' 
                        id="secondary-background-url" 
                        label={t("overlays.properties.secondaryBackgroundUrl")} 
                        variant="outlined" 
                        value={srcIconHover}
                        onChange={ e=>{setSrcIconHover(e.target.value)}}/>
                </Grid>
                <Grid className='item-container' container spacing={2}>
                    <TextField 
                        className='item-overlay' 
                        size='small' 
                        error={srcIconAlt.length ? false : true}
                        placeholder='https://s3.amazonaws.com/vr.camonapp.com/disney-asia-assets/btn-close.png'
                        id="terciary-background-url" 
                        label={t("overlays.properties.terciaryBackgroundUrl")} 
                        variant="outlined" 
                        value={srcIconAlt}
                        onChange={ e=>{setSrcIconAlt(e.target.value)}}/>
                        
                </Grid>
                {/* <Grid xs={6} className='item-overlay' container spacing={2} sx={{flexDirection: "column"}}>
                    <BackgroundForm 
                        color = {primaryColor}
                        hiddenOpacity="false"
                        onChange={ (e)=>{setPrimaryColor(e.target.value)}}>
                    </BackgroundForm>
                    <ColorPicker defaultColor={primaryColor} handleColor={(color) => setPrimaryColor(color)}></ColorPicker>
                </Grid>
                <Grid xs={6} className='item-overlay' container spacing={2} sx={{flexDirection: "column"}}>
                    <BackgroundForm 
                        color = {secondaryColor}
                        hiddenOpacity="false"
                        onChange={ (e)=>{setSecondaryColor(e.target.value)}}>
                    </BackgroundForm>
                </Grid> */}
                </>
            ) ;
            //TODO: Ver el tipo en el menu
           /*  case t("overlays.properties.type"): return(
                <Grid className='item-overlay' container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                                <TextField
                                    select
                                    id="type-select"
                                    value={type}
                                    label={t("overlays.properties.type")}
                                    onChange={e => {setType(e.target.value)}}
                                    >
                                       <MenuItem value="list">List</MenuItem>
                                       <MenuItem value="matrix">Matrix</MenuItem>
                                </TextField>       
                        </FormControl>   
                    </Grid>
                </Grid> 
            ) ; */
            case t("overlays.properties.position"): return(
                <>
                <Grid className='item-container' sx={{flexDirection: "column"}} container spacing={2}>
                    <TextField
                        className='item-overlay' 
                        size='small' 
                        value={position}
                        select
                        id="position-select"
                        label={t("overlays.properties.alignment")}
                        onChange={e => {setPosition(e.target.value)}}
                        >
                            <MenuItem value="right">{t("overlays.properties.rightPosition")}</MenuItem>
                            <MenuItem value="left">{t("overlays.properties.leftPosition")}</MenuItem>
                    </TextField>       
                </Grid>
                <Grid className='item-container' sx={{flexDirection: "column"}} container spacing={4}>
                    <TextField
                        className='item-overlay' 
                        size='small' 
                        value={transitionDirect}
                        select
                        id="transitionDirection-select"
                        label={t("overlays.properties.transitionDirection")}
                        onChange={e => {setTransitionDirect(e.target.value)}}
                        >
                            <MenuItem value="left">{t("overlays.properties.transitionRTL")}</MenuItem>
                            <MenuItem value="right">{t("overlays.properties.transitionLTR")}</MenuItem>
                    </TextField>       
                </Grid> 
                <Grid className='item-container' sx={{flexDirection: "column", marginTop: "30px"}} container spacing={2}>
                    <TextField  
                        className='item-overlay' 
                        size='small' 
                        value={top}
                        id="top" 
                        label={t("overlays.properties.top") + " (px)"}
                        onChange={ e=>{setTop(e.target.value)}}/>
                </Grid>
                </> 
            ) ;
            case t("overlays.properties.items"): return(
                <>
                <Grid className='item-container' container spacing={2}>
                    <TextField 
                        size='small' 
                        className='item-overlay'   
                        id="header" 
                        label={t("overlays.properties.header")} 
                        value={header}
                        onChange={ e=>{setHeader(e.target.value)}}/>
                </Grid>
                
                <Grid className='item-container' container spacing={2} xs={12}>
                    {cowcMenuItems.map((mi,index)=>{
                        console.log({mi})
                       return (
                       
                        <Fragment key={index}>
                                <Grid item xs={1}>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <AddCircleIcon  onClick={handleClickAddItemButton}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                        <DeleteIcon  onClick={() => {handleClickDeleteteItemButton(mi)}}/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField   
                                            size='small' 
                                            value={mi.href}
                                            id="menu-item-href" 
                                            label={t("overlays.properties.href")} 
                                            variant="outlined" 
                                            onChange={(e)=>{handleChangeItemHref(e,index)}}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                            <TextField
                                                size='small' 
                                                select
                                                value={mi.tab}
                                                labelId="menu-item-tab"
                                                id="menu-item-tab"
                                                label={t("overlays.properties.tab")}
                                                onChange={(e)=>{handleChangeItemTab(e,index)}}
                                                >
                                                <MenuItem value="new">{t("overlays.properties.new")}</MenuItem>
                                                <MenuItem value="current">{t("overlays.properties.current")}</MenuItem>
                                            </TextField>       
                                    </FormControl> 
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                            <TextField
                                                select
                                                size='small' 
                                                value={mi.textAlign}
                                                labelId="menu-item-text-align"
                                                id="menu-item-text-align"
                                                label={t("overlays.properties.textAlign")}
                                                onChange={(e)=>{handleChangeItemTextAlign(e,index)}}
                                                >
                                                <MenuItem value="left">{t("overlays.properties.rightPosition")}</MenuItem>
                                                <MenuItem value="center">{t("overlays.properties.centerPosition")}</MenuItem>
                                                <MenuItem value="right">{t("overlays.properties.leftPosition")}</MenuItem>
                                            </TextField>       
                                    </FormControl> 
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <TextField   
                                            size='small' 
                                            id="menu-item-text" 
                                            label={t("overlays.properties.text")} 
                                            variant="outlined" 
                                            value={mi.text}
                                            onChange={(e)=>{handleChangeItemText(e,index)}}/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <BackgroundForm
                                        color = {mi.textColor}
                                        hiddenOpacity={false}
                                        onChange={ (e)=>{handleChangeItemTextColor(e,index)}}>
                                     </BackgroundForm>
                                </Grid>
                                <Grid item xs={12} ></Grid>
                        </Fragment>
                       )
                    })}
                    
                    {/* <Grid item xs={1}>

                         <IconButton color="primary" aria-label="upload picture" component="span">
                            <AddCircleIcon/>
                        </IconButton>
                    </Grid>
                     */}
                </Grid>
                </>
            ) ;
           
            
        }
    }

    

    //todo pasar a componente este modal
    const handleClickExport = () => {
        setOpenModal(!openModal);
    }

    const handleClickCloseModal = () => {
        setOpenModal(false)
    }
    
      
    return (
        <Fragment>
            <Grid className='item-container' container spacing={2}>
            <TextField
                size='small' 
                label='Variables'
                select
                className='item-overlay'
                id="variables-select"
                input={<OutlinedInput label="VARIABLES" />}
                onChange={e => {setVariableSelected(e.target.value)}}
                >
                {optionsVariables.map(component => {
                    return <MenuItem value={component.value}>{component.label}</MenuItem>
                })}
            </TextField> 
            </Grid>       
            {renderVariableSelected()}
            <div className='previsualizer-container'>
            <Previsualiser>
            <cowc-menu 
            button-position="absolute"
            visible={visible} 
            position={position} 
            header={header}
            button-shape={buttonShape}
            type={type}
            width={width/3+widthTypeSelected} 
            height={height/3+heigthTypeSelected} 
            src-icon={srcIcon}
            src-icon-alt={srcIconAlt}
            src-icon-hover={srcIconHover}
            font={font} 
            transition-direction={transitionDirect} 
            top={top > 300 ? 300+"px" : top/3+"px"}
            id="menu-header" 
           >
                {cowcMenuItems.map(mi => {
                                return (
                                    <cowc-menu-item 
                                    href={mi.href} 
                                    tab={mi.tab}
                                    text-align={mi.textAlign}
                                    text-color={mi.textColor} 
                                    >{mi.text}
                                    </cowc-menu-item>
                                )
                            })}
                </cowc-menu>
            </Previsualiser>
            <FormGroup className="text-center"> 
                    <SausageButton
                        className="btn-export"
                        label={t("overlays.export")}
                        onClick={handleClickExport}
                        size="medium"
                        fsize="16px"
                        spacer="2px"
                        raised
                        fluid
                    />
            </FormGroup>
            </div>
            <WebComponentModal title="Cowc-footer-botton" open={openModal} onCloseModal={handleClickCloseModal}>{getScript()}</WebComponentModal>
        </Fragment>
    );
}

export default CowcMenuTemplate;
