import request from '../utils/request';
import { parseParams } from '../utils/url';

class UserService {
    static async getProfile() {
        return request.get(`${process.env.REACT_APP_KWM_ENDPOINT}/me`);
    }

    static async getUsers(paginate = false, page = 1, perPage = 5, paramsQuery) {
        const baseUrl = `${process.env.REACT_APP_KWM_ENDPOINT}/users`;
        if (paginate) {
            return request.get(`${baseUrl}?page=${page}&per_page=${perPage}&${parseParams(paramsQuery)}`);
        } else {
            return request.get(`${baseUrl}?${parseParams(paramsQuery)}`);
        }
    }
    //
    static async getUser(id) {
        const url = `${process.env.REACT_APP_KWM_ENDPOINT}/users/${id}`;
        const users = await request.get(url);
        const user = users.data.find((u) => { return u.id === id });
        return Promise.resolve(user);
    }
    static async signUp(params) {
        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/signup`, {
            body: params
        }, false);
    }
    static async addUser(params, companyid) {

        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyid}/users`, {
            body: params
        });
    }
    static async updateUser(params, companyid, userId) {
        return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyid}/users/${userId}`, {
            body: params
        });
    }
    static async updateProfile(params, companyid, userId) {
        return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyid}/users/${userId}`, {
            body: params
        });
    }

    static async updateSecProfile(params, companyid, userId) {
        return request.put(`${process.env.REACT_APP_KWM_ENDPOINT}/companies/${companyid}/users/${userId}/changePassword`, {
            body: params
        });
    }



    static async getProfesors() {
        const users = await UserService.getUsers(false);

        const profesors = users.data.filter((user) => {
            return user.userType.name === 'PROFESSOR';

        });

        return Promise.resolve({
            data: profesors
        });
    }
    static async confirmUser(params) {
        const users = await request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/confirm`, {
            body: params
        }, false);

        return Promise.resolve(users);
    }
    static async deleteAccount(profileId) {
        return request.delete(`${process.env.REACT_APP_KWM_ENDPOINT}/users/${profileId}`)
    }
    static async disableUser(userId) {
        return request.delete(`${process.env.REACT_APP_KWM_ENDPOINT}/users/${userId}/disable`)
    }
    static async updateLang(user, lang) {
        return request.postJson(`${process.env.REACT_APP_KWM_ENDPOINT}/users/${user}/updateLang`, {
            body: { lang }
        })

    }
}
export default UserService;
