import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Button} from '@rmwc/button';
import {CircularProgress} from '@rmwc/circular-progress';
import moment from 'moment';
import {useTranslate} from 'react-polyglot';
import AppContext from '../../components/app/AppContext';
import SubscriptionService from '../../services/Subscription';
import CancelSub from '../../components/actions/CancelSub';
import AlertDialog from '../../components/general/AlertDialog';
import {getExpirationDays} from '../../utils/user';

import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/circular-progress/circular-progress.css';
import Loading from "../../components/loading";

import SausageButton from '../../components/coa/SausageButton';

const Subscription = ({setModal}) => {
    const context = useContext(AppContext);
    const [subscribed, setSubscribed] = useState(false);
    const [subscription, setSubscription] = useState({});
    const [fetchingData, setFetchingData] = useState(true);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const t = useTranslate();

    //useEffect(() => {
        //context.setPage({...context.page, openSubDialog: false});
        // initialize state
        // openSubscriptionDialog();
        // getSubscription();
        /*  setSubscription({
              base: {},
              plan: {},
              payment: {billing_details: {name: '', address: {line1: '1'}}},
              invoices: []
          });
          setSubscribed(false);
          setFetchingData(false);*/
    //}, []);



    useEffect(() => {
        if (context.openSubPro){
            context.setPage({...context.page, openSubDialog: true});
        }
    }, [context.openSubPro]);

    function getSubscription() {
        context.setPage({...context.page, loading: true});
        SubscriptionService.get()
            .then(res => {
                if (res.data && res.data.status === 'active') {
                    Promise.all([
                        SubscriptionService.getPlan(),
                        SubscriptionService.getPaymentMethods(),
                        SubscriptionService.getInvoices()
                    ]).then(([response, payments, invoicesData]) => {
                        const invoicesList = invoicesData
                            .filter(f => !(f.items.some(fact => fact.description.includes('× view')) && f.amount_paid === 0));
                        setSubscription({
                            base: res.data,
                            plan: response.data,
                            payment: payments.data[0],
                            invoices: invoicesList
                        });
                        setSubscribed(true);
                        context.setPage({...context.page, loading: false});
                        setFetchingData(false);
                    })
                } else {
                    context.setPage({...context.page, loading: false});
                    setFetchingData(false);
                }
            });
    }

    function openSubscriptionDialog(event) {
        context.setPage({...context.page, openSubDialog: true});
    }

    async function cancelSubscription() {
        setLoading(true);
        try {
            await SubscriptionService.deleteSubscription();
            setOpen(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    function openCancelSuscription() {
        const actionsDialogData = {
            title: t('pages.account.subscription.modal.cancel.title'),
            componentData: null,
            component: CancelSub,
            componentKey: 1,
            action: cancelSubscription,
        };
        setModal({openModal: true, propsModal: actionsDialogData});
    }

    const getPlanContainer = () => {
        return (
            <Fragment>
                <div className="profile-item-label">
                    <span>{t('pages.account.subscription.labels.free')}</span>
                    <span>
                        {getExpirationDays(context.userData.created_at) <= 0 ?
                            `${t('pages.account.subscription.labels.expired')}`
                            : `${t('pages.account.subscription.labels.expiration')} ${getExpirationDays(context.userData.created_at)} ${t('pages.account.subscription.labels.days')}`}
                    </span>
                </div>
                <div className="profile-item-value">
                    <Button label={t('sidebar.footer.action')} raised className={'pro-button'}
                            onClick={openSubscriptionDialog}/>
                </div>
            </Fragment>
        )
    };

    const getPaymentMethodContainer = () => {
        return (<Fragment>
            <div className="profile-item-label">
                <span>{t('pages.account.subscription.labels.freePlan')}</span>
            </div>
        </Fragment>)
    };

    const getBillingInfoContainer = () => {
        return (<Fragment>
            <div className="profile-item-label">
                <span>{t('pages.account.subscription.labels.freePlan')}</span>
            </div>
        </Fragment>)
    };

    const getInvoiceInfoContainer = () => {
        return (<Fragment>
            <div className="profile-item-label">
                <span>{t('pages.account.subscription.labels.noPurchase')}</span>
            </div>
        </Fragment>)
    };

    function renderSubdata() {
        return (
            <section className={'profile-card-container'}>
                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.yourPlan')}</span>
                    </div>
                    <div className={'profile-item yourPlanContainer'}>
                        <div className="profile-item-label">
                            <p className={'freePlanSubsP'}>{t('pages.account.subscription.labels.profesionalSubscription')}</p>
                            {subscription.base.cancelled ?
                                <span
                                    className={'spanSubscription'}>{`${t('pages.account.subscription.labels.untilValid')} ${moment(subscription.base.ends_at).format('YYYY-MM-DD hh:mm a')}`}</span>
                                :
                                <span
                                    className={'spanSubscription'}>{subscription.plan.interval === 'year' ? t('pages.account.subscription.labels.planAnnual') : t('pages.account.subscription.labels.planMonthly')}</span>
                            }
                        </div>
                        <div className="profile-item-value">
                            {!subscription.base.cancelled && (
                                <SausageButton
                                    label={t('pages.account.subscription.labels.cancelButton')}
                                    onClick={openCancelSuscription}
                                    disabled={loading}
                                    icon={loading}
                                    secondary
                                    size="large"
                                    raised
                                />)}
                        </div>
                    </div>
                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.paymentMethod').toUpperCase()}</span>

                    </div>
                    <div className={'profile-item'}>
                        <div className="profile-item-label">
                            <span>{t('pages.account.subscription.labels.creditCard')}</span>
                        </div>
                        <div className="profile-item-value">
                            {`${subscription.payment.name} terminada en ${subscription.payment.last_numbers}`}
                        </div>
                    </div>

                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.billingInfo').toUpperCase()}</span>

                    </div>
                    <div className={'profile-item'}>
                        <div className="profile-item-label">
                            <span>{t('common.labels.nameSurname')}</span>
                        </div>
                        <div className="profile-item-value">
                            {subscription.payment.billing_details.name}
                        </div>
                    </div>
                    <div className={'profile-item'}>
                        <div className="profile-item-label">
                            <span>{t('pages.account.subscription.labels.address')}</span>
                        </div>
                        <div className="profile-item-value">
                            {`${subscription.payment.billing_details.address.line1}`}
                        </div>
                    </div>
                    <div className={'profile-item'}>
                        <div className="profile-item-label">
                            <span>{t('pages.account.subscription.labels.city')}</span>
                        </div>
                        <div className="profile-item-value">
                            {`${subscription.payment.billing_details.address.city}`}
                        </div>
                    </div>
                    <div className={'profile-item'}>
                        <div className="profile-item-label">
                            <span>{t('pages.account.subscription.labels.country')}</span>
                        </div>
                        <div className="profile-item-value">
                            {`${subscription.payment.billing_details.address.country}`}
                        </div>
                    </div>
                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.invoices').toUpperCase()}</span>

                    </div>
                    {subscription.invoices && subscription.invoices.map(invoice => {
                        return (
                            <div className={'profile-item'}>
                                <div className="profile-item-label">
                                    {localStorage.lang === 'es' ?
                                        <span>{`Subscripción ${invoice.items[0].interval === 'month' ? 'Mensual' : 'Anual'}`}</span> :
                                        <span>{`${invoice.items[0].interval === 'month' ? 'Monthly' : 'Annual'} Subscription `}</span>}

                                </div>
                                <div className="profile-item-value invoices">
                                    <a target="_blank" rel="noopener noreferrer" href={invoice.pdf}>
                                        {t('pages.account.subscription.labels.downloadInvoice')}
                                    </a>
                                    <span>{`${invoice.items[0].currency.toUpperCase()} ${invoice.items[0].amount / 100}`}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </section>
        )
    }

    function renderSubOption() {
        return (
            <section className={'profile-card-container'}>
                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.yourPlan')}</span>
                    </div>
                    <div className={'profile-item'}>
                        {fetchingData ? '-' : getPlanContainer()}
                    </div>
                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.paymentMethod')}</span>
                    </div>
                    <div className={'profile-item'}>
                        {fetchingData ? '-' : getPaymentMethodContainer()}
                    </div>
                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.billingInfo')}</span>
                    </div>
                    <div className={'profile-item'}>
                        {fetchingData ? '-' : getBillingInfoContainer()}
                    </div>
                </div>

                <div className={'profile-element'}>
                    <div className={'profile-title'}>
                        <span>{t('pages.account.subscription.labels.invoices')}</span>
                    </div>
                    <div className={'profile-item'}>
                        {fetchingData ? '-' : getInvoiceInfoContainer()}
                    </div>
                </div>
            </section>
        )
    }

    function renderContent() {
        if (subscribed) {
            return renderSubdata()
        } else {
            return renderSubOption()
        }
    }

    return (
        <>
            {renderContent()}
            <AlertDialog
                title={'Subscripción'}
                bodyMessage={t('pages.account.subscription.labels.subscriptionCancelled')}
                setOpen={setOpen}
                open={open}
                functionHandler={() => window.location.reload()}
            />
        </>
    )
};


export default Subscription
