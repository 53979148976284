import styled from 'styled-components';
import { Button } from '@rmwc/button';

import '@material/button/dist/mdc.button.css';

export const SectionContainer = styled.section`

    position: absolute;
    display:${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 6000;
`

export const ImgsContainer = styled.div`
  display: flex;
      
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 200px;
`
export const Title = styled.h3`
  font-family: 'Public Sans';
  font-size: '30px';
  font-weight: 700;
`
export const Paragraph = styled.p`
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
`
export const Rounded = styled(Button)`
  border-radius: '200px';
`