import React, {memo, useState} from 'react'
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-polyglot';

import './profileInfo.scss';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

import ErrorMessage from '../ErrorMessage/ErrorMessage'
import userService from "../../services/User";

const ProfileInfo = memo(function ProfileInfo(props) {
    const t = useTranslate();
    const [data, setData] = useState({
        name: props.data.name || '',
        surname: props.data.surname || '',
        phone: props.data.phone || '',
    });
    const [errorState, setErrorState] = useState(false);
    const [message, setMessage] = useState('');
    const handleInputChange = (e) => {
        const name = e.target.name;
        setData({
            ...data,
            [name]: e.target.value
        });
        props.onChange(data)
    };

    const phoneValid = () => {
        if (data.phone) {
            let regexp = /^[0-9\\+]+$/;
            return regexp.test(data.phone);
        }
        return true;
    };

    return (
        <div>
            <div className={'profile-item-field'}>
                <TextField outlined label={t('common.labels.name')} name="name" value={data.name} onChange={handleInputChange}
                           className={'fullwidth'}/>
            </div>
            <div className={'profile-item-field'}>
                <TextField outlined label={t('common.labels.surname')} name="surname" value={data.surname} onChange={handleInputChange}
                           className={'fullwidth'}/>
            </div>
            <div className={'profile-item-field'}>
                <TextField outlined label={t('common.labels.phone')} name="phone" value={data.phone} onChange={handleInputChange}
                           className={'fullwidth'}/>
            </div>
            {errorState ? <ErrorMessage message={message}/> : null}
            <Button label={t('actions.add')} onClick={(e) => {
                if (phoneValid()) {
                    e.preventDefault();
                    let formattedData = {...data, companyId: props.data.user.company.id};
                    userService.updateProfile(formattedData, props.data.user.company.id, props.data.user.id).then((res) => {
                        props.data.setProfileInfo(res);
                        props.data.closeModal();
                    });
                } else {
                    setErrorState(true);
                    setMessage(t('pages.account.userProfile.labels.phoneInvalid'))
                }
            }}>
            </Button>
            <Button label={t('actions.cancel')} onClick={(e) => {
                e.preventDefault();
                props.setOpen(false);
            }}>
            </Button>
        </div>
    )
})

ProfileInfo.propTypes = {}

export default ProfileInfo
