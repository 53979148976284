import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import ExperienceService from '../../services/Experience';
import ExperienceRequest from '../../Request/ExperienceRequest';
import DropInput from '../dropInput';
import CampaignService from '../../services/Campaign';
import AppContext from '../app/AppContext';


import Icon from '../icon';

import './dialog.scss';
function DialogContainer() {
    const context = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [type, setType] = useState('');
    const [experience, setExperience] = useState(null);
    const [project, setProject] = useState(null);
    const [name, setName] = useState("");
    const [inputFileValue, setInputFileValue] = useState("");

    const [projects, setProjects] = useState([]);

    const [fileValue, setFileValue] = useState('');
    const [filePath, setFilePath] = useState('');
    const [plainHover, setPlainHover] = useState(false);
    const [worldHover, setWorldHover] = useState(false);
    const [campaign, setCampaign] = useState({});

    useEffect(() => {
        if (context.page.newExperience) {
            setOpen(true);
        }
        if (context.page.editExperience) {
            setExperience(context.page.experience);
            setStep(2);
            setExperienceForm(context.page.experience);
            setOpen(true);
        }
        if (context.page.changeTargetExperience) {
            setExperience(context.page.experience);
            setStep(2);
            setExperienceForm(context.page.experience);
            setOpen(true);
        }
    })
    useEffect(() => {
        CampaignService.list()
            .then((res) => {
                setProjects(res.data);
            });
        return function () {
            setOpen(false);
        }
    }, [])

    const handleChange = set => evt => {
        set(evt.target.value);
    };

    function setExperienceForm(experience) {
        if (name === "") {
            setName(experience.name);
            setProject(experience.campaign);
            setInputFileValue(experience.target ? experience.target.src : "");
        }
    }
    function getFilePath(file) {
        if (file.files[0]) {
            const url = URL.createObjectURL(file.files[0]);
            setFilePath(url);
        } else {
            setFileValue(false);
        }

    }

    async function createExperience(e) {

        e.preventDefault();
        try {
            const params = getParams(e.target);
            if (context.page.changeTargetExperience) {
                const response = await ExperienceService.changeTarget(params);
            } else {

                const response = await ExperienceService.create(params);

                if (response) {
                    //TODO: Go to studio
                    setContextToFalse();
                    setOpen(false);
                }
            }

        } catch (error) {
            console.log(error);

        }

    }

    function setContextToFalse() {
        context.setPage({ ...context.page, newExperience: false, editTargetExperience: false, changeTargetExperience: false });
    }
    function getParams(form) {

        const formData = ExperienceRequest.getParams(form);

        if (formData.image && formData.image.size > 0) {
            formData.target = formData.image;
        }


        formData.type = 'world';
        formData.campaign = campaign;
        formData.targetless = formData.target ? false : true;
        return ExperienceRequest.make(formData);
    }

    function checkInput(e) {

    }

    function getCampaign(campaign) {
        setCampaign(campaign);
    }
    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('inputFile').click();

    }
    function getTitle() {
        if (context.page.changeTargetExperience) {
            return `Cambiar Target a experiencia ${experience.name}`;
        }
        else if (context.page.editExperience) {
            return `Editar experiencia ${experience.name}`;
        }
        else {
            return `Nueva experiencia  ${type === 'plain' ? 'plana' : 'mundo'} `;
        }
    }
    function getButtonTitle() {
        if (context.page.changeTargetExperience) {
            return 'Cambiar Target';
        }
        else if (context.page.editExperience) {
            return 'Editar Target';
        }
        else {
            return 'Crear experiencia';
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={evt => {
                    setContextToFalse()
                    setOpen(false);
                }}
                className={'dialog-full'}
            >
                <DialogTitle>
                    {step === 2 &&
                        <Icon name={'arrow-left'} onClickFn={() => { setStep(1) }} className={'new-experience-back'} />
                    }
                    
                </DialogTitle>
                <DialogContent>
                    <div className={'new-experience-title-container'}>
                        {step === 1 ?
                            <h2 className={'new-experience-title'}>
                                Elige un tipo de experiencia
                            </h2>
                            :
                            <h2 className={'new-experience-title'}>
                                {getTitle()}

                            </h2>
                        }

                    </div>
                    {step === 1 ?
                        <div className={'new-experience-type-container'} >

                            <div className={'new-experience-type'} onMouseEnter={() => { setPlainHover(true) }} onMouseLeave={() => { setPlainHover(false) }}>
                                <div className={'new-experience-type-header'}>
                                    <div className={'placeholder'}>

                                    </div>
                                </div>
                                <div className={'new-experience-type-title'}>
                                    <span>Plana</span>
                                </div>
                                <div className={'new-experience-type-text'}>
                                    <p>
                                        experiencias de tipo plana
                                </p>
                                </div>
                                <div className={'new-experience-type-button'}>
                                    <Button label="Crear una experiencia plana" className={'new-experience-button'} outlined={!plainHover} raised={plainHover} onClick={() => { setStep(2); setType('plain') }} />
                                </div>
                            </div>
                            <div className={'new-experience-type'} onMouseEnter={() => { setWorldHover(true) }} onMouseLeave={() => { setWorldHover(false) }}>
                                <div className={'new-experience-type-header'}>
                                    <div className={'placeholder'}>

                                    </div>
                                </div>
                                <div className={'new-experience-type-title'}>
                                    <span>Mundo</span>
                                </div>
                                <div className={'new-experience-type-text'} >
                                    <p>
                                        experiencias de tipo mundo
                                </p>
                                </div>
                                <div className={'new-experience-type-button'}>
                                    <Button label="Crear una experiencia mundo" className={'new-experience-button'} outlined={!worldHover} raised={worldHover} onClick={() => { setStep(2); setType('world') }} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className={'new-experience-type-container'}>
                            <div className={'new-experience-form'}>
                                <form onSubmit={createExperience}>
                                    {!context.page.changeTargetExperience &&
                                        <React.Fragment>
                                            <input type="hidden" name="campaign[name]" value={campaign.name} />
                                            <input type="hidden" name="campaign[id]" value={campaign.id} />
                                            <div className={'form-control'}>
                                                <TextField name={'name'} value={name} outlined label="Nombre de la experiencia" required onChange={handleChange(setName)} />
                                            </div>
                                            <div className={'form-control file-container'}>
                                                <DropInput
                                                    label="Crear un proyecto o agrega la experiencia aun proyecto existente"
                                                    outlined
                                                    className={'new-experience-file-input'}
                                                    icon={<Icon name={'campaings'} className={'new-experience-file-input-icon'} />}
                                                    onChangeFn={getCampaign}
                                                    data={projects}
                                                    project={project}
                                                />

                                                {//<span className={'file-input-value'}>{fileValue.split('\\').reverse()[0]}</span>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }

                                    <div className={'form-control preview-file'}>
                                        <div className={'new-experience-preview' + (fileValue ? ' file-selected' : '')} onClick={() => {
                                            if (fileValue) {
                                                document.getElementById('inputFile').click();

                                            }
                                        }}>
                                            <input id="inputFile" type="file" name="image" style={{ display: 'none' }} value={inputFileValue} onChange={(e) => { setInputFileValue(e.target.value); setFileValue(true); getFilePath(e.target); checkInput(e) }} />

                                            {fileValue ?
                                                <img src={filePath} alt={'fileValue'} className={'new-experience-preview-image'} />
                                                :
                                                <div className={'image-input'}>
                                                    <div className={'label-input-image'}>
                                                        <span>Agrega o arrastra el target (</span> <Icon name={'scan'} className={'scan-icon'} /> <span> ) que activará tu experiencia</span>
                                                    </div>
                                                    <div className={'label-secondary'}>
                                                        <span>Formatos de target .jpg </span>
                                                    </div>
                                                    <div>
                                                        <Button outlined label={'Agregar Target'} className={'new-experience-button'} onClick={triggerFile} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={'new-experience-preview-text'}>
                                            <p className={'title'}>
                                                Tips to create a printed support with good detection quality:
                                            </p>
                                            <p className={'description'}>
                                                Variety of forms and contrasts, Designs with angular shapes that generate vertices, Well-defined figures and backgrounds, Graphic contents in most of the surface of the composition.
                                            </p>
                                            <p className={'footer'}>
                                                Para más información sobre los targets visitá nuestra <span className="link">Página de soporte</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'form-control'}>
                                        <Button type="submit" label={getButtonTitle()} raised className={'login-button experience-button'} />
                                    </div>
                                </form>

                            </div>
                        </div>
                    }

                </DialogContent>
            </Dialog>
        </>
    );
}

export default DialogContainer;