import React, {createContext, useContext, useEffect, useState} from 'react';
import ChangeTargetComponent from "../components/actionsDialog/ChangeTargetComponent";
import AppContext from "../components/app/AppContext";
import ExperienceService from "../services/Experience";
import {Alert} from "rsuite";
import {useTranslate} from "react-polyglot";

export const ChangeTargetDialogContext = createContext();

const ChangeTargetDialogProvider = (props) => {
    const [openChangeTargetDialog, setOpenChangeTargetDialog] = useState(false);
    const [payload, setPayload] = useState({id: '', file: null});
    const ChangeTargetDialog = () => (<ChangeTargetComponent/>);
    const context = useContext(AppContext);
    const t = useTranslate();
    const onChangeTarget = async () => {
        try {
            context.setPage({...context.page, loading: true});
            let formData = new FormData();
            formData.append('target', payload.file);
            formData.append('id', payload.id);
            let result = await ExperienceService.update(formData, payload.id);
            if (result) {
                Alert.success(t('Cambio de target exitoso'));
                context.setPage({...context.page, loading: false});
                window.location.reload();
            }
        } catch (e) {
            context.setPage({...context.page, loading: false});
            Alert.error(t('Error al cambiar de target'));
        }
    };

    return (<ChangeTargetDialogContext.Provider
        value={{
            openChangeTargetDialog,
            setOpenChangeTargetDialog,
            payload,
            setPayload,
            ChangeTargetDialog,
            onChangeTarget
        }}>{props.children}</ChangeTargetDialogContext.Provider>)
};

export default ChangeTargetDialogProvider;