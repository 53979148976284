import React, {Fragment, useState} from "react";
import userService from '../../services/User';
import {navigate} from '@reach/router';
import {TextField} from '@rmwc/textfield';
import SingLayout from '../signLayout';
import Icon from '../icon';
import {Button} from '@rmwc/button';
import {CircularProgress} from '@rmwc/circular-progress';
import {translate} from 'react-polyglot';
import {Row, Col} from "react-flexbox-grid";
import {Elevation} from '@rmwc/elevation';
import {ControlLabel, Input, InputGroup, Icon as RIcon} from "rsuite";

import SusageButton from '../coa/SausageButton';

import '@material/textfield/dist/mdc.textfield.css';

import SausageButton from './../../components/coa/SausageButton';


import './signup.css';
import RegisterHeader from "../headers/RegisterHeader";
import {Checkbox} from "rsuite";

const BASE_ERROR = 'pages.signUp.errors';
const ERROR_MAP = [
    {
        msg: 'Member must have length greater than or equal to 6',
        key: `${BASE_ERROR}.passwordLength`,
        type: 'password'
    },
    {
        msg: 'passwordNotMatch',
        key: `${BASE_ERROR}.passwordNotMatch`,
        type: 'password'
    },
    {
        msg: 'An account with the given email already exists.',
        key: `${BASE_ERROR}.accountExist`,
        type: 'email'
    },
    {
        msg: 'Password does not conform to policy: Password must have symbol characters',
        key: `${BASE_ERROR}.characters`,
        type: 'password'
    },
    {
        msg: 'Password did not conform with policy: Password must have uppercase characters',
        key: `${BASE_ERROR}.passwordMayus`,
        type: 'password'
    },
    {
        msg: 'Password did not conform with policy: Password must have lowercase characters',
        key: `${BASE_ERROR}.passwordMinus`,
        type: 'password'
    },
    {
        msg: 'Password did not conform with policy: Password not long enough',
        key: `${BASE_ERROR}.passwordLength`,
        type: 'password'
    },
    {
        msg: 'Password did not conform with policy: Password must have symbol characters',
        key: `${BASE_ERROR}.passwordSymbol`,
        type: 'password'
    }
]

class CustomSignUp extends React.Component {
    constructor(props) {
        super(props);

        this.gotoSignIn = this.gotoSignIn.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            inputTypePass: 'password',
            inputTypeConfirm: 'password',
            code: (new URLSearchParams(window.location.search)).get('tag'),
            loading: false,
            logged: false,
            error: false,
            errorMessage: '',
            errorMessageMail: '',
            errorMessagePassword: '',
            errorMessageConfirmPassword: '',
            errorClassPassword: '',
            errorClassPasswordLabel: '',
            errorClassMail: '',
            errorClassMailLabel: '',
            errorClassConfirmP: '',
            checked: false
        }
    }

    async submit(e) {
        e.preventDefault();
        this.setState({
            loading: true
        });
        try {
            const form = new FormData(e.target);
            let formData = {};
            Array.from(form.entries()).forEach((d) => {
                formData[d[0]] = d[1];
            });

            if (formData.password === '') {
                this.setState({errorMessagePassword: 'pages.signUp.errors.passwordReq'});
                this.setState({errorClassPassword: 'inputCoAError'});
                this.setState({errorClassPasswordLabel: 'inputCoALabelError'})

            } else {
                this.setState({errorMessagePassword: ''});
                this.setState({errorClassPassword: ''});
                this.setState({errorClassPasswordLabel: ''})
            }

            if (formData.email === '') {
                this.setState({errorMessageMail: 'pages.signUp.errors.emailReq'});
                this.setState({errorClassMail: 'inputCoAError'});
                this.setState({errorClassMailLabel: 'inputCoALabelError'})
            } else {
                this.setState({errorMessageMail: ''});
                this.setState({errorClassMail: ''});
                this.setState({errorClassMailLabel: ''})
            }

            if (formData.passwordConfirm === '') {
                this.setState({errorMessageConfirmPassword: 'pages.signUp.errors.confirmPassReq'});
                this.setState({errorClassConfirmP: 'mdc-text-field--invalid colorRedLabel'})
            } else {
                this.setState({errorMessageConfirmPassword: ''});
                this.setState({errorClassConfirmP: ''});
            }

            formData.email = formData.email.toLowerCase();
            formData['code'] = this.state.code;

            await userService.signUp(formData);


            this.gotoSignIn();
        } catch (e) {
            this.handleError(e);
            this.setState({
                error: true
            })

        } finally {
            this.setState({
                loading: false
            });
        }
    }

    handleError(error) {
        const err = ERROR_MAP.find(e => error.message.includes(e.msg))
        if (err.type === 'password') {
            this.setState({errorMessagePassword: err ? err.key : ''});
            this.setState({errorClassPassword: 'inputCoAError'});
            this.setState({errorClassPasswordLabel: 'inputCoALabelError'})

        } else {
            this.setState({errorMessageMail: err ? err.key : ''});
            this.setState({errorClassMail: 'inputCoAError'});
            this.setState({errorClassMailLabel: 'inputCoALabelError'})
        }
    }

    gotoSignIn() {
        this.props.onStateChange('signIn', {confirmation: true});
        navigate('/confirmationSend')
    }

    gotoLogin = () => {
        this.setState({
            inputTypePass: 'password',
            inputTypeConfirm: 'password',
            code: (new URLSearchParams(window.location.search)).get('tag'),
            loading: false,
            logged: false,
            error: false,
            errorMessage: '',
            errorMessageMail: '',
            errorMessagePassword: '',
            errorMessageConfirmPassword: '',
            errorClassPassword: '',
            errorClassPasswordLabel: '',
            errorClassMail: '',
            errorClassMailLabel: '',
            errorClassConfirmP: '',
            checked: false
        });
        this.props.onStateChange('signIn', {});
    };

    render() {
        const {t} = this.props;
        if (this.props.authState === 'signUp') {
            return (
                <Fragment>
                    <RegisterHeader/>
                    <SingLayout type={'signUp'} base={
                        <div className="sign-up-container">
                            <Col md={12}>
                                <p className={'sign-in-title'} style={{width:'600px'}}>
                                    {t('pages.signUp.registerInStudio')}
                                </p>
                            </Col>
                            <form className={'sign-form'} onSubmit={this.submit}>
                                <div className={'form-control'}>
                                    <ControlLabel style={{marginBottom: '8px'}}
                                                  className={`labelInput ${this.state.errorClassMailLabel}`}>{t('common.labels.email')}</ControlLabel>
                                    <Input className={`inputCoA ${this.state.errorClassMail}`} tabIndex="1"
                                           name={'email'} type={'email'}
                                           placeholder={t('common.labels.email')}/>
                                    <span className={'error'}>{t(this.state.errorMessageMail)}</span>
                                </div>
                                <div className={'form-control-signUP'}>
                                    <ControlLabel style={{marginBottom: '8px'}}
                                                  className={`labelInput  ${this.state.errorClassPasswordLabel}`}>{t('common.labels.password')}</ControlLabel>
                                    <InputGroup className={`inputGroupCoA ${this.state.errorClassPassword}`}>
                                        <Input tabIndex="2" name={'password'}
                                               type={this.state.inputTypePass}
                                               placeholder={t('common.labels.password')}/>
                                        <InputGroup.Button onClick={() => {
                                            this.setState(
                                                {
                                                    inputTypePass: this.state.inputTypePass === 'password' ? 'text' : 'password'
                                                }
                                            )
                                        }}>
                                            <RIcon icon="eye"/>
                                        </InputGroup.Button>
                                    </InputGroup>
                                    <span className={'error'}>{t(this.state.errorMessagePassword)}</span>
                                </div>
                                {this.state.errorMessage && <div className="form-control">
                                    <span className={'error'}
                                          style={{textAlign: 'center'}}>{t(this.state.errorMessage)}</span>
                                </div>}
                                <div className={'sign-up-footer-container'} style={{marginBottom: '15px'}}>
                                    <div>
                                        <Checkbox checked={this.state.checked} onChange={(e, checked) => {
                                            this.setState({'checked': checked})
                                        }}/>
                                    </div>
                                    <span className={'sign-up-footer'}>{t('pages.signUp.conditions')}<a
                                        className={'marginRef'}
                                        href={'https://camonapp.com/terms-privacy/'}>{t('pages.signUp.conditionsTwo')} </a> {t('pages.signUp.conditionsAnd')}
                                        <a className={'marginRef'}
                                           href={'https://camonapp.com/terms-privacy/'}> {t('pages.signUp.politicies')}</a></span>
                                </div>
                                <div className={'form-control'}>

                                    {(this.state.loading || this.state.checked) && <SusageButton type="submit"
                                            label={this.state.loading ? '' : t('pages.signUp.createFreeAccount')}
                                            disabled={this.state.loading || !this.state.checked}
                                            icon={this.state.loading && 'icon'} 
                                            fluid
                                            size="large"
                                            name="password"
                                    />}
                                    <div className={'sign-in-actions'}>
                                        <span className={'new'}>{t('pages.signUp.haveAnAccount')}</span> <span
                                        className={'sign-up'} onClick={this.gotoLogin}>{t('pages.signUp.enter')}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }/>
                </Fragment>

            );
        } else {
            return null;
        }
    }
}

export default translate()(CustomSignUp);
