import React, {useEffect,useState,useContext} from "react";
import InputFile from "../InputFile";
import {useTranslate} from 'react-polyglot';
import CustomMonoriel from "../../services/CustomMonoriel";
import { DialogActions, DialogButton } from '@rmwc/dialog';
import ShowLoadingContext from '../../context/ShowLoadingContext';
import "./editAR.scss"
import AlertDialog from '../general/AlertDialog';
export default function ChangeLoading (props){
    const t = useTranslate();
    const [imageURL, setImageURL] = useState(null)
    const [file, setFile] = useState (null)
    const { setShowLoading } = useContext(ShowLoadingContext)
    const [open,setOpen] = useState({
        title: '',
        subtitle: '',
        isOpen: false
    })

    useEffect(()=> {
        console.log(props.data)
        getBackgroundAR()
    },[])
    async function getBackgroundAR() {
        try {
            setShowLoading(true)
            await CustomMonoriel.getCustomMonorielConfig(props.data)
            .then(response=> response.json())
            .then(data => {
              if(data.hasLoadingURL == "true"){
                setImageURL(`${process.env.REACT_APP_MONORAIL_BUCKET}/${props.data}/mr-loading.jpeg`)
              }
            });
        } catch (error) {
            throw new Error(error);
        } 
        setShowLoading(false)
    }
    async function changeLoadingAR() {
        try {
            setShowLoading(true)
            const update = new FormData()
            update.append("hasLoadingURL", file != null ? true : false)
            update.append("loading", file)
            await CustomMonoriel.updateCustomMonorielConfig(props.data,update)
            .then(rta => {
               setShowLoading(false)
               setOpen({
                title: t('experiences.success'),
                subtitle: t('experiences.successBody'),
                isOpen: true
               })
            }) 
        } catch (error) {
            throw new Error(error);
        } 
    }

    return (
        <div className="edit-container">
            <div style={{width:"100%", marginBottom:"20px", marginTop:"20px"}}>
            </div>
            <InputFile
                defaultFile={imageURL} 
                handleFile={(file) => setFile(file)} 
                handleURL={(url) => setImageURL(url)} 
                handleQuitFile={(file) => setFile(null)}>
            </InputFile>            
            <DialogActions style={{width:"100%",justifyContent:"flex-end"}}>
                <DialogButton 
                    action="close">{t('actions.cancel')}
                </DialogButton>
                <DialogButton
                    action="accept" 
                    isDefaultAction 
                    onClick={() => {changeLoadingAR()}}>{t('actions.accept')}
                </DialogButton>
            </DialogActions>
            <AlertDialog
                title={open.title}
                bodyMessage={open.subtitle}
                setOpen={setOpen}
                open={open.isOpen}
                buttonMessage='OK'
            />  
        </div>
    )
}